import styles from './SupportTicketList.module.scss'
import { ISupportTicket } from '@/store/supportTickets/supportTickets'
import { IClass } from '@/interfaces/general'
import SupportTicketCard from '@/components/SupportTicket/SupportTicketCard/SupportTicketCard'
import classNames from 'classnames'
import CirclePreloader from '@/components/Primitive/CirclePreloader/CirclePreloader'
import { useCallback, useEffect, useRef } from 'react'

interface SupportTicketListProps extends IClass {
  tickets: ISupportTicket[]
  fullLoaded?: boolean
  loading?: boolean
  onChange?: (ticket: ISupportTicket) => void
  onMoreLoad?: () => void
}

const SupportTicketList = (
  {
    tickets,
    className,
    fullLoaded = false,
    loading,
    onChange,
    onMoreLoad
  }: SupportTicketListProps) => {
  const checkEndObserver = useRef<IntersectionObserver | null>(null)
  const checkEndElement = useRef<HTMLDivElement>(null)

  const checkEndCb: IntersectionObserverCallback = useCallback((entry, observer) => {
    const item = entry[0]
    if (!item) {
      return
    }
    if (item.isIntersecting) {
      onMoreLoad?.()
    }
  }, [onMoreLoad])

  useEffect(() => {
    if (!checkEndElement.current) {
      return
    }
    checkEndObserver.current = new IntersectionObserver(checkEndCb, {
      root: document.querySelector(styles.box)
    })
    checkEndObserver.current?.observe(checkEndElement.current)
    return () => {
      checkEndObserver.current?.disconnect()
      checkEndObserver.current = null
    }
  }, [checkEndCb])

  return <div className={styles.box}>
    <ul className={classNames(styles.list, className)}>
      {tickets.map(ticket => <SupportTicketCard
        key={ticket.id}
        TagName='li'
        className={styles.ticketCard}
        ticket={ticket}
        onClick={() => onChange?.(ticket)}
      />)}
    </ul>
    {loading && <CirclePreloader
      className={styles.preloader}
      withoutProgress
      color={'var(--main-color)'}
    /> }
    {!fullLoaded && <div
      ref={checkEndElement}
      className={styles.checkEnd}
    />}
  </div>
}

export default SupportTicketList
