import {IMessageJSON, IMessageType} from '@/interfaces/XMPP';

export interface PingProps {
  from: string
}

export interface IPromiseObj {
  resolve: (value?: unknown) => void,
  reject: (value?: unknown) => void,
}

export enum ConnectionEventNames {
  StatusChanged = 'StatusChanged',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  ConnectedChanged = 'ConnectedChanged'
}

export interface ConnectionEventMap {
  [ConnectionEventNames.StatusChanged]: (status: Strophe.Status) => void
  [ConnectionEventNames.Connected]: () => void
  [ConnectionEventNames.Disconnected]: () => void
  [ConnectionEventNames.ConnectedChanged]: (connected: boolean) => void
}

export type CbList = {
  [key in ConnectionEventNames]: ConnectionEventMap[key][];
};

export interface IMessagePromise {
  resolve: (value: IMessageJSON) => void
  reject: (reason?: any) => void
  timer: NodeJS.Timeout
}

export type IMessagePromises = {
  [key in IMessageType]?: {
    [key: string]: IMessagePromise
  }
}
