import {useCallback, useEffect, useRef, useState} from 'react';
import {useAppSelector} from '@/hooks/appHook';
import {getChat} from '@/store/chats/chats';
import {IMessage} from '@/store/messages/messages';
import useVCard from '@/hooks/useVCard';
import {getName} from '@/utils/chats';
import {getBlockList} from '@/store/blockList/blockList';
import { INews, isActiveNews, isNews } from '@/store/news/news'

interface GetNamesProps {
  message: IMessage | INews,
  incoming: boolean
}

const vCardRequest: Map<string, Promise<unknown>> = new Map()

const useGetNames = ({message, incoming}: GetNamesProps) => {
  const from = isNews(message) ? '' : message.from
  const forwardedFrom = isNews(message) ? '' : message.forwardedFrom
  const chat = useAppSelector(getChat(from))
  const isActiveNewsChat = useAppSelector(isActiveNews)
  const blocklist = useAppSelector(getBlockList)
  const [fromName, setFromName] = useState<string>(getName(chat, incoming) || '')
  const forwardedChat = useAppSelector(getChat(forwardedFrom || ''))
  const [forwardedName, setForwardedName] = useState(forwardedChat?.vcard?.nickname)
  const {getVCardWithQueue} = useVCard()
  const updateFromNameRef = useRef<() => void>()
  const promises = useRef<Set<Promise<unknown>>>(new Set())

  const promiseResolve = useCallback((promise: Promise<unknown>) => {
    promise.then(() => {
      promises.current.delete(promise)
      vCardRequest.delete(from)
      updateFromNameRef.current?.()
    }).finally(() => {
      promises.current.delete(promise)
      vCardRequest.delete(from)
    })
  }, [from])

  updateFromNameRef.current = useCallback(() => {
    if (!from) {
      return
    }
    let promise = vCardRequest.get(from)
    if (promise) {
      if (promises.current.has(promise)) {
        return
      }
      promises.current.add(promise)
      promiseResolve(promise)
      return;
    }
    let name = getName(chat, incoming)
    if (name == null) {
      const blockedUser = blocklist.find(user => user.jid === from)
      if (blockedUser) {
        name = blockedUser.name
      } else {
        promise = getVCardWithQueue({jid: from, immediately: true})
        vCardRequest.set(from, promise)
        promises.current.add(promise)
        promiseResolve(promise)
        return;
      }
    }
    if (name !== fromName && !vCardRequest.has(from)) {
      setFromName(name || '')
    }
  }, [from, promiseResolve, chat, getVCardWithQueue, incoming, blocklist, fromName])

  useEffect(() => {
    if (isActiveNewsChat) {
      return
    }
    updateFromNameRef.current?.()
  }, [from, chat, incoming, blocklist, isActiveNewsChat])

  useEffect(() => {
    if (isActiveNewsChat) {
      return
    }
    if (forwardedFrom && !forwardedChat?.vcard?.nickname) {
      getVCardWithQueue({jid: forwardedFrom, immediately: true})
    }
  }, [forwardedChat, getVCardWithQueue, forwardedFrom, isActiveNewsChat])

  useEffect(() => {
    if (isActiveNewsChat) {
      return
    }
    if (forwardedChat?.$jid && forwardedChat.nick) {
      setForwardedName(forwardedChat.nick || '')
    } else {
      setForwardedName(forwardedChat?.vcard?.nickname || '')
    }
  }, [forwardedChat, isActiveNewsChat])

  return {forwardedName, fromName}
}

export default useGetNames
