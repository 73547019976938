import {CacheEvent} from '@/interfaces/general';
import {ILastMessages} from '@/store/cache/lastMessage';
import {getAllMessages, IMessage, isNotBreakMessage} from '@/store/messages/messages';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {useCallback, useEffect, useRef} from 'react';
import {set as setMessagesCache} from '@/store/cache/lastMessage'

interface IField {
  jid: string
  message: IMessage
}

interface ILastMessageProps {
  watch: boolean
}

const lastMessageDB = indexedDB.open('last-message-cache', 1)

const UPDATED_CACHE_EVENT = CacheEvent.UPDATED_LAST_MESSAGE_CACHE_EVENT
const DELAY_UPDATE_TIMEOUT = 1000

const updatedCacheEvent = new Event(UPDATED_CACHE_EVENT)

let db: IDBDatabase
const lastMessagesCache: ILastMessages = {}
let cacheInited = false

const MESSAGE_STORE_NAME = 'lastMessage'

lastMessageDB.onupgradeneeded = (event) => {
  db = lastMessageDB.result
  switch (event.oldVersion) {
    case 0:
      db.createObjectStore(MESSAGE_STORE_NAME, {keyPath: 'jid'})
      break
  }
}

lastMessageDB.onsuccess = () => {
  db = lastMessageDB.result
  const transaction = db.transaction(MESSAGE_STORE_NAME)
  const lastMessages = transaction.objectStore(MESSAGE_STORE_NAME)
  const request = lastMessages.getAll()

  request.onsuccess = () => {
    request.result.forEach(({jid, message}: IField) => {
      lastMessagesCache[jid] = message
    })
    cacheInited = true
    window.dispatchEvent(updatedCacheEvent)
  }
}

const getChatStore = (write?: boolean) => {
  if (!db) {
    return
  }
  const transaction = db.transaction(MESSAGE_STORE_NAME, write ? 'readwrite': 'readonly')
  return transaction.objectStore(MESSAGE_STORE_NAME)
}

const updateCache = (messages: ILastMessages) => {
  const messagesCache = getChatStore(true)
  if (!messagesCache) {
    return
  }
  for (const messagesKey in messages) {
    messagesCache.put({
      jid: messagesKey,
      message: messages[messagesKey]
    })
  }
}

const useLastMessageCache = ({watch}: ILastMessageProps = {watch: false}) => {
  const dispatch = useAppDispatch()
  const messages = useAppSelector(getAllMessages)
  const delayUpdateTimer = useRef<NodeJS.Timer>()

  const clearCache = useCallback(() => {
    const messages = getChatStore(true)
    if (!messages) {
      return
    }
    messages.clear()
    dispatch(setMessagesCache({}))
  }, [dispatch])

  useEffect(() => {
    return () => {
      clearTimeout(delayUpdateTimer.current)
    }
  }, []);

  useEffect(() => {
    if (!watch) {
      return
    }
    const update = () => {
      dispatch(setMessagesCache({...lastMessagesCache}))
    }
    window.addEventListener(UPDATED_CACHE_EVENT, update)

    return () => {
      window.removeEventListener(UPDATED_CACHE_EVENT, update)
    }
  }, [dispatch, watch])

  useEffect(() => {
    if (!cacheInited || !watch) {
      return
    }
    for (const jid in messages) {
      const lastMessage = messages[jid]?.messages
        .filter(isNotBreakMessage)
        .reduce((accMessage: IMessage | undefined, message) =>
          accMessage && accMessage.timestamp > message.timestamp ? accMessage : message,
          undefined)
      if (lastMessage) {
        lastMessagesCache[jid] = lastMessage
      }
    }
    clearTimeout(delayUpdateTimer.current)
    delayUpdateTimer.current = setTimeout(() => {
      dispatch(setMessagesCache({...lastMessagesCache}))
      updateCache(lastMessagesCache)
    }, DELAY_UPDATE_TIMEOUT)
  }, [messages, dispatch, watch])

  return {clearCache}
}

export default useLastMessageCache
