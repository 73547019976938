import {createSlice} from "@reduxjs/toolkit";
import {RootState} from '@/store';

const initialState: {
  activeService: string | null
} = {
  activeService: null
}

const services = createSlice({
  name: 'services',
  initialState,
  reducers: {
    clear(state) {
      state.activeService = null
    },
    setActiveService(state, {payload}: { payload: string | null }) {
      state.activeService = payload
    }
  }
})

export const {
  clear,
  setActiveService,
} = services.actions

export const getActiveService = (state: RootState): string | null => state.services.activeService
export default services.reducer
