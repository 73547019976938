import styles from './RadioButtons.module.scss'
import {FunctionComponent, SVGProps, useEffect, useState} from 'react';
import classNames from 'classnames';

export interface RadioButtonItem {
  title: string,
  action: string
  SvgIcon?: FunctionComponent<SVGProps<SVGSVGElement>>
}

interface RadioButtonProps {
  list: RadioButtonItem[]
  value?: string
  initAction?: string
  disable?: boolean
  className?: string
  onChange?: (action: string) => void
}

interface InitActionProps {
  list: RadioButtonItem[],
  initAction?: string
  value?: string
}
const getInitAction = ({list, initAction, value}: InitActionProps) => {
  const actions = list.map(item => item.action)
  if (value && actions.includes(value)) {
    return value
  }
  if (initAction && actions.includes(initAction)) {
    return initAction
  }
  return actions[0] || ''
}

const RadioButtons = (
  {
    list,
    initAction,
    value,
    disable,
    className,
    onChange
  }: RadioButtonProps) => {
  const [action, setAction] = useState<string | undefined>(getInitAction({list, initAction, value}))

  useEffect(() => {
    if (action != null) {
      onChange?.(action)
    }
  }, [action, onChange])

  useEffect(() => {
    if (value != null) {
      setAction(value)
    }
  }, [value])

  const onItemClick = (action: string) => {
    onChange?.(action)
    if (value == null) {
      setAction(action)
    }
  }

  return <ul className={classNames(styles.list, disable && styles.disable, className)}>
    {list.map(item => {
      const SvgIcon = item.SvgIcon
      return <li
        key={item.action}
        className={classNames(styles.item, item.action === action && styles.active)}
        onClick={item.action !== action && !disable ? () => onItemClick(item.action) : undefined}>
        {SvgIcon && <SvgIcon className={styles.svgIcon}/>}
        <span className={styles.title}>{item.title}</span>
        <span className={classNames(styles.tick, item.action === action && styles.active)}/>
      </li>
    })}
  </ul>
}

export default RadioButtons
