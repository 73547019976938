import React from "react";
import styles from './Auth.module.scss';

interface AuthProps extends React.PropsWithChildren {
}

const Auth = ({children}: AuthProps) => {
  return (
    <div className={styles.form}>
      {children}
    </div>)
}

export default Auth;