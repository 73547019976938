import styles from './BasePreviewModal.module.scss'
import classNames from 'classnames';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import {
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {IMessage} from '@/store/messages/messages';
import {forwardMessage} from '@/components/Chat/ChatMessages/ChatMessages';
import {saveAs} from 'file-saver';
import {getFileExtension, getMimeType} from '@/utils/file';
import {ReactComponent as ZoomInImage} from '@/images/icons/zoom_in.svg';
import {ReactComponent as ZoomOutImage} from '@/images/icons/zoom_out.svg';
import {isTouchDevice} from '@/utils/device';
import {isImageMessage} from '@/components/Chat/ChatMessage/ImageMessage/ImageMessage';
import {convertToPng} from '@/utils/image';

interface BasePreviewModalProps extends PropsWithChildren {
  onClose?: () => void
  zoomAvailable?: boolean
  onIncrease?: () => void
  onDecrease?: () => void
  onBackgroundClick?: () => void
}

interface ShowProps {
  message?: IMessage,
  url?: string
  name?: string,
}

export interface PreviewHandle {
  show: (props: ShowProps) => void
  hide: () => void
}

const HIDE_TIMEOUT = 5000

const BasePreviewModal = forwardRef<PreviewHandle, BasePreviewModalProps>((
  {
    children,
    zoomAvailable,
    onIncrease,
    onDecrease,
    onClose,
    onBackgroundClick,
  }: BasePreviewModalProps, ref) => {
  const [show, setShow] = useState(false)
  const [url, setUrl] = useState('')
  const [message, setMessage] = useState<IMessage | null>(null)
  const [name, setName] = useState<string>()
  const [hidedElement, setHidedElement] = useState(false)
  const hideTimer = useRef<NodeJS.Timer>()
  const boxRef = useRef<HTMLDivElement>(null)

  const handleClose = useCallback(() => {
    setShow(false)
    setUrl('')
    onClose?.()
  }, [onClose])

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        handleClose()
        break;
      default:
        break
    }
  }, [handleClose])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const hideElement = useCallback(() => {
    setHidedElement(true)
  }, [])

  const showElement = useCallback(() => {
    setHidedElement(false)
    clearTimeout(hideTimer.current)
    hideTimer.current = setTimeout(hideElement, HIDE_TIMEOUT)
  }, [hideElement])

  useEffect(() => {
    if (isTouchDevice()) {
      return
    }
    const events = ['mousemove', 'mousedown']
    events.forEach(item => {
      document.addEventListener(item, showElement)
    })
    return () => {
      events.forEach(item => {
        document.removeEventListener(item, showElement)
      })
    }
  }, [showElement]);

  const handleForward = () => {
    if (!message) {
      return
    }
    forwardMessage({message})
    handleClose()
  }

  const handleDownload = () => {
    if (!url) {
      return
    }
    fetch(url)
      .then(res => res.arrayBuffer())
      .then(buffer => {
        const ext = getFileExtension(name || '')
        const mimeType = getMimeType(ext)
        const blob = new Blob([buffer], mimeType ? {type: mimeType} : undefined)
        saveAs(blob, name)
      })
  }

  const handleCopyImage = () => {
    if (!message || !isImageMessage(message) || !url) {
      return
    }
    convertToPng(url)
      .then(blob => {
        navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ])
      })
  }

  useImperativeHandle(ref, () => {
    return {
      show({message, url, name}: ShowProps) {
        setMessage(message || null)
        setUrl(url || '')
        setName(name)
        setShow(true)
      },
      hide: () => {
        handleClose()
      },
    }
  })

  if (!show) {
    return null
  }

  const handleBackgroundClick: MouseEventHandler = (e) => {
    if (boxRef.current && e.target === boxRef.current) {
      onBackgroundClick?.()
    }
  }

  return <div
    ref={boxRef}
    className={styles.viewer}
    onClick={handleBackgroundClick}
  >
    <div className={classNames(
      styles.buttonBox,
      styles.headerButtons,
      hidedElement && styles.hide,
    )}>
      <IconButton className={styles.btn} onClick={handleClose}>
        <i className={classNames('chat-cross', styles.action)}/>
      </IconButton>
    </div>

    <div
      className={classNames(
        styles.buttonBox,
        styles.footerButtons,
        hidedElement && styles.hide,
      )}>
      {zoomAvailable && <>
        <IconButton className={styles.btn} onClick={onIncrease}>
          <ZoomInImage/>
        </IconButton>
        <IconButton className={styles.btn} onClick={onDecrease}>
          <ZoomOutImage/>
        </IconButton>
        <div className={styles.btnDivider}/>
      </>}
      {url && <IconButton className={styles.btn} onClick={handleDownload}>
        <i className={classNames('chat-download', styles.action)}/>
      </IconButton>}
      {!!message && isImageMessage(message) && <IconButton
        className={styles.btn}
        onClick={handleCopyImage}
      >
        <i className={classNames('chat-copy', styles.action)}/>
      </IconButton>}
      {!!message && <IconButton className={styles.btn} onClick={handleForward}>
        <i className={classNames('chat-forward', styles.action)}/>
      </IconButton>}
    </div>
    {children}
  </div>
})

export default BasePreviewModal
