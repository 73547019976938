import {AnyObject} from '@/index';
import {IMessageType} from '@/interfaces/XMPP';

const createMessageFromObject = (xmlObj: AnyObject | string, msg?: Strophe.Builder): Strophe.Builder => {
  if (typeof xmlObj === 'string') {
    if (!msg) {
      throw new Error('Invalid structure passed')
    }
    return msg.t(xmlObj)
  }
  const keys = Object.keys(xmlObj)
  const attrs = keys.filter(key => key.startsWith('$') && ['string', 'number'].includes(typeof xmlObj[key]))
  const text = xmlObj._
  const attrsObjs: Record<string, string> = {}
  const simpleKeys: string[] = keys.filter(key => !key.startsWith('$') &&
    !key.startsWith('_') &&
    ['string', 'number'].includes(typeof xmlObj[key]))
  const fields = keys.filter(key => !key.startsWith('$') && typeof xmlObj[key] === 'object')
  attrs.forEach(key => {
    attrsObjs[key.slice(1)] = xmlObj[key]
  })
  const message = msg ? msg.attrs(attrsObjs) : $msg(attrsObjs)

  if (text) {
    message.t(text)
  }

  simpleKeys.forEach(key => {
    message.c(key).t(xmlObj[key]).up()
  })

  fields.forEach(key => {
    if (Array.isArray(xmlObj[key])) {
      xmlObj[key].forEach((item: AnyObject) => {
        message.c(key)
        createMessageFromObject(item, message)
        message.up()
      })
    } else {
      message.c(key)
      createMessageFromObject(xmlObj[key], message)
      message.up()
    }
  })

  return message
}

export const createStropheFromObject = (type: IMessageType, structure: AnyObject): Strophe.Builder => {
  const msg = new Strophe.Builder(type)
  return createMessageFromObject(structure, msg)
}
