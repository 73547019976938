import React from "react";
import styles from "@/components/Pin/AutoDelete/AutoDelete.module.scss";
import {useAppSelector, useAppDispatch} from "@/hooks/appHook";
import {getActiveChatId, getChat, updateChat} from "@/store/chats/chats";
import {hidePin} from "@/api/hide-pin";
import GetAutoDeleteMessage from "@/components/Modal/AutoDeleteMessages/GetAutoDeleteMessage";

const AutoDelete = () => {
  const dispatch = useAppDispatch()
  const activeChatId = useAppSelector(getActiveChatId)
  const currentChat = useAppSelector(getChat(activeChatId + ''))

  const handleChatCross = () => {
    if (currentChat && currentChat?.$jid && currentChat?.type) {
      hidePin({"chat": currentChat?.$jid, "kind": currentChat?.type, "typeHide": 'auto-delete'}).then((res) => {
        dispatch(updateChat({
          chatJid: currentChat.$jid || '',
          options: {
            autoDeleteMessages: {
              period: currentChat.autoDeleteMessages?.period || 1,
              typePeriod: currentChat.autoDeleteMessages?.typePeriod || 'disable',
              mine: currentChat.autoDeleteMessages?.mine || true,
              visible: false,
            }
          },
        }))
      })
    }
  }

  if (!currentChat?.autoDeleteMessages || ! currentChat.autoDeleteMessages.visible) {
    return <></>
  }

  return <div className={styles.pinAutoDelete}>
    <div>{GetAutoDeleteMessage(currentChat)}</div>
    <i className={"chat-cross"} onClick={handleChatCross} />
  </div>
}

export default AutoDelete
