import {v4 as uuid} from 'uuid';

class SetSettings {
  static MESSAGES = {
    enableCarbon() {
      return $iq({
        id: uuid(),
        type: 'set'
      }).c('enable', {
        xmlns: 'urn:xmpp:carbons:2'
      }).tree()
    }
  }
}

export default SetSettings
