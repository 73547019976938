import styles from './ContactSliderList.module.scss'
import Slider from "@/components/Slider/Slider";
import React, {MouseEventHandler, MouseEvent, useState, useEffect, useRef, useCallback} from 'react';
import {getAllChats, Chat} from '@/store/chats/chats';
import Avatar from '@/components/Avatar/Avatar';
import classNames from 'classnames';
import useChatItemRendered from "@/hooks/useChatItemRendered";
import {useAppSelector} from "@/hooks/appHook";
import {useIntl} from "react-intl";
import {getNameOrNick} from '@/utils/chats';

const MAX_DIFF_FOR_CLICK = 5

interface ContactSliderListProps {
  contacts: Chat[],
  onClick: (contact: Chat) => void
}
const ContactSliderList = ({contacts, onClick}: ContactSliderListProps) => {
  const chats = useAppSelector(getAllChats)
  const [xClick, setXClick] = useState(0)
  const intersectionObserver = useRef<IntersectionObserver | null>(null)
  const sliderRef = useRef(null)
  const slidesMap = useRef(new Map<Element, string>())
  const {loadMessagesIfNotLoaded} = useChatItemRendered()
  const {formatMessage} = useIntl()

  const cb: IntersectionObserverCallback = useCallback((entry, observer) => {
    const map = slidesMap.current
    entry.forEach(item => {
      if (item.isIntersecting) {
        const jid: string = map.get(item.target) || ''
        loadMessagesIfNotLoaded(chats[jid])
      }
    })
  }, [loadMessagesIfNotLoaded, chats])

  useEffect(() => {
    intersectionObserver.current = new IntersectionObserver(cb, {
      root: sliderRef.current
    })
    slidesMap.current.forEach((_, key) => {
      intersectionObserver.current?.observe(key)
    })
    return () => {
      intersectionObserver.current?.disconnect()
      intersectionObserver.current = null
    }
  }, [cb])

  const handleSlidesRef = (element: HTMLDivElement | null, jid: string) => {
    if (!intersectionObserver.current || !element) {
      return
    }
      intersectionObserver.current.observe(element)
    const map = slidesMap.current
      if (!map.has(element)) {
        map.set(element, jid)
      }
  }

  const onMouseDown: MouseEventHandler = (e) => {
    setXClick(e.pageX)
  }

  const onMouseUp = (e: MouseEvent<HTMLDivElement>, contact: Chat) => {
    if (Math.abs(e.pageX - xClick) < MAX_DIFF_FOR_CLICK) {
      onClick?.(contact)
    }
  }

  return (
    <>
    <div className={styles.groupName}>{formatMessage({id:"CONTACTS.PENDING_INVITES"})}</div>
    <Slider
      ref={sliderRef}
      className={styles.slider}
    >
      {contacts.map(contact => (
        <div ref={(el) => {handleSlidesRef(el, contact.$jid)}} key={contact.$jid}>
          <div
            className={styles.contact}
            onMouseDown={onMouseDown}
            onMouseUp={(e) => onMouseUp(e, contact)}
          >
            <Avatar src={contact.vcard?.thumbnail} draggable={false} name={getNameOrNick(contact)}/>
            <span
              className={
              classNames(
                styles.nickname,
                contact.ask ? styles.outgoing : styles.incoming
              )
            }
            >{getNameOrNick(contact)}</span>
          </div>
        </div>
      ))}
    </Slider>
    </>
  )
}

export default ContactSliderList
