import styles from './ImageViewer.module.scss'
import {IImageMessage} from '@/store/messages/messages';
import {createPubSub} from '@inficen/react-events'
import {MouseEventHandler, useCallback, useRef, useState} from 'react';
import BasePreviewModal, {PreviewHandle} from '@/components/Modal/BasePreviewModal/BasePreviewModal';
import {getFileExtension} from '@/utils/file';
import ImageResizer, {IImageResizer} from '@/components/ImageResizer/ImageResizer';

interface ImageViewerProps {
  name?: string
}

const DEFAULT_NAME = 'generalImageViewer'

type MessageEvent = {
  name: string,
  payload: {
    message: IImageMessage
  }
}

type ImageEvent = {
  name: string,
  payload: {
    url: string,
    name: string
  }
}

const {publish: publishMessageImage, useSubscribe: useSubscribeMessageImage} = createPubSub<MessageEvent>()
const {publish: publishOnlyImage, useSubscribe: useSubscribeOnlyImage} = createPubSub<ImageEvent>()

interface ShowMessageImageProps {
  name?: string,
  message: IImageMessage
}

interface ShowOnlyImageProps {
  url: string,
  imageName: string,
  name?: string
}

const SHOW_MESSAGE_EVENT = 'showImageMessage'
const SHOW_ONLY_IMAGE = 'showOnlyImage'

export const show = ({message, name = DEFAULT_NAME}: ShowMessageImageProps) => {
  const nameEvent: string = `${name}/${SHOW_MESSAGE_EVENT}`
  publishMessageImage(nameEvent, {message})
}

export const showOnlyImage = ({name = DEFAULT_NAME, imageName, url}: ShowOnlyImageProps) => {
  const nameEvent: string = `${name}/${SHOW_ONLY_IMAGE}`
  publishOnlyImage(nameEvent, {url, name: imageName || 'image.jpg'})
}

const ImageViewer = ({name = DEFAULT_NAME}: ImageViewerProps) => {
  const [url, setUrl] = useState('')
  const containerRef = useRef<PreviewHandle>(null)
  const resizerRef = useRef<IImageResizer>(null)

  useSubscribeMessageImage(`${name}/${SHOW_MESSAGE_EVENT}`, ({message}) => {
    setUrl(message.image.url)
    const ext = getFileExtension(message.image.url)
    containerRef.current?.show({
      message,
      url: message.image.url,
      name: message.id + '.' + ext,
    })
  })

  useSubscribeOnlyImage(`${name}/${SHOW_ONLY_IMAGE}`, ({url, name}) => {
    setUrl(url)
    containerRef.current?.show({
      url,
      name,
    })
  })

  const handleClose = useCallback(() => {
    setUrl('')
  }, [])

  const handleIncrease = () => {
    resizerRef.current?.increaseZoom()
  }

  const handleDecrease = () => {
    resizerRef.current?.decreaseZoom()
  }

  const handleBackGroundClick = () => {
    containerRef.current?.hide()
  }

  const handleResizerClick: MouseEventHandler = (e) => {
    if (e.target instanceof HTMLImageElement) {
      return
    }
    handleBackGroundClick()
  }

  return <BasePreviewModal
    ref={containerRef}
    zoomAvailable={true}
    onIncrease={handleIncrease}
    onDecrease={handleDecrease}
    onClose={handleClose}
    onBackgroundClick={handleBackGroundClick}
  >
    <ImageResizer
      ref={resizerRef}
      className={styles.imageContainer}
      stepScale={0.6}
      src={url}
      onClick={handleResizerClick}
    />
  </BasePreviewModal>
}

export default ImageViewer
