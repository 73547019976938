export const getOrCreateArray = <T = any>(value: T | T[] | null | undefined): T[] => {
  if (value == null) {
    return []
  }

  if (Array.isArray(value)) {
    return value
  } else {
    return [value]
  }
}
