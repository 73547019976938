import styles from './SupportTicketCard.module.scss'
import { IClass } from '@/interfaces/general'
import classNames from 'classnames'
import { ISupportTicket } from '@/store/supportTickets/supportTickets'
import Avatar from '@/components/Avatar/Avatar'
import supportImage from '@/images/icons/support.svg'
import { getDayFormat } from '@/utils/dateutils'
import { ElementType } from 'react'
import { useAppSelector } from '@/hooks/appHook'
import { getUser } from '@/store/user/user'
import SupportTicketStatusComponent
  from '@/components/SupportTicket/SupportTicketStatusComponent/SupportTicketStatusComponent'
import { FormattedMessage } from 'react-intl'

interface SupportTicketCardProps extends IClass {
  ticket: ISupportTicket
  TagName?: ElementType
  onClick?: () => void
}

const SupportTicketCard = (
  {
    className,
    ticket,
    TagName = 'div',
    onClick
  }: SupportTicketCardProps) => {
  const user = useAppSelector(getUser)

  const avatarImage = +(user?.memberId || -1) === ticket.lastReplier?.id
    ? user?.url || ''
    : supportImage

  return <TagName
    className={classNames(styles.box, className)}
    onClick={onClick}
  >
    <div className={styles.statusesBox}>
      <SupportTicketStatusComponent
        status={ticket.status}
      />
      {!ticket.isRead && <FormattedMessage id="new_reply">
        {txt => <span className={styles.newReply}>{txt}</span>}
      </FormattedMessage>}
    </div>

    <p className={styles.title}>{ticket.title}</p>
    <div className={styles.user}>
      <Avatar
        className={styles.avatar}
        src={avatarImage}
        size={34}
      />
      {ticket.lastReplier && <div className={styles.userInfo}>
        <span className={styles.userName}>{ticket.lastReplier.name}</span>
        <span className={styles.date}>{getDayFormat(ticket.lastReplier.date)}</span>
      </div>}
    </div>
  </TagName>
}

export default SupportTicketCard
