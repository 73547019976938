import {EventHandler, useCallback, useEffect, useRef, useState} from 'react';

interface Hide {
  hide: () => void
}

interface EmptyModalProps {
  onHide?: () => void
}

interface NotEmptyModalProps<T extends Record<string, any>> {
  props: T
}

type ModalProps<T extends Record<string, any> = Record<string, never>> =
  T extends Record<string, never> ? Hide : T & Hide

type ShowFunc<T> = EventHandler<any> & ((props?: Partial<T>) => void)

interface IModal<T extends Record<string, any> = Record<string, never>> extends Hide{
  visible: boolean,
  show: ShowFunc<T>
  props: ModalProps<T>
}

interface UseModal {
  <T extends Record<string, never>>(props?: EmptyModalProps): IModal<T>
  <T extends Record<string, any>>(props: NotEmptyModalProps<T> & EmptyModalProps): IModal<T>
}

const useModal: UseModal = <T extends Record<string, any> = Record<string, never>,>
(
  modalProps: T extends Record<string, never> ? EmptyModalProps | undefined : NotEmptyModalProps<T> & EmptyModalProps
): IModal<T> => {
  const {onHide} = modalProps || {}
  const [visible, setVisible] = useState(false)
  const hide = useCallback(() => {
    setVisible(false)
  }, [])

  const [propsState, setPropsState] = useState<ModalProps<T>>({
    ...modalProps?.props,
    hide
  } as ModalProps<T>)

  const onHideRef = useRef(onHide)
  const firstStart = useRef(true)

  useEffect(() => {
    onHideRef.current = onHide
  }, [onHide])

  useEffect(() => {
    if (!visible && !firstStart.current) {
      onHideRef.current?.()
    }
    firstStart.current = false
  }, [visible])

  const show: ShowFunc<T> = useCallback((partialProps?: Partial<T> | Event) => {
    if (partialProps && !(partialProps instanceof Event) && propsState) {
      setPropsState({
        ...propsState,
        ...partialProps
      })
    }
    if (!propsState && partialProps) {
      console.error('You need to set the initial value') // eslint-disable-line no-console
    }
    setVisible(true)
  }, [propsState])

  return {visible, show, hide, props: propsState}
}

export default useModal
