import BaseModalWithTitle, {BaseModalWithTitleProps} from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import {useIntl} from 'react-intl';
import {getLang, setLang, TypeLang} from '@/store/language/language';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import styles from './Language.module.scss';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import { LangList } from '@/constants/languages'

interface LangProps extends Omit<BaseModalWithTitleProps, 'title'>  {
  title?: string
  onBack?: () => void
  onClose?: () => void
}

const Language = ({hide, onBack, onClose, ...props}: LangProps) => {
  const {formatMessage} = useIntl()
  const dispatch = useAppDispatch()
  const lang = useAppSelector(getLang)
  const navigate = useNavigate()

  const handleBack = () => {
    onBack?.()
    hide?.()
  }

  const updateLang = (langKey: any) => {
    dispatch(setLang(langKey))
    onBack?.()
    navigate('/' + langKey,{
      replace: true
    })
  }

  return <BaseModalWithTitle
    title={formatMessage({id: 'language'})}
    canBack={true}
    onBack={handleBack}
    onClose={onClose}
    hide={handleBack}
    {...props}
  >
    <ul className={styles.langList}>
      {Object.keys(LangList).map((langKey) =>
        <li onClick={() => updateLang(langKey)}>
          {LangList[langKey as TypeLang]}
          {langKey === lang ? <i className="chat-check"></i> : null}
        </li>)}
    </ul>
  </BaseModalWithTitle>
}

export default Language
