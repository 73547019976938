import {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {getActiveChatId, Chat, getPrivateChats, GroupCategoriesMap} from '@/store/chats/chats';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import ChatItem, {CHAT_ITEM_HEIGHT, isChat} from '@/components/Chat/ChatItem/ChatItem';
import VirtualList from '@/components/VirtualList/VirtualList';
import {
  createChatsData,
  filterChats,
  ChatsDataWithDivider,
  Divider,
  filterFavoriteChats,
  filterAcceptedWithoutFavorites,
} from '@/utils/chats';
import useChatItemRendered from '@/hooks/useChatItemRendered';
import ContactSliderList from '@/components/ContactSliderList/ContactSliderList';
import {useIntl} from 'react-intl';
import {getBlockList} from '@/store/blockList/blockList';
import {ListOnScrollProps, VariableSizeList} from 'react-window';
import {ModifiedTouchEvent} from '@/components/Chat/ChatMessage/ChatMessage';
import useContextMenu from '@/components/Menu/ContextMenu/useContextMenu';
import ContextMenuList, {ContextMenuProps} from '@/components/Menu/ContextMenu/ContextMenuList';
import Confirm from '@/components/Modal/Confirm/Confirm';
import useChatActions from '@/hooks/useChatActions';
import {getContactScroll, updateContactScroll} from '@/store/scroll/scroll';
import {IClass} from '@/interfaces/general';
import AutoDeleteMessages from "@/components/Modal/AutoDeleteMessages/AutoDeleteMessages";
import useActiveService from '@/hooks/useActiveService'

interface ContactListProps extends IClass{
  filter?: string
}

const ContactList = ({className, filter}: ContactListProps) => {
  const contactChats = useAppSelector(getPrivateChats)
  const activeJid = useAppSelector(getActiveChatId)
  const blockedUsers = useAppSelector(getBlockList)
  const lastSavedScroll = useAppSelector(getContactScroll)
  const dispatch = useAppDispatch()
  const [contacts, setContacts] = useState<Chat[]>([])
  const [invites, setInvites] = useState<Chat[]>([])
  const [favoriteChats, setFavoriteChats] = useState<Chat[]>([])
  const [acceptedChats, setAcceptedChats] = useState<Chat[]>([])
  const [contactsWithDivider, setContactsWithDivider] = useState<(Chat | Divider)[]>([])
  const {handleChatRendered} = useChatItemRendered({chats: contacts})
  const lastScroll = useRef(0)
  const firstOpen = useRef(true)
  const {formatMessage} = useIntl()
  const {
    confirmProps,
    visibleConfirmModal,
    hideConfirmModal,
    getList,
    autoDeleteMessagesProps,
    visibleAutoDeleteMessagesModal,
    hideAutoDeleteMessagesModal,
    onClickContextMenu} = useChatActions()
  const {setActiveChatId} = useActiveService()

  const {show: showContextMenu, ContextMenu} = useContextMenu<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: [],
    },
  })

  useEffect(() => {
    return () => {
      dispatch(updateContactScroll(lastScroll.current))
    }
  }, [dispatch]);

  useEffect(() => {
    const blocked = new Set(blockedUsers.map(user => user.jid))
    const keys = Object.keys(contactChats)
    setInvites(keys
      .filter(key => !blocked.has(key))
      .filter(key => (contactChats[key].groups?.includes(GroupCategoriesMap.pending)
          || contactChats[key].$subscription === 'from') &&
        contactChats[key].$subscription !== 'none')
      .map(key => contactChats[key]),
    )
  }, [contactChats, blockedUsers])

  useEffect(() => {
    const blocked = new Set(blockedUsers.map(user => user.jid))
    const keys = Object.keys(contactChats)
    const sortChats = filterChats(keys
      .filter(key => !blocked.has(key))
      .filter(key => !contactChats[key].groups?.includes(GroupCategoriesMap.pending))
      .map(key => contactChats[key]), filter)
    sortChats.sort((v1, v2) => {
      return (v1.name || '') > (v2.name || '') ? 0 : -1
    })
    setContacts(sortChats)
  }, [contactChats, filter, blockedUsers])

  useEffect(() => {
    setFavoriteChats(contacts.filter(filterFavoriteChats))
    setAcceptedChats(contacts.filter(filterAcceptedWithoutFavorites))
  }, [contacts])

  useEffect(() => {
    setContactsWithDivider([...favoriteChats, ...acceptedChats])
  }, [favoriteChats, acceptedChats, formatMessage])

  const onClick = (chat: string) => {
    setActiveChatId(chat)
  }

  const onContextMenu = (e: ModifiedTouchEvent, chat: Chat) => {
    const position = {
      x: e.pageX,
      y: e.pageY,
    }

    const list = getList(chat)

    showContextMenu({
      position,
      componentProps: {
        list,
        message: chat,
        onClick: onClickContextMenu,
      },
    })
  }

  const onScroll = (scrollProps: ListOnScrollProps) => {
    if (firstOpen.current) {
      return
    }
    lastScroll.current = scrollProps.scrollOffset
  }
  const refSetter = (list: VariableSizeList | null) => {
    if (list && firstOpen.current) {
      firstOpen.current = false
      list.scrollTo(lastSavedScroll)
    }
  }

  const invitesHeight = !!invites.length ? 111 : 0
  const getItemSize = (index: number) => isChat(contactsWithDivider[index]) ? CHAT_ITEM_HEIGHT : 36

  return <div className={classNames(className)}>
    {!!invites.length && <>
      <ContactSliderList contacts={invites} onClick={(chat) => {
        onClick(chat.$jid)
      }}/>
    </>}
    <AutoSizer>
      {({height, width}) => (
        <VirtualList<ChatsDataWithDivider>
          listRef={refSetter}
          height={(height || 100) - invitesHeight}
          width={width || 380}
          itemSize={getItemSize}
          onScroll={onScroll}
          data={{
            chats: contactsWithDivider,
            activeChatId: '' + activeJid,
            activeNews: null,
            onClick,
            onContextMenu,
          }}
          render={ChatItem}
          overscanCount={5}
          createItemData={createChatsData}
          onItemsRendered={handleChatRendered}
        />
      )}
    </AutoSizer>
    <ContextMenu/>
    {visibleConfirmModal && <Confirm hide={hideConfirmModal} {...confirmProps} />}
    {visibleAutoDeleteMessagesModal &&
      <AutoDeleteMessages hide={hideAutoDeleteMessagesModal} {...autoDeleteMessagesProps} />}
  </div>
}

export default ContactList
