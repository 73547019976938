import styles from './CallModal.module.scss'
import AutoSizer from 'react-virtualized-auto-sizer';
import {JitsiMeeting} from '@jitsi/react-sdk';
import {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {ErrorFactory} from '@/services/errorFactory';
import {ID_MODAL_PORTAL} from '@/constants/general';
import {BaseModalProps} from '@/components/Modal/BaseModal/BaseModal';
import {FilmstripEvent, IErrorOccured, INewJitsiMeetExternalApi} from '@/components/Modal/CallModal/ICall';
import classNames from 'classnames';

interface IFrameOptions {
  width: number,
  height: number
}

interface CallModalProps extends BaseModalProps {
  token: string
  name: string
  invisible?: boolean
  hide?: () => void
  onClose?: () => void
}

export interface ICallModal {
  close: () => void
}

const NOT_ALLOWED_CONNECTION_TIMEOUT = 3000
const HANGUP_DELAY_TIMEOUT = 500

const USER_NOTIFICATION = [
  'connection.CONNFAIL', // shown when the connection fails,
  'dialog.cameraConstraintFailedError', // shown when the camera failed
  'dialog.cameraNotSendingData', // shown when there's no feed from user's camera
  'dialog.kickTitle', // shown when user has been kicked
  'dialog.liveStreaming', // livestreaming notifications (pending, on, off, limits)
  'dialog.lockTitle', // shown when setting conference password fails
  'dialog.maxUsersLimitReached', // shown when maximmum users limit has been reached
  'dialog.micNotSendingData', // shown when user's mic is not sending any audio
  'dialog.passwordNotSupportedTitle', // shown when setting conference password fails due to password format
  'dialog.recording', // recording notifications (pending, on, off, limits)
  'dialog.remoteControlTitle', // remote control notifications (allowed, denied, start, stop, error)
  'dialog.reservationError',
  'dialog.screenSharingFailedTitle', // shown when the screen sharing failed
  'dialog.serviceUnavailable', // shown when server is not reachable
  'dialog.sessTerminated', // shown when there is a failed conference session
  'dialog.sessionRestarted', // show when a client reload is initiated because of bridge migration
  'dialog.tokenAuthFailed', // show when an invalid jwt is used
  'dialog.tokenAuthFailedWithReasons', // show when an invalid jwt is used with the reason behind the error
  'dialog.transcribing', // transcribing notifications (pending, off)
  'dialOut.statusMessage', // shown when dial out status is updated.
  'liveStreaming.busy', // shown when livestreaming service is busy
  'liveStreaming.failedToStart', // shown when livestreaming fails to start
  'liveStreaming.unavailableTitle', // shown when livestreaming service is not reachable
  'lobby.joinRejectedMessage', // shown when while in a lobby, user's request to join is rejected
  'lobby.notificationTitle', // shown when lobby is toggled and when join requests are allowed / denied
  'notify.audioUnmuteBlockedTitle', // shown when mic unmute blocked
  'notify.chatMessages', // shown when receiving chat messages while the chat window is closed
  // 'notify.connectedOneMember', // show when a participant joined
  // 'notify.connectedThreePlusMembers', // show when more than 2 participants joined simultaneously
  // 'notify.connectedTwoMembers', // show when two participants joined simultaneously
  'notify.dataChannelClosed', // shown when the bridge channel has been disconnected
  'notify.hostAskedUnmute', // shown to participant when host asks them to unmute
  'notify.invitedOneMember', // shown when 1 participant has been invited
  'notify.invitedThreePlusMembers', // shown when 3+ participants have been invited
  'notify.invitedTwoMembers', // shown when 2 participants have been invited
  'notify.kickParticipant', // shown when a participant is kicked
  // 'notify.leftOneMember', // show when a participant left
  // 'notify.leftThreePlusMembers', // show when more than 2 participants left simultaneously
  // 'notify.leftTwoMembers', // show when two participants left simultaneously
  'notify.linkToSalesforce', // shown when joining a meeting with salesforce integration
  'notify.localRecordingStarted', // shown when the local recording has been started
  'notify.localRecordingStopped', // shown when the local recording has been stopped
  'notify.moderationInEffectCSTitle', // shown when user attempts to share content during AV moderation
  'notify.moderationInEffectTitle', // shown when user attempts to unmute audio during AV moderation
  'notify.moderationInEffectVideoTitle', // shown when user attempts to enable video during AV moderation
  'notify.moderator', // shown when user gets moderator privilege
  'notify.mutedRemotelyTitle', // shown when user is muted by a remote party
  'notify.mutedTitle', // shown when user has been muted upon joining,
  'notify.newDeviceAudioTitle', // prompts the user to use a newly detected audio device
  'notify.newDeviceCameraTitle', // prompts the user to use a newly detected camera
  'notify.noiseSuppressionFailedTitle', // shown when failed to start noise suppression
  'notify.participantWantsToJoin', // shown when lobby is enabled and participant requests to join meeting
  'notify.participantsWantToJoin', // shown when lobby is enabled and participants request to join meeting
  'notify.passwordRemovedRemotely', // shown when a password has been removed remotely
  'notify.passwordSetRemotely', // shown when a password has been set remotely
  'notify.raisedHand', // shown when a partcipant used raise hand,
  'notify.screenShareNoAudio', // shown when the audio could not be shared for the selected screen
  'notify.screenSharingAudioOnlyTitle', // shown when the best performance has been affected by screen sharing
  'notify.selfViewTitle', // show "You can always un-hide the self-view from settings"
  'notify.startSilentTitle', // shown when user joined with no audio
  'notify.suboptimalExperienceTitle', // show the browser warning
  'notify.unmute', // shown to moderator when user raises hand during AV moderation
  'notify.videoMutedRemotelyTitle', // shown when user's video is muted by a remote party,
  'notify.videoUnmuteBlockedTitle', // shown when camera unmute and desktop sharing are blocked
  'prejoin.errorDialOut',
  'prejoin.errorDialOutDisconnected',
  'prejoin.errorDialOutFailed',
  'prejoin.errorDialOutStatus',
  'prejoin.errorStatusCode',
  'prejoin.errorValidation',
  'recording.busy', // shown when recording service is busy
  'recording.failedToStart', // shown when recording fails to start
  'recording.unavailableTitle', // shown when recording service is not reachable
  'toolbar.noAudioSignalTitle', // shown when a broken mic is detected
  'toolbar.noisyAudioInputTitle', // shown when noise is detected for the current microphone
  'toolbar.talkWhileMutedPopup', // shown when user tries to speak while muted
  'transcribing.failedToStart', // shown when transcribing fails to start
]
const CALL_CONFIG = {
  disableReactions: true,
  startWithVideoMuted: true,
  deeplinking: {
    disabled: true,
  },
  disableFilmstripAutohiding: true,
  toolbarButtons: ['hangup', 'camera', 'microphone', 'settings', 'desktop'],
  filmstrip: {
    disableResizable: true,
    stageFilmstripParticipants: 1,
  },
  prejoinConfig: {
    enabled: false,
  },
  disabledSounds: ['E2EE_ON_SOUND', 'PARTICIPANT_JOINED_SOUND', 'REACTION_SOUND'],
  notifications: USER_NOTIFICATION,
  // apiLogLevels: ['warn', 'log', 'error', 'info', 'debug']
}

export const isCallModalProps = (props: Partial<CallModalProps>): props is CallModalProps =>
  !!props.token && !!props.name

const CallModal = forwardRef<ICallModal, CallModalProps>((
  {
    token,
    name,
    hide,
    invisible = false,
    onClose,
  }: CallModalProps, ref) => {
  const [api, setApi] = useState<INewJitsiMeetExternalApi>()
  const frameRef = useRef<HTMLDivElement>()
  const containerElement = useMemo(
    () => document.getElementById(ID_MODAL_PORTAL),
    [],
  );
  const closeForce = useRef(false)
  const apiRef = useRef(api)
  const invisibleRef = useRef(invisible)
  const closeTimer = useRef<NodeJS.Timer>()
  const hangup = useRef<() => void>()

  invisibleRef.current = invisible

  if (!containerElement) {
    throw ErrorFactory.createPortalNotFound(ID_MODAL_PORTAL)
  }

  useEffect(() => {
    const api = apiRef.current
    if (!invisible && api) {
      api.isAudioMuted().then(muted => {
        if (muted) {
          api.executeCommand('toggleAudio')
        }
      })
    }
  }, [invisible]);

  hangup.current = useCallback(() => {
    clearTimeout(closeTimer.current)
    closeTimer.current = setTimeout(() => {
      hangup.current?.()
    }, HANGUP_DELAY_TIMEOUT)
    apiRef.current?.executeCommand('hangup');
  }, [])

  useEffect(() => {
    return () => {
      apiRef.current?.executeCommand('hangup');
    }
  }, []);

  const onErrorOccurred = useCallback((e: IErrorOccured) => {
    switch (e.error.name) {
      case 'conference.connectionError.notAllowed':
        setTimeout(() => {
          hide?.()
          onClose?.()
        }, NOT_ALLOWED_CONNECTION_TIMEOUT)

        break
    }
  }, [onClose, hide])

  useEffect(() => {
    if (!api) {
      return
    }
    api.addListener('errorOccurred', onErrorOccurred)
    return () => {
      api.removeListener('errorOccurred', onErrorOccurred)
    }
  }, [onErrorOccurred, api]);

  const checkBanned = useCallback(async () => {
    const api = apiRef.current
    if (!api?.getRoomsInfo) {
      return
    }
    const rooms = await api.getRoomsInfo()
    const room = rooms.rooms.find(room => room.isMainRoom)
    if (!room || !('id' in room) || room.participants.length < 2) {
      hangup.current?.()
      return
    }
  }, [])

  const onParticipantLeft = useCallback(() => {
    checkBanned()
  }, [checkBanned])

  useEffect(() => {
    if (!api) {
      return
    }
    api.addListener('participantLeft', onParticipantLeft)
    return () => {
      api.removeListener('participantLeft', onParticipantLeft)
    }
  }, [onParticipantLeft, api]);

  const resize = ({width, height}: { width: number, height: number }) => {
    const frame = frameRef.current
    if (!frame) {
      return
    }
    frame.style.height = `${height}px`
    frame.style.width = `${width}px`
  }

  const getIFrameRef = (frame: HTMLDivElement, {width, height}: IFrameOptions) => {
    frameRef.current = frame
    resize({width, height})
  }

  const onApiReady = (api: INewJitsiMeetExternalApi) => {
    apiRef.current = api
    if (closeForce.current) {
      hangup.current?.()
      return
    }
    setApi(api)
    api.once('filmstripDisplayChanged', ({visible}: FilmstripEvent) => {
      if (!visible) {
        api.executeCommand('toggleFilmStrip')
      }
    })
    api.executeCommand('subject', 'Call');

    if (invisible) {
      api.isAudioAvailable().then(available => {
        if (available && invisibleRef.current) {
          api.executeCommand('toggleAudio')
        }
      })
    }
  }

  useImperativeHandle(ref, () => ({
    close() {
      if (!apiRef.current) {
        closeForce.current = true
        return
      }
      hangup.current?.()
    },
  }))
  const onReadyClose = () => {
    apiRef.current = undefined
    clearTimeout(closeTimer.current)
    hide?.()
    onClose?.()
  }

  return ReactDOM.createPortal(<div
    className={classNames(styles.box, invisible && 'visually-hidden')}
  >
    <AutoSizer>
      {({width, height}) => {
        resize({width: width || 100, height: height || 100})
        return token ? <JitsiMeeting
          domain={process.env.REACT_APP_JITSI_HOST}
          jwt={token}
          roomName={name}
          getIFrameRef={(frame) => getIFrameRef(frame, {
            width: width || 100,
            height: height || 100,
          })}
          onApiReady={onApiReady}
          onReadyToClose={onReadyClose}

          configOverwrite={CALL_CONFIG}
        /> : null
      }}
    </AutoSizer>
  </div>, containerElement)
})

export default CallModal
