import Socket from '../Socket/Socket';
import {SendingMessagePromise, SendingMessages} from '@/services/CallSocket/ICallSocket';
import {
  CallAnswer,
  CallAnswerError,
  CallAnswerSuccess,
  CallMessage,
  CallRequest,
  CallRequests,
  isAnswerSuccess,
  MessageAnswerCall,
  MessageCancelCall,
  MessageInit,
  MessageOutgoingCall,
} from '@/services/CallSocket/ICallEvents';
import {v4 as uuid} from 'uuid';
import {SocketEvents} from '@/services/Socket/ISocket';

const domain = ('' + process.env.REACT_APP_PUBLIC_URL)
  .replace(/^https?/, 'wss')
  .replace(/\/$/, '')
const URL = `${domain}/ws`

const sendingMessages: SendingMessages = {}
const addToSendingMessages = (type: CallRequests, message: SendingMessagePromise) => {
  if (!sendingMessages[type]) {
    sendingMessages[type] = []
  }
  sendingMessages[type]!.push(message)
}

const removeFromSendingMessage = (msg: CallAnswer, success: boolean) => {
  const messages = sendingMessages[msg.type]
  if (!messages) {
    return;
  }
  const messageIndex = messages.findIndex(message => message.id === msg.id)
  const message = messages[messageIndex]
  if (!message) {
    return
  }
  if (success && isAnswerSuccess(msg)) {
    message.resolve(msg)
  } else {
    message.reject((msg as CallAnswerError).message)
  }
  clearTimeout(message.timer)
  messages.splice(messageIndex, 1)
}

const TIMEOUT = 5000

class CallSocket extends Socket {

  Messages = {
    init(token: string): MessageInit {
      return {
        type: CallRequests.Init,
        token,
      }
    },
    call(target: string): MessageOutgoingCall {
      return {
        type: CallRequests.Call,
        target,
      }
    },
    access(target: string): MessageAnswerCall {
      return {
        type: CallRequests.Answer,
        target,
        result: 'ok',
      }
    },
    cancelOutgoing(target: string): MessageCancelCall {
      return {
        type: CallRequests.CancelCall,
        target,
      }
    },
    cancelIncoming(target: string): MessageAnswerCall {
      return {
        type: CallRequests.Answer,
        result: 'cancel',
        target,
      }
    },
  }

  private async init(token: string): Promise<CallAnswerSuccess> {
    if (!this.isOpened) {
      return Promise.reject('Socket is not connected')
    }
    return await this.sendMessage(this.Messages.init(token))
  }

  async open(token: string): Promise<CallAnswerSuccess> {
    if (!this.isOpened) {
      await super.connect()
    }
    return this.init(token);
  }

  protected onMessage(event: MessageEvent) {
    const msg: CallAnswer = JSON.parse(event.data)
    this.emit(SocketEvents.onMessage, msg)
    removeFromSendingMessage(msg, msg.result === 'success')
  }

  async sendMessage<T extends CallRequest>(
    data: CallMessage<T>,
    timeout = TIMEOUT,
  ): Promise<CallAnswerSuccess> {
    const id = uuid()
    const promise = new Promise<CallAnswerSuccess>((resolve, reject) => {
      const timer = setTimeout(() => {
        removeFromSendingMessage({
          id,
          type: data.type,
          result: 'error',
          message: `timeout: ${timeout}, type: ${data.type}, id: ${id}`,
        }, false)
      }, timeout)
      const message: SendingMessagePromise = {
        resolve,
        reject,
        id,
        timer,
      }
      addToSendingMessages(data.type, message)
    })
    this.send(JSON.stringify({
      ...data,
      id,
    }))
    return promise;
  }
}

const callSocket = new CallSocket(URL)
export default callSocket
