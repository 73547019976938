import styles from './SupportTicketStatusComponent.module.scss'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { SupportTicketStatus } from '@/store/supportTickets/supportTickets'
import { IClass } from '@/interfaces/general'

interface SupportTicketStatusComponentProps extends IClass{
    status: SupportTicketStatus
}

const SupportTicketStatusComponent = (
  {
    className,
    status
  }: SupportTicketStatusComponentProps) => {
  return <FormattedMessage id={`SUPPORT_TICKET.STATUS.${status}`}>
    {txt => <span className={classNames(styles.status, styles[status], className)}>{txt}</span>}
  </FormattedMessage>
}

export default SupportTicketStatusComponent
