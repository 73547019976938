import request from "@/api/request";
import {TypePeriodStr} from "@/store/chats/chats";

export const getAutoDeleteMessages = () => {
  return request('get', '/api/message/auto-delete', {
  }).then(({data}) => {
    return data
  })
}

interface AutoDeleteMessagesProps {
  period: number,
  typePeriod: TypePeriodStr,
  jid: string,
  kind: string,
}

export const setAutoDeleteMessages = ({period, typePeriod, jid, kind}: AutoDeleteMessagesProps) => {
  return request('post', '/api/message/auto-delete/update', {
    period,
    typePeriod,
    jid,
    kind,
  }).then(({data}) => {
    return data
  })
}
