import {useCallback, useEffect, useState, useImperativeHandle, forwardRef} from 'react';
import {getCaptcha} from '@/api/auth';
import styles from './Captcha.module.scss';
import classNames from 'classnames';

interface CaptchaProps {
  className?: string
  onChange?: (token: string) => void,
}

export interface CaptchaUpdateHandle {
  update: () => void
}

const Captcha =
    forwardRef<CaptchaUpdateHandle, CaptchaProps>(({onChange, className}:CaptchaProps = {}, ref) => {
  const [src, setSrc] = useState('')

  const updateImage = useCallback(() => {
    getCaptcha()
      .then(data => {
        setSrc(data.data)
        onChange?.(data.token)
      })
  }, [onChange])

  useEffect(() => {
    updateImage()
  }, [updateImage])

  useImperativeHandle(ref, () => {
    return {
      update() {
        updateImage()
      }
    }
  }, [updateImage])

  return <div className={classNames(styles.block, className)}>
    <img className={styles.img} src={src} alt=""/>
    <div className={styles.refresh}>
      <i className={"chat-roll"} onClick={updateImage}></i>
    </div>
  </div>
})

export default Captcha
