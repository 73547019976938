import {Navigate, Outlet} from "react-router-dom";
import React from "react";
import { getQueryParams } from '@/utils/url';

interface PrivateRouteProps extends React.PropsWithChildren{
  isLoggedIn?: boolean,
  redirectPath?: string
}
const PrivateRoute = ({isLoggedIn, redirectPath = '/en/login', children}: PrivateRouteProps) => {
  if (!isLoggedIn) {
    return <Navigate to={redirectPath + getQueryParams()} replace />
  }
  return <>{children ? children : <Outlet/>}</>
}

export default PrivateRoute;
