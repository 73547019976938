import styles from './Search.module.scss'
import React, {ChangeEventHandler, ForwardedRef, forwardRef, useEffect, useState} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';

interface SearchProps extends Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
> {
  className?: string
  wrapperClassName?: string
  onChange?: (filter: string) => void
  light?: boolean
  big?: boolean
}

const Search = forwardRef((
  {
    className,
    wrapperClassName,
    onChange,
    light = false,
    big = false,
    ...props
  }: SearchProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {formatMessage} = useIntl()
  const [filter, setFilter] = useState('')

  useEffect(() => {
    onChange?.(filter)
  }, [filter, onChange])
  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilter(e.target.value)
  }


  return <label className={classNames(
    styles.label,
    wrapperClassName,
    light && styles.light,
    big && styles.big
  )}>
    <i className={classNames(styles.icon, 'chat-search')}></i>
    <input
      ref={ref}
      className={classNames(styles.input, className, big && styles.big)}
      type="text"
      placeholder={formatMessage({id: 'search'})}
      {...props}
      value={filter}
      onChange={onChangeHandler}
    />
    {!!filter.length &&
      <IconButton
        className={styles.closeBtn}
        onClick={() => {setFilter('')}}
      ><i className={'chat-cross'}/>
      </IconButton>}
  </label>
})

export default Search
