import styles from './SupportTickets.module.scss'
import BaseModalWithTitle, { BaseModalWithTitleProps } from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@/components/Primitive/Buttons/Button/Button'
import {
  addTickets, decUnreadMessage,
  getSupportTickets, getTicketById,
  getTicketsFullLoaded, ISupportTicket, setReadTicket,
  setTickets,
  setTicketsFullLoaded, unshiftTicket, updateTicket
} from '@/store/supportTickets/supportTickets'
import { useAppDispatch, useAppSelector } from '@/hooks/appHook'
import SupportTicketList from '@/components/SupportTicket/SupportTicketList/SupportTicketList'
import useModalSimple from '@/hooks/useModalSimple'
import CreateSupportTicket from '@/components/Modal/SupportTicketsModal/CreateSupportTicket/CreateSupportTicket'
import classNames from 'classnames'
import useSecondaryPopupHandler from '@/hooks/useSecondaryPopupHandler'
import { useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from '@/store/screenSize/screenSize'
import { getTicketDetails, getTickets, readNewMessages } from '@/api/supportTickets'
import SupportTicketDetails from '@/components/Modal/SupportTicketsModal/SupportTicketDetails/SupportTicketDetails'

interface SupportTicketsProps extends Omit<BaseModalWithTitleProps, 'title'> {

}

const TICKETS_PER_PAGE = 20

const SupportTickets = (
  {
    hide,
    ...props
  }: SupportTicketsProps) => {
  const dispatch = useAppDispatch()
  const mobileView = useAppSelector(isMobile)
  const tickets = useAppSelector(getSupportTickets)
  const ticketsFullLoaded = useAppSelector(getTicketsFullLoaded)
  const [ticketsLoading, setTicketsLoading] = useState(false)
  const [ticketDetailsLoading, setTicketDetailsLoading] = useState(false)
  const [selectedTicketId, setSelectedTicketId] = useState<number | null>(null)
  const selectedTicket = useAppSelector(getTicketById(selectedTicketId))
  const loading = useRef(false)
  const modalClosed = useRef(false)
  const { formatMessage } = useIntl()

  useEffect(() => {
    return () => {
      dispatch(setTicketsFullLoaded(false))
      dispatch(setTickets([]))
      modalClosed.current = true
    }
  }, [dispatch]);

  const moreLoad = () => {
    if (loading.current || ticketsFullLoaded) {
      return
    }
    setTicketsLoading(true)
    loading.current = true
    getTickets({
      offset: Math.floor(tickets.length / TICKETS_PER_PAGE)
    })
      .then(tickets => {
        if (modalClosed.current) {
          return
        }
        dispatch(addTickets(tickets || []))
        if ((tickets || []).length < TICKETS_PER_PAGE) {
          dispatch(setTicketsFullLoaded(true))
        }
      })
      .finally(() => {
        loading.current = false
        setTicketsLoading(false)
      })
  }

  const { show: showCreateTicket, hide: hideCreateTicket, visible: visibleCreateTicket } = useModalSimple()
  const { show: showTicketDetails, hide: hideTicketDetails, visible: visibleTicketDetails } = useModalSimple()

  const hideSecondaryList = useMemo(() => [
    hideCreateTicket,
    hideTicketDetails
  ], [hideCreateTicket, hideTicketDetails])

  const { onBackModal, onCloseModal } = useSecondaryPopupHandler({
    hide,
    hideSecondaryList
  })

  const selectTicketHandler = async (ticket: ISupportTicket) => {
    setTicketDetailsLoading(true)
    setSelectedTicketId(ticket.id)
    const ticketPromise = getTicketDetails(ticket.id)
    try {
      showTicketDetails()
      const ticketInfo = await ticketPromise
      dispatch(updateTicket({
        ...ticketInfo,
        ...(!ticketInfo.messages && {
          messages: []
        })
      }))
      if (!ticket.isRead) {
        await readNewMessages(ticket.id)
        dispatch(setReadTicket(ticket.id))
        dispatch(decUnreadMessage())
      }
    } finally {
      setTicketDetailsLoading(false)
    }
  }

  const onCreatedTicket = (ticket: ISupportTicket) => {
    dispatch(unshiftTicket(ticket))
    onBackModal()
  }

  const secondaryPopupShown = visibleCreateTicket || visibleTicketDetails

  return <BaseModalWithTitle
    {...props}
    className={classNames(
      secondaryPopupShown && 'visually-hidden'
    )}
    title={formatMessage({ id: 'support_tickets' })}
    hide={hide}
  >
    <div className={styles.box}>
      {/*<Search*/}
      {/*  wrapperClassName={styles.search}*/}
      {/*/>*/}
      <FormattedMessage id="create_ticket">
        {txt => <Button
          className={styles.btn}
          onClick={showCreateTicket}
        >
          {txt}
        </Button>}
      </FormattedMessage>
    </div>
    <SupportTicketList
      tickets={tickets}
      loading={ticketsLoading}
      onMoreLoad={moreLoad}
      onChange={selectTicketHandler}
    />
    {visibleCreateTicket
      && <CreateSupportTicket
        inside
        canBack={!mobileView}
        onBack={onBackModal}
        onClose={onCloseModal}
        onCreated={onCreatedTicket}
      />
    }
    {visibleTicketDetails && <SupportTicketDetails
      ticket={selectedTicket}
      messageLoading={!selectedTicket?.messages && ticketDetailsLoading}
      inside
      canBack={!mobileView}
      onBack={onBackModal}
      onClose={onCloseModal}
    />}
  </BaseModalWithTitle>
}

export default SupportTickets
