import styles from './SupportTicketDocPreview.module.scss'
import docImage from '@/images/icons/support-doc.svg'
import { useState } from 'react'
import classNames from 'classnames'
import CirclePreloader from '@/components/Primitive/CirclePreloader/CirclePreloader'
import { IClass } from '@/interfaces/general'

interface SupportTicketDocPreviewProps extends IClass {
  src: string
}

const SupportTicketDocPreview = (
  {
    src,
    className
  }: SupportTicketDocPreviewProps) => {
  const [url, setUrl] = useState(src)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const onLoad = () => {
    setLoading(false)
  }

  const onError = () => {
    setError(true)
    setLoading(false)
    setUrl(docImage)
  }

  return <div className={classNames(styles.box, className)}>
    <img
      className={classNames(
        styles.image,
        loading && 'visually-hidden',
        error && styles.error
      )}
      src={url}
      onLoad={onLoad}
      onError={onError}
      alt="document"
    />
    {loading && <CirclePreloader
      className={styles.preloader}
      color={'var(--main-color)'}
      withoutProgress
    />}
  </div>
}

export default SupportTicketDocPreview
