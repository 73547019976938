import {useCallback, useEffect} from 'react';
import {useAppDispatch} from '@/hooks/appHook';
import {updateOnline} from '@/store/internetStatus/internetStatus';

const useOnline = () => {
  const dispatch = useAppDispatch()
  const updateStatus = useCallback(() => {
    dispatch(updateOnline(navigator.onLine))
  }, [dispatch])

  useEffect(() => {
    updateStatus()
    window.addEventListener('online', updateStatus)
    window.addEventListener('offline', updateStatus)
    return () => {
      window.removeEventListener('online', updateStatus)
      window.removeEventListener('offline', updateStatus)
    }
  }, [updateStatus])
}

export default useOnline
