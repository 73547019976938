import styles from './Select.module.scss'
import {
  useMemo,
  useState,
  useRef,
  useEffect, useCallback
} from 'react'
import classNames from 'classnames'
import { ReactComponent as ArrowDown } from './image/down.svg'
import { IClass } from '@/interfaces/general'

export interface SelectOption<T extends (string | number)> {
  value: T
  text: string
}

export interface SelectProps<T extends (string | number)> extends IClass{
  options: SelectOption<T>[]
  defaultValue?: T
  label?: string
  disabled?: boolean
  onChange?: (value: T) => void
}

const Select = <T extends (string | number) = string,>(
  {
    options,
    className,
    defaultValue,
    disabled,
    label,
    onChange,
  }: SelectProps<T>) => {
  const hiddenSelect = useRef<HTMLSelectElement>(null)
  const initOption = useMemo<SelectOption<T> | undefined>(() => {
    let option
      if (defaultValue) {
        option = options.find(option => option.value === defaultValue)
      }
    if (!option) {
      option = options[0]
    }
    return option
  }, [options, defaultValue])
  const [selected, setSelected] = useState(initOption)
  const [opened, setOpened] = useState(false)
  const [selectWidth, setSelectWidth] = useState(0)
  const resizeObserver = useRef<ResizeObserver>()
  const resizeObserverCb = useRef<ResizeObserverCallback>()
  if (!resizeObserver.current) {
    resizeObserver.current = new ResizeObserver((entries, observer) => {
      resizeObserverCb.current?.(entries, observer)
    })
  }

  useEffect(() => {
    if (disabled) {
      setOpened(false)
    }
  }, [disabled]);

  resizeObserverCb.current = useCallback<ResizeObserverCallback>((entries) => {
    const entry = entries[0]
    if (!entry) {
      return
    }
    setSelectWidth(entry.target.clientWidth)
  }, [])

  useEffect(() => {
    const select = hiddenSelect.current
    if (!select) {
      return
    }
    resizeObserver.current?.observe(select)
    return () => {
      resizeObserver.current?.unobserve(select)
    }
  }, []);

  useEffect(() => {
    if (!opened) {
      return
    }
    const hide = () => {
      setOpened(false)
    }
    window.addEventListener('mouseup', hide)
    return () => {
      window.removeEventListener('mouseup', hide)
    }
  }, [opened]);

  const titleClickHandler = () => {
    if (disabled) {
      return
    }
    setOpened(prev => !prev)
  }

  const onSelectHandler = (option: SelectOption<T>) => {
    setSelected(option)
    onChange?.(option.value)
    if (hiddenSelect.current) {
      if (option.value) {
        hiddenSelect.current.value = '' + option.value
      }
    }
  }

  const onHiddenSelectorChange = () => {
    const selector = hiddenSelect.current
    if (!selector) {
      return
    }
    const option = options.find(option => option.value === selector.value)
    if (option) {
      if (selected?.value !== option.value) {
        onChange?.(option.value)
      }
      setSelected(option)
    }
  }

  return <div
    style={{
      minWidth: `min(100%, ${selectWidth}px)`
    }}
    className={classNames(styles.box, className)}>
    {label && <span className={styles.label}>{label}</span>}
    <select
      defaultValue={initOption?.value}
      ref={hiddenSelect}
      className={styles.hiddenList}
      onChange={onHiddenSelectorChange}
    >
      {options.map((opt) => <option
        key={opt.value}
        {...opt}>{opt.text}
      </option>)}
    </select>
    <div className={classNames(
      styles.selector,
      disabled && styles.disabled
    )}>
      <span
        className={classNames(
          styles.selectorTitle,
          opened && styles.opened
        )}
        onClick={titleClickHandler}
        onMouseUp={e => e.stopPropagation()}
      >{selected?.text}
        <ArrowDown className={styles.arrow} />
      </span>
      {opened && <ul className={classNames(styles.list)}>
        {options.map((option, index) => <li
          key={typeof option.value === 'string' ? option.value : index}
          className={styles.listItem}
          onMouseDown={() => onSelectHandler(option)}
        >
          <span>{option.text}</span>
        </li>)}
      </ul>}
    </div>

  </div>
}

export default Select
