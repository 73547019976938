import styles from './ConnectIcon.module.scss'
import {IClass} from '@/interfaces/general';
import classNames from 'classnames';

const ConnectIcon = ({className}: IClass) => {
  return <div className={classNames(styles.box, className)}>
    <div className={styles.item} />
  </div>
}

export default ConnectIcon
