class ConnectionHandler {
  public constructor(public handler: (stanza: Element) => boolean,
              public ns: string,
              public name: string,
              public type?: string | string[],
              public id?: string,
              public from?: string,
              public options?: { matchBareFromJid: boolean, ignoreNamespaceFragment: boolean }) {
  }
}

export default ConnectionHandler
