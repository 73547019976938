import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@/store';

export interface Draft {
    [jid: string]: string
}

const initialState: {
  draft: Draft
} = {
  draft: {}
}

const draft = createSlice({
  name: 'draft',
  initialState,
  reducers: {
    update(state, {payload}: {payload: {jid: string, draft: string}}) {
      const {jid, draft} = payload
      state.draft[jid] = draft
    },
    remove(state, {payload}: {payload: string}) {
      delete state.draft[payload]
    },
    clear(state) {
      state.draft = {}
    }
  }
})


export const {update, remove, clear} = draft.actions
export const getDraft = (jid: string) => (state: RootState): string | undefined => state.draft.draft[jid]
export const getAllDraft = (state: RootState): Draft => state.draft.draft
export default draft.reducer
