import styles from './CirclePreloader.module.scss'
import classNames from 'classnames';

interface CirclePreloaderProps {
  progress?: number,
  withoutProgress?: boolean
  color?: string,
  className?: string
}

const CirclePreloader = ({progress, color = '#fff', className, withoutProgress}: CirclePreloaderProps) => {

  return <svg className={classNames(
    styles.container,
    !progress && !withoutProgress && 'visually-hidden',
    withoutProgress && styles.infinityProgress,
    className)} viewBox="2 -2 28 36">
    <circle
      className={styles.progress}
      r={16}
      cx={16}
      cy={16}
      style={{
        strokeDashoffset: withoutProgress ? 30 : 100 - (progress || 0),
        stroke: color,
        transition: (progress || 0) > 0 ? undefined : 'none',
      }}
    />
  </svg>
}

export default CirclePreloader;
