export enum SocketEvents {
  onClose = 'onClose',
  onOpen = 'onOpen',
  onMessage = 'onMessage',
  onStateChanged = 'onStateChanged'
}

export interface SocketEventMap extends Record<string, (arg: any) => void> {
  [SocketEvents.onClose]: (event: CloseEvent) => void
  [SocketEvents.onMessage]: (data: any) => void
  [SocketEvents.onOpen]: () => void
  [SocketEvents.onStateChanged]: (state: boolean) => void
}
