import {useEffect, useState} from 'react';
import BaseModalWithTitle, {BaseModalWithTitleProps} from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import {Chat, getActiveChatId, getChat, updateChat} from '@/store/chats/chats';
import {QRCode, ErrorCorrectLevel, QR8BitByte} from 'qrcode-generator-ts/js';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import styles from './GroupChatType.module.scss'
import {setTypeChat} from '@/api/chats';
import Field from '@/components/Primitive/Field/Field';
import {ReactComponent as LinkImage} from '@/images/icons/link.svg';
import {ReactComponent as PrivateGroupImage} from '@/images/icons/private-group.svg';
import {ReactComponent as PublicGroupImage} from '@/images/icons/public-group.svg';
import {ReactComponent as ShareImage} from '@/images/icons/share.svg';
import {FormattedMessage, useIntl} from 'react-intl';
import RadioButtons, {RadioButtonItem} from '@/components/Primitive/RadioButtons/RadioButtons';
import {checkOwner, getChatId} from '@/utils/chats';
import {getUser, IInitUser} from '@/store/user/user';
import {showOnlyImage} from '@/components/Modal/ImageViewer/ImageViewer';
import {getChatsCache} from '@/store/cache/chats';

interface SelectChatsModalProps extends Omit<BaseModalWithTitleProps, 'title'> {
  chats?: Chat[]
  title?: string
  maxSelected?: number
  selectButtonName?: string
  showCancelButton?: boolean
  cancelButtonName?: string
  onAdd?: (chats: Chat[]) => void
}

enum RADIO_BUTTON_ACTIONS {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

const radioButtonList: RadioButtonItem[] = [
  {action: RADIO_BUTTON_ACTIONS.PRIVATE, title: 'GROUP_CHAT.PRIVATE_GROUP', SvgIcon: PrivateGroupImage},
  {action: RADIO_BUTTON_ACTIONS.PUBLIC, title: 'GROUP_CHAT.PUBLIC_GROUP', SvgIcon: PublicGroupImage},
]

const generatorQRCode = (data: string) => {
  let qr = new QRCode()
  qr.setTypeNumber(5)
  qr.setErrorCorrectLevel(ErrorCorrectLevel.L)
  qr.addData(new QR8BitByte(data))
  qr.make()

  return qr.toDataURL()
}

const getLink = (user?: IInitUser | null, chat?: Chat | null): string => {
  const base = process.env.REACT_APP_PUBLIC_URL + ''
  const url = new URL(base)
  if (!chat || !user) {
    return url.href
  }
  url.searchParams.append('join', getChatId(chat.$jid))
  url.searchParams.append('ref', getChatId(user.$jid || ''))
  return url.href
}

const GroupChatType = ({...props}: SelectChatsModalProps) => {
  const {hide} = props
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const activeChatJid = useAppSelector(getActiveChatId)
  const activeChat = useAppSelector(getChat(activeChatJid || ''))
  const chatCache = useAppSelector(getChatsCache)
  const [cachedChat, setCachedChat] = useState<Chat | null>(activeChat ? {
    ...chatCache[activeChat.$jid],
    ...activeChat
  } : null)
  const [link, setLink] = useState(getLink(user, activeChat))
  const [QRCodeUrl, setQRCodeUrl] = useState('')
  const [isPublic, setIsPublic] = useState(activeChat?.groupType === 'public')
  const [isLoading, setIsLoading] = useState(false)
  const [isOwner, setIsOwner] = useState(checkOwner(user?.$jid || '', activeChat?.members || []))
  const {formatMessage} = useIntl()

  useEffect(() => {
    setCachedChat(prevState => {
      if (!activeChat) {
        return activeChat
      }
      if (prevState?.$jid === activeChat.$jid) {
        return {
          ...prevState,
          ...activeChat
        }
      }
      return {
        ...chatCache[activeChat.$jid],
        ...activeChat
      }
    })
  }, [activeChat, chatCache])

  useEffect(() => {
    setIsOwner(checkOwner(user?.$jid || '', cachedChat?.members || []))
  }, [user?.$jid, cachedChat?.members])

  useEffect(() => {
    setQRCodeUrl(generatorQRCode(link))
  }, [link])

  useEffect(() => {
    const url = getLink(user, cachedChat)
    if (link !== url) {
      setLink(url)
    }
  }, [cachedChat, link, user])

  const changeType = (action: string) => {
    if (!cachedChat || isLoading) {
      return
    }
    const isPublicGroup = action === RADIO_BUTTON_ACTIONS.PUBLIC
    if (isPublicGroup === isPublic) {
      return;
    }
    setIsLoading(true)
    setIsPublic(isPublicGroup)
    setTypeChat(cachedChat, isPublicGroup)
      .then(() => {
        dispatch(updateChat({
          chatJid: cachedChat.$jid,
          options: {groupType: isPublicGroup ? 'public' : 'private'},
        }))
      })
      .catch(() => {
        setIsPublic(!isPublicGroup)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setIsPublic(cachedChat?.groupType === 'public')
  }, [cachedChat?.groupType])

  const handleShareQRCode = () => {
    hide?.()
    showOnlyImage({
      url: QRCodeUrl,
      imageName: 'qrcode.gif'
    })
  }

  return <BaseModalWithTitle
    title={formatMessage({id: isOwner ? 'group_type' : 'share_group'})}
    {...props}
  >
    {isOwner && activeChat?.membersLoaded && <>
      <FormattedMessage id={'select_group_type'}>
        {txt => <p className={styles.title}>{txt}</p>}
      </FormattedMessage>
      <FormattedMessage id={'for_private_groups_only'}>
        {txt => <p className={styles.desc}>{txt}</p>}
      </FormattedMessage>
      <RadioButtons
        className={styles.radioButtons}
        list={radioButtonList.map(item => ({
          ...item,
          title: formatMessage({id: item.title}),
        }))}
        value={isPublic ? 'public' : 'private'}
        disable={isLoading}
        onChange={changeType}
      />
    </>}
    {
      cachedChat?.groupType && isPublic && <>
        <Field
          className={styles.field}
          text={link}
          SvgIcon={LinkImage}
          highlighted={true}
          button={'copy'}
        />
        <div className={styles.qrBox}>
          <div className={styles.qrInfo}>
            <FormattedMessage id={'qr_code'}>
              {txt =><p className={styles.qrTitle}>{txt}</p>}
            </FormattedMessage>
            <FormattedMessage id={'copy_this_qr_code'}>
              {txt => <p className={styles.qrDesc}>{txt}</p>}
            </FormattedMessage>
            <FormattedMessage id={'share_qr_code'}>
              {txt => <p
                className={styles.qrLink}
                onClick={handleShareQRCode}
              >
                <ShareImage />
                {txt}</p>}
            </FormattedMessage>
          </div>
          <img
            className={styles.qrImg}
            src={QRCodeUrl}
            alt="QRCode"
          />
        </div>
      </>
    }
  </BaseModalWithTitle>
}

export default GroupChatType
