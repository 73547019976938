import styles from './StickerMessage.module.scss'
import generalStyle from '../ChatMessage.module.scss'
import {IMessageComponent} from '@/components/Chat/ChatMessage/ChatMessage';
import {IMessage, IStickerMessage, update as updateMessages} from '@/store/messages/messages';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {getStickerInfo} from '@/api/stickers';
import {getLastTime} from "@/utils/dateutils";
import ReplyMessage from '@/components/Chat/ChatMessage/ReplyMessage/ReplyMessage';
import classNames from 'classnames';
import {getActiveChatId, getChat} from "@/store/chats/chats";
import { INews } from '@/store/news/news'

const ANIMATION_TIME = 5000

export const isStickerMessage = (msg: IMessage | INews): msg is IStickerMessage => {
  return 'sticker' in msg
}

const StickerMessage = ({msg, incoming}: IMessageComponent) => {
  const dispatch = useAppDispatch()
  const [url, setUrl] = useState<string>()
  const [anim, setAnim] = useState(false)
  const [hover, setHover] = useState(false)
  const timerAnimation = useRef<NodeJS.Timer>()
  const loading = useRef(false)
  const activeChatId = useAppSelector(getActiveChatId)
  const activeChat = useAppSelector(getChat(activeChatId + ''))

  useEffect(() => {
    if (!isStickerMessage(msg)) {
      return
    }
    if (!msg.sticker.url && !loading.current) {
      loading.current = true
      getStickerInfo(msg.sticker.id)
        .then(result => {
          const newMessage = {
            ...msg,
            sticker: {
              ...msg.sticker,
              url: result.url,
              ff: result.ff
            }
          }
          const jid = incoming && !msg.to.endsWith(`@conference.${process.env.REACT_APP_EJ_HOST}`) ? msg.from : msg.to
          dispatch(updateMessages({jid, messages: [newMessage]}))
        })
        .finally(() => {
          loading.current = false
        })
    }
  }, [msg, dispatch, incoming])

  useEffect(() => {
    if (!isStickerMessage(msg)) {
      return
    }
    if (msg.sticker.url && msg.sticker.url !== url) {
      setUrl(msg.sticker.url)
    }
  }, [msg, url])

  const startTimer = useCallback(() => {
    clearTimeout(timerAnimation.current)
    setAnim(true)
    timerAnimation.current = setTimeout(() => {
      setAnim(false)
    }, ANIMATION_TIME)
  }, [])

  useEffect(() => {
    if (!url) {
      return
    }
    startTimer()
  }, [url, startTimer])

  useEffect(() => {
    return () => {
      clearTimeout(timerAnimation.current)
    }
  }, [])

  if (!isStickerMessage(msg)) {
    return null
  }

  return <div
    className={classNames(
      styles.box,
      !!msg.replyMessage && styles.replyBox,
      incoming && styles.incoming
    )}
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
    onClick={startTimer}
  >
    {!!msg.replyMessage && <ReplyMessage message={msg.replyMessage}/>}
    <img
      className={styles.img}
      src={(hover|| anim) ? msg.sticker.url : msg.sticker.ff || msg.sticker.url}
      alt=""
    />
    <div className={styles.bottom}>
      <span className={styles.time}>
        {activeChat?.pin?.uid === msg.id && activeChat.pin.visible
          && <i className={classNames("chat-pin", styles.chatPin)} />}
        {getLastTime(msg.timestamp)}
      </span>
      {!incoming && (msg.status === 'sent' || msg.status === 'received')
        ? <i className={classNames(
          "chat-check",
          generalStyle.tick,
          msg.status === 'sent' ? styles.sent : null)}/> : null}
      {!incoming && msg.status === 'displayed' ? <i className={classNames(
        'chat-double-check seen',
        generalStyle.tick,
      )}/> : null}
      {!incoming && msg.status === 'sending' ? <i className={classNames(
        "chat-clock",
        generalStyle.tick,
        styles.sent)}/> : null}
    </div>
  </div>
}

export default StickerMessage
