import styles from './ParticipantItem.module.scss'
import generalStyles from '@/styles/YcGeneral.module.scss'
import {CSSProperties, memo, MouseEventHandler, MouseEvent, useMemo} from 'react';
import {Affiliation} from '@/store/chats/chats';
import {areEqual} from 'react-window';
import Avatar from '@/components/Avatar/Avatar';
import {ReactComponent as MenuImage} from '@/images/icons/kebab-menu.svg';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

export interface IChatMember {
  jid: string,
  name: string,
  url: string | undefined,
  affiliation: Affiliation
  muted?: boolean
  nick?: string
  isTeam?: boolean
}

type onMenuClickCb = (user: IChatMember, event: MouseEvent) => void

export interface ParticipantsData {
  users: IChatMember[],
  canManage?: boolean
  canOpenChat?: boolean
  isOwner: boolean
  isAdmin: boolean
  onMenuClick?: onMenuClickCb
  onOpenChat?: (user: IChatMember) => void
}

interface ParticipantItemProps {
  index: number,
  style: CSSProperties,
  data: ParticipantsData
}

const ParticipantItem = memo(({index, style, data}: ParticipantItemProps) => {
  const {
    users,
    onMenuClick,
    isOwner,
    isAdmin,
    canManage = true,
    canOpenChat,
    onOpenChat
  } = data
  const user = users[index]

  const handleClickMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    onMenuClick?.(user, e)
  }

  const showClickMenu = useMemo(() => {
    if (isOwner) {
      return user.affiliation !== 'owner'
    }
    if (isAdmin) {
      return user.affiliation !== 'owner' && user.affiliation !== 'admin'
    }
    return false
  }, [user.affiliation, isOwner, isAdmin])


  return <div style={style} className={styles.participant}>
    <Avatar src={user.url} name={user.nick ? user.nick : user.name}/>
    <p className={styles.userName}>
      {user.isTeam && <span className={classNames(generalStyles.teamIcon, styles.teamIcon)}>L1</span>}
      {!!user.nick ? user.nick : user.name}
    </p>
    {['owner', 'admin'].includes(user.affiliation) && <FormattedMessage id={user.affiliation === 'owner' ?
      'GROUP_CHAT.OWNER' : 'GROUP_CHAT.ADMIN'}>
      {txt => <span className={styles.memberType}>{txt}</span>}
    </FormattedMessage>}
    {canOpenChat && isOwner && user.affiliation !== 'owner'
      && <IconButton
        className={styles.openChatBtn}
        onClick={() => onOpenChat?.(user)}
      >
      <i className='chat-send'/>
    </IconButton>}
    {canManage && showClickMenu && <IconButton
      className={classNames(styles.menuBtn, user.muted && styles.muted)}
      onClick={handleClickMenu}
    ><MenuImage/></IconButton>}
  </div>
}, areEqual)

export default ParticipantItem
