import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@/store';

const initialState = {
  visibility: false,
}

const pageState = createSlice({
  name: 'pageState',
  initialState,
  reducers: {
    changeState(state, {payload}: { payload: boolean }) {
      state.visibility = payload
    },
  },
})

export const {changeState} = pageState.actions
export const isVisible = (state: RootState) => state.pageState.visibility
export default pageState.reducer
