import styles from './SelectChatsModal.module.scss'
import BaseModalWithTitle, {BaseModalWithTitleProps} from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import {useIntl} from 'react-intl';
import Search from '@/components/Primitive/Inputs/Search/Search';
import {useState} from 'react';
import ContactSelect from '@/components/ContactSelect/ContactSelect';
import {Chat} from '@/store/chats/chats';
import Button from '@/components/Primitive/Buttons/Button/Button';
import {getScreenHeight} from '@/store/screenSize/screenSize';
import {useAppSelector} from '@/hooks/appHook';

interface SelectChatsModalProps extends Omit<BaseModalWithTitleProps, 'title'> {
  chats?: Chat[]
  title?: string
  maxSelected?: number
  selectButtonName?: string
  showCancelButton?: boolean
  cancelButtonName?: string
  onAdd?: (chats: Chat[]) => void
}

const SelectChatsModal = (
  {
    chats = [],
    hide,
    title,
    selectButtonName,
    maxSelected,
    showCancelButton,
    cancelButtonName,
    onAdd,
    ...props
  }: SelectChatsModalProps) => {
  const screenHeight = useAppSelector(getScreenHeight)
  const [filter, setFilter] = useState('')
  const [selectedChats, setSelectedChats] = useState<Chat[]>([])
  const {formatMessage} = useIntl()

  const handleAddClick = () => {
    onAdd?.(selectedChats)
  }

  const handleCancel = () => {
    if (props.canBack && props.onBack) {
      props.onBack()
    } else {
      hide?.()
    }
  }

  return <BaseModalWithTitle
    {...props}
    title={title || formatMessage({id: 'CONTACTS.MY_CONTACTS'})}
    hide={hide}
  >
    <Search wrapperClassName={styles.search} onChange={setFilter}/>
    <ContactSelect
      className={styles.selector}
      contacts={chats}
      filter={filter}
      maxSelected={maxSelected || chats.length}
      onSelected={setSelectedChats}
      maxHeight={(screenHeight || 205) - 205}
    />
    <div className={styles.buttons}>
      <Button
        view={'main'}
        onClick={handleAddClick}
        disabled={!selectedChats.length}
      >{selectButtonName || formatMessage({id: 'ACTION.ADD'})}
      </Button>
      {showCancelButton && <Button
        view={'link'}
        onClick={handleCancel}
      >{cancelButtonName || formatMessage({id: 'ACTION.CANCEL'})}</Button>}
    </div>
  </BaseModalWithTitle>
}

export default SelectChatsModal
