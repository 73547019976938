import {v4 as uuid} from 'uuid'
import {AnyObject} from '@/index';
import {ISentMessageWithReply, MediaType} from '@/interfaces/YC';
import {
  IAudioMessage,
  ICallMessage,
  IFileFields,
  IFileMessage,
  IImageMessage,
  IMessage,
  IMessageStatus, IStickerMessage,
  ITextMessage, IVideoMessage,
} from '@/store/messages/messages';
import {getMessageType, getSender} from '@/utils/messages';
import {isTextMessage} from '@/components/Chat/ChatMessage/TextMessage/TextMessage';
import {isImageMessage} from '@/components/Chat/ChatMessage/ImageMessage/ImageMessage';
import {isFileMessage} from '@/components/Chat/ChatMessage/FileMessage/FileMessage';
import {createStropheFromObject} from '@/services/utils';
import dayjs from 'dayjs';
import {Sticker} from '@/store/stickers/stickers';
import {isAudioMessage} from '@/components/Chat/ChatMessage/AudioMessage/AudioMessage';
import {isStickerMessage} from '@/components/Chat/ChatMessage/StickerMessage/StickerMessage';
import {isVideoMessage} from '@/components/Chat/ChatMessage/VideoMessage/VideoMessage';
import { isCallMessage } from '@/components/Chat/ChatMessage/CallMessage/CallMessage'

export interface IForwardedFrom {
  forwardedFrom: string
}

interface INotifyMessage {
  to: string,
  from: string
}

interface INotifyStatusMessage extends INotifyMessage {
  from: string,
  to: string,
  messageId: string,
  timestamp: number,
  thread?: string,
  isRoom?: boolean
}

interface IMessageParams extends Partial<IForwardedFrom>, INotifyMessage {
  thread?: string,
  isRoom?: boolean,
  uid?: string
  replyMessage?: IMessage | null
}

interface ITextMessageParams extends IMessageParams {
  text: string,
}

interface IImageMessageParams extends IMessageParams {
  url: string,
  thumbnail: string
}

interface IFileMessageParams extends IMessageParams {
  file: IFileFields
}

interface IAudioMessageParams extends IMessageParams {
  url: string
  name?: string
}

interface IVideoMessageParams extends IMessageParams {
  url: string,
  thumbnail?: string,
  name?: string
}

interface IStickerMessageParams extends IMessageParams {
  sticker: Partial<Omit<Sticker, 'id'>> & {
    id: string
  }
}

interface CreateMessageOptions extends Partial<IForwardedFrom> {
  from: string,
  to: string,
  uid: string,
  isRoom: boolean,
  thread?: string,
  replyMessage?: IMessage | null
}

const createUniqStructureForTextReply = (message: ITextMessage) => ({
  body: message.text,
})

const createUniqStructureForImageReply = (message: IImageMessage) => ({
  yc: {
    media: {
      item: {
        $uid: message.id,
        $type: MediaType.image,
        $url: message.image.url,
        $thumbnail: message.image.thumbnail,
      },
    },
  },
})

const createUniqStructureForFileReply = (message: IFileMessage) => {
  const fileAttrs: AnyObject = {}
  Object.keys(message.file).forEach(key => {
    fileAttrs['$' + key] = message.file[key as keyof IFileFields]
  })
  return {
    yc: {
      file: {
        $xmlns: 'urn:yc:msg:f:0',
        ...fileAttrs,
      },
    },
  }
}

const createUniqStructureForAudioReply = (message: IAudioMessage) => {
  return {
    yc: {
      media: {
        item: {
          $uid: message.id,
          $type: MediaType.audio,
          $url: message.url,
        },
      },
    },
  }
}

const createUniqStructureForVideoReply = (message: IVideoMessage) => {
  return {
    yc: {
      media: {
        item: {
          $uid: message.id,
          $type: MediaType.video,
          $url: message.url,
          ...(message.thumbnail && {$thumbnail: message.thumbnail}),
          ...(message.name && {$name: message.name})
        },
      },
    },
  }
}

const createUniqStructureForStickerReply = (message: IStickerMessage) => {
  const {sticker} = message
  return {
    yc: {
      sticker: {
        info: {
          $stickerId: sticker.id,
          $url: sticker.url,
          $ff: sticker.ff,
        },
      },
    },
  }
}

const createUniqStructureForCallReply = (message: ICallMessage) => {
  const {call} = message
  return {
    yc: {
      call: {
        info: {
          $type: call?.type || '',
          $duration: call?.duration || 0
        }
      }
    },
  }
}

const createStructureForReply = (replyMessage: IMessage) => {
  const {id, from, to, timestamp, status} = replyMessage
  const uniqParams = {
    ...(isTextMessage(replyMessage) ? createUniqStructureForTextReply(replyMessage) : null),
    ...(isImageMessage(replyMessage) ? createUniqStructureForImageReply(replyMessage) : null),
    ...(isFileMessage(replyMessage) ? createUniqStructureForFileReply(replyMessage) : null),
    ...(isAudioMessage(replyMessage) ? createUniqStructureForAudioReply(replyMessage) : null),
    ...(isVideoMessage(replyMessage) ? createUniqStructureForVideoReply(replyMessage) : null),
    ...(isStickerMessage(replyMessage) ? createUniqStructureForStickerReply(replyMessage) : null),
    ...(isCallMessage(replyMessage) ? createUniqStructureForCallReply(replyMessage) : null)
  }
  return {
    replyMessage: {
      $id: id,
      $from: from,
      $to: to,
      $timestamp: timestamp,
      $status: status,
      ...uniqParams,
      yc: {
        ...uniqParams.yc,
      },
    },
  }
}

const createUniqFieldsForFile = (message: ISentMessageWithReply) => {
  const {$mimetype, $url, $name, $token, $size} = message.yc.file || {}
  return !!message.yc.file ? {
    file: {
      mimetype: $mimetype,
      url: $url,
      name: $name,
      token: $token,
      size: $size,
    },
  } : null
}

const createUniqFieldsForAudio = (message: ISentMessageWithReply) => {
  return getMessageType(message) === 'audio' ?
    {
      url: message.yc.media!.item.$url || '',
      ...(!!message.yc.media!.item.$name && {name: message.yc.media!.item.$name})
    } : null
}

const createUniqFieldsForVideo = (message: ISentMessageWithReply) => {
  return getMessageType(message) === 'video' ?
    {
      url: message.yc.media!.item.$url || '',
      ...(message.yc.media!.item.$thumbnail ? {thumbnail: message.yc.media!.item.$thumbnail} : null),
      ...(message.yc.media!.item.$name ? {name: message.yc.media!.item.$name} : null)
    } : null
}

const createIniqFieldsForImage = (message: ISentMessageWithReply) => {
  return getMessageType(message) === 'image' ?
    {
      image: {
        url: message.yc.media!.item.$url,
        thumbnail: message.yc.media!.item.$thumbnail || message.yc.media!.item.$url,
      },
    } : null
}

const createUniqFieldsForText = (message: ISentMessageWithReply) => {
  return typeof message.body === 'string' ?
    {text: message.body || ''} : null
}

const createUniqFieldsForSticker = (message: ISentMessageWithReply) => {
  const sticker = message.yc.sticker
  return !!sticker ?
    {
      sticker: {
        id: sticker.info.$stickerId,
        ...(sticker.info.$url && {url: sticker.info.$url}),
        ...(sticker.info.$ff && {ff: sticker.info.$ff}),
      },
    } : null
}

const createUniqFieldsForCall = (message: ISentMessageWithReply) => {
  const call = message.yc.call
  if (!call) {
    return null
  }
  return {
    call: {
      type: call.info.$type,
      duration: +call.info.$duration
    }
  }
}

const createMessage = (paramsObj: AnyObject, options: CreateMessageOptions): Element => {
  const {uid, thread, from, to, isRoom, replyMessage, forwardedFrom} = options

  const defaultParams = {
    $from: from,
    $to: to,
    $id: uid,
    $type: isRoom ? 'groupchat' : 'chat',
    request: {
      $xmlns: 'urn:xmpp:receipts',
    },
    store: {
      $xmlns: 'urn:xmpp:hints',
    },
    ...(!!thread && {
      thread: thread,
    }),
    ...(!!forwardedFrom && {
      forwardedFrom: {
        $jid: forwardedFrom,
      },
    }),
    ...(!!replyMessage && createStructureForReply(replyMessage)),
  }
  const ycWithDefault = {
    $xmlns: 'urn:yc:message:data',
    info: {
      $uid: uid || uuid(),
      $sent: new Date().getTime(),
    },
    ...paramsObj.yc,
  }
  const props = {
    ...defaultParams,
    ...paramsObj,
    yc: ycWithDefault,
  }
  return createStropheFromObject('message', props).tree()
}

interface IParseOptions {
  status?: IMessageStatus
}

interface ICreateForwardMessageProps extends IForwardedFrom {
  from: string,
  to: string,
  isRoom?: boolean,
  message: IMessage
}

type ICreateHistoryMessageProps = ({
  timestampStart: number
  timestampStop?: number
} | {
  timestampStart?: number
  timestampStop: number
}) & {
  to?: string
}


class Messages {
  static Messages = {
    parseMessage(message: ISentMessageWithReply, {status = 'sent'}: IParseOptions = {}) {
      const replyMessage: IMessage | undefined = message.replyMessage && {
        id: message.replyMessage.$id,
        from: message.replyMessage.$from,
        to: message.replyMessage.$to,
        type: getMessageType(message.replyMessage),
        timestamp: message.replyMessage.$timestamp || 0,
        ...(message.replyMessage.$status && {status: message.replyMessage.$status}),
        ...createUniqFieldsForText(message.replyMessage),
        ...createIniqFieldsForImage(message.replyMessage),
        ...createUniqFieldsForAudio(message.replyMessage),
        ...createUniqFieldsForVideo(message.replyMessage),
        ...createUniqFieldsForFile(message.replyMessage),
        ...createUniqFieldsForSticker(message.replyMessage),
        ...createUniqFieldsForCall(message.replyMessage)
      }
      const {sender} = getSender(message)
      const formatMessage: IMessage = {
        id: message.yc.info.$uid,
        type: getMessageType(message),
        from: sender,
        to: message.$to,
        status: status,
        timestamp: message.archived?.$id ? parseInt(message.archived?.$id) : new Date().getTime() * 1000,
        ...(message.thread && {thread: message.thread}),
        ...createUniqFieldsForText(message),
        ...createIniqFieldsForImage(message),
        ...createUniqFieldsForAudio(message),
        ...createUniqFieldsForVideo(message),
        ...createUniqFieldsForFile(message),
        ...createUniqFieldsForSticker(message),
        ...createUniqFieldsForCall(message),
        ...(message.forwardedFrom && {
          forwardedFrom: message.forwardedFrom.$jid,
        }),
        ...(replyMessage && {replyMessage}),
      }
      return formatMessage
    },
    createTextMessage(
      {
        from,
        to,
        text,
        thread,
        isRoom = false,
        replyMessage,
        forwardedFrom,
      }: ITextMessageParams) {
      const uid = uuid()
      return createMessage({
        body: text,
      }, {from, to, uid, thread: thread || uid, isRoom, replyMessage, forwardedFrom})
    },
    createImageMessage(
      {
        to,
        from,
        isRoom = false,
        thread,
        url,
        thumbnail,
        uid = uuid(),
        replyMessage,
        forwardedFrom,
      }: IImageMessageParams) {
      return createMessage({
        yc: {
          media: {
            item: {
              $uid: uid,
              $type: MediaType.image,
              $url: url,
              $thumbnail: thumbnail,
            },
          },
        },
      }, {from, to, uid, thread, isRoom, replyMessage, forwardedFrom})
    },
    createFileMessage(
      {
        from,
        to,
        isRoom = false,
        thread,
        uid = uuid(),
        file,
        replyMessage,
        forwardedFrom,
      }: IFileMessageParams) {
      let fileAttrs: AnyObject = {...file}
      Object.keys(file).forEach(key => {
        fileAttrs['$' + key] = fileAttrs[key]
        delete fileAttrs[key]
      })
      return createMessage({
        yc: {
          file: {
            $xmlns: 'urn:yc:msg:f:0',
            ...fileAttrs,
          },
        },
      }, {from, to, isRoom, thread, uid, replyMessage, forwardedFrom})
    },
    createAudioMessage(
      {
        from,
        to,
        isRoom = false,
        thread,
        uid = uuid(),
        replyMessage,
        forwardedFrom,
        url,
        name
      }: IAudioMessageParams) {
      return createMessage({
        yc: {
          media: {
            item: {
              $uid: uid,
              $type: MediaType.audio,
              $url: url,
              ...(name && {$name: name})
            },
          },
        },
      }, {from, to, isRoom, thread, uid, replyMessage, forwardedFrom})
    },
    createVideoMessage(
      {
        from,
        to,
        isRoom = false,
        thread,
        uid = uuid(),
        replyMessage,
        forwardedFrom,
        url,
        thumbnail,
        name
      }: IVideoMessageParams) {
      return createMessage({
        yc: {
          media: {
            item: {
              $uid: uid,
              $type: MediaType.video,
              $url: url,
              ...(thumbnail && {$thumbnail: thumbnail}),
              ...(name && {$name: name})
            },
          },
        },
      }, {from, to, isRoom, thread, uid, replyMessage, forwardedFrom})
    },
    createStickerMessage(
      {
        from,
        to,
        isRoom = false,
        thread,
        uid = uuid(),
        replyMessage,
        forwardedFrom,
        sticker,

      }: IStickerMessageParams) {
      return createMessage({
        yc: {
          sticker: {
            info: {
              $xmlns: 'urn:yc:msg:s:0',
              $stickerId: sticker.id,
              $url: sticker.url,
              $ff: sticker.ff,
            },
          },
        },
      }, {from, to, isRoom, uid, forwardedFrom, replyMessage, thread})
    },
    createForwardMessage(
      {
        from,
        to,
        message,
        isRoom = false,
        forwardedFrom,
      }: ICreateForwardMessageProps): Element {
      let msg: Element
      switch (message.type) {
        case 'text':
          msg = this.createTextMessage({
            from,
            to,
            text: (message as ITextMessage).text,
            isRoom,
            forwardedFrom,
          })
          break
        case 'image':
          msg = this.createImageMessage({
            to,
            from,
            isRoom,
            url: (message as IImageMessage).image.url,
            thumbnail: (message as IImageMessage).image.thumbnail,
            forwardedFrom,
          })
          break
        case 'file':
          msg = this.createFileMessage({
            from,
            to,
            isRoom,
            file: {
              ...(message as IFileMessage).file,
            },
            forwardedFrom,
          })
          break
        case 'audio':
          msg = this.createAudioMessage({
            from,
            to,
            isRoom,
            url: (message as IAudioMessage).url,
            forwardedFrom,
          })
          break;
        case 'video':
          msg = this.createVideoMessage({
            from,
            to,
            isRoom,
            url: (message as IVideoMessage).url,
            name: (message as IVideoMessage).name,
            forwardedFrom
          })
          break;
        case 'sticker':
          msg = this.createStickerMessage({
            from,
            to,
            isRoom,
            forwardedFrom,
            sticker: (message as IStickerMessage).sticker,
          })
          break
        default:
          throw new Error(`There is no such message type for forward: ${message.type}`)
      }
      return msg
    },
    createHistoryMessage({timestampStart, timestampStop, to}: ICreateHistoryMessageProps) {
      const fields: {
        $var: string,
        $type?: string,
        value: string | number
      }[] = [
        {
          $var: 'FORM_TYPE',
          $type: 'hidden',
          value: 'urn:xmpp:mam:2',
        },
      ]
      if (timestampStart) {
        fields.push({
          $var: 'start',
          value: dayjs(timestampStart / 1000).toISOString(),
        })
      }
      if (timestampStop) {
        fields.push({
          $var: 'start',
          value: dayjs(timestampStop / 1000).toISOString(),
        })
      }
      return createStropheFromObject('iq', {
        $type: 'set',
        ...(!!to && {$to: to}),
        $id: uuid(),
        query: {
          $xmlns: 'urn:xmpp:mam:2',
          x: {
            $xmlns: 'jabber:x:data',
            $type: 'submit',
            field: fields,
          },
          // для ограничения, если понадобится, пока без ограничений
          // set: {
          //   $xmlns: 'http://jabber.org/protocol/rsm',
          //   max: 10,
          // }
        },
      }).tree()
    },
    createNotifyMessageReceived(
      {
        from,
        to,
        messageId,
        timestamp,
        thread,
        isRoom = false,
      }: INotifyStatusMessage) {
      return createStropheFromObject('message', {
        $from: from,
        $to: to,
        $id: uuid(),
        $type: isRoom ? 'groupchat' : 'chat',
        ...(!thread && {thread}),
        private: {
          $xmlns: 'urn:xmpp:carbons:2',
        },
        store: {
          $xmlns: 'urn:xmpp:hints',
        },
        'no-copy': {
          $xmlns: 'urn:xmpp:hints',
        },
        received: {
          $xmlns: 'urn:xmpp:receipts',
          $id: messageId,
          $timestamp: timestamp,
        },
      }).tree()
    },
    createNotifyMessageDisplayed(
      {
        from,
        to,
        messageId,
        timestamp,
        thread,
        isRoom = false,
      }: INotifyStatusMessage) {
      return createStropheFromObject('message', {
        $from: from,
        $to: to,
        $id: uuid(),
        $type: isRoom ? 'groupchat' : 'chat',
        ...(!!thread && {thread}),
        store: {
          $xmlns: 'urn:xmpp:hints',
        },
        displayed: {
          $xmlns: 'urn:xmpp:receipts',
          $id: messageId,
          $timestamp: timestamp,
        },
      }).tree()
    },
    createNotifyClearChatMessage({from, to}: INotifyMessage) {
      return createStropheFromObject('message', {
        $from: from,
        $to: to,
        $type: 'groupchat',
        id: uuid(),
        delete: {
          $xmlns: 'urn:yc:msg:d:all',
          $uid: uuid(),
        },
        store: {
          $xmlns: 'urn:xmpp:hints',
        },
      }).tree()
    },
  }
}

export default Messages
