import styles from './ShareFile.module.scss'
import BaseModalWithTitle from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import {BaseModalProps} from '@/components/Modal/BaseModal/BaseModal';
import {FormattedMessage, useIntl} from 'react-intl';
import Search from '@/components/Primitive/Inputs/Search/Search';
import React, {useEffect, useState} from 'react';
import ContactSelect from '@/components/ContactSelect/ContactSelect';
import {useAppSelector} from '@/hooks/appHook';
import {Chat, getMUChats, getPrivateChats} from '@/store/chats/chats';
import {contactListToArray} from '@/utils/chats';
import Button from '@/components/Primitive/Buttons/Button/Button';
import useMessage from '@/hooks/useMessage';
import {getUser} from '@/store/user/user';

interface ShareFileProps extends BaseModalProps {
  file: File | undefined
}

const sortChats = (v1: Chat, v2: Chat) => {
  return (v1.name || '') > (v2.name || '') ? 0 : -1
}

const ShareFile = ({file, hide}: ShareFileProps) => {
  const user = useAppSelector(getUser)
  const groups = useAppSelector(getMUChats)
  const contacts = useAppSelector(getPrivateChats)
  const [filter, setFilter] = useState('')
  const [sortedChats, setSortedChats] = useState([
    ...contactListToArray(contacts).sort(sortChats),
    ...contactListToArray(groups).sort(sortChats),
  ])
  const [selectedChats, setSelectedChats] = useState<Chat[]>([])
  const {sendFile} = useMessage()
  const {formatMessage} = useIntl()

  useEffect(() => {
    setSortedChats([
      ...contactListToArray(contacts).sort(sortChats),
      ...contactListToArray(groups).sort(sortChats),
    ])
  }, [contacts, groups])

  if (!file) {
    return null
  }

  const handleSendFile = () => {
    if (selectedChats.length === 0) {
      return
    }
    selectedChats.forEach(chat => {
      sendFile({
        file,
        toChat: chat,
        userJid: '' + user?.$jid
      })
    })
    hide?.()
  }

  return <BaseModalWithTitle
    title={formatMessage({id: 'ACTION.SEND'})}
    hide={hide}
  >
    <Search wrapperClassName={styles.search} light={true} onChange={setFilter}/>
    <ContactSelect
      contacts={sortedChats}
      filter={filter}
      onSelected={setSelectedChats}
      maxSelected={sortedChats.length}
    />
    <div className={styles.buttons}>
      <FormattedMessage id={'ACTION.SEND'}>
        {txt => <Button disabled={selectedChats.length < 1} onClick={handleSendFile}>
          {txt}
        </Button>}
      </FormattedMessage>
      <FormattedMessage id={'ACTION.CANCEL'}>
        {txt => <Button view={'link'} onClick={hide}>{txt}</Button>}
      </FormattedMessage>
    </div>
  </BaseModalWithTitle>
}

export default ShareFile
