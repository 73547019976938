import {useCallback, useEffect, useRef, useState} from 'react';

interface UseModalSimple {
  onHide?: () => void
}

const useModalSimple = ({onHide}: UseModalSimple = {}) => {
  const [visible, setVisible] = useState(false)
  const onHideRef = useRef(onHide)
  const firstStart = useRef(true)

  useEffect(() => {
    onHideRef.current = onHide
  }, [onHide])

  useEffect(() => {
    if (!visible && !firstStart.current) {
      onHideRef.current?.()
    }
    firstStart.current = false
  }, [visible])

  const show = useCallback(() => {
    setVisible(true)
  }, [])

  const hide = useCallback(() => {
    setVisible(false)
  }, [])

  return {visible, show, hide}
}

export default useModalSimple
