import Auth from '@/pages/Auth/Auth';
import React, {useCallback, useEffect, useState, useRef} from 'react';
import {useAppDispatch} from '@/hooks/appHook';
import {setToken, setUser} from '@/store/user/user';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {authenticate, login, loginBySso} from '@/api/auth';
import Captcha from '@/components/Captcha/Captcha';
import styles from './Login.module.scss';
import classNames from 'classnames';
import Logo from '@/components/Logo/Logo';
import {useIntl} from 'react-intl';
import {FormattedMessage} from 'react-intl';
import {CaptchaUpdateHandle} from '@/components/Captcha/Captcha';
import Button from '@/components/Primitive/Buttons/Button/Button';
import Lang from '@/components/Auth/inc/lang-dropdown/Lang';
import Input from '@/components/Primitive/Inputs/Input/Input';
import {
  changeSearchParams,
  getQueryParams
} from '@/utils/url';
import useUtils from '@/hooks/useUtils';
import {queries} from '@/constants/general';
import CirclePreloader from '@/components/Primitive/CirclePreloader/CirclePreloader';
import { isStatusError } from '@/api/interfaces';

interface LoginRouteProps extends React.PropsWithChildren {
  isLoggedIn?: boolean,
  lang: any,
}

const getSSOParams = () => {
  const params = new URLSearchParams(window.location.search)
  const id = params.get(queries.memberId)
  const token = params.get(queries.otp)
  if (!id || !token) {
    return {}
  }
  return {id, token}
}

const Login = ({isLoggedIn, lang}: LoginRouteProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [id, setId] = useState('')
  const [pass, setPass] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [captchaToken, setCaptchaToken] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [disabledInput, setDisabledInput] = useState(false)
  const [errorMessagePass, setErrorMessagePass] = useState(false)
  const [errorMessageCaptcha, setErrorMessageCaptcha] = useState(false)
  const [submitButtonDis, setSubmitButtonDis] = useState(true)
  const [processAuth, setProcessAuth] = useState(false)
  const [isSSOLogin, setIsSSOLogin] = useState(true)
  const {formatMessage} = useIntl()
  const CaptchaUpdateRef = useRef<CaptchaUpdateHandle>(null)
  const signRef = useRef<() => void>()
  const {langParam} = useParams()
  const {logout} = useUtils()

  const parseError = (error: any) => {
    CaptchaUpdateRef.current?.update()
    if (error?.reason === 'invalidCredentials') {
      setErrorMessagePass(true)
    } else if (error?.reason === 'captchaError') {
      setErrorMessageCaptcha(true)
    }
    setCaptcha('')
    setProcessAuth(false)
  }

  useEffect(() => {
    const {id, token} = getSSOParams()
    setIsSSOLogin(!!(id && token))
  }, []);

  const sign = useCallback(async () => {
    setProcessAuth(true)
    setErrorMessageCaptcha(false)
    setErrorMessagePass(false)
    setDisabledInput(true)
    const {id: ssoId, token: ssoToken} = getSSOParams()
    if (ssoId || ssoToken) {
      const params = new URLSearchParams(window.location.search)
      params.delete(queries.memberId)
      params.delete(queries.otp)
      changeSearchParams(params)
    }
    try {
      const userData = await ((ssoId && ssoToken) ?
        loginBySso({
          memberId: ssoId,
          otp: ssoToken,
        }) :
        login({
          memberId: id,
          password: pass,
          captcha: captcha,
          token: captchaToken,
        }))

      if (isStatusError(userData)) {
        parseError(userData.error)
        return
      }

      dispatch(setUser(userData))
      const {data: userToken} = await authenticate({
        memberId: userData.memberId,
        accessToken: userData.accessToken,
      })
      dispatch(setToken(userToken.token))
      setProcessAuth(false)
      navigate('/' + lang + getQueryParams())
    } catch (err) {
      console.error(err)  // eslint-disable-line no-console
    } finally {
      setDisabledInput(false)
      setIsSSOLogin(false)
    }

  }, [lang, dispatch, id, pass, captcha, navigate, captchaToken])

  useEffect(() => {
    if (isLoggedIn) {
      const {id, token} = getSSOParams()
      if (!!id && !!token) {
        logout(false)
      }
    }
  }, [isLoggedIn, logout]);

  signRef.current = sign

  useEffect(() => {
    if (isLoggedIn) {
      return;
    }
    const {id, token} = getSSOParams()
    if (!id || !token) {
      return
    }
    signRef.current?.()
  }, [isLoggedIn]);

  const forgotRedirect = () => {
    window.open(`https://login.adxsales.com/en/forgot`, '_blank')
  }

  const handleInputNumber =
    (e: React.ChangeEvent<HTMLInputElement>, cb: React.Dispatch<React.SetStateAction<string>>) => {
      const regex = /^[0-9\b]+$/
      if (e.target.value === '' || regex.test(e.target.value)) {
        cb(e.target.value)
      }
    }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, cb: React.Dispatch<React.SetStateAction<string>>) => {
    cb(e.target.value)
  }

  const handleShowPass = () => {
    setShowPass(!showPass)
  }

  useEffect(() => {
    setCaptcha('')
  }, [captchaToken])

  useEffect(() => {
    setErrorMessagePass(false)
  }, [pass])

  useEffect(() => {
    if (captcha !== '') {
      setErrorMessageCaptcha(false)
    }
  }, [captcha])

  useEffect(() => {
    if (id !== '' && pass !== '' && captcha !== '') {
      setSubmitButtonDis(false)
    } else {
      setSubmitButtonDis(true)
    }
  }, [id, pass, captcha])

  useEffect(() => {
    if (!langParam) {
      navigate('/' + lang + '/login' + getQueryParams())
    }
  }, [navigate, langParam, lang])

  if (isLoggedIn) {
    const {id, token} = getSSOParams()
    if (!id || !token) {
      return <Navigate to={`/` + lang + getQueryParams()} replace/>
    }
  }

  const onCaptchaKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      sign().then(() => {

      })
    }
  }

  return (
    <div
      style={{backgroundImage: `url('${process.env.PUBLIC_URL}/assets/img/bg/1l.jpg')`}}
      className={classNames(styles.layout)}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.left}>
            <Logo className={styles.logo}/>
          </div>
          <div className={styles.right}>
            <Lang/>
          </div>
        </div>
        {isSSOLogin && <CirclePreloader
          className={styles.preloader}
          withoutProgress={true}
        />}
        {!isSSOLogin && <div className={classNames(styles.body)}>
          <Auth>
            <h4>{formatMessage({id: 'welcome_text'})}</h4>
            <div className={styles.row}>
              <div className={styles.labelText}>
                {formatMessage({id: 'enterMemberId'})}
              </div>
              <label>
                <Input
                  disabled={disabledInput}
                  type="text"
                  value={id}
                  onChange={(e) => handleInputNumber(e, setId)} maxLength={7}
                  placeholder={formatMessage({id: 'PROFILE.MEMBERID'})}
                />
              </label>
            </div>
            <div className={styles.row}>
              <div className={styles.labelText}>
                {formatMessage({id: 'enterPassword'})}
              </div>
              <label>
                <Input
                  disabled={disabledInput}
                  type={showPass ? 'text' : 'password'}
                  value={pass}
                  maxLength={32}
                  className={errorMessagePass ? styles.error : ''}
                  onChange={(e) => handleInput(e, setPass)}
                  placeholder={formatMessage({id: 'PASSWORD_PLACEHOLDER'})}
                />
                <i onClick={handleShowPass} className={showPass ? 'chat-eye-o-off' : 'chat-eye-o'}/>
              </label>
              {errorMessagePass ? <div className={styles.errorText}>
                <FormattedMessage id="LOGIN.INVALID_CREDENTIALS"></FormattedMessage>
              </div> : null}
            </div>
            <div className={styles.row}>
              <div className={styles.labelText}>
                {formatMessage({id: 'enterCaptcha'})}
              </div>
              <div className={styles.rowCaptcha}>
                <label>
                  <Input
                    disabled={disabledInput}
                    type="text"
                    value={captcha}
                    className={errorMessageCaptcha ? styles.error : ''}
                    onChange={(e) => handleInputNumber(e, setCaptcha)} maxLength={4}
                    onKeyPress={onCaptchaKeyPress}
                  />
                </label>
                <Captcha className={styles.captcha} onChange={setCaptchaToken} ref={CaptchaUpdateRef}/>
              </div>
              {errorMessageCaptcha ? <div className={styles.errorText}>
                <FormattedMessage id="err_captcha"></FormattedMessage>
              </div> : null}
            </div>
            <Button onClick={sign} className={styles.logInBtn} disabled={submitButtonDis || processAuth}>
              <FormattedMessage id="LOGIN.TAB_LABEL"></FormattedMessage>
            </Button>
            <Button view={'link'} onClick={forgotRedirect} className={styles.forgotBtn}>
              <FormattedMessage id="FORGOT.FORGOT_PASSWORD"></FormattedMessage>
            </Button>
          </Auth>
        </div>}
      </div>
    </div>
  )
}

export default Login;
