import {useIntl} from "react-intl";
import styles from './ConnectPanel.module.scss'
import classNames from "classnames";
import ConnectIcon from '@/components/ConnectIcon/ConnectIcon';

interface ConnectPanelProps {
  className?: string
}
const ConnectPanel = ({className}: ConnectPanelProps) => {
  const {formatMessage} = useIntl()
  return <div className={classNames(styles.panel, className)}>
    <ConnectIcon className={styles.connectIcon}/>
    <p className={styles.text}>{formatMessage({id: "connecting_message"})}</p>
  </div>
}

export default ConnectPanel
