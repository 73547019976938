import styles from './FileMessage.module.scss'
import generalStyle from '../ChatMessage.module.scss'
import {IMessageComponent} from '@/components/Chat/ChatMessage/ChatMessage';
import {ReactComponent as FileImage} from '@/images/icons/file-download.svg';
import GeneralMessage from '@/components/Chat/ChatMessage/GeneralMessage/GeneralMessage';
import {IFileMessage, IMessage} from '@/store/messages/messages';
import {downloadFileWithProgress} from '@/api/upload';
import React, {useCallback, useEffect, useState} from 'react';
import {AxiosProgressEvent} from 'axios';
import { formatFileSize, getFileExtension, getMimeType } from '@/utils/file';
import {FileType, FileTypes, show as showViewer} from '@/components/Modal/FileViewer/FileViewer';
import {saveAs} from 'file-saver';
import CirclePreloader from '@/components/Primitive/CirclePreloader/CirclePreloader';
import {createPubSub} from '@inficen/react-events';
import {getLastTime} from "@/utils/dateutils";
import classNames from "classnames";
import {useAppSelector} from "@/hooks/appHook";
import {getActiveChatId, getChat} from "@/store/chats/chats";
import { INews } from '@/store/news/news'

type Event = {
  name: string,
  payload: {
    messageId: string,
    force?: boolean
  }
}

const {publish, useSubscribe} = createPubSub<Event>()

interface ILoadFileNotify {
  messageId: string,
  force?: boolean
}

const DEFAULT_NAME = 'FileMessageNotifier'
const LOAD_FILE_EVENT = 'loadFileEvent'
export const loadFileNotify = ({messageId, force}: ILoadFileNotify) => {
  const nameEvent = `${DEFAULT_NAME}/${LOAD_FILE_EVENT}`
  publish(nameEvent, {messageId, force})
}

export const isFileMessage = (msg: IMessage | INews): msg is IFileMessage => {
  return msg.type === 'file' && 'file' in msg
}

const FileMessage = ({msg, incoming}: IMessageComponent) => {
  const fileMessage = isFileMessage(msg) ? msg : null
  const [progress, setProgress] = useState(0)
  const activeChatId = useAppSelector(getActiveChatId)
  const activeChat = useAppSelector(getChat(activeChatId + ''))

  useEffect(() => {
    setProgress(fileMessage?.downloaded || 0)
  }, [fileMessage?.downloaded])

  const progressFile = useCallback((progressEvent: AxiosProgressEvent) => {
    setProgress(Math.round((progressEvent.progress || 0) * 100))
  }, [])

  const downloadFile = async (force: boolean = false) => {
    if (!isFileMessage(msg)) {
      return
    }
    const {data} = await downloadFileWithProgress({
      url: msg.file.url,
      onDownloadProgress: progressFile
    })
    setTimeout(() => {
      setProgress(0)
    }, 500)
    const fileExt = getFileExtension(msg.file.url)
    const url = URL.createObjectURL(data)
    if (fileExt in FileTypes && !force) {
      showViewer({url, type: fileExt as FileType, message: msg})
    } else {
      const mimeType = getMimeType(fileExt)
      const blob = new Blob([data], mimeType ? {type: mimeType} : undefined)
      saveAs(blob, msg.file.name)
    }
  }

  useSubscribe(`${DEFAULT_NAME}/${LOAD_FILE_EVENT}`, ({messageId, force}) => {
    if (messageId !== msg.id) {
      return
    }
    downloadFile(force)
  })

  if (!isFileMessage(msg)) {
    return null
  }

  return <GeneralMessage msg={msg} incoming={incoming}>
    <div className={styles.messageWrapper}>
      <div className={styles.fileImgWrapper} onClick={() => downloadFile()}>
        <FileImage/>
        <CirclePreloader className={styles.preloader} progress={progress} />
      </div>
      <div className={styles.messageInfo}>
        <p className={styles.name}>{msg.file.name}</p>
        <div className={styles.bottom}>
          <div className={styles.size}>
            {formatFileSize(msg.file.size) as String}
          </div>
          <div className={styles.right}>
            {activeChat?.pin?.uid === msg.id && activeChat.pin.visible
              && <i className={classNames("chat-pin", styles.chatPin)} />}
            <div className={styles.time}>{getLastTime(msg.timestamp)}</div>
            {!incoming && (msg.status === 'sent' || msg.status === 'received')
              ? <i className={classNames(
                "chat-check",
                generalStyle.tick,
                msg.status === 'sent' ? styles.sent : null)}/> : null}
            {!incoming && msg.status === 'displayed' ? <i className={classNames(
              'chat-double-check seen',
              generalStyle.tick
            )}/> : null}
            {!incoming && msg.status === 'sending' ? <i className={classNames(
              "chat-clock",
              generalStyle.tick,
              styles.sent)}/> : null}
          </div>
        </div>
      </div>
    </div>

  </GeneralMessage>
}

export default FileMessage
