import styles from './SupportTicketFilesList.module.scss'
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton'
import { IClass } from '@/interfaces/general'
import classNames from 'classnames'

interface SupportTicketFilesListProps extends IClass{
  files: File[]
  disabled?: boolean
  onRemove?: (file: File) => void
}

const SupportTicketFilesList = (
  {
    className,
    files,
    disabled,
    onRemove
  }: SupportTicketFilesListProps) => {
  if (!files.length) {
    return null
  }
  return <ul
    className={classNames(styles.fileList, className)}
  >
    {files.map((file, index) => <li
      key={index}
      className={styles.fileItem}
    >
      <i className="chat-archive" />
      {file.name}
      <IconButton
        className={styles.removeBtn}
        disabled={disabled}
        onClick={() => onRemove?.(file)}
      ><i className='chat-close' /></IconButton>
    </li>)}
  </ul>
}

export default SupportTicketFilesList
