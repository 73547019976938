import {MouseEventHandler, useCallback, useEffect, useRef, useState} from 'react';
import styles from './FileViewer.module.scss'
import classNames from 'classnames';
import {createPubSub} from '@inficen/react-events'
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack5'
import {PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';
import {useIntl} from 'react-intl';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css'
import {IFileMessage} from '@/store/messages/messages';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {convertToHtml} from 'mammoth/mammoth.browser.min';
import axios from 'axios';
import BasePreviewModal, {PreviewHandle} from '@/components/Modal/BasePreviewModal/BasePreviewModal';
import {Handler, useGesture} from '@use-gesture/react';

pdfjs.GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.min.js';

export enum FileTypes {
  pdf,
  docx
}

export type FileType = keyof typeof FileTypes

type Event = {
  name: string,
  payload: {
    url?: string,
    type: FileTypes,
    message?: IFileMessage
    fileName?: string
  }
}

const {publish, useSubscribe} = createPubSub<Event>()

interface FileViewerProps {
  name?: string
}

const DEFAULT_NAME = 'generalPDFViewer'

interface ShowProps {
  url?: string,
  type: FileType
  name?: string,
  message?: IFileMessage
  fileName?: string
}

const SHOW_EVENT = 'showFile'

export const show = ({message, name = DEFAULT_NAME, url, type, fileName}: ShowProps) => {
  const nameEvent: string = `${name}/${SHOW_EVENT}`
  publish(nameEvent, {message, url, type: FileTypes[type], fileName})
}

const MAX_SCALE = 6
const MIN_SCALE = .5
const STEP_SCALE = .2

const getWidth = (el: Element | null | undefined) => {
  if (!el) {
    return 0
  }
  const computedStyle = getComputedStyle(el)
  const width = el.clientWidth
  const paddings = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight)
  return width - paddings
}

const FileViewer = ({name = DEFAULT_NAME}: FileViewerProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [url, setUrl] = useState('')
  const [type, setType] = useState<FileTypes | null>(null)
  const [numPages, setNumPages] = useState<number[]>([]);
  const [scale, setScale] = useState(1)
  const {formatMessage} = useIntl()
  const pdfContainerRef = useRef<HTMLDivElement>(null)
  const docxRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<PreviewHandle>(null)

  const onDrag: Handler<
    'drag',
    PointerEvent | MouseEvent | TouchEvent | KeyboardEvent
  > = useCallback((state) => {
    const {delta: [x]} = state
    pdfContainerRef.current?.scrollBy({
      left: -x,
    })
  }, [])

  const bind = useGesture({
    onDrag,
  }, {})

  useEffect(() => {
    if (url && type === FileTypes.docx && docxRef.current) {
      axios.get(url, {
        responseType: 'arraybuffer',
      }).then(result => {
        convertToHtml({arrayBuffer: result.data})
          .then(({value}: { value: string }) => {
            if (docxRef.current) {
              docxRef.current.innerHTML = value
            }
          })

      })
    }
  }, [url, type])

  useSubscribe(`${name}/${SHOW_EVENT}`, ({message, url, type, fileName}) => {
    setUrl(url || message?.file.url || '')
    setType(type)
    containerRef.current?.show({
      message,
      url: url || message?.file.url,
      name: message?.file.name || fileName,
    })

  })

  const handleClose = useCallback(() => {
    setType(null)
    setUrl('')
    setScale(1)
  }, [])

  const onPdfDocumentLoadSuccess = ({numPages}: PDFDocumentProxy) => {
    const arr = []
    for (let i = 1; i <= numPages; i++) {
      arr.push(i)
    }
    setNumPages(arr);
    setIsLoaded(true)
  }

  const changeScale = useCallback((step: number) => {
    setScale(prevScale => Math.max(Math.min(MAX_SCALE, prevScale + step), MIN_SCALE))
  }, [])

  const increaseScale = () => {
    changeScale(STEP_SCALE)
  }

  const decreaseScale = () => {
    changeScale(-STEP_SCALE)
  }

  const onPdfBoxClick: MouseEventHandler = (e) => {
    if (pdfContainerRef.current && e.target === pdfContainerRef.current) {
      containerRef.current?.hide()
    }
  }

  return <BasePreviewModal
    ref={containerRef}
    onClose={handleClose}
    zoomAvailable={type === FileTypes.pdf}
    onIncrease={increaseScale}
    onDecrease={decreaseScale}
  >
    {type === FileTypes.pdf && !isLoaded && <p className={styles.loadText}>{formatMessage({id: 'LOADING_FILE'})}</p>}
    {type === FileTypes.pdf && <div
      ref={pdfContainerRef}
      className={styles.documentBox}
      onClick={onPdfBoxClick}
    >
      <Document
        className={classNames(!isLoaded && styles.invisible, styles.document)}
        file={url}
        onLoadSuccess={onPdfDocumentLoadSuccess}
        onLoadError={(err) => console.log('loading error: ', err)}  // eslint-disable-line no-console
      >
        <div
          {...bind()}
          className={styles.pageBox}
          draggable={false}
          onContextMenu={e => e.preventDefault()}
        >
          {numPages.map(item =>
            <Page
              width={Math.min(getWidth(pdfContainerRef.current) || 1024, 1024)}
              scale={scale}
              pageNumber={item}
              key={item}
            />,
          )}
        </div>
      </Document>
    </div>}
    {
      type === FileTypes.docx && <div className={styles.docxBox}>
        <div ref={docxRef}/>
      </div>
    }
  </BasePreviewModal>
}

export default FileViewer
