import {AxiosResponse} from "axios";
import {auth} from "@/utils/auth";
import {axiosRequest} from "@/api/axiosInit";
import {AUTHENTICATE_PATH} from "@/api/auth";
import {AxiosRequestConfig} from 'axios/index';
import {logoutEvent} from "@/pages/YouChat/YouChat";

const methods = ['post', 'get', 'delete', 'put'] as const;
const methodsSet = new Set(methods) as Set<string>
type TMethod = typeof methods[number];

function isTMethod(method: string): method is TMethod {
  return methodsSet.has(method)
}

type AnyObj = {
  [key: string]: any
}

let pause: null | Promise<void> = null

const reauth = async () => {
  const promise: {
    resolve?: (value: (void | PromiseLike<void>)) => void
    reject?: (reason?: any) => void
  } = {}
  pause = new Promise((resolve, reject) => {
    promise.resolve = resolve
    promise.reject = reject
  })
  try {
    await auth()
    promise.resolve?.()
  } catch (err) {
    return promise.reject?.(err)
  } finally {
    pause = null
  }
}

const request = async (
  method: TMethod | Uppercase<TMethod>,
  url: string,
  params: AnyObj = {},
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse> => {
  if (!method) {
    throw new Error('Method is wrong')
  }
  // method = method.toLowerCase()
  if ('headers' in params && !params.params) {
    params.params = {}
  }
  if (method === 'get' && !params.params) {
    params = {params}
  }

  if (['get', 'delete', ].includes(method.toLowerCase())) {
    params = {
      ...params,
      ...config
    }
  }

  const requestMethod = method.toLowerCase()

  if (!(isTMethod(requestMethod))) {
    throw new Error('Method is wrong')
  }

  const axiosMethod: TMethod = requestMethod

  if (pause && url !== AUTHENTICATE_PATH) {
    await pause
  }

  const promise = axiosRequest[axiosMethod](url, params, config)

  return promise.catch(async (err) => {
    if (err.response.status === 406) {
      if (pause) {
        return request(method, url, params, config)
      }
      try {
        await reauth();
        return axiosRequest[axiosMethod](url, params, config)
      } catch (err) {
        return Promise.reject(err)
      }
    }
    if (err.response.status === 401 || err.response.status === 422) {
      logoutEvent()
    }
    return Promise.reject(err)
  })
}

export default request
