import React, {
  CSSProperties,
  LegacyRef,
  MemoExoticComponent,
} from 'react';
import {VariableSizeList as List, VariableSizeListProps} from 'react-window';
import CustomScrollbars from '@/components/Scrollbars/CustomScrollbars/CustomScrollbars';
import {MemoizedFn} from 'memoize-one';

interface IRenderProps<T> {
  style: CSSProperties,
  index: number,
  data: T
}

type IItemData<T> = T & {
  length: number,
}

export type MemoItemData<T> = MemoizedFn<(data: T) => IItemData<T>>

interface IVirtualList<T> extends Omit<VariableSizeListProps<IItemData<T>>, 'itemCount'> {
  data: T,
  render: MemoExoticComponent<({index, style, data}: IRenderProps<IItemData<T>>) => JSX.Element>,
  createItemData: MemoItemData<T>,
  itemCount?: number
  listRef?: LegacyRef<List>
}

type IVirtualListProps<T> = Omit<IVirtualList<T>, 'children'>

function VirtualList<T>(
  {
    data,
    render,
    createItemData,
    listRef,
    ...props
  }: IVirtualListProps<T>) {
  const itemData = createItemData(data);

  return (
    <List<IItemData<T>>
      {...props}
      ref={listRef}
      itemCount={itemData.length}
      itemData={itemData}
      outerElementType={CustomScrollbars}
    >
      {render}
    </List>
  );
}

export default VirtualList

