import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface IComponent {
  hide?: () => void
}

interface IOptions {
  allowCloseByEsc?: boolean
  onClose?: () => void
}

const defaultOptions = {
  allowCloseByEsc: true
}

export const useModal = <P extends IComponent, >(Component: FC<P>, options?: IOptions) => {
  const [showModal, setShowModal] = useState(false);
  const [params] = useState({
    ...defaultOptions,
    ...options
  })

  const [componentProps, setComponentProps] =
    useState<P>()

  const show = useCallback((props?: ComponentProps<FC<P>>) => {
    props && setComponentProps(prevState => ({
      ...prevState,
      ...props
    }))
    setShowModal(true)
  }, [])

  const hide = useCallback(() => {
    setShowModal(false)
    const onClose = options?.onClose
    onClose?.()
  }, [options?.onClose])

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        hide()
        break;
      default:
        break
    }

  }, [hide])

  useEffect(() => {
    if (params.allowCloseByEsc) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, params])

  const Modal = useMemo(() => (props: P) =>
      <>{showModal &&
        <Component
          {...props}
          {...componentProps}
          hide={hide}
        />}
      </>,
    [showModal, componentProps, Component, hide])
  return {show, hide, Modal}
}
