import styles from './TabPanel.module.scss'
import {useEffect, useState} from 'react';
import classNames from 'classnames';

export interface Tab {
  name: string,
  action: string
}

interface TabPanelProps {
  tabs: Tab[],
  onChange?: (tab: string) => void
  initAction?: string
  className?: string
}

const TabPanel = ({tabs, onChange, initAction, className}: TabPanelProps) => {
  const [currentTab, setCurrentTab] = useState(initAction || tabs[0]?.action || '')

  useEffect(() => {
    onChange?.(currentTab)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const changeAction = (action: string) => {
    setCurrentTab(action)
    onChange?.(action)
  }

  return <ul className={classNames(styles.tabPanel, className)}>
    {tabs.map(tab => <li
      key={tab.action}
      className={classNames(styles.tabItem, currentTab === tab.action && styles.active)}
      onClick={() => changeAction(tab.action)}>
      <span>{tab.name}</span>
    </li>)}
  </ul>
}

export default TabPanel
