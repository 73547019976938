import styles from './ReplyMessage.module.scss'
import {IMessage} from '@/store/messages/messages';
import {useAppSelector} from '@/hooks/appHook';
import {getChat} from '@/store/chats/chats';
import {MouseEventHandler, useContext, useEffect, useState} from 'react';
import useVCard from '@/hooks/useVCard';
import {isTextMessage} from '@/components/Chat/ChatMessage/TextMessage/TextMessage';
import classNames from 'classnames';
import {isImageMessage} from '@/components/Chat/ChatMessage/ImageMessage/ImageMessage';
import {isFileMessage} from '@/components/Chat/ChatMessage/FileMessage/FileMessage';
import {ReactComponent as FileImage} from '@/images/icons/file-download.svg';
import {ReactComponent as AudioImage} from '@/images/icons/triangle-right-offset.svg';
import {isAudioMessage} from '@/components/Chat/ChatMessage/AudioMessage/AudioMessage';
import {ChatMessagesContext} from '@/components/Chat/ChatMessages/ChatMessages';
import {isVideoMessage} from '@/components/Chat/ChatMessage/VideoMessage/VideoMessage';
import {getNameOrNick} from '@/utils/chats';
import {useIntl} from 'react-intl';
import {getTypeTranslation} from '@/utils/messages';

interface ReplyMessageProps {
  message: IMessage
}

const ReplyMessage = ({message}: ReplyMessageProps) => {
  const {onReplyClick} = useContext(ChatMessagesContext)
  const chat = useAppSelector(getChat(message.from))
  const [findingMessage, setFindingMessage] = useState(false)
  const {formatMessage} = useIntl()

  const {getVCard} = useVCard()

  useEffect(() => {
    if (!chat?.vcard) {
      getVCard(message.from)
    }
  }, [message.from, chat, getVCard])

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation()
    if (onReplyClick) {
      setFindingMessage(true)
      onReplyClick(message).finally(() => {
        setFindingMessage(false)
      })
    }
  }

  const typeTranslation = getTypeTranslation(message.type)

  return <div className={classNames(styles.reply, findingMessage && styles.anim)} onClick={handleClick}>
    {isImageMessage(message) && <img className={styles.image} src={message.image.thumbnail} alt=""/>}
    {isFileMessage(message) && <span className={styles.fileImgBox}><FileImage /></span>}
    {(isAudioMessage(message) || isVideoMessage(message))
      && <span className={styles.fileImgBox}><AudioImage /></span>}
    <div className={styles.content}>
      <p className={styles.userName}>{!! chat ? getNameOrNick(chat) : ''}</p>
      <p className={classNames(
        styles.text,
        !isTextMessage(message) && styles.textType)}
      >
        {isTextMessage(message) ?
          message.text :
          typeTranslation ? formatMessage({
          id: typeTranslation,
          defaultMessage: message.type
        }) : message.type}
      </p>
    </div>
  </div>
}

export default ReplyMessage
