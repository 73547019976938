import { Chat, ChatList, GroupCategories, GroupCategoriesMap, IGroupChatMember } from '@/store/chats/chats';
import memoize from 'memoize-one';
import { IMessage, IMessagesItem } from '@/store/messages/messages';
import { IInitUser } from '@/store/user/user';
import { Draft } from '@/store/draft/draft';
import { ModifiedTouchEvent } from '@/components/Chat/ChatMessage/ChatMessage';
import { ILastMessages } from '@/store/cache/lastMessage';
import { INews } from '@/store/news/news'
import { isNewsChat } from '@/components/Chat/ChatItem/ChatItem'

export const isMUChat = (chat: Chat) => {
  const reg = new RegExp(`@conference.${process.env.REACT_APP_EJ_HOST}$`)
  return reg.test(chat.$jid)
}

export const isRoomMessage = (message: IMessage) => {
  const reg = new RegExp(`@conference.${process.env.REACT_APP_EJ_HOST}$`)
  return reg.test(message.from) || reg.test(message.to)
}

export const getChatJid = (message: IMessage, userJid: string) => {
  if (isRoomMessage(message)) {
    return message.to
  }
  return message.from === userJid ? message.to : message.from
}

export interface ChatsData {
  chats: Chat[],
  user?: IInitUser,
  messages?: IMessagesItem
  draft?: Draft
  showFullInfo?: boolean,
  activeChatId: string | null,
  activeNews: string | null,
  onClick?: (chat: string) => void,
  onContextMenu?: (e: ModifiedTouchEvent, chat: Chat) => void,
}

export interface Divider {
  name: string
}

export interface INewsChat {
  id: string
  news: INews[]
  name: string
  avatar?: string
}

export interface ChatsDataWithDivider extends Omit<ChatsData, 'chats'> {
  chats: (Chat | Divider | INewsChat)[]
}

export const createChatsData =
  memoize((
    {
      chats,
      ...props
    }: ChatsDataWithDivider) => ({
    chats,
    ...props,
    length: chats.length,
  }))

export const getChatId = (jid: string) => {
  const index = jid.indexOf('@')
  return index > 0 ? jid.slice(0, index) : jid
}

export const filterChats = (chats: Chat[], filter: string = '') => {
  return chats.filter(chat => getChatId(chat.$jid).toLowerCase().includes(filter.toLowerCase().trim())
    || chat.name?.toLowerCase().includes(filter.toLowerCase().trim())
    || chat.nick?.toLowerCase().includes(filter.toLowerCase().trim()))
}

export const filterChatsWithNews = (chats: (Chat | INewsChat)[], filter: string = '') => {
  return chats.filter(chat => isNewsChat(chat)
    ? chat.name.toLowerCase().includes(filter.toLowerCase().trim())
    : getChatId(chat.$jid).toLowerCase().includes(filter.toLowerCase().trim())
    || chat.name?.toLowerCase().includes(filter.toLowerCase().trim())
    || chat.nick?.toLowerCase().includes(filter.toLowerCase().trim()))
}

export const getApprovedChats = (chats: Chat[] | undefined) => {
  return chats?.filter(chat => filterAcceptedChats(chat) || filterFavoriteChats(chat)) || []
}

export const filterFavoriteChats = (chat: Chat) =>
  !!chat.groups?.includes(GroupCategoriesMap.favorite)

export const filterAcceptedChats = (chat: Chat) =>
  (chat.type === 'groupchat' && chat.$subscription !== 'none') ||
  ((chat.groups?.length === 0 || chat.groups?.includes(GroupCategoriesMap.accepted)) && chat.$subscription === 'both')

export const filterAcceptedWithoutFavorites = (chat: Chat | INewsChat) => {
  return isNewsChat(chat) || (filterAcceptedChats(chat) && !filterFavoriteChats(chat))
}

export const filterAcceptedWithFavorites = (chat: Chat) => {
  return filterAcceptedChats(chat) || filterFavoriteChats(chat)
}

export const filterConsultantsChannel = (chat: Chat) =>
  chat.type === 'groupchat' && chat.groups?.includes(GroupCategoriesMap.consultant)

export const filterCustomersChannel = (chat: Chat) =>
  chat.type === 'groupchat' && chat.groups?.includes(GroupCategoriesMap.customer)

export const filterSponsorChannel = (chat: Chat) =>
  chat.type === 'groupchat' && chat.groups?.includes(GroupCategoriesMap.sponsor)


export const filterTeamContactsWithoutSponsor = (chat: Chat) => {
  const groups = new Set(chat.groups || [])
  return chat.type === 'chat' && (
    groups.has(GroupCategoriesMap.customer)
    || groups.has(GroupCategoriesMap.consultant)
  )
}

export const filterTeamChats = (chat: Chat) => {
  const groups = new Set(chat.groups || [])
  return groups.has(GroupCategoriesMap.customer)
    || groups.has(GroupCategoriesMap.sponsor)
    || groups.has(GroupCategoriesMap.consultant)
}

export const filterSponsorContacts = (chat: Chat) => {
  return chat.type === 'chat' && chat.groups?.includes(GroupCategoriesMap.sponsor)
}

export const contactListToArray = (list: ChatList) => {
  return Object.keys(list).map(key => list[key])
}

export const getName = (chat: Chat | null | undefined, incoming: boolean) => {
  if (!incoming) {
    return ''
  }
  if (chat?.nick) {
    return chat?.nick
  }
  if (chat) {
    return chat.vcard?.nickname
  }
  return null
}

export const checkOwner = (userJid: string, members: IGroupChatMember[]) => {
  const user = members.find(member => member.jid === userJid)
  return user?.affiliation === 'owner'
}

export const checkAdmin = (userJid: string, members: IGroupChatMember[]) => {
  const user = members.find(member => member.jid === userJid)
  return user?.affiliation === 'admin'
}

export const sortChats = (messages: IMessagesItem) => (
  chat1: string | Chat | INewsChat,
  chat2: string | Chat | INewsChat) => {
  const isNewsChat1 = typeof chat1 !== 'string' && isNewsChat(chat1)
  const isNewsChat2 = typeof chat2 !== 'string' && isNewsChat(chat2)
  const jid1 = typeof chat1 === 'string'
    ? chat1 : isNewsChat1 ? '' : chat1.$jid
  const jid2 = typeof chat2 === 'string'
    ? chat2 : isNewsChat2 ? '' : chat2.$jid
  let time1: number
  if (isNewsChat1) {
    time1 = chat1.news[chat1.news.length - 1]?.timestamp
  } else {
    time1 = messages[jid1]?.messages[messages[jid1].messages.length - 1]?.timestamp || 0
  }
  let time2: number
  if (isNewsChat2) {
    time2 = chat2.news[chat2.news.length - 1]?.timestamp
  } else {
    time2 = messages[jid2]?.messages[messages[jid2].messages.length - 1]?.timestamp || 0
  }
  return time2 - time1
}

export const sortChatsCache = (messages: ILastMessages) => (
  chat1: string | Chat | INewsChat,
  chat2: string | Chat | INewsChat
) => {
  const isNewsChat1 = typeof chat1 !== 'string' && isNewsChat(chat1)
  const isNewsChat2 = typeof chat2 !== 'string' && isNewsChat(chat2)
  const jid1 = typeof chat1 === 'string'
    ? chat1
    : isNewsChat1 ? '' : chat1.$jid
  const jid2 = typeof chat2 === 'string'
    ? chat2
    : isNewsChat2 ? '' : chat2.$jid
  let time1: number
  if (isNewsChat1) {
    time1 = chat1.news[chat1.news.length - 1]?.timestamp
  } else {
    time1 = messages[jid1]?.timestamp || 0
  }
  let time2: number
  if (isNewsChat2) {
    time2 = chat2.news[chat2.news.length - 1]?.timestamp
  } else {
    time2 = messages[jid2]?.timestamp || 0
  }
  return time2 - time1
}

export const sortChatsWithFavorites = (messages: IMessagesItem) => (chat1: Chat, chat2: Chat) => {
  const isFavorite1 = chat1.groups?.includes(GroupCategoriesMap.favorite)
  const isFavorite2 = chat2.groups?.includes(GroupCategoriesMap.favorite)
  if (isFavorite1 && !isFavorite2) return -1
  if (isFavorite2 && !isFavorite1) return 1
  return sortChats(messages)(chat1, chat2)
}

export const isTeamChannel = (chat: Chat | null | undefined) => {
  if (chat?.type !== 'groupchat') return false
  const groups = new Set(chat.groups || [])
  return groups.has(GroupCategoriesMap.consultant)
    || groups.has(GroupCategoriesMap.customer)
    || groups.has(GroupCategoriesMap.sponsor)
}

export const isMyTeam = (groups: GroupCategories[] | Set<GroupCategories>) => {
  const groupsSet = groups instanceof Set ? groups : new Set(groups)
  return groupsSet.has(GroupCategoriesMap.sponsor)
    || groupsSet.has(GroupCategoriesMap.customer)
    || groupsSet.has(GroupCategoriesMap.consultant)
}

export const isChannelConsultant = (chat: Chat | null | undefined) => {
  if (chat?.type !== 'groupchat') return false
  const groups = new Set(chat.groups || [])
  return groups.has(GroupCategoriesMap.consultant)
}

export const isChannelCustomer = (chat: Chat | null | undefined) => {
  if (chat?.type !== 'groupchat') return false
  const groups = new Set(chat.groups || [])
  return groups.has(GroupCategoriesMap.customer)
}

export const getNameOrNick = (chat: Chat) => {
  if (chat.type === 'groupchat') {
    return chat.name
  }
  return !!chat.nick ? chat.nick : chat.name
}
