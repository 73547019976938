import request from './request';
import {StickerPackInfo} from "@/store/stickers/stickers";
import axios from 'axios';

interface StickerMetaApi {
  id: string,
  published_date: string
  updated_at: string
  version: string
}

interface StickerMeta {
  id: string,
  publishedDate: Date | null
  updatedAt: Date | null
  version: string
}

export const getStickerCollection = (): Promise<StickerMeta[]> => {
  return request('get', '/api/stickers/collectionsid')
    .then(response => {
      const collection = response.data.data
      return collection.map((pack: StickerMetaApi) => {
        const updatedAt = new Date(pack.updated_at)
        const publishedDate = new Date(pack.published_date)
        return {
          ...pack,
          updatedAt: isNaN(updatedAt.getTime()) ? null : updatedAt,
          publishedDate: isNaN(publishedDate.getTime()) ? null : publishedDate,
        }
      })
    })
}


interface StickerPackInfoProps {
  id: string,
  memberId: string
}

export const getStickerPackInfo = ({id, memberId}: StickerPackInfoProps): Promise<StickerPackInfo> => {
  return request('get', `/api/stickers/collections/${id}`, {
    memberId
  })
    .then(response => ({
      ...response.data.data,
      coverUrl: response.data.data.cover_url,
      iconUrl: response.data.data.icon_url,
      publishedDate: response.data.data.published_date,
      zipUrl: response.data.data.zip_url
    }))
}

interface StickerInfo {
  ff: string,
  url: string
}

export const getStickerInfo = (id: string): Promise<StickerInfo> => {
  return axios.get(`/api/url/collection/sticker/${id}`, {
    headers: {
      Authorization: process.env.REACT_APP_STICKERS_API_KEY
    }
  })
    .then(response => response.data.data)
}
