import styles from './BlockedUsers.module.scss'
import BaseModalWithTitle, {BaseModalWithTitleProps} from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import {useIntl} from 'react-intl';
import {useAppSelector} from '@/hooks/appHook';
import {getBlockList} from '@/store/blockList/blockList';
import Avatar from '@/components/Avatar/Avatar';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import {usePrivateChatList} from '@/hooks/usePrivateChatList';

interface BlockedUsersProps extends Omit<BaseModalWithTitleProps, 'title'> {
  title?: string
  onBack?: () => void
  onClose?: () => void
}

const BlockedUsers = ({hide, onBack, onClose, ...props}: BlockedUsersProps) => {
  const blockedUsers = useAppSelector(getBlockList)
  const {formatMessage} = useIntl()
  const {unblockContact} = usePrivateChatList()

  const handleBack = () => {
    onBack?.()
    hide?.()
  }

  const unblockUser = (jid: string) => {
    unblockContact(jid)
  }

  return <BaseModalWithTitle
    title={formatMessage({id: 'blocked_users'})}
    canBack={true}
    onBack={handleBack}
    onClose={onClose}
    hide={handleBack}
    {...props}
  >
    <ul className={styles.list}>
      {blockedUsers.map(user => <li className={styles.item} key={user.jid}>
        <Avatar src={user.avatar} name={user.name}/>
        <span className={styles.name}>{user.name}</span>
        <IconButton className={styles.closeBtn} onClick={() => {
          unblockUser(user.jid)
        }}><i className={'chat-cross'}/></IconButton>
      </li>)}
    </ul>
  </BaseModalWithTitle>
}

export default BlockedUsers
