import {useCallback, useEffect, useRef} from 'react';
import callSocket from '@/services/CallSocket/CallSocket';
import {useAppSelector} from '@/hooks/appHook';
import {getUser, setToken} from '@/store/user/user';
import {SocketEvents} from '@/services/Socket/ISocket';
import {authenticate} from '@/api/auth';
import store from '@/store';

const DELAY_RECONNECT = [10000, 30000, 60000]

const useCallSocketHandler = () => {
  const user = useAppSelector(getUser)
  const closedNormally = useRef(false)
  const openSocket = useRef<() => void>()
  const delayTimer = useRef<NodeJS.Timer>()
  const connectionAttempts = useRef(0)

  openSocket.current = useCallback(() => {
    if (!user?.token) {
      return
    }
    closedNormally.current = false
    callSocket.open(user.token)
      .then(() => {
        connectionAttempts.current = 0
      })
      .catch(() => {
        clearTimeout(delayTimer.current)
        delayTimer.current = setTimeout(async () => {
          try {
            const {data: userToken} = await authenticate({
              memberId: user.memberId,
              accessToken: user.accessToken,
            })
            store.dispatch(setToken(userToken.token))
          } finally {
            openSocket.current?.()
          }
        }, DELAY_RECONNECT[Math.min(connectionAttempts.current, DELAY_RECONNECT.length - 1)])
        connectionAttempts.current++
      })
  }, [user])

  const onClose = useCallback(() => {
    if (closedNormally.current) {
      return
    }
    openSocket.current?.()
  }, [])

  useEffect(() => {
    callSocket.addEventListener(SocketEvents.onClose, onClose)
    return () => {
      callSocket.removeEventListener(SocketEvents.onClose, onClose)
    }
  }, [onClose]);

  useEffect(() => {
    openSocket.current?.()
    return () => {
      closedNormally.current = true
      clearTimeout(delayTimer.current)
      callSocket.close()
    }
  }, [user?.token]);
}

export default useCallSocketHandler
