import {
  authenticate,
  loginBySso
} from "@/api/auth";
import {
  setAdditionalFields,
  setToken,
  setUser
} from "@/store/user/user";
import store from "@/store";
import { isStatusError } from '@/api/interfaces';

export const auth = async () => {
  const state = store.getState()
  const user = state.user.user
  if (!user?.memberId || !user.accessToken) {
    throw new Error('User not found')
  }
  let token = user.accessToken
  let dataUser
  if (user.sso) {
    try {
      dataUser = await loginBySso({
        memberId: user.memberId,
        otp: token
      })
      if (isStatusError(dataUser)) {
        throw new Error(dataUser.error.message)
      }
      token = dataUser.accessToken
      store.dispatch(setAdditionalFields({
        ...dataUser,
        sso: false
      }))
    } catch (e) {
      store.dispatch(setUser(null))
      return
    }
  }
  const {data: userToken} = await authenticate({
    memberId: user.memberId,
    accessToken: token
  })
  store.dispatch(setToken(userToken.token))
}
