import React, {ChangeEvent, ChangeEventHandler, KeyboardEvent, useEffect, useRef, useState} from 'react'
import BaseModalWithTitle from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import {BaseModalProps} from '@/components/Modal/BaseModal/BaseModal';
import {useAppSelector} from '@/hooks/appHook';
import {Chat, getMUChats, getPrivateChats} from '@/store/chats/chats';
import {contactListToArray, filterAcceptedWithFavorites} from '@/utils/chats';
import ContactSelect from '@/components/ContactSelect/ContactSelect';
import styles from './Broadcast.module.scss';
import Search from '@/components/Primitive/Inputs/Search/Search';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from '@/components/Primitive/Buttons/Button/Button';
import {getUser} from '@/store/user/user';
import {show as showNotify} from '@/components/Modal/UserNotify/UserNotify';
import useMessage from '@/hooks/useMessage';
import Messages from '@/services/Messages';
import TabPanel from '@/components/TabPanel/TabPanel';
import classNames from 'classnames';
import Textarea from '@/components/Primitive/Textarea/Textarea';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import {getChatsCache} from '@/store/cache/chats';

interface IBroadcast extends BaseModalProps {
  initMessage?: string
  title?: string
}

const MAX_SELECTED = 499

const CONTACT_TAB = {
  name: 'TABS.CONTACTS',
  action: 'contacts',
}
const GROUP_TAB = {
  name: 'TABS.GROUPS',
  action: 'groups',
}

const TABS = [
  CONTACT_TAB,
  GROUP_TAB,
]

const sortContacts = (v1: Chat, v2: Chat) => {
  return (v1.name || '') > (v2.name || '') ? 0 : -1
}

const Broadcast = ({hide, initMessage, title}: IBroadcast) => {
  const {formatMessage} = useIntl()
  const contactsList = useAppSelector(getPrivateChats)
  const groupList = useAppSelector(getMUChats)
  const chatsCache = useAppSelector(getChatsCache)
  const user = useAppSelector(getUser)
  const [tabs] = useState(TABS.map(tab => ({
    ...tab,
      name: formatMessage({id: tab.name})
  })))
  const [currentTab, setCurrentTab] = useState(tabs[0].action)
  const [contacts, setContacts] = useState(contactListToArray(contactsList)
    .filter(filterAcceptedWithFavorites)
    .sort(sortContacts))
  const [groups, setGroups] = useState(contactListToArray(groupList).sort(sortContacts))
  const [contactsWithCache, setContactsWithCache] = useState(contacts)
  const [groupsWithCache, setGroupsWithCache] = useState(groups)
  const [selectedContacts, setSelectedContacts] = useState<Chat[]>([])
  const [selectedGroups, setSelectedGroups] = useState<Chat[]>([])
  const [selectedChats, setSelectedChats] = useState<Chat[]>([])
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [filter, setFilter] = useState('')
  const [step, setStep] = useState(0)
  const [text, setText] = useState(initMessage || '')
  const fileInput = useRef<HTMLInputElement>(null)
  const {sendMessage, sendFile} = useMessage()

  useEffect(() => {
    setContacts(contactListToArray(contactsList)
      .filter(filterAcceptedWithFavorites)
      .sort(sortContacts))
  }, [contactsList])

  useEffect(() => {
    setGroups(contactListToArray(groupList).sort(sortContacts))
  }, [groupList])

  useEffect(() => {
    setSelectedChats([
      ...selectedContacts,
      ...selectedGroups
    ])
  }, [selectedContacts, selectedGroups])

  useEffect(() => {
    setContactsWithCache(contacts.map(contact => ({
      ...chatsCache[contact.$jid],
      ...contact
    })))
  }, [contacts, chatsCache])

  useEffect(() => {
    setGroupsWithCache(groups.map(group => ({
      ...chatsCache[group.$jid],
      ...group
    })))
  }, [groups, chatsCache])

  const sendBroadcastMessage = () => {
    const trimmedText = text.trim()
    if ((!trimmedText && !selectedFiles.length) || selectedChats.length < 1) {
      return
    }

    selectedChats.forEach((chat) => {
      if (trimmedText) {
        const msg = Messages.Messages.createTextMessage({
          from: user?.$jid + '',
          to: chat.$jid + '',
          text: text,
          isRoom: chat.type === 'groupchat',
        })
        sendMessage(msg)
      }
      if (selectedFiles.length) {
        selectedFiles.forEach(file => {
          sendFile({
            file,
            toChat: chat,
            userJid: '' + user?.$jid
          })
        })
      }
    })
    hide?.()

    showNotify({
      message: formatMessage({id: `message${selectedChats.length > 1 ? 's' : ''}_sent`}),
    })
  }

  const next = () => {
    setStep(1)
  }

  const handleText = (e: ChangeEvent<HTMLTextAreaElement> | undefined) => {
    if (e) {
      setText(e?.target.value)
    }
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      // Have Shift-Enter insert a line break instead
      if (!e.shiftKey) {
        e.preventDefault();
        sendBroadcastMessage()
      }
    }
  }

  const handleSelectFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files) {
      const files: File[] = []
      for (let i = 0; i < e.target.files.length; i++) {
        files.push(e.target.files[i])
      }
      setSelectedFiles(files)
    }
  }

  return <BaseModalWithTitle
    title={title || formatMessage({'id': 'ACTION.BROADCAST'})}
    hide={hide}
  >
    {
      step === 0 ? <>
        <Search wrapperClassName={styles.search} onChange={setFilter}/>
        <TabPanel tabs={tabs} onChange={setCurrentTab}/>
        <ContactSelect
          className={classNames(currentTab !== CONTACT_TAB.action && 'd-none')}
          canSelectAll={true}
          contacts={contactsWithCache}
          filter={filter}
          maxSelected={MAX_SELECTED}
          onSelected={setSelectedContacts}/>
        <ContactSelect
          className={classNames(currentTab !== GROUP_TAB.action && 'd-none')}
          canSelectAll={true}
          contacts={groupsWithCache}
          filter={filter}
          maxSelected={MAX_SELECTED}
          onSelected={setSelectedGroups}/>
        <div className={styles.buttons}>
          <FormattedMessage id={'ACTION.NEXT'}>
            {txt => <Button disabled={selectedChats.length < 1} onClick={next}>{txt}</Button>}
          </FormattedMessage>
          <FormattedMessage id={'ACTION.CANCEL'}>
            {txt => <Button view={'link'} onClick={hide}>{txt}</Button>}
          </FormattedMessage>
        </div>
      </> : null
    }
    {
      step === 1 ? <>
        <div className={styles.inputBox}>
          <Textarea
            value={text}
            className={styles.input}
            minRows={1}
            maxRows={8}
            maxLength={4000}
            onChange={handleText}
            onKeyDown={handleKeyPress}
            placeholder={formatMessage({id: 'CHAT.TYPE_MESSAGE'})}
          />
          <IconButton
            className={styles.iconBtn}
            onClick={() => fileInput.current?.click()}
          ><i className="chat-clip"/></IconButton>
        </div>
        <ul className={styles.fileBox}>
          {selectedFiles.map((file, index) => <li
            key={index}
            className={styles.fileItem}
          >{file.name}</li>)}
        </ul>
        <div className={styles.buttons}>
          <FormattedMessage id={'ACTION.SEND'}>
            {txt => <Button onClick={sendBroadcastMessage}>{txt}</Button>}
          </FormattedMessage>
          <FormattedMessage id={'ACTION.CANCEL'}>
            {txt => <Button view={'link'} onClick={hide}>{txt}</Button>}
          </FormattedMessage>
        </div>
        <input
          ref={fileInput}
          className="d-none"
          type="file"
          id="input-file"
          multiple={true}
          onChange={handleSelectFile}
        />
      </> : null
    }
  </BaseModalWithTitle>
}

export default Broadcast
