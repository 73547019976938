import styles from './StickerStoreItem.module.scss'
import {CSSProperties, memo, useState} from 'react';
import {MyStickerPack, StickerPack} from '@/store/stickers/stickers';
import {areEqual} from "react-window";
import {TypeLang} from "@/store/language/language";
import Button from '@/components/Primitive/Buttons/Button/Button';
import {useIntl} from 'react-intl';
import CirclePreloader from "@/components/CirclePreloader/CirclePreloader";

export interface StickerPackData {
  packs: (StickerPack | MyStickerPack)[],
  lang: TypeLang,
  myStickers: Set<string>,
  onAdd?: (pack: MyStickerPack) => void
  onRemove?: (pack: MyStickerPack) => void
}

interface StickerStoreItemProps {
  index: number,
  style: CSSProperties,
  data: StickerPackData
}

const isMyStickerPack = (pack: StickerPack | MyStickerPack): pack is MyStickerPack => {
  return !!pack.info
}

const StickerStoreItem = memo(({index, style, data}: StickerStoreItemProps) => {
  const {packs, lang, myStickers, onAdd, onRemove} = data
  const pack = packs[index]
  const [hover, setHover] = useState<Set<string>>(new Set())
  const {formatMessage} = useIntl()

  if (!isMyStickerPack(pack)) {
    return <div className={styles.preloaderBox} style={style}><CirclePreloader /></div>
  }
  const addHoverIcon = (key: string) => {
    setHover(prevState => {
      prevState.add(key)
      return new Set(prevState)
    })
  }

  const removeHoverIcon = (key: string) => {
    setHover(prevState => {
      prevState.delete(key)
      return new Set(prevState)
    })
  }

  const toggleMyPack = () => {
    if (myStickers.has(pack.id)) {
      onRemove?.(pack)
    } else {
      onAdd?.(pack)
    }
  }

  return <div
    className={styles.box}
    style={style}
  >
    <div className={styles.infoBox}>
      <img className={styles.packIcon} src={pack.info.iconUrl} alt={pack.info.name[lang]}/>
      <span className={styles.packName}>{pack.info.name[lang]}</span>
      {
        pack.id === 'aafb2e9b-0fb1-4280-8138-c3c7aea0dfd0'
          ? null
          : <Button
            view={myStickers.has(pack.id) ? 'dark' : 'main'}
            className={styles.btn}
            onClick={toggleMyPack}
          >
            <i className={myStickers.has(pack.id) ? 'chat-remove' : 'chat-download'}/>
            {formatMessage({id: myStickers.has(pack.id) ? 'ACTION.REMOVE' : 'ACTION.ADD'} )}
          </Button>
      }
    </div>
    <ul className={styles.stickers}>
      {pack.info.stickers.map(sticker => <li
        key={sticker.id}
        onMouseEnter={() => addHoverIcon(sticker.id)}
        onMouseLeave={() => removeHoverIcon(sticker.id)}
      >
        <img
          className={styles.sticker}
          src={hover.has(sticker.id) ? sticker.url : sticker.ff || sticker.url}
          alt=""
        />
      </li>)}
    </ul>

  </div>
}, areEqual)

export default StickerStoreItem
