import request from "@/api/request";

export const getADXMailUrl = () => {
  return request('post', '/api/adxmail', {})
    .then(data => {
      return data.data
    })
}

export const getAConnectUrl = () => {
  return request('post', '/api/aconnect', {})
    .then(data => {
      return data.data
    })
}
