import styles from './ImageResizer.module.scss'
import classNames from 'classnames';
import {
  forwardRef,
  ImgHTMLAttributes,
  MouseEventHandler,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {Handler, useGesture, WebKitGestureEvent} from '@use-gesture/react';

interface ImageResizerProps extends ImgHTMLAttributes<HTMLImageElement> {
  minScale?: number
  maxScale?: number
  maxBorderOffset?: number
  stepScale?: number
  onClick?: MouseEventHandler<HTMLDivElement>
}

export interface IImageResizer {
  increaseZoom: (scale?: number) => void
  decreaseZoom: (scale?: number) => void
}

interface Point {
  x: number
  y: number
}

const MAX_SCALE = 10
const MIN_SCALE = .5
const LIMIT_IMAGE_OFFSET = 20
const STEP_SCALE = 0.4

const initOffset = {x: 0, y: 0}

interface MaxOffsetProps {
  image: HTMLImageElement,
  scale: number
  maxOffset?: number
}
interface MaxOffset {
  maxX: number
  minX: number
  maxY: number
  minY: number
}
const maxOffset = ({image, scale, maxOffset = 0}: MaxOffsetProps): MaxOffset => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth
  const screenHeight = window.innerHeight || document.documentElement.clientHeight
  const screenRatio = screenWidth / screenHeight
  const ratio = image.naturalWidth / image.naturalHeight
  let imageWidth = image.clientWidth
  let imageHeight = image.clientHeight
  if (ratio >= screenRatio) {
    imageWidth = imageWidth * scale
    imageHeight = imageWidth / ratio
  } else {
    imageHeight = imageHeight * scale
    imageWidth = imageHeight * ratio
  }
  const maxX = screenWidth > imageWidth ? 0 : (imageWidth - screenWidth) / (2 * scale) + (maxOffset / scale)
  const minX = -maxX
  const maxY = screenHeight > imageHeight ? 0 : (imageHeight - screenHeight) / (2 * scale) + (maxOffset / scale)
  const minY = -maxY
  return {
    maxX,
    minX,
    maxY,
    minY,
  }
}

const ImageResizer = forwardRef<IImageResizer, ImageResizerProps>((
  {
    className,
    minScale = MIN_SCALE,
    maxScale = MAX_SCALE,
    maxBorderOffset = LIMIT_IMAGE_OFFSET,
    stepScale = STEP_SCALE,
    onClick,
    ...props
  }: ImageResizerProps, ref) => {
  const [scale, setScale] = useState(1)
  const [offset, setOffset] = useState(initOffset)
  const [enableTransition, setEnableTransition] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)

  const moveOffset = useCallback(({x, y, scale}: Point & {scale: number}) => {
    setOffset(prevState => {
      let xOffset = prevState.x + x
      let yOffset = prevState.y + y
      const img = imgRef.current
      if (!img) {
        xOffset = 0
        yOffset = 0
      } else {
        const limit = maxOffset({
          image: imgRef.current,
          scale,
          maxOffset: maxBorderOffset,
        })
        xOffset = Math.max(Math.min(xOffset, limit.maxX), limit.minX)
        yOffset = Math.max(Math.min(yOffset, limit.maxY), limit.minY)
      }
      return {
        x: xOffset,
        y: yOffset,
      }
    })
  }, [maxBorderOffset])

  const calculateNewScale = useCallback((prevScale: number, step: number = stepScale) => {
    const newScale = Math.min(Math.max(minScale, prevScale + stepScale * step), maxScale)
    moveOffset({
      x: 0,
      y: 0,
      scale: newScale
    })
    return newScale
  }, [stepScale, maxScale, minScale, moveOffset])

  const onWheel: Handler<'wheel', WheelEvent> = useCallback((state) => {
    const {ctrlKey, direction} = state
    if (ctrlKey) {
      return
    }
    const step = -direction[1] * stepScale
    setEnableTransition(true)
    setScale(prevScale => calculateNewScale(prevScale, step))

  }, [calculateNewScale, stepScale])

  const onDrag: Handler<'drag', MouseEvent | TouchEvent | PointerEvent | KeyboardEvent> = useCallback((state) => {
    const {delta: [x, y], pinching, cancel} = state
    if (pinching) {
      return cancel()
    }
    setEnableTransition(false)
    moveOffset({
      x: scale !== 0 ? x / scale : 0,
      y: scale !== 0 ? y / scale : 0,
      scale
    })
  }, [scale, moveOffset])

  const onPinch: Handler<
    'pinch',
    WheelEvent | PointerEvent | TouchEvent | WebKitGestureEvent> = useCallback((state) => {
    const {offset: [scale]} = state
    const newScale = Math.max(Math.min(scale, maxScale), minScale)
    setScale(newScale)
    moveOffset({
      x: 0,
      y: 0,
      scale: newScale
    })
  }, [maxScale, minScale, moveOffset])

  const bind = useGesture({
    onWheel,
    onDrag,
    onPinch,
  }, {})

  useImperativeHandle(ref, () => ({
    decreaseZoom: () => {
      setEnableTransition(true)
      setScale(prevScale => calculateNewScale(prevScale, -stepScale))
    },
    increaseZoom: () => {
      setEnableTransition(true)
      setScale(prevScale => calculateNewScale(prevScale, stepScale))
    }
  }), [stepScale, calculateNewScale])

  return <div
    className={classNames(styles.box, className)}
    onClick={onClick}
    {...bind()}
  >
    <img
      {...props}
      ref={imgRef}
      draggable={false}
      style={{
        ...props.style,
        transform: `scale(${scale}) translate(${offset.x}px, ${offset.y}px)`,
        transition: enableTransition ? 'transform .3s ease-out' : 'none',
      }}
      alt={props.alt || ''}
      onContextMenu={e => e.preventDefault()}
      onContextMenuCapture={e => e.preventDefault()}

    />
  </div>
})

export default ImageResizer
