import {Area} from 'react-easy-crop'

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc: string, pixelCrop: Area, rotation = 0): Promise<Blob | null> {
  // Safari can’t handle canvas elements having more than 16777216 pixels
  const SAFE_AREA_KOEF = Math.sqrt(2)
  const MAX_SIZE = Math.round(4000 / window.devicePixelRatio) / SAFE_AREA_KOEF
  const MAX_PIXELS = 16777216
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  let imageWidth = image.naturalWidth;
  let imageHeight = image.naturalHeight;
  let koef = 1

  if (imageWidth * imageHeight * window.devicePixelRatio * SAFE_AREA_KOEF >= MAX_PIXELS) {
    if (imageWidth > imageHeight) {
      koef = MAX_SIZE / imageWidth
      imageHeight = Math.ceil(MAX_SIZE * imageHeight / imageWidth)
      imageWidth = MAX_SIZE
    } else {
      koef = MAX_SIZE / imageHeight
      imageWidth = Math.ceil(MAX_SIZE * imageWidth / imageHeight)
      imageHeight = MAX_SIZE

    }
  }

  const crop = {
    x: Math.round(pixelCrop.x * koef),
    y: Math.round(pixelCrop.y * koef),
    width: Math.round(pixelCrop.width * koef),
    height: Math.round(pixelCrop.height * koef)
  }

  const maxSize = Math.max(imageWidth, imageHeight)
  const safeArea = Math.ceil(maxSize  * SAFE_AREA_KOEF)
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    Math.round(safeArea / 2 - imageWidth * 0.5),
    Math.round(safeArea / 2 - imageHeight * 0.5),
    imageWidth,
    imageHeight
  )

  const data = ctx.getImageData(
    Math.round(safeArea / 2 - imageWidth * 0.5 + crop.x),
    Math.round(safeArea / 2 - imageHeight * 0.5 + crop.y),
    crop.width,
    crop.height
  );

  canvas.width = crop.width
  canvas.height = crop.height

  ctx.putImageData(
    data,
    0,
    0
  );

  const imageCropped = await createImage(canvas.toDataURL('image/png'))

  canvas.width = 640;
  canvas.height = 640;

  ctx.drawImage(imageCropped,0,0, crop.width, crop.height, 0, 0, 640, 640);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file)
    }, 'image/png')
  })
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  })

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}
