import React, {ForwardedRef, forwardRef, useEffect, useRef, useState} from 'react'
import styles from './Slider.module.scss'
import classNames from 'classnames'
import {useGesture} from '@use-gesture/react';

interface SliderProps extends React.PropsWithChildren {
  className?: string
}

const Slider = forwardRef(({children, className}: SliderProps, ref: ForwardedRef<HTMLDivElement>) => {
  const [coordX, setCoordX] = useState(0)
  const [height, setHeight] = useState(10)
  const [showArrow, setShowArrow] = useState(false)
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const slidesRef = useRef<HTMLDivElement>(null);
  const leftBtn = useRef<HTMLButtonElement>(null)
  const rightBtn = useRef<HTMLButtonElement>(null)

  const setActiveAfterCheck = () => {
    if (!leftBtn.current?.classList.contains(styles.active)) {
      setLeftActive(true)
    }
    if (!rightBtn.current?.classList.contains(styles.active)) {
      setRightActive(true)
    }
  }

  useEffect(() => {
    const updateShowArrow = () => {
      if (!sliderRef.current || !slidesRef.current) {
        return
      }
      setShowArrow(sliderRef.current?.clientWidth < slidesRef.current?.clientWidth)
      setHeight(slidesRef.current.clientHeight || 0)
    }
    const resizeObserver = new ResizeObserver(updateShowArrow)
    if (!slidesRef.current || !sliderRef.current) {
      return
    }
    resizeObserver.observe(slidesRef.current)
    resizeObserver.observe(sliderRef.current)
    updateShowArrow()
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const setRightActive = (flag: boolean) => {
    rightBtn.current?.classList[flag ? 'add' : 'remove'](styles.active)
  }

  const setLeftActive = (flag: boolean) => {
    leftBtn.current?.classList[flag ? 'add' : 'remove'](styles.active)
  }

  useEffect(() => {
    if (slidesRef.current) {
      setHeight(slidesRef.current.clientHeight || 0)
    }
  }, [])

  const dragCb = (state: any) => {
    if (!sliderRef.current || !slidesRef.current) {
      return
    }
    if (slidesRef.current?.clientWidth < sliderRef.current?.clientWidth) {
      return
    }
    const {delta: [x]} = state
    setCoordX(prev => {
      if (!sliderRef.current || !slidesRef.current) {
        return prev
      }
      const newX = prev + x;
      setActiveAfterCheck()
      if (newX <= sliderRef.current.clientWidth - slidesRef.current.clientWidth) {
        setRightActive(false)
        return  sliderRef.current.clientWidth - slidesRef.current.clientWidth
      }
      if (newX >= 0) {
        setLeftActive(false)
        return  0
      }
      return newX
    })
    return 0
  }



  const dragSlides = useGesture({
    onDrag: (state: any) => dragCb(state),
  }, {})

  const enableAnimation = () => {
    slidesRef.current?.classList.add(styles.slidesAnim)
  }

  const handleLeftScroll = () => {
    enableAnimation()
    setCoordX(prevState => {
      if (!sliderRef.current) {
        return prevState
      }
      const newCoordX = prevState + sliderRef.current.clientWidth;
      if (newCoordX >= 0) {
        setRightActive(true)
        setLeftActive(false)
        return 0
      }
      setActiveAfterCheck()
      return newCoordX
    })
  }

  const handleRightScroll = () => {
    enableAnimation()
    setCoordX(prevState => {
      if (!sliderRef.current || !slidesRef.current) {
        return prevState
      }
      const newCoordX = prevState - (sliderRef.current.clientWidth || 0);
      if (newCoordX <= sliderRef.current.clientWidth - slidesRef.current.clientWidth) {
        setLeftActive(true)
        setRightActive(false)
        return sliderRef.current.clientWidth - slidesRef.current.clientWidth

      }
      setActiveAfterCheck()
      return newCoordX
    })
  }

  const handleAnimationEnd = () => {
    slidesRef.current?.classList.remove(styles.slidesAnim)
  }

  const handleRef = ((element: HTMLDivElement) => {
    sliderRef.current = element
    if (ref == null) {
      return
    }
    if (typeof ref === 'function') {
      ref(element)
    } else if (typeof ref === 'object') {
      ref.current = element
    }
  })

  return (
    <div
      ref={handleRef}
      className={classNames(styles.slider, className)}
      style={{height}}
    >
      {showArrow &&
        <button
          ref={leftBtn}
          className={classNames(styles.btn, styles.btnLeft)}
          onClick={handleLeftScroll}
        />}
      {showArrow &&
        <button
          ref={rightBtn}
          className={classNames(styles.btn, styles.btnRight, styles.active)}
          onClick={handleRightScroll}
        />}
      <div
        ref={slidesRef}
        className={styles.slides}
        {...dragSlides()}
        style={{left: coordX}}
        onTransitionEnd={handleAnimationEnd}
      >
        {children}
      </div>
    </div>
  )
})

export default Slider;
