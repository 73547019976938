import React from "react";
import {IMessage} from "@/store/messages/messages";
import AutoDelete from "@/components/Pin/AutoDelete/AutoDelete";
import MessagePin from "@/components/Pin/Message/MessagePin";

export interface IPin {
  onClickReply?: (msg: IMessage) => void
}

const Pin = ({onClickReply}: IPin) => {
  return <>
    <AutoDelete/>
    <MessagePin onClickReply={onClickReply}/>
  </>
}

export default Pin
