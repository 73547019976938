import {IMessageComponent} from '@/components/Chat/ChatMessage/ChatMessage';
import styles from './TextMessage.module.scss'
import generalStyles from '../ChatMessage.module.scss'
import {getLastTime} from '@/utils/dateutils';
import {IMessage, ITextMessage} from '@/store/messages/messages';
import GeneralMessage from '@/components/Chat/ChatMessage/GeneralMessage/GeneralMessage';
import classNames from 'classnames';
import linkify from 'linkify-html'
import {encode} from 'html-entities'
import {useAppSelector} from '@/hooks/appHook';
import {getActiveChatId, getChat} from '@/store/chats/chats';
import { INews } from '@/store/news/news'

export const isTextMessage = (msg: IMessage | INews): msg is ITextMessage => {
  return 'text' in msg && msg.type === 'text'
}

const TextMessage = ({msg, incoming}: IMessageComponent) => {
  const activeChatId = useAppSelector(getActiveChatId)
  const activeChat = useAppSelector(getChat(activeChatId + ''))

  if (!isTextMessage(msg)) {
    return null
  }

  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    return false
  }

  return <GeneralMessage msg={msg} incoming={incoming}>
    <p className={styles.text} onContextMenu={onContextMenu}>
      <span className={styles.time}>
        {activeChat?.pin?.uid === msg.id && activeChat.pin.visible
          && <i className={classNames('chat-pin', styles.chatPin)}/>}
        {getLastTime(msg.timestamp)}
        {!incoming && (msg.status === 'sent' || msg.status === 'received')
          ? <i className={classNames(
            'chat-check',
            generalStyles.tick,
            msg.status === 'sent' ? styles.sent : null)}/> : null}
        {!incoming && msg.status === 'displayed' ? <i className={
          classNames(
            'chat-double-check seen',
            generalStyles.tick,
          )}/> : null}
        {!incoming && msg.status === 'sending' ? <i className={classNames(
          'chat-clock',
          generalStyles.tick,
          styles.sent,
        )}></i> : null}
      </span>
      <span
        dangerouslySetInnerHTML={{
          __html: linkify(
            encode(msg.text.toString()).replaceAll('&apos;', '\''),
            {
              className: styles.link,
              target: '_blank',
              rel: 'noreferrer',
            }),
        }}/>
      {msg.isTranslating && <><br/><span>...</span></>}
      {msg.isTranslated && <><br/><span style={{fontSize: '80%'}}>{msg.textTranslate}</span></>}
      <span className={styles.timeWidth}/>
    </p>
  </GeneralMessage>
}

export default TextMessage
