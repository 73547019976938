import styles from './Field.module.scss'
import {
  ChangeEventHandler,
  CSSProperties,
  EventHandler,
  FunctionComponent,
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from "classnames";
import IconButton from "@/components/Primitive/Buttons/IconButton/IconButton";
import {ReactComponent as EditImage} from "@/images/icons/pencil.svg";
import {ReactComponent as SaveImage} from "@/images/icons/tick.svg";
import Input from "@/components/Primitive/Inputs/Input/Input";
import {copyTextToClipboard} from '@/utils/app';

interface FieldProps extends PropsWithChildren{
  className?: string
  text?: string
  textIcon?: string
  fontIcon?: string
  SvgIcon?: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>
  color?: string
  button?: 'edit' | 'copy' | 'next' | 'button'
  textStyle?: CSSProperties
  highlighted?: boolean
  placeholder?: string
  maxLength?: number
  minLength?: number
  moveWithoutIcon?: boolean
  onSave?: (value: string) => void
  onNext?:  MouseEventHandler<HTMLDivElement>
  onClick?:  MouseEventHandler<HTMLDivElement>
}

const Field = (
  {
    className,
    text = '',
    textIcon,
    fontIcon,
    SvgIcon,
    button,
    color,
    placeholder,
    maxLength,
    minLength,
    highlighted,
    children,
    moveWithoutIcon = true,
    textStyle,
    onSave,
    onNext,
    onClick
  }: FieldProps) => {
  const [value, setValue] = useState(text)
  const [edit, setEdit] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    setValue(text)
  }, [text])

  const icon = useMemo(() => {
    if (fontIcon) {
      return <i style={{color}} className={classNames(styles.icon, fontIcon)}/>
    } else if (textIcon) {
      return <span style={{color}} className={styles.textIcon}>{textIcon}</span>
    } else if (SvgIcon) {
      return <SvgIcon style={{color}}  className={styles.svgIcon}/>
    }

    return null
  }, [fontIcon, textIcon, SvgIcon, color])

  const changeEdit: EventHandler<any> = useCallback((event) => {
    event.stopPropagation()
    setEdit(prevState => {
      if (prevState) {
        onSave?.(value)
      }
      return !prevState
    })
    inputRef.current?.focus()
  }, [onSave, value])

  const handleChangeValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value)
  }

  const copyText = useCallback(() => {
    copyTextToClipboard(text);
    setShowCopied(true);

    const timer = setTimeout(() => {
      setShowCopied(false);

      return () => clearTimeout(timer);
    }, 2000);
  }, [text])

  const buttonComponent = useMemo(() => {
    switch (button) {
      case 'edit':
        return <IconButton
          className={classNames(
            styles.button,
            edit && styles.editBtn,
            edit && !!minLength && value?.trim()?.length < minLength && styles.disabled)}
          disabled={edit && !!minLength && value?.trim()?.length < minLength}
          onClick={changeEdit}
        >{!edit ? <EditImage/> : <SaveImage/>}
        </IconButton>
      case 'copy':
        return <>
          { showCopied ? <IconButton className={classNames(styles.button, styles.copiedBtn)}>
              <i className={classNames('chat-check')} /></IconButton>
            : <IconButton className={classNames(styles.button, styles.copyBtn)} onClick={copyText}
            ><i className={classNames('chat-copy')} /></IconButton>
        }
        </>
      case 'next':
        return <i className={classNames(styles.button, styles.nextButton, 'chat-chevron-right')} />
    }

    return null
  }, [showCopied, button, edit, changeEdit, copyText, minLength, value])

  const handleInputRef = (el: HTMLInputElement | null) => {
    el?.focus()
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation()
    switch (e.key) {
      case 'Enter':
        if (minLength && value?.length < minLength) {
          break
        }
        changeEdit(e)
        break
      case 'Escape':
        setValue(text)
        setEdit(false)
    }
  }

  return <div
    className={classNames(
      styles.container,
      button === 'edit' && edit && styles.editContainer,
      button === 'next' && styles.nextContainer,
      highlighted && styles.highlighted,
      highlighted === false && styles.notHighligted,
      ['next', 'button', 'edit'].includes(button || '') && !edit && styles.asButton,
      !icon && !moveWithoutIcon && styles.notMove,
      edit && styles.notHover,
      className
      )}
    {...(button === 'button' ? {onClick: onClick} : null)}
    {...(button === 'next' ? {onClick: onNext} : null)}
    {...(button === 'edit' && !edit ? {onClick: changeEdit} : null)}
  >
    {icon}
    {!edit && (children ? children :
      <span style={{color, ...textStyle}} className={styles.text}>{value}</span>)}
    {edit && <Input
        ref={handleInputRef}
        className={styles.input}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={handleChangeValue}
        onKeyDown={handleKeyDown}
    />}
    {buttonComponent}
  </div>
}

export default Field
