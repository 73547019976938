import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {Chat, getMUChats, GroupCategoriesMap, updateChat} from '@/store/chats/chats';
import {useEffect, useState} from 'react';
import {contactListToArray, isTeamChannel} from '@/utils/chats';
import {useIntl} from 'react-intl';

const getTeamChannelName = (chat: Chat): string => {
  if (!isTeamChannel(chat)) {
    return chat.name || ''
  }
  const groups = new Set(chat.groups || [])
  if (groups.has(GroupCategoriesMap.sponsor)) {
    return 'my_sponsors_channel_name'
  } else if (groups.has(GroupCategoriesMap.customer)) {
    return 'my_customers_channel_name'
  } else if (groups.has(GroupCategoriesMap.consultant)) {
    return 'my_consultants_channel_name'
  } else {
    return chat.name || ''
  }
}

const useTeamChannelsNameObserver = () => {
  const dispatch = useAppDispatch()
  const muChats = useAppSelector(getMUChats)
  const [teamChannels, setTeamChannels] = useState<Chat[]>([])
  const {formatMessage} = useIntl()

  useEffect(() => {
    setTeamChannels(contactListToArray(muChats).filter(chat => isTeamChannel(chat)))
  }, [muChats]);

  useEffect(() => {
    teamChannels.forEach(channel => {
      const name = formatMessage({id: getTeamChannelName(channel)})
      if (channel.name !== name) {
        dispatch(updateChat({
          chatJid: channel.$jid,
          options: {
            name
          }
        }))
      }
    })
  }, [teamChannels, formatMessage, dispatch]);
}

export default useTeamChannelsNameObserver
