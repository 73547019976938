import request from "./request";
import { IVcard } from '@/api/chats'
import JXON from 'jxon'
import { AnyObject } from '@/index'

interface ISearchProps {
  searchString: string,
  withStart?: number,
  withNumRows?: number
}

export interface IUsersFound {
  jid: string,
  nick: string,
  user: string
  avatar: string,
  countMessage: number
}


interface SearchGroupApi {
  name: string
  vcard: string
}

export interface ISearchGroup {
  uid: string
  vcard: IVcard
}
export interface SearchData {
  users: IUsersFound[]
  groups: ISearchGroup[]
  more: number
}

export const search = ({searchString, withStart = 0, withNumRows = 5}: ISearchProps): Promise<SearchData> => {
  return request(
    'get',
    `/api/user/account/find/youchat/search`,
    {
      searchString,
      withStart,
      withNumRows
    }
  )
    .then(data => {
      const groups = (data.data.groups || []).map((group: SearchGroupApi) => {
        const { vCard }: AnyObject = JXON.stringToJs(group.vcard)
        const vcard = vCard
        for (const key in vcard) {
          const lowerKey = key.toLowerCase()
          if (key !== lowerKey) {
            vcard[lowerKey] = vcard[key]
            delete vcard[key]
          }
        }
        return {
          uid: group.name,
          vcard
        }
      })
      return {
        ...data.data,
        groups
      }
    })
}
