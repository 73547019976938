import {v4 as uuid} from 'uuid'
import {createStropheFromObject} from '@/services/utils';
import {GroupCategories} from '@/store/chats/chats';

interface IAddItemProps {
  from: string,
  to: string
  name?: string,
  groups?: GroupCategories[]
}

interface IRemoveItemProps {
  from: string,
  to: string
}

interface IBlockUserProps {
  from: string
  userJid: string | string[]
}

class Roster {
  static Messages = {
    updateItem({from, to, name, groups}: IAddItemProps) {
      const msg = $iq({
        from,
        id: uuid(),
        type: 'set'
      })
        .c('query', {
          xmlns: Strophe.NS.ROSTER
        })

      if (name) {
        msg.c('item', {
          jid: to,
          name,
        })
      } else {
        msg.c('item', {
          jid: to,
        })
      }
      if (groups) {
        groups.forEach(group => {
          msg.c('group').t(group).up()
        })

      }
      return msg.tree()
    },
    removeItem({from, to}: IRemoveItemProps) {
      return $iq({
        from,
        id: uuid(),
        type: 'set'
      })
        .c('query', {
          xmlns: Strophe.NS.ROSTER
        })
        .c('item', {
          jid: to,
          subscription: 'remove'
        })
    },
    blockUser({from, userJid}:IBlockUserProps) {
      const users = (Array.isArray(userJid) ? userJid : [userJid]).map(jid => ({
        $jid: jid
      }))
      return createStropheFromObject('iq', {
        $from: from,
        $type: 'set',
        $id: uuid(),
        block: {
          $xmlns: 'urn:xmpp:blocking',
          item: users
        }
      }).tree()
    },
    unblockUser({from, userJid}:IBlockUserProps) {
      const users = (Array.isArray(userJid) ? userJid : [userJid]).map(jid => ({
        $jid: jid
      }))
      return createStropheFromObject('iq', {
        $from: from,
        $type: 'set',
        $id: uuid(),
        unblock: {
          $xmlns: 'urn:xmpp:blocking',
          item: users
        }
      }).tree()
    },
    getBlockList() {
      return createStropheFromObject('iq', {
        $type: 'get',
        $id: uuid(),
        blocklist: {
          $xmlns: 'urn:xmpp:blocking'
        }
      })
    }
  }
}

export default Roster
