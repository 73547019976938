import styles from './EmojiPicker.module.scss'
import {CSSProperties, forwardRef, useEffect, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import Emoji from './Emoji/Emoji';
import StickerStore from "./StickerStore/StickerStore";
import Stickers from './Stickers/Stickers';
import {useAppDispatch, useAppSelector} from "@/hooks/appHook";
import {
  getActiveTab,
  isStickersLoaded,
  loadPacks,
  setActiveTab,
  Sticker,
  Tabs,
  update as updatePack
} from '@/store/stickers/stickers';
import {getStickerCollection} from "@/api/stickers";

const tabs = Object.values(Tabs)

export interface EmojiPickerProps {
  style?: CSSProperties
  onEmojiSelect?: (emoji: string) => void
  onStickerSelect?: (sticker: Sticker) => void
}

const EmojiPicker = forwardRef<HTMLDivElement, EmojiPickerProps>((
  {
    style,
    onEmojiSelect,
    onStickerSelect,
  }, ref) => {
  const dispatch = useAppDispatch()
  const stickersLoaded = useAppSelector(isStickersLoaded)
  const currentTab = useAppSelector(getActiveTab)
  const stickerLoading = useRef(false)

  const setCurrentTab = (tab: Tabs) => {
    dispatch(setActiveTab(tab))
  }

  useEffect(() => {
    if (!stickersLoaded && !stickerLoading.current) {
      stickerLoading.current = true
      getStickerCollection()
        .then(collection => {
          dispatch(updatePack(collection))
          dispatch(loadPacks(true))
        })
        .finally(() => {
          stickerLoading.current = false
        })
    }
  }, [stickersLoaded, dispatch])

  return <div
    ref={ref}
    className={styles.container}
    style={style}
    onClick={(e) => e.stopPropagation()}
  >
    <ul className={styles.tabs}>
      {tabs.map(key => <li
        className={classNames(styles.menuItem, currentTab === key && styles.active)}
        key={key}
        onClick={() => setCurrentTab(key)}
      >
        <FormattedMessage id={key}>
          {txt => <span>{txt}</span>}
        </FormattedMessage>
      </li>)}
    </ul>
    {currentTab === Tabs.emoji && <div className={styles.emojiBox}><Emoji onSelect={onEmojiSelect}/></div>}
    {currentTab === Tabs.stickers && <div className={styles.stickerBox}><Stickers onSelect={onStickerSelect}/></div>}
    {currentTab === Tabs.stickerStore && <div className={styles.storeBox}><StickerStore/></div>}
  </div>
})

export default EmojiPicker
