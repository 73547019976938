import styles from './DevelopmentNotify.module.scss'
import {useEffect, useState} from 'react';
import {createPubSub} from '@inficen/react-events';
import {v4 as uuid} from 'uuid';

type DeleteType =  'time' | 'click'
interface DevelopMessage {
  message: string,
  deleteBy?: DeleteType
}

type Event = {
  name: string,
  payload: DevelopMessage
}

const {publish, useSubscribe} = createPubSub<Event>()

const TIMEOUT_MESSAGE = 5000
const DEFAULT_NAME = 'DevelopmentNotify'
const DEVELOP_MESSAGE_EVENT = 'DevelopMessageEvent'

export const show = ({message, deleteBy = 'time'}: DevelopMessage) => {
  const nameEvent = `${DEFAULT_NAME}/${DEVELOP_MESSAGE_EVENT}`
  publish(nameEvent, {message, deleteBy})
}

const DevelopmentNotify = () => {
  const [messages, setMessages] = useState<{message: string, id: string, type: DeleteType}[]>([])
  const [show, setShow] = useState(false)

  useSubscribe(`${DEFAULT_NAME}/${DEVELOP_MESSAGE_EVENT}`, ({message, deleteBy}) => {
    const id = uuid()
    setMessages(prevState => [
      ...prevState,
      {
        message,
        id,
        type: deleteBy || 'time'
      },
    ])
    if (deleteBy === 'time') {
      setTimeout(() => {
        setMessages(prevState => prevState.filter((message) => message.id !== id))
      }, TIMEOUT_MESSAGE)
    }
    setShow(true)
  })

  useEffect(() => {
    if (!messages.length) {
      setShow(false)
    }
  }, [messages])

  const handlerClick = () => {
    setMessages(prevState => prevState.filter(message => message.type !== 'click'))
  }

  if (!show) {
    return null
  }

  return <div
    className={styles.box}
    onClick={handlerClick}
  >
    {messages.map(message => <p className={styles.text}>{message.message}</p>)}
  </div>
}

export default DevelopmentNotify
