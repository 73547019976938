import {Scrollbars} from 'react-custom-scrollbars-2';
import {
  CSSProperties, ForwardedRef, forwardRef,
  ReactNode,
  UIEventHandler,
} from 'react';

interface CustomScrollbarsProps {
  onScroll: UIEventHandler<any>
  forwardedRef: (instance: HTMLDivElement | null) => void,
  style?: CSSProperties,
  children: ReactNode,

  [key: string]: any
}

const CustomScrollbars = forwardRef((
  {onScroll, forwardedRef, style, children, ...props}: CustomScrollbarsProps,
  ref: ForwardedRef<HTMLDivElement>) => {

  const refCallback = (scrollBar: Scrollbars | null) => {
    const container = scrollBar?.container.querySelector('div') || null
    if (typeof ref === 'function') {
      ref(container)
    } else if (ref != null) {
      ref.current = container
    }
  }

  return (
    <Scrollbars
      {...props}
      ref={refCallback}
      style={{...style, overflow: 'hidden'}}
      onScroll={onScroll}
    >
      {children}
    </Scrollbars>
  );
})

export default CustomScrollbars
