import {useEffect, useRef, useState} from "react";
import styles from './UserNotify.module.scss'
import {createPubSub} from '@inficen/react-events';
import {ReactComponent as WarningImage} from "./icons/warning.svg";
import IconButton from "@/components/Primitive/Buttons/IconButton/IconButton";

interface UserNotifyProps {
  name?: string
  timeout?: number
}

type Event = {
  name: string,
  payload: {
    message: string,
    timeout?: number
  }
}

const {publish, useSubscribe} = createPubSub<Event>()

const DEFAULT_NAME = 'generalNotifier'
const DEFAULT_TIMEOUT = 3000

interface ShowProps {
  message: string,
  name?: string,
  timeout?: number
}
export const show = ({message, name = DEFAULT_NAME, timeout}: ShowProps) => {
  const nameEvent: string = `${name}/show`
  publish(nameEvent, {
    message,
    timeout
  })
}

const UserNotify = ({name = DEFAULT_NAME, timeout = DEFAULT_TIMEOUT}: UserNotifyProps = {}) => {
  const [message, setMessage] = useState('')
  const [show, setShow] = useState(false)
  const timer = useRef<NodeJS.Timer>()

  useEffect(() => {
    const lTimer = timer
    return () => {
      clearTimeout(lTimer.current)
    }
  }, [])

  useSubscribe(`${name}/show`, ({message, timeout: time}) => {
    clearTimeout(timer.current)
    setMessage(message)
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, time || timeout)
  })

  if (!show) {
    return null
  }

  return <div className={styles.notifyBox}>
    <WarningImage className={styles.icon} />
    <span className={styles.text}>{message}</span>
    <IconButton
      className={styles.btn}
      onClick={() => setShow(false)}
    ><i className={'chat-cross'}/></IconButton>
  </div>
}

export default UserNotify
