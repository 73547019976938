import styles from './Stickers.module.scss'
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {getActiveSticker, getMyStickers, setActiveSticker, Sticker} from '@/store/stickers/stickers';
import {getLang} from '@/store/language/language';
import {useEffect, useState} from 'react';
import classNames from 'classnames';

interface StickersProps {
  onSelect?: (sticker: Sticker) => void
}

const Stickers = ({onSelect}: StickersProps) => {
  const dispatch = useAppDispatch()
  const myStickers = useAppSelector(getMyStickers)
  const lang = useAppSelector(getLang)
  const activePackId = useAppSelector(getActiveSticker) || myStickers[0]?.id
  const [activePack, setActivePack] = useState(myStickers.find(pack => pack.id === activePackId ) || myStickers[0])
  const [hover, setHover] = useState('')

  useEffect(() => {
    setActivePack(myStickers.find(pack => pack.id === activePackId ) || myStickers[0])
  }, [activePackId, myStickers])

  const setActivePackId = (id: string) => {
    dispatch(setActiveSticker(id))
  }

  return <div className={styles.box}>
    <ul className={styles.stickerTabs}>
      {myStickers.map(pack => <li
        className={classNames(styles.packTab, activePackId === pack.id && styles.active)}
        key={pack.id}
        onClick={() => setActivePackId(pack.id)}
      >
        <img
          className={styles.packIcon}
          src={pack.info.iconUrl}
          alt={pack.info.name[lang]}
        />
      </li>)}
    </ul>
    <ul className={styles.stickers}>
      {activePack?.info.stickers.map(sticker => <li
        key={sticker.id}
        onMouseEnter={() => setHover(sticker.id)}
        onMouseLeave={() => setHover('')}
        onClick={() => onSelect?.(sticker)}
      >
        <img
          className={styles.sticker}
          src={hover === sticker.id ? sticker.url || sticker.ff : sticker.url}
          alt=""
        />
      </li>)}
    </ul>
  </div>
}

export default Stickers
