import styles from './SupportTicketDialog.module.scss'
import { ISupportTicket } from '@/store/supportTickets/supportTickets'
import { IClass } from '@/interfaces/general'
import classNames from 'classnames'
import SupportTicketMessage from '@/components/SupportTicket/SupportTicketMessage/SupportTicketMessage'
import CirclePreloader from '@/components/Primitive/CirclePreloader/CirclePreloader'

interface SupportTicketDialogProps extends IClass {
  ticket?: ISupportTicket | null
  loading?: boolean
}

const SupportTicketDialog = (
  {
    className,
    ticket,
    loading
  }: SupportTicketDialogProps) => {

  if (loading) {
    return <div className={styles.loaderBox}>
      <CirclePreloader
        color={'var(--main-color)'}
        className={styles.preloader}
        withoutProgress
      />
    </div>
  }

  if (!ticket?.messages) {
    return null
  }

  return <ul className={classNames(styles.list, className)}>
    {ticket.messages.map(message => <SupportTicketMessage
      key={message.id}
      className={styles.message}
      message={message}
      TagName={'li'}
    />)}
  </ul>
}

export default SupportTicketDialog
