import BaseModalWithTitle, { BaseModalWithTitleProps } from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle'
import { useIntl } from 'react-intl'
import CreateTicket from '@/components/SupportTicket/CreateSupportTicket/CreateSupportTicket'
import { ISupportTicket } from '@/store/supportTickets/supportTickets'

interface CreateSupportTicketProps extends Omit<BaseModalWithTitleProps, 'title'>{
  onCreated?: (ticket: ISupportTicket) => void
}

const CreateSupportTicket = (
  {
    onCreated,
    ...props
  }: CreateSupportTicketProps) => {
  const {formatMessage} = useIntl()
  return <BaseModalWithTitle
    {...props}
    title={formatMessage({id: 'create_ticket'})}
  >
    <CreateTicket
      onCreated={onCreated}
    />
  </BaseModalWithTitle>
}

export default CreateSupportTicket
