import React, {forwardRef} from 'react';
import styles from "./IconButton.module.scss"
import classNames from "classnames";

interface IconButtonProps extends React.PropsWithChildren,
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  className?: string,
  disabled?: boolean
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((
  {
    children,
    className,
    disabled = false,
    ...props
  }: IconButtonProps, ref) => {
  return <button
    ref={ref}
    className={classNames(
      styles.iconButton,
      disabled && styles.disabled,
      className
    )}
    disabled={disabled}
    {...props}
  >{children}</button>
})

export default IconButton
