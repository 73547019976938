import {useCallback, useEffect} from 'react';
import {useAppDispatch} from '@/hooks/appHook';
import {changeState} from '@/store/pageState/pageState';

const usePageState = () => {
  const dispatch = useAppDispatch()

  const updateVisible = useCallback(() => {
    dispatch(changeState(document.visibilityState === 'visible'))
  }, [dispatch])

  useEffect(() => {
    updateVisible()
    document.addEventListener('visibilitychange', updateVisible)
    return () => {
      document.removeEventListener('visibilitychange', updateVisible)
    }
  }, [updateVisible])
}

export default usePageState
