import styles from './VideoMessage.module.scss'
import {IMessageComponent} from '@/components/Chat/ChatMessage/ChatMessage';
import {IMessage, IVideoMessage, update} from '@/store/messages/messages';
import ReplyMessage from '@/components/Chat/ChatMessage/ReplyMessage/ReplyMessage';
import {MouseEventHandler, useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import CirclePreloader from '@/components/Primitive/CirclePreloader/CirclePreloader';
import {createPubSub} from '@inficen/react-events';
import {downloadFileWithProgress} from '@/api/upload';
import {getFileExtension, getMimeType} from '@/utils/file';
import {saveAs} from 'file-saver';
import {AxiosProgressEvent} from 'axios/index';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {getUser} from '@/store/user/user';
import {getChatJid} from '@/utils/chats';
import {CustomEvents, CustomWindow, ObserverEvent} from '@/interfaces/general';
import {getActiveChat} from '@/store/chats/chats';
import { INews } from '@/store/news/news'

declare let window: CustomWindow

type Event = {
  name: string,
  payload: {
    messageId: string,
  }
}

const {publish, useSubscribe} = createPubSub<Event>()

interface ILoadFileNotify {
  messageId: string,
}

const DEFAULT_NAME = 'VideoMessageNotifier'
const LOAD_VIDEO_EVENT = 'loadVideoEvent'
export const loadVideoNotify = ({messageId}: ILoadFileNotify) => {
  const nameEvent = `${DEFAULT_NAME}/${LOAD_VIDEO_EVENT}`
  publish(nameEvent, {messageId})
}

export const isVideoMessage = (msg: IMessage | INews): msg is IVideoMessage => {
  return msg.type === 'video' && 'url' in msg
}

let runningVideo: HTMLVideoElement | null = null


const videoSizeObserver = new ResizeObserver((...props) => {
  const entries = props[0]
  window.dispatchEvent(new CustomEvent<ObserverEvent>(CustomEvents.VIDEO_RESIZE_EVENT, {
    detail: [...props, new Map(entries.map(entry => [(entry.target as HTMLVideoElement).src, entry]))],
  }))
})

const postfix = '#t=0.01'

const VideoMessage = ({msg, incoming}: IMessageComponent) => {
  const dispatch = useAppDispatch()
  const activeChat = useAppSelector(getActiveChat)
  const user = useAppSelector(getUser)
  const [loaded, setLoaded] = useState(false)
  const [progress, setProgress] = useState(0)
  const [videoWidth, setVideoWidth] = useState(0)
  const [minHeight, setMinHeight] = useState((msg as IVideoMessage).height || 0)
  const videoRef = useRef<HTMLVideoElement>(null)
  const downloadVideo = useRef<Function>()
  const loadedRef = useRef(false)

  const cbResizeObserver = useRef<(
    entries: ResizeObserverEntry[],
    observer: ResizeObserver,
    map: Map<string, ResizeObserverEntry>,
  ) => void>()

  cbResizeObserver.current = useCallback((
    entries: ResizeObserverEntry[],
    observer: ResizeObserver,
    map: Map<string, ResizeObserverEntry>,
  ) => {
    if (!isVideoMessage(msg) || !loaded) {
      return
    }
    const entry = map.get(msg.url + postfix)
    if (!entry) {
      return;
    }
    setVideoWidth(entry.contentRect.width)
    setMinHeight(prevHeight => prevHeight < entry.contentRect.height ?
      entry.contentRect.height : prevHeight,
    )
  }, [msg, loaded]);

  useEffect(() => {
    const cb = (ev: CustomEvent<ObserverEvent>) => {
      cbResizeObserver.current?.(...ev.detail)
    }
    window.addEventListener(CustomEvents.VIDEO_RESIZE_EVENT, cb)
    return () => {
      window.removeEventListener(CustomEvents.VIDEO_RESIZE_EVENT, cb)
    }
  }, []);

  useEffect(() => {
    if (!isVideoMessage(msg)) {
      return
    }
    if (msg.height === minHeight || !loaded) {
      return;
    }
    const videoMessage: IVideoMessage = {
      ...msg,
      height: minHeight,
    }
    const jid = getChatJid(msg, user?.$jid || '')
    dispatch(update({jid, messages: [videoMessage]}))
  }, [minHeight, dispatch, msg, user?.$jid, loaded]);

  useEffect(() => {
    const video = videoRef.current
    if (!video) {
      return
    }
    const observer = videoSizeObserver
    observer.observe(video)
    return () => {
      observer.unobserve(video)
    }
  }, []);

  const downloadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    setProgress(Math.round((progressEvent.progress || 0) * 100))
  }, [])

  downloadVideo.current = useCallback(async () => {
    if (!isVideoMessage(msg)) {
      return
    }
    const {data} = await downloadFileWithProgress({
      url: msg.url,
      onDownloadProgress: downloadProgress,
    })
    setTimeout(() => {
      setProgress(0)
    }, 500)
    const fileExt = getFileExtension(msg.url)
    const mimeType = getMimeType(fileExt)
    const blob = new Blob([data], mimeType ? {type: mimeType} : undefined)
    saveAs(blob, msg.name || 'video' + fileExt)
  }, [msg, downloadProgress])

  useSubscribe(`${DEFAULT_NAME}/${LOAD_VIDEO_EVENT}`, ({messageId}) => {
    if (messageId !== msg.id) {
      return
    }
    downloadVideo.current?.()
  })

  if (!isVideoMessage(msg)) {
    return null
  }

  const onPlay = () => {
    if (runningVideo) {
      runningVideo.pause()
    }
    runningVideo = videoRef.current
  }

  const onPause = () => {
    if (runningVideo === videoRef.current) {
      runningVideo = null
    }
  }

  const onVideoContextMenu: MouseEventHandler<HTMLVideoElement> = (e) => {
    e.preventDefault()
    return false
  }

  const onVideoLoaded = () => {
    if (loadedRef.current) {
      return
    }
    loadedRef.current = true
    setTimeout(() => {
      setLoaded(true)
      loadedRef.current = false
    }, 50)
  }

  return <div
    className={classNames(
      styles.box,
      incoming && styles.incoming,
      incoming && activeChat?.type === 'groupchat' && styles.withAvatar,
      !!msg.replyMessage && styles.withReply,
    )}>
    {!!msg.replyMessage && <div
      style={{
        width: loaded ? videoWidth || '100%' : '100%',
      }}
      className={styles.replyBox}>
      <ReplyMessage message={msg.replyMessage}/>
    </div>}
    {!loaded && !msg.thumbnail && <span className={styles.stub}/>}
    {((msg.uploaded || progress) > 0 || !loaded) && <CirclePreloader
      className={styles.preloader}
      withoutProgress={!msg.uploaded && !progress}
      progress={msg.uploaded || progress}
    />}
    {!loaded && !!msg.thumbnail && <img
      style={{height: !!minHeight ? `min(${minHeight}px, 70vh)` : undefined}}
      className={styles.video}
      src={msg.thumbnail}
      alt="video thumbnail"
    />}
    <video
      ref={videoRef}
      className={classNames(styles.video, !loaded && styles.notLoaded)}
      controls={loaded}
      disablePictureInPicture
      disableRemotePlayback
      preload="metadata"
      src={msg.url + postfix}
      onProgress={!loaded ? onVideoLoaded : undefined}
      onPlay={onPlay}
      onPause={onPause}
      onContextMenu={onVideoContextMenu}
    />
  </div>
}

export default VideoMessage
