import {Chat} from "@/store/chats/chats";
import {useIntl} from "react-intl";

const TYPE_PERIOD_TRANSLATE = {
  day: "AUTO_DELETE_MESSAGES.DAY",
  week: "AUTO_DELETE_MESSAGES.WEEK",
  month: "AUTO_DELETE_MESSAGES.MONTH",
}

export const GetAutoDeleteMessage = (chat: Chat) => {
  const {formatMessage} = useIntl()

  if (! chat?.autoDeleteMessages) {
    return ""
  }

  if (chat?.autoDeleteMessages?.typePeriod === 'disable') {
    return formatMessage({id: "AUTO_DELETE_MESSAGES.DISABLE_MESSAGE"})
  } else {
    const translateCode = TYPE_PERIOD_TRANSLATE[chat?.autoDeleteMessages.typePeriod]
    const translateTypePeriod = formatMessage({id: translateCode}, {val: chat?.autoDeleteMessages.period})
    return formatMessage({id: "AUTO_DELETE_MESSAGES.ENABLE_MESSAGE"}, {val: translateTypePeriod})
  }
}

export default GetAutoDeleteMessage
