import { useCallback, useEffect, useRef } from 'react';
import PrivateChat from '@/services/PrivateChat';
import JXON from 'jxon';
import { AnyObject } from '@/index';
import { useAppDispatch, useAppSelector } from '@/hooks/appHook';
import {
  Chat,
  ChatList,
  getActiveChatId,
  getChat,
  getPrivateChats,
  GroupCategories, GroupCategoriesMap,
  GroupCategoriesStr,
  isPrivateChatsLoaded,
  setPrivateChats,
  setPrivateChatsLoaded,
} from '@/store/chats/chats';
import { v4 as uuid } from 'uuid'
import { Stanza } from '@/interfaces/XMPP';
import Roster from '@/services/Roster';
import { getUser } from '@/store/user/user';
import useVCard from '@/hooks/useVCard';
import {
  set as setBlockList,
} from '@/store/blockList/blockList'
import { getBlockList } from '@/api/chats';
import connection from '@/services/Connection/Connection';
import useActiveService from '@/hooks/useActiveService'

interface ChatStanza extends Omit<Chat, 'group'> {
  group?: GroupCategoriesStr | GroupCategories[]
}

export const usePrivateChatList = ({ watch = false } = {}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const currentChatJid = useAppSelector(getActiveChatId)
  const currentChat = useAppSelector(getChat(currentChatJid || ''))
  const privateChats = useAppSelector(getPrivateChats)
  const privateChatsLoaded = useAppSelector(isPrivateChatsLoaded)
  const blockListDownloaded = useRef(false)
  const { getVCardWithQueue } = useVCard()
  const { setActiveChatId } = useActiveService()

  const blockListUpdated = useCallback(() => {
    getBlockList().then(users => {
      dispatch(setBlockList(users))
    })
    return true
  }, [dispatch])

  const checkAndFixRosterItem = useCallback((item: ChatStanza) => {
    const itemGroups: GroupCategories[] = []
    if (item.groups) {
      itemGroups.push(...item.groups)
    } else if (item.group) {
      const groups: GroupCategories[] = Array.isArray(item.group) ?
        item.group : (item.group.split(',') as GroupCategories[])
      itemGroups.push(...groups)
    }
    if (item.$subscription === 'both' && itemGroups.includes(GroupCategoriesMap.pending)) {
      const groups = itemGroups
          ?.filter(group => group !== GroupCategoriesMap.pending && group !== GroupCategoriesMap.accepted)
        || []
      groups.push(GroupCategoriesMap.accepted)
      const msg = Roster.Messages.updateItem({
        from: user?.$jid || '',
        to: item.$jid,
        name: item.nick || item.name,
        groups,
      })
      connection.send(msg)
    }
  }, [user?.$jid])

  const rosterUpdated = useCallback((stanza: Stanza) => {
    const list: ChatList = { ...privateChats };
    const msg: AnyObject = JXON.stringToJs(stanza.outerHTML)
    console.log('roster changed: ', msg)  // eslint-disable-line no-console
    const roster = Array.isArray(msg.iq.query.item) ? msg.iq.query.item : [msg.iq.query.item]
    const vCardsForGet: string[] = []

    if (!privateChatsLoaded) {
      const rosterSet = new Set(roster.map((item: any) => item?.$jid))
      if (currentChat?.type === 'chat' && !rosterSet.has(currentChat.$jid)) {
        setActiveChatId(null)
      }
      Object.keys(list).forEach(key => {
        if (!rosterSet.has(key) && list[key].$subscription !== 'none') {
          delete list[key]
        }
      })
    }

    roster.forEach((item: ChatStanza) => {
      if (!item) {
        return;
      }
      if (item.$subscription === 'remove') {
        delete list[item.$jid]
        return
      }
      const groups = Array.isArray(item.group) ? item.group : (item.group?.split(',') as GroupCategories[]) || []
      const thread = uuid()
      list[item.$jid] = {
        ...list[item.$jid],
        ...item,
        groups,
        thread,
        type: 'chat',
        ask: item.ask || item.$ask,
      }

      if (privateChatsLoaded && !list[item.$jid].vcard) {
        vCardsForGet.push(item.$jid)
      }
      checkAndFixRosterItem(item)
    })
    dispatch(setPrivateChats(list))
    dispatch(setPrivateChatsLoaded('loaded'))
    vCardsForGet.forEach(jid => {
      getVCardWithQueue({ jid, immediately: true })
    })
    return true
  }, [
    dispatch,
    privateChats,
    privateChatsLoaded,
    getVCardWithQueue,
    currentChat,
    checkAndFixRosterItem,
    setActiveChatId
  ])

  useEffect(() => {
    if (watch && !blockListDownloaded.current && user?.token) {
      getBlockList()
        .then((list) => {
          dispatch(setBlockList(list))
        })
      blockListDownloaded.current = true
    }
  }, [watch, dispatch, user?.token])

  useEffect(() => {
    if (!watch) {
      return
    }
    const refHandler: Symbol[] = []
    refHandler.push(connection.addHandler(rosterUpdated, Strophe.NS.ROSTER, ''))
    refHandler.push(connection.addHandler(blockListUpdated, 'urn:xmpp:blocking', 'iq'))
    return () => {
      refHandler.forEach(handler => {
        connection?.deleteHandler(handler)
      })
    }
  }, [rosterUpdated, blockListUpdated, watch])

  const update = useCallback((id: string, force: boolean = true) => {
    const msg = PrivateChat.Messages.getList(id)
    if (connection.isConnected) {
      if (force) {
        dispatch(setPrivateChatsLoaded('loading'))
      }
      connection.send(msg)
    }
  }, [dispatch])

  const removeContact = useCallback((id: string) => {
    const msg = Roster.Messages.removeItem({ from: user?.$jid || '', to: id })
    connection.sendStrophe(msg)
  }, [user])

  const addContact = useCallback((
    id: string,
    name: string,
    groups: GroupCategories[] = [GroupCategoriesMap.accepted],
  ) => {
    const msg = Roster.Messages.updateItem({
      from: user?.$jid || '',
      to: id,
      name: '',
      groups,
    })
    connection.sendStrophe(msg)
  }, [user?.$jid])

  const blockContact = useCallback(async(userJids: string | string[]) => {
    const msg = Roster.Messages.blockUser({
      from: user?.$jid || '',
      userJid: userJids,
    })
    return connection.send(msg)
  }, [user?.$jid])

  const unblockContact = useCallback(async(userJids: string | string[]) => {
    const msg = Roster.Messages.unblockUser({
      from: user?.$jid || '',
      userJid: userJids,
    })
    return connection.send(msg)
  }, [user?.$jid])

  return {
    update,
    addContact,
    removeContact,
    blockContact,
    unblockContact,
  }
}
