import styles from './SizeSelector.module.scss'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import {ReactNode} from 'react';
import classNames from 'classnames';

interface SizeSelectorProps {
  initValue?: number
  minValue?: number;
  maxValue?: number;
  step?: number
  Icon?: ReactNode
  textIcon?: string
  onChange?: (value: number) => void
}

const SizeSelector = (
  {
    minValue = 1,
    maxValue = 5,
    step = 1,
    Icon,
    textIcon,
    initValue = minValue,
    onChange,
  }: SizeSelectorProps) => {

  const handleChange = (value: number | number[]) => {
    const num = Array.isArray(value) ? value[0] : value
    onChange?.(num)
  }

  return <div className={styles.box}>
    {!!Icon ? Icon : <span className={styles.textIcon}>{textIcon}</span>}
    <Slider
      defaultValue={initValue}
      className={styles.slider}
      min={minValue}
      max={maxValue}
      step={step}
      included={false}
      dots
      onChange={handleChange}
    />
    {!!Icon ? Icon : <span className={classNames(styles.textIcon, styles.big)}>{textIcon}</span>}
  </div>
}

export default SizeSelector
