import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@/store';

const internetStatus = createSlice({
  name: 'internetStatus',
  initialState: {
    online: false
  },
  reducers: {
    updateOnline(state, {payload}: {payload: boolean}) {
      state.online = payload
    }
  }
})

export const {updateOnline} = internetStatus.actions
export const isInternetOnline = (state: RootState) => state.internetStatus.online
export default internetStatus.reducer
