import {createSlice} from '@reduxjs/toolkit';
import {IMessage} from '@/store/messages/messages';
import {RootState} from '@/store';

export interface ILastMessages {
  [key: string]: IMessage
}

const initialState: ILastMessages = {
}

const lastMessage = createSlice({
  name: 'lastMessageCache',
  initialState,
  reducers: {
    set(state, {payload}: {payload: ILastMessages}) {
      return payload
    },
    update(state, {payload}: {payload: { jid: string, message: IMessage }}) {
      const {jid, message} = payload
      state[jid] = message
    }
  }
})

export const {set, update} = lastMessage.actions
export const getLastMessagesCache = (state: RootState): ILastMessages => state.lastMessageCache
export default lastMessage.reducer
