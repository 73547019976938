import { useCallback } from 'react'
import { getNews as getNewsApi, GetNewsProps } from '@/api/news'
import {show as showNotify} from '@/components/Modal/UserNotify/UserNotify';
import { useAppDispatch } from '@/hooks/appHook'
import { setNews } from '@/store/news/news'


export const useNews = () => {
  const dispatch = useAppDispatch()

  const updateNews = useCallback(async (props: GetNewsProps) => {
    try {
      const news = await getNewsApi(props)
      news.reverse()
      dispatch(setNews(news))
    } catch (e) {
      if (typeof e === 'string') {
        showNotify({
          message: e
        })
      }
    }
  }, [dispatch])

  return {
    updateNews
  }
}
