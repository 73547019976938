export const isFileImageSupported = (file?: File | Blob) => {
  if (!file) {
    return false
  }
  return ['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
}

export const isFileImage = (file?: File) => {
  if (!file) {
    return false
  }

  return file.type.startsWith('image/')
}

let image: HTMLImageElement
let ctx: CanvasRenderingContext2D | null
let canvas: HTMLCanvasElement

const createCanvasIfNo = () => {
  if (!image) {
    image = new Image()
    image.crossOrigin="anonymous"
  }
  if (!canvas) {
    canvas = document.createElement('canvas')
  }
  if (!ctx) {
    ctx = canvas.getContext('2d')
    if (!ctx) {
      return false
    }
  }
  return true
}
export const convertToPng = (url: string): Promise<Blob> => {
  if (!createCanvasIfNo()) {
    return Promise.reject('ContextType doesn\'t match a possible drawing context')
  }
  let resolve: (blob: Blob) => void
  let reject: (reason: string) => void
  const promise = new Promise<Blob>((res, rej) => {
    resolve = res
    reject = rej
  })
  const img = image
  img.onload = () => {
    canvas.width = img.naturalWidth
    canvas.height = img.naturalHeight
    ctx?.drawImage(img, 0, 0)
    canvas.toBlob(blob => {
      if (!blob) {
        reject('Image cannot be created')
        return
      }
      resolve(blob)
    }, 'image/png')
  }
  img.onerror = () => {
    reject('The image was not loaded')
  }
  img.src = url
  return promise
}
