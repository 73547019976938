import TextareaAutosize, {TextareaAutosizeProps} from '@gry-dmitrij/react-textarea-autosize';
import classNames from 'classnames';
import styles from './Textarea.module.scss'
import {ForwardedRef, forwardRef} from 'react';

interface TextareaProps extends TextareaAutosizeProps{
  className?: string
}

const Textarea = forwardRef(({className, ...props}: TextareaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
  return <TextareaAutosize
    ref={ref}
    minRows={1}
    {...props}
    className={classNames(styles.textarea, className)}
  />
})

export default Textarea
