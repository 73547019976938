import {IMessageComponent} from "@/components/Chat/ChatMessage/ChatMessage";
import classNames from "classnames";
import generalStyle from "../ChatMessage.module.scss"
import React from "react";
import ReplyMessage from '@/components/Chat/ChatMessage/ReplyMessage/ReplyMessage';
import {getChatId} from '@/utils/chats';
import {FormattedMessage} from 'react-intl';
import useGetNames from '@/hooks/useGetNames';
import { isNews } from '@/store/news/news'

interface GeneralMessageProps extends IMessageComponent, React.PropsWithChildren {
  className?: string
}

const GeneralMessage = ({msg, incoming, children, className}: GeneralMessageProps) => {
  const {forwardedName, fromName} = useGetNames({message: msg, incoming})

  if (isNews(msg)) {
    return <div
      className={classNames(generalStyle.infoBlock, incoming && generalStyle.incoming, className)}
    >
      {incoming && <p className={generalStyle.titleNews} dangerouslySetInnerHTML={{ __html: msg.title}} />}
      {children}
    </div>
  }

  return <div
    className={classNames(generalStyle.infoBlock, incoming && generalStyle.incoming, className)}
  >
    {incoming && msg.type !== 'call' && <p className={generalStyle.fromBlock}>{fromName}</p>}
    {msg.forwardedFrom && <FormattedMessage id={'forwarded_from'}>
      {txt => <p className={generalStyle.forwardedFrom}>{txt} {forwardedName || getChatId(msg.forwardedFrom!)}</p>}
    </FormattedMessage>
    }
    {!!msg.replyMessage && <ReplyMessage message={msg.replyMessage} />}
    {children}
  </div>
}

export default GeneralMessage
