import {ListOnItemsRenderedProps} from 'react-window';
import {getMessages} from '@/api/chats';
import {getAllMessages, messagesWasLoaded, update} from '@/store/messages/messages';
import {fromHistoryToMessage} from '@/utils/messages';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {useCallback, useEffect, useRef} from 'react';
import {
  Chat,
  getActiveChatId,
  getAllChats,
  updateChat
} from '@/store/chats/chats';
import {Divider, getChatId} from '@/utils/chats';
import PrivateChat from '@/services/PrivateChat';
import {getUser} from "@/store/user/user";
import dayjs from "dayjs";
import {isChat} from '@/components/Chat/ChatItem/ChatItem';
import connection from '@/services/Connection/Connection';

interface ChatItemRenderProps {
  chats?: (Chat | Divider)[]
  watch?: boolean
}

const DELAY_RENDER_ITEM = 500

const loadingMessagesGlobal: Set<string> = new Set()
const loadingLastActiveGlobal: Set<string> = new Set()

const useChatItemRendered = ({chats, watch = false}: ChatItemRenderProps = {}) => {
  const messages = useAppSelector(getAllMessages)
  const currentJid = useAppSelector(getActiveChatId)
  const allChats = useAppSelector(getAllChats)
  const user = useAppSelector(getUser)
  const dispatch = useAppDispatch()
  const loadingMessages = useRef<Set<string>>(loadingMessagesGlobal)
  const loadingLastActive = useRef<Set<string>>(loadingLastActiveGlobal)
  const itemRenderDelayTimer = useRef<NodeJS.Timeout>()
  const visibleElements = useRef({
    min: -Infinity,
    max: Infinity
  });

  const loadLastActive = useCallback(async (chat: Chat) => {
    if (chat.type === 'groupchat' || !user?.$jid) {
      return ''
    }

    const jid = chat.$jid
    const loading = loadingLastActive.current
    if (chat.lastActive || loading.has(jid)) {
      return
    }

    loading.add(jid)

    try {
      const msg = PrivateChat.Messages.getLastActive({
          from: user.$jid,
          to: jid
        })
      const result = await connection.send(msg)

      let seconds
      if (result?.iq?.query?.$seconds) {
        if (result.iq.query.$seconds === '0') {
          seconds = true
        } else {
          seconds = dayjs().subtract(result.iq.query.$seconds, 'seconds').toDate()
        }
      } else {
        seconds = false
      }

      dispatch(updateChat({chatJid: jid, options: {lastActive: seconds}}))
    } finally {
      loading.delete(jid)
    }

  }, [dispatch, user?.$jid])

  const loadMessagesIfNotLoaded = useCallback((chat: Chat) => {
    if (!chat) {
      return;
    }
    const jid = chat.$jid
    const mess = messages[jid]
    const loading = loadingMessages.current
    if (mess?.isLoaded || loading.has(jid)) {
      return
    }

    loading.add(jid)

    const id = getChatId(jid)
    getMessages({id, isRoom: chat.type === 'groupchat', limit: 15})
      .then(data => {
        dispatch(messagesWasLoaded({jid: jid, value: true}))
        dispatch(update({jid: jid, messages: data.map(item => fromHistoryToMessage(item))}))
      })
      .finally(() => {
        loading.delete(jid)
      })
  }, [messages, dispatch])

  useEffect(() => {
    if (!watch || !currentJid) {
      return
    }
    const chat = allChats[currentJid]
    if (!!chat && chat.$subscription !== 'none' && !messages[currentJid]?.isLoaded) {
      loadMessagesIfNotLoaded(allChats[currentJid])
    }
  }, [watch, allChats, currentJid, loadMessagesIfNotLoaded, messages])

  const handleChatRendered = useCallback((
    {
      overscanStartIndex,
      overscanStopIndex
    }: ListOnItemsRenderedProps) => {
    for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
      const chat = chats?.[i]
      if (!chat || !isChat(chat)) {
        continue
      }
      loadMessagesIfNotLoaded(chat)
      loadLastActive(chat)
    }
  }, [loadLastActive, loadMessagesIfNotLoaded, chats])

  const handleChatRenderWithDelay = useCallback((
    {
      overscanStartIndex,
      overscanStopIndex,
      visibleStartIndex,
      visibleStopIndex,
    }: ListOnItemsRenderedProps) => {
    visibleElements.current = {
      min: visibleStartIndex,
      max: visibleStopIndex
    }
    clearTimeout(itemRenderDelayTimer.current)
    itemRenderDelayTimer.current = setTimeout(() => {
      handleChatRendered({
        overscanStartIndex,
        overscanStopIndex,
        visibleStartIndex,
        visibleStopIndex,
      })
    }, DELAY_RENDER_ITEM)
  }, [handleChatRendered])

  const isElementVisible = useCallback((chat: Chat | Divider) => {

    let index
    if (isChat(chat)) {
      index = chats?.findIndex(c => isChat(c) && c.$jid === chat.$jid)
    } else {
      index = chats?.findIndex(c => !isChat(c) && c.name === chat.name)
    }
    return index != null && index >= visibleElements.current.min && index <= visibleElements.current.max
  }, [chats])

  return {
    handleChatRendered,
    loadMessagesIfNotLoaded,
    handleChatRenderWithDelay,
    isElementVisible
  }
}

export default useChatItemRendered
