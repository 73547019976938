import { TypeLang } from '@/store/language/language'
import request from '@/api/request'
import { AxiosResponse } from 'axios'
import { INews } from '@/store/news/news'
import camelcaseKeys from 'camelcase-keys'

export interface GetNewsProps {
  lang: TypeLang
  limit?: number
  offset?: number
}

interface INewsApi extends Record<string, any>{
  announcement_id: number
  description: string
  language_id: number
  published_at: string
  title: string
  viewed: boolean
  visible_to: number
}

interface IResponseNews {
  data: INewsApi[]
}

export const getNews = (
  {
    lang,
    limit = 20,
    offset = 0
  }: GetNewsProps): Promise<INews[]> => {
  return request(
    'get',
    '/api/announcements',
    {
      lang,
      limit,
      offset
    }
  ).then((data: AxiosResponse<IResponseNews>) => {
    const news: INewsApi[] = data.data.data
    return news.map(announce => ({
      ...camelcaseKeys(announce, { deep: true }),
        id: '' + announce.announcement_id,
        timestamp: (new Date(announce.published_at).getTime() || 0) * 1000,
        type: 'news'
    }))
  })
}

interface ReadNewsProps {
  lang: TypeLang
  id: number
}
export const readNews = ({lang, id}: ReadNewsProps): Promise<void> => {
  return request(
    'post',
    '/api/announcements/read',
    {
      lang,
      id
    }
  ).then()
}
