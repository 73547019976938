import { useCallback } from 'react'
import { useAppSelector } from '@/hooks/appHook'
import { isMobile } from '@/store/screenSize/screenSize'

interface UseSecondaryPopupHandlerProps {
  hide?: () => void
  hideSecondaryList?: (() => void)[]
}

const useSecondaryPopupHandler = (
  {
    hide,
    hideSecondaryList = []
  }: UseSecondaryPopupHandlerProps) => {
  const mobileView = useAppSelector(isMobile)

  const onBackModal = useCallback(() => {
    hideSecondaryList?.forEach(handler => {
      handler()
    })
  }, [hideSecondaryList])

  const onCloseModal = useCallback(() => {
    if (mobileView) {
      onBackModal()
    } else {
      hide?.()
    }
  }, [hide, onBackModal, mobileView])

  return {
    onBackModal,
    onCloseModal
  }
}

export default useSecondaryPopupHandler
