import {createSlice} from '@reduxjs/toolkit';
import {Chat, ChatList} from '@/store/chats/chats';
import {RootState} from '@/store';

const initialState: {
  chats: ChatList
} = {
  chats: {}
}

const disabledFields: (keyof Chat)[] = ['lastActive', 'files', 'autoDeleteMessages']

const filterFields = (list: ChatList) => {
  const newList: ChatList = {}
  for (const jid in list) {
    const cacheChat: Chat = {
      ...list[jid]
    }
    disabledFields.forEach(name => {
      delete cacheChat[name]
    })
    newList[jid] = cacheChat
  }
  return newList
}

const chats = createSlice({
  name: 'cacheChats',
  initialState,
  reducers: {
    set(state, {payload}: {payload: ChatList}) {
      state.chats = filterFields(payload)
    }
  }
})

export const {set: setChatsCache} = chats.actions
export const getChatsCache = (state: RootState) => state.chatsCache.chats
export const getCachedChat = (jid: string) => (state: RootState) => state.chatsCache.chats[jid]
export default chats.reducer
