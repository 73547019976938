import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "@/store";
import dayjs from "dayjs";

const FIELD_NAME = 'language'

export enum langTypes {
    en, cn, tc, kr, jp
}

export const keyValLang = {
  en: 'en',
  jp: 'ja',
  kr: 'ko',
  cn: 'zh-cn',
  tc: 'zh-cn'
};

export type TypeLang = keyof typeof langTypes

export interface ILanguage {
    lang: TypeLang
}

let initLang
const langUrl = window.location.pathname.trim().split("/")[1]

if ((localStorage.getItem(FIELD_NAME)) as TypeLang) {
  initLang = localStorage.getItem(FIELD_NAME) as TypeLang
} else if (langUrl in langTypes) {
  initLang = langUrl as TypeLang
  localStorage.setItem(FIELD_NAME, initLang)
} else {
  initLang = 'en' as TypeLang
}
dayjs.locale(keyValLang[initLang])

const initialState: ILanguage = {
    lang: initLang
}

const language = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLang: (state, {payload}: PayloadAction<TypeLang>) => {
            state.lang = payload
            localStorage.setItem(FIELD_NAME, payload)
            dayjs.locale(keyValLang[state.lang])
        }
    }
})

export const {setLang} = language.actions
export const getLang = (state: RootState) => state.language.lang

export default language.reducer
