import {BaseModal, BaseModalProps} from '@/components/Modal/BaseModal/BaseModal';
import {FormattedMessage, useIntl} from 'react-intl';
import Search from '@/components/Primitive/Inputs/Search/Search';
import styles from './CreateGroupChat.module.scss'
import modalStyles from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle.module.scss'
import ContactSelect from '@/components/ContactSelect/ContactSelect';
import {useAppSelector} from '@/hooks/appHook';
import {Chat, getPrivateChats} from '@/store/chats/chats';
import React, {
  ChangeEventHandler,
  ComponentProps,
  FC, KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Button from '@/components/Primitive/Buttons/Button/Button';
import {useDropzone} from 'react-dropzone';
import classNames from 'classnames';
import {ReactComponent as ImageStub} from '@/images/icons/img-stub.svg';
import Input from '@/components/Primitive/Inputs/Input/Input';
import {useModal} from '@/hooks/useModal';
import CropImage from '@/components/Modal/CropImage/CropImage';
import Avatar from '@/components/Avatar/Avatar';
import {createRoom, setMUChatAvatar} from '@/api/chats';
import {contactListToArray, getChatId} from '@/utils/chats';
import ModalTitle from '@/components/Modal/ModalTitle/ModalTitle';
import Preloader from '@/components/Preloader/Preloader';
import VCard from '@/services/VCard';
import connection from '@/services/Connection/Connection';
import useActiveService from '@/hooks/useActiveService'

const MAX_SELECTED = 499
const MAX_STEP = 2
const MAX_NAME_LENGTH = 32

const findAcceptedContacts = (contacts: Chat[]) => {
  return contacts.filter(contact => contact.$subscription === 'both')
}

const CreateGroupChat = ({hide}: BaseModalProps) => {
  const {formatMessage} = useIntl()
  const contactsList = useAppSelector(getPrivateChats)
  const [contacts, setContacts] = useState(findAcceptedContacts(contactListToArray(contactsList)))
  const [selectedContacts, setSelectedContacts] = useState<Chat[]>([])
  const [length, setLength] = useState(0)
  const [filter, setFilter] = useState('')
  const [step, setStep] = useState(0)
  const [groupName, setGroupName] = useState('')
  const [selectedImage, setSelectedImage] = useState<File>()
  const [hidden, setHidden] = useState(false)
  const [avatarImage, setAvatarImage] = useState<Blob>()
  const [preloader, setPreloader] = useState(false)
  const {setActiveChatId} = useActiveService()

  const onCropImageClose = useCallback(() => {
    setHidden(false)
  }, [])


  const CropImageComponent: FC = useCallback(
    ({...props}: ComponentProps<typeof CropImageComponent>) => <CropImage
      {...props}
      image={selectedImage}
      onSaveArea={setAvatarImage}
    />,
    [selectedImage])

  const {show: showCropImage, Modal: CropImageModal} =
    useModal<ComponentProps<typeof CropImageComponent>>(CropImageComponent, {
      onClose: onCropImageClose,
    })
  const onDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      return
    }
    setSelectedImage(acceptedFiles[0])
    showCropImage()
    setHidden(true)
  }

  const {getRootProps, getInputProps, isDragReject} = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  })


  useEffect(() => {
    setContacts(findAcceptedContacts(contactListToArray(contactsList)))
  }, [contactsList])

  useEffect(() => {
    setLength(selectedContacts.length)
  }, [selectedContacts.length])

  const next = () => {
    if (step < MAX_STEP) {
      setStep(prevStep => prevStep + 1)
    }
  }

  const remove: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setAvatarImage(undefined)
  }

  const handleGroupName: ChangeEventHandler<HTMLInputElement> = (e) => {
    const name = e.target.value
    if (name.length > MAX_NAME_LENGTH) {
      return
    }

    setGroupName(name)
  }

  const handleCreateGroup = async () => {
    if (groupName.trim().length < 2) {
      throw new Error('Group name is not set')
    }
    if (selectedContacts.length < 1) {
      throw new Error('The minimum number of users must be greater than 1')
    }
    try {
      setPreloader(true)
      const {room} = await createRoom({
        title: groupName,
        participants: selectedContacts.map(chat => getChatId(chat.$jid)),
      })
      const jid = room + '@conference.' + process.env.REACT_APP_EJ_HOST
      if (avatarImage) {
        const {url, thumbnail} = await setMUChatAvatar({
          room,
          avatar: avatarImage,
        })
        const msg = VCard.Messages.setVCard({
          nickname: groupName,
          to: jid,
          url,
          thumbnail
        })
        await connection.send(msg)
      }
      setActiveChatId(jid)
    } finally {
      setPreloader(false)
      hide?.()
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    switch (e.key) {
      case 'Enter':
        if (groupName) {
          handleCreateGroup()
        }
    }
  }

  return <BaseModal
    wrapperClassName={hidden ? 'visually-hidden' : ''}
    hide={hide}
    className={styles.createChatGroupClassName}
  >
    {!preloader && <><ModalTitle
      title={formatMessage({id: 'GROUP_CHAT.CREATE_NEW'})}
      canClose={true}
      onClose={hide}
    />
      <div className={modalStyles.context}>
        {step === 0 && <>
          <FormattedMessage id={'CHAT.INVITE_PARTICIPANTS'}>
            {txt => <p className={styles.description}>{txt}
              <span className={styles.amount}>{`${length}/${MAX_SELECTED}`}</span>
            </p>}
          </FormattedMessage>
          <Search wrapperClassName={styles.search} onChange={setFilter}/>
          <ContactSelect contacts={contacts} filter={filter} maxSelected={MAX_SELECTED}
                         onSelected={setSelectedContacts}/>
          <div className={styles.buttons}>
            <FormattedMessage id={'ACTION.NEXT'}>
              {txt => <Button disabled={selectedContacts.length < 1} onClick={next}>{txt}</Button>}
            </FormattedMessage>
            <FormattedMessage id={'ACTION.CANCEL'}>
              {txt => <Button view={'link'} onClick={hide}>{txt}</Button>}
            </FormattedMessage>
          </div>
        </>}

        {
          step === 1 && <>
            <div className={classNames(styles.dropzone, isDragReject && styles.reject)} {...getRootProps()}>
              <input {...getInputProps()} />
              {avatarImage ?
                <Avatar className={styles.image} src={URL.createObjectURL(avatarImage)}/>
                : <ImageStub className={styles.image}/>}
              {avatarImage ?
                <>
                  <FormattedMessage id={'ACTION.REUPLOAD'}>
                    {txt => <Button view={'link'}>{txt}</Button>}
                  </FormattedMessage>
                  <FormattedMessage id={'ACTION.REMOVE'}>
                    {txt => <Button className={styles.removeBtn} view={'link'} onClick={remove}>{txt}</Button>}
                  </FormattedMessage>
                </>
                : <FormattedMessage id={'dragAndDropOrChooseFile'}>
                  {txt => <p>{txt}</p>}
                </FormattedMessage>}
            </div>
            <FormattedMessage id={'enterChatName'}>
              {txt => <p className={styles.description}>{txt}</p>}
            </FormattedMessage>
            <Input
              className={styles.input}
              value={groupName}
              onChange={handleGroupName}
              onKeyDown={handleKeyDown}
            />
            <div className={styles.buttons}>
              <FormattedMessage id={'ACTION.ADD'}>
                {txt => <Button disabled={groupName.trim().length < 1} onClick={handleCreateGroup}>
                  {txt}
                </Button>}
              </FormattedMessage>
              <FormattedMessage id={'ACTION.CANCEL'}>
                {txt => <Button view={'link'} onClick={hide}>{txt}</Button>}
              </FormattedMessage>
            </div>
          </>}
      </div>
    </>}
    {preloader && <Preloader className={styles.preloader} />}

    <CropImageModal/>
  </BaseModal>
}

export default CreateGroupChat
