import {ReactComponent as LogoImage} from '@/images/icons/logo.svg';
import styles from './Logo.module.scss'
import classNames from 'classnames';
interface LogoProps {
  className?: string
}

const Logo = ({className}: LogoProps) => {
  return <div className={classNames(styles.logoContainer, className)}>
    <LogoImage className={styles.logo}/>
  </div>
}

export default Logo
