import styles from './ModalTitle.module.scss'
import IconButton from "@/components/Primitive/Buttons/IconButton/IconButton";
import classNames from "classnames";
import {MouseEventHandler} from "react";

export interface ModalTitleProps {
  title: string,
  canClose?: boolean,
  canBack?: boolean,
  onClose?: () => void
  onBack?: () => void
}

const ModalTitle = (
  {
    title,
    canClose = true,
    canBack = false,
    onClose,
    onBack
  }: ModalTitleProps) => {

  const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    onClose?.()
  }

  return <div className={styles.modalTitle}>
    {canBack && <IconButton
      className={classNames(styles.iconButton, styles.backButton)}
      onClick={onBack}
    >
      <i className={'chat-arrow-l'}></i>
    </IconButton>}
    <h3 className={styles.titleText}>{title}</h3>
    {canClose && <IconButton
      className={styles.iconButton}
      onClick={handleClose}
    >
      <i className="chat-cross"/>
    </IconButton>}
  </div>
}

export default ModalTitle
