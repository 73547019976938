import {createIntlSegmenterPolyfill} from 'intl-segmenter-polyfill';



(async function () {
  if (global.Intl.Segmenter) {
    return
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.Intl.Segmenter = await createIntlSegmenterPolyfill(
    fetch('/assets/wasm/break_iterator.wasm'),
  )
})()
