import styles from './PhotoEditor.module.scss'
import avatarImage from '@/images/icons/avatar-big.png';
import classNames from 'classnames';
import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useIntl} from 'react-intl';
import useContextMenu from '@/components/Menu/ContextMenu/useContextMenu';
import ContextMenuList, {ContextMenuProps} from '@/components/Menu/ContextMenu/ContextMenuList';
import CropImage from '@/components/Modal/CropImage/CropImage';
import useModalSimple from '@/hooks/useModalSimple';
import {Property} from 'csstype'
import {ErrorFactory} from '@/services/errorFactory';

const ACTIONS = {
  NEW_AVATAR: 'new-avatar',
  DELETE_AVATAR: 'delete-avatar',
}

const contextMenuList = {
  list: [
    {text: 'PROFILE.NEW_AVATAR_MENU', action: ACTIONS.NEW_AVATAR},
    {text: 'PROFILE.DELETE_AVATAR_MENU', action: ACTIONS.DELETE_AVATAR},
  ],
}

interface PhotoEditorProps {
  url?: string,
  text?: string,
  canEdit?: boolean,
  deletePhoto?: () => void
  actionBeforeCropClosed?: (blob: Blob) => void
  transparentModalBackground?: boolean
  className?: string
  imageSize?: {
    width: Property.Width<string | number> | undefined,
    height: Property.Height<string | number> | undefined
  }
  onSave?: (blob: Blob) => void
  onCropEditorOpened?: () => void
  onCropEditorClose?: () => void
}

const PhotoEditor = (
  {
    url,
    text,
    canEdit = true,
    deletePhoto,
    onCropEditorOpened,
    actionBeforeCropClosed,
    className,
    imageSize,
    transparentModalBackground,
    onSave,
    onCropEditorClose,
  }: PhotoEditorProps) => {
  const [photo, setPhoto] = useState(url || avatarImage)
  const [contextMenuActive, setContextMenuActive] = useState(false)
  const [selectedImage, setSelectedImage] = useState<File>()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const {formatMessage} = useIntl()

  useEffect(() => {
    setPhoto(url || avatarImage)
  }, [url])

  const {visible: cropImageVisible, hide: hideCropImage, show: showCropImage} = useModalSimple({
    onHide: onCropEditorClose,
  })

  const onContextMenuHide = () => {
    setContextMenuActive(false)
  }

  const {show: showContextMenu, hide: hideContextMenu, ContextMenu} = useContextMenu<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: [],
    },
    onHide: onContextMenuHide,
  })

  useEffect(() => {
    const hide = () => {
      setTimeout(hideContextMenu, 0)
    }
    if (contextMenuActive) {
      document.addEventListener('mouseup', hide)
    }

    return () => {
      document.removeEventListener('mouseup', hide)
    }
  }, [contextMenuActive, hideContextMenu])

  const handleNewAvatar = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const onClickContextMenu = useCallback((action: string) => {
    switch (action) {
      case ACTIONS.NEW_AVATAR:
        handleNewAvatar()
        break
      case ACTIONS.DELETE_AVATAR:
        deletePhoto?.()
        break
      default:
        throw ErrorFactory.createActionNotImplemented(action)
    }
  }, [deletePhoto, handleNewAvatar])

  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (e) => {
    let list = null

    if (photo === avatarImage) {
      list = contextMenuList.list.filter(item => item.action !== ACTIONS.DELETE_AVATAR)
    } else {
      list = contextMenuList.list
    }
    list = list.map(item => ({
      ...item,
      text: formatMessage({id: item.text}),
    }))
    const position = {
      x: e.pageX,
      y: e.pageY,
    }
    showContextMenu({
      position,
      componentProps: {
        list,
        onClick: onClickContextMenu,
      },
    })
    setContextMenuActive(true)
  }

  const onImageChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length) {
      return
    }
    onCropEditorOpened?.()
    setSelectedImage(e.target.files[0])
    showCropImage()
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return <div className={classNames(styles.imageWrapper, className)} onClick={e => e.stopPropagation()}>
    {!!text && photo === avatarImage ? <div
        style={{height: imageSize?.width, width: imageSize?.width}}
        className={classNames(
          styles.textContainer,
          `userColor${text.charCodeAt(0) % 5 + 1}`,
          canEdit && styles.active
        )}
        onClick={canEdit ? handleNewAvatar : undefined}
      >
        <span>{text}</span>
      </div> :
      <img
        className={classNames(styles.image, photo === avatarImage && canEdit && styles.active)}
        style={{height: imageSize?.height, width: imageSize?.width}}
        src={photo}
        alt="Avatar"
        onClick={photo === avatarImage && canEdit ? handleNewAvatar : undefined}
        onError={() => setPhoto(avatarImage)}
      />
    }
    {canEdit && <div onClick={handleContextMenu}
                     className={classNames(styles.avatarEdit, contextMenuActive && styles.avatarEditActive)}>
      <i className="chat-pencil"/>
    </div>}
    <input
      ref={fileInputRef}
      maxLength={1}
      className={'d-none'}
      type="file"
      onChange={onImageChange}
      accept="image/*"
    />
    <ContextMenu/>

    {cropImageVisible && <CropImage
      wrapperClassName={classNames(transparentModalBackground && styles.transparentBg)}
      image={selectedImage}
      actionBeforeClose={actionBeforeCropClosed}
      onSaveArea={onSave}
      hide={hideCropImage}
    />}
  </div>
}

export default PhotoEditor
