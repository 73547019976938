export enum queries {
  memberId = 'member_id',
  otp = 'otp',
  ref = 'ref',
  join = 'join',
  jid = 'jid',
  broadcast = 'broadcast',
  broadcastTitle = 'broadcastTitle'
}

export const ID_MODAL_PORTAL = 'modal-container'
