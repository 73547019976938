import React, {UIEvent, TouchEvent} from 'react';

export const isTouchEvent = (e: UIEvent): e is TouchEvent => {
  return /touch/i.test(e.type)
}

export const isMouseEvent = (e: UIEvent): e is React.MouseEvent => {
  return /mouse/i.test(e.type) ||
    (e.nativeEvent instanceof MouseEvent && e.nativeEvent.button >= 0)
}
