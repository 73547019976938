import { ISupportTicket, ISupportTicketMessage, SupportTicketStatus } from '@/store/supportTickets/supportTickets'
import { AxiosResponse } from 'axios'
import request from '@/api/request'
import { TypeLang } from '@/store/language/language'
import { getADXLanguageId, getADXLanguageName } from '@/constants/languages'
import { getFullVersion } from '@/utils/version'

type ApiBoolean = 0 | 1

interface GetTicketsProps {
  offset?: number
  limit?: number
}

interface IApiSupportTicket {
  ticket_id: number
  subject: string
  status: ApiBoolean
  language_id: number
  date_create: string
  last_update: string
  last_replier: number
  // is_read: ApiBoolean
  is_active: ApiBoolean
  queue: number
  is_phone: ApiBoolean
  member_read: ApiBoolean
  project: string
  is_system: ApiBoolean
  member_name: string
  last_replier_name?: string
  language_title: string
}

interface IApiSupportTickets {
  data: IApiSupportTicket[] | null
}

interface IApiMessageTicket {
  attach?: string
  files?: string[] | null
  date_post: string
  message: string
  post_rate?: number
  replier_name: string
  ticket_id: number
  ticket_message_id: number
}

interface IApiSupportTicketWithMessages extends IApiSupportTicket {
  messages?: {
    count: number
    data?: IApiMessageTicket[] | null
  }
}

interface IApiDetailsTicket {
  data: IApiSupportTicketWithMessages | null
}

const formatTicketMessageFromApi = (message: IApiMessageTicket): ISupportTicketMessage => {
  return {
    id: message.ticket_message_id,
    message: message.message,
    files: message.files,
    date: message.date_post,
    replierName: message.replier_name,
    postRate: message.post_rate || 0
  }
}

const formatTicketFromApi = (ticket: IApiSupportTicketWithMessages): ISupportTicket => {
  const status: SupportTicketStatus = ticket.is_active === 0 ? SupportTicketStatus.closed : SupportTicketStatus.opened
  const regExp = /(.*)\s\(#(.*)\)$/
  const match = ticket.last_replier_name?.match(regExp) || []
  const replierId = match[2]
  const replierName = match[1] || ticket.last_replier_name
  const messages = ticket.messages
  return {
    id: ticket.ticket_id,
    title: ticket.subject,
    language: getADXLanguageName(ticket.language_id),
    created: ticket.date_create,
    status,
    isRead: !!ticket.member_read,
    ...(replierName != null && {
      lastReplier: {
        id: +replierId || null,
        name: replierName || '',
        date: ticket.last_update
      }
    }),
    ...(messages && {
      messages: messages.data?.map(message => formatTicketMessageFromApi(message))
    })
  }
}

export const getTickets = async(
  props: GetTicketsProps = {}): Promise<ISupportTicket[] | null> => {
  const { offset = 0, limit = 20 } = props
  const { data: { data } }: AxiosResponse<IApiSupportTickets> = await request(
    'get',
    '/api/tickets', {
      offset,
      limit
    }
  )

  return data?.map(ticket => formatTicketFromApi(ticket)) || null
}

interface CreateTicketProps {
  lang: TypeLang
  title: string
  message: string
  attach?: File[]
}

export const createTicket = async({
                                    lang,
                                    title,
                                    message,
                                    attach
                                  }: CreateTicketProps): Promise<ISupportTicket> => {
  const formData = new FormData()
  formData.append('subject', title)
  formData.append('message', message)
  formData.append('lang', '' + getADXLanguageId(lang))
  formData.append('version', await getFullVersion())
  if (attach) {
    attach.forEach(file => {
      formData.append('attach[]', file)
    })
  }
  const { data }: AxiosResponse<{ data: IApiSupportTicket }> = await request(
    'post',
    '/api/tickets/create',
    formData
  )
  return formatTicketFromApi(data.data)
}

export const getTicketDetails = async(ticketId: number): Promise<ISupportTicket> => {
  const { data: { data } }: AxiosResponse<IApiDetailsTicket> = await request(
    'get',
    `/api/tickets/${ticketId}`
  )
  if (!data) {
    throw new Error('Ticket not found')
  }
  return formatTicketFromApi(data)
}

export interface SendTicketMessageProps {
  ticketId: number
  message: string
  files?: File[]
}

export const sendTicketMessage = async(
  {
    ticketId,
    message,
    files
  }: SendTicketMessageProps): Promise<ISupportTicketMessage> => {
  const formData = new FormData()
  formData.append('message', message)
  formData.append('version', await getFullVersion())
  files?.forEach(file => {
    formData.append('attach[]', file)
  })
  const { data: { data } }: AxiosResponse<{ data: IApiMessageTicket }> = await request(
    'post',
    `/api/tickets/${ticketId}/message/create`,
    formData
  )
  if (!data) {
    throw new Error('Message not created')
  }

  return formatTicketMessageFromApi(data)
}

interface IApiUnreadMessages {
  total: number
}
export const getUnreadMessagesAmount = async(): Promise<number> => {
  const { data }: AxiosResponse<IApiUnreadMessages> = await request(
    'get',
    '/api/tickets/unread'
  )
  return data.total
}

export const readNewMessages = async(ticketId: number): Promise<void> => {
  return await request(
    'get',
    `/api/tickets/${ticketId}/read`
  ).then()
}
