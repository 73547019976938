import {CustomWindow} from '@/interfaces/general'

declare let window: CustomWindow

type pushArgs = [any, string, string | URL | null]


(function(history){
  let pushState = history.pushState;
  history.pushState = function(...args: pushArgs) {
    pushState.call(history, ...args)
    if (typeof history.onpushstate == "function") {
      history.onpushstate(...args);
    }
  };
})(window.history);
