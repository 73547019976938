import {Chat, ChatList, getAllChats} from '@/store/chats/chats';
import {useCallback, useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {setChatsCache} from '@/store/cache/chats';
import {CacheEvent} from '@/interfaces/general';

let chatsDB = indexedDB.open('chats-cache', 1)

const UPDATED_CACHE_EVENT = CacheEvent.UPDATED_CHATS_CACHE_EVENT
const DELAY_UPDATE_TIMEOUT = 1000

const updatedCacheEvent = new Event(UPDATED_CACHE_EVENT)

let db: IDBDatabase
const chatsCacheInit: ChatList = {}
let cacheInited = false

const CHATS_STORE_NAME = 'chats'

chatsDB.onupgradeneeded = (event) => {
  db = chatsDB.result
  switch (event.oldVersion) {
    case 0:
      db.createObjectStore(CHATS_STORE_NAME, {keyPath: '$jid'})
      break
  }
}

chatsDB.onsuccess = () => {
  db = chatsDB.result
  const transaction = db.transaction(CHATS_STORE_NAME)
  const chats = transaction.objectStore(CHATS_STORE_NAME)
  const request = chats.getAll()

  request.onsuccess = () => {
    request.result.forEach((chat: Chat) => {
      chatsCacheInit[chat.$jid] = chat
    })
    cacheInited = true
    window.dispatchEvent(updatedCacheEvent)
  }
}

const getChatStore = (write?: boolean) => {
  if (!db) {
    return
  }
  const transaction = db.transaction(CHATS_STORE_NAME, write ? 'readwrite': 'readonly')
  return  transaction.objectStore(CHATS_STORE_NAME)
}

const updateCache = (chats: ChatList) => {
  const chatsList = getChatStore(true)
  if (!chatsList) {
    return
  }
  for (const chatsKey in chats) {
    chatsList.put(chats[chatsKey])
  }
}

interface IChatCacheProps {
  watch: boolean
}

const useChatCache = ({watch}: IChatCacheProps = {watch: false}) => {
  const dispatch = useAppDispatch()
  const allChats = useAppSelector(getAllChats)
  const delayUpdateTimer = useRef<NodeJS.Timer>()

  const clearCache = useCallback(() => {
    const chatList = getChatStore(true)
    if (!chatList) {
      return
    }
    chatList.clear()
    dispatch(setChatsCache({}))
  }, [dispatch])

  useEffect(() => {
    return () => {
      clearTimeout(delayUpdateTimer.current)
    }
  }, []);

  useEffect(() => {
    if (!watch) {
      return
    }
    const update = () => {
      dispatch(setChatsCache({...chatsCacheInit}))
    }
    window.addEventListener(UPDATED_CACHE_EVENT, update)

    return () => {
      window.removeEventListener(UPDATED_CACHE_EVENT, update)
    }
  }, [dispatch, watch])

  useEffect(() => {
    if (!cacheInited || !watch) {
      return
    }
    for (const chatIndex in allChats) {
      const chat = allChats[chatIndex]
      chatsCacheInit[chatIndex] = {
        ...chatsCacheInit[chatIndex],
        ...chat
      }
    }
    clearTimeout(delayUpdateTimer.current)
    delayUpdateTimer.current = setTimeout(() => {
      dispatch(setChatsCache({...chatsCacheInit}))
      updateCache(chatsCacheInit)
    }, DELAY_UPDATE_TIMEOUT)
  }, [allChats, dispatch, watch])

  return {clearCache}
}

export default useChatCache
