import styles from './NavTabs.module.scss'
import classNames from 'classnames';
import {getCurrentTab, setTab, Tabs} from '@/store/navTab/navTab';
import {FormattedMessage} from 'react-intl';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import Avatar from '@/components/Avatar/Avatar';
import {getUser} from '@/store/user/user';
import {useEffect, useState} from 'react';
import {ReactComponent as ADXSvg} from '@/images/icons/adx.svg';
import {Chat, getAllChats, getPrivateChats, GroupCategoriesMap} from '@/store/chats/chats';
import {
  contactListToArray,
  filterAcceptedChats,
  filterTeamChats,
  isTeamChannel,
} from '@/utils/chats';
import {getAllMessages, isNotBreakMessage} from '@/store/messages/messages';
import { getUnreadNews } from '@/store/news/news'

const tabs = [
  {
    id: Tabs.Chats,
    icon: 'chat-chat',
  },
  {
    id: Tabs.Contacts,
    icon: 'chat-contacts',
  },
  {
    id: Tabs.Team,
    icon: 'chat-group-o',
  },
  {
    id: Tabs.Profile,
  },
  {
    id: Tabs.Service,
  },
]

interface NavTabsProps {
  className?: string
}

const NavTabs = ({className}: NavTabsProps) => {
  const user = useAppSelector(getUser)
  const activeTab = useAppSelector(getCurrentTab)
  const chats = useAppSelector(getAllChats)
  const messages = useAppSelector(getAllMessages)
  const contactChats = useAppSelector(getPrivateChats)
  const unreadNews = useAppSelector(getUnreadNews)
  const [acceptedChats, setAcceptedChats] = useState(contactListToArray(chats).filter(filterAcceptedChats))
  const [myTeamChatsJid, setMyTeamChatsJid] = useState<string[]>([])
  const [invites, setInvites] = useState<Chat[]>([])
  const [chatWithUnreadMessage, setChatWithUnreadMessage] = useState(0)
  const [myTeamUreadMessages, setMyTeamUreadMessages] = useState(0)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setMyTeamChatsJid(contactListToArray(chats)
      .filter(filterTeamChats)
      .map(chat => chat.$jid),
    )
  }, [chats]);

  useEffect(() => {
    const unreadMessages = myTeamChatsJid.reduce((sum, jid) => {
      const find = messages[jid]?.messages
        .find(message => message.type !== 'break'
          && message.from !== user?.$jid
          && message.status !== 'displayed')
      return sum + +(!!find)
    }, 0)
    setMyTeamUreadMessages(unreadMessages)
  }, [myTeamChatsJid, messages, user?.$jid]);

  useEffect(() => {
    setAcceptedChats(contactListToArray(chats)
      .filter((chat) => filterAcceptedChats(chat) && !isTeamChannel(chat)))
  }, [chats])

  useEffect(() => {
    let amount = acceptedChats.reduce((sum, chat) => {
      const chatMessages = messages[chat.$jid]?.messages
      const message = chatMessages
        ?.filter(isNotBreakMessage)
        .find(message => message.status !== 'displayed' && message.from !== user?.$jid)
      return sum + (!!message ? 1 : 0)
    }, 0)
    amount += unreadNews > 0 ? 1 : 0
    setChatWithUnreadMessage(amount)
  }, [acceptedChats, user?.$jid, messages, unreadNews])

  useEffect(() => {
    const keys = Object.keys(contactChats)
    setInvites(keys
      .filter(key => (contactChats[key].groups?.includes(GroupCategoriesMap.pending)
          || contactChats[key].$subscription === 'from') &&
        contactChats[key].$subscription !== 'none' && !contactChats[key].ask)
      .map(key => contactChats[key]),
    )
  }, [contactChats])

  const handleClick = (tabId: Tabs) => {
    dispatch(setTab(tabId))
  }

  return <>
    <ul
      className={classNames(styles.navTabs, className)}
    >
      {tabs.map(tab => <li
        key={tab.id}
        className={classNames(styles.navItem, activeTab === tab.id && styles.active)}
        onClick={() => {
          handleClick(tab.id)
        }}
      >
        {!!tab.icon && <i className={classNames(tab.icon, styles.icon)}/>}
        {tab.id === Tabs.Chats && <>
          {!!chatWithUnreadMessage && <span className={styles.counter}>{chatWithUnreadMessage}</span>}
        </>}
        {tab.id === Tabs.Contacts && <>
          {!!invites.length && <span className={styles.counter}>{invites.length}</span>}
        </>}
        {tab.id === Tabs.Team && <>
          {!!myTeamUreadMessages && <span className={styles.counter}>{myTeamUreadMessages}</span>}
        </>}
        {tab.id === Tabs.Profile && <Avatar
          className={styles.icon}
          src={user?.thumbnail}
          size={'small'}
          name={user?.nickname}
        />}
        {tab.id === Tabs.Service && <ADXSvg className={classNames(styles.icon, styles.adxIcon)}/>}
        <FormattedMessage id={tab.id}>{txt => <p className={styles.text}>{txt}</p>}</FormattedMessage>
      </li>)}
    </ul>
  </>
}

export default NavTabs
