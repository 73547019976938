import styles from './ChatFileItem.module.scss'
import { CSSProperties, memo, useMemo, useRef, useState } from 'react';
import { ChatFile } from '@/store/chats/chats';
import { areEqual } from 'react-window';
import { getFileExtension, getMimeType } from '@/utils/file';
import { FileType, FileTypes, show as showDocViewer } from '@/components/Modal/FileViewer/FileViewer';
import { showOnlyImage as showImageViewer } from '@/components/Modal/ImageViewer/ImageViewer'
import { saveAs } from 'file-saver';
import { downloadFileWithProgress } from '@/api/upload';
import { AxiosProgressEvent } from 'axios';
import CirclePreloader from '@/components/Primitive/CirclePreloader/CirclePreloader';
import { ReactComponent as FileImage } from '@/images/icons/docs/file.svg';
import { ReactComponent as PictureImage } from '@/images/icons/docs/image.svg'
import { ReactComponent as ExcelImage } from '@/images/icons/docs/excel.svg'
import { ReactComponent as PdfImage } from '@/images/icons/docs/pdf.svg'
import { ReactComponent as PointImage } from '@/images/icons/docs/point.svg'
import { ReactComponent as WordImage } from '@/images/icons/docs/word.svg'
import { ReactComponent as VideoImage } from '@/images/icons/docs/video.svg'
import Button from '@/components/Primitive/Buttons/Button/Button'
import { isFileImageSupported } from '@/utils/image'
import { FormattedMessage } from 'react-intl'

interface ChatFileProps {
  index: number,
  style: CSSProperties,
  data: ChatFileData
}

export interface ChatFileData {
  files: ChatFile[]
  onFileShow?: () => void
}

const canOpenedFiles = new Map([
  ['jpg', true],
  ['jpeg', true],
  ['png', true],
  ['pdf', true],
  ['docx', true]
])

const ChatFileItem = memo(({ index, style, data }: ChatFileProps) => {
  const { files, onFileShow } = data
  const file = files[index]
  const [progress, setProgress] = useState(0)
  const loading = useRef(false)

  const Image = useMemo(() => {
    const fileExt = getFileExtension(file.name)
    switch (fileExt.toLowerCase()) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        return PictureImage
      case 'xls':
      case 'xlsx':
        return ExcelImage
      case 'pdf':
        return PdfImage
      case 'doc':
      case 'docx':
        return WordImage
      case 'ppt':
      case 'pptx':
      case 'pptm':
        return PointImage
      case 'avi':
      case 'mpg':
      case 'mpeg':
      case 'webm':
      case 'wmv':
      case 'mov':
        return VideoImage
      default:
        return FileImage
    }
  }, [file.name])

  const canOpen = useMemo(() => {
    const fileExt = getFileExtension(file.url)
    return !!canOpenedFiles.get(fileExt)
  }, [file.url])

  if (!file) {
    return <div style={style} />
  }

  const onPreviewFile = async() => {
    if (loading.current) {
      return
    }
    const onDownloadProgress = (e: AxiosProgressEvent) => {
      setProgress(Math.round((e.progress || 0) * 100))
    }
    const fileExt = getFileExtension(file.name)
    loading.current = true
    try {
      const { data } = await downloadFileWithProgress({
        url: file.url,
        onDownloadProgress
      })
      const url = URL.createObjectURL(data)
      if (fileExt in FileTypes) {
        showDocViewer({ url, type: fileExt as FileType, fileName: file.name })
        onFileShow?.()
      }
      if (isFileImageSupported(data)) {
        showImageViewer({
          url: file.url,
          imageName: file.name
        })
        onFileShow?.()
      }
    } finally {
      loading.current = false
      setProgress(0)
    }
  }

  const onDownload = async() => {
    if (loading.current) {
      return
    }
    const onDownloadProgress = (e: AxiosProgressEvent) => {
      setProgress(Math.round((e.progress || 0) * 100))
    }
    const fileExt = getFileExtension(file.name)
    loading.current = true
    try {
      const { data } = await downloadFileWithProgress({
        url: file.url,
        onDownloadProgress
      })
      const mimeType = getMimeType(fileExt)
      const blob = new Blob([data], mimeType ? { type: mimeType } : undefined)
      saveAs(blob, file.name)
    } finally {
      loading.current = false
      setProgress(0)
    }
  }

  return <div
    className={styles.box}
    style={style}
  >
    <Image className={styles.image} />
    <div className={styles.infoBox}>
      <span className={styles.name}>{file.name}</span>
      <div className={styles.btnBox}>
        <FormattedMessage id="ACTION.DOWNLOAD">
          {txt => <Button
            view="link"
            className={styles.linkBtn}
            onClick={onDownload}
            disabled={progress > 0}
          >
            {txt}
          </Button>}
        </FormattedMessage>
        {canOpen && <>
          <span className={styles.btnDivider}>|</span>
          <FormattedMessage id="ACTION.OPEN">
            {txt => <Button
              view="link"
              className={styles.linkBtn}
              disabled={progress > 0}
              onClick={onPreviewFile}
            >
              {txt}
            </Button>}
          </FormattedMessage>
        </>}
      </div>
    </div>
    <CirclePreloader className={styles.preloader} progress={progress} color={'var(--main-color)'} />
  </div>
}, areEqual)

export default ChatFileItem
