export enum CallRequests {
  Init = 'init',
  Call = 'call',
  CallEvent = 'call-event',
  Answer = 'answer',
  AnswerEvent = 'answer-event',
  CancelCall = 'cancel-call',
  CancelEvent = 'cancel-event'
}

export interface CallRequest {
  id: string
  type: CallRequests
}

export type CallMessage<T extends CallRequest = CallRequest> = Omit<T, 'id'>

export interface CallAnswerSuccess extends CallRequest {
  result: 'success'
}

export interface CallAnswerError extends CallRequest {
  result: 'error'
  message: string
}

export type CallAnswer = CallAnswerSuccess | CallAnswerError

export const isAnswerSuccess = (answer: CallAnswer): answer is CallAnswerSuccess => {
  return answer.result === 'success'
}

export interface OutgoingCallRequest extends CallRequest{
  type: CallRequests.Call,
  target: string
}

export type MessageOutgoingCall = Omit<OutgoingCallRequest, 'id'>

export interface OutgoingCallAnswer extends CallAnswerSuccess {
  token: string
}

export const isIncomingCall = (event: CallRequest): event is IncomingCallEvent => {
  return event.type === CallRequests.CallEvent
}

export interface IncomingCallEvent extends CallRequest{
  type: CallRequests.CallEvent,
  target: string
  token: string
}

export interface AnswerCallRequest extends CallRequest {
  type: CallRequests.Answer
  target: string
  result: 'ok' | 'cancel'
}

export type MessageAnswerCall = Omit<AnswerCallRequest, 'id'>

export interface AnswerCallEvent extends CallRequest {
  type: CallRequests.AnswerEvent,
  target: string
  result: 'ok' | 'cancel'
}

export interface CancelCallRequest extends CallRequest {
  type: CallRequests.CancelCall,
  target: string
}

export type MessageCancelCall = Omit<CancelCallRequest, 'id'>

export interface CancelCallEvent extends CallRequest {
  type: CallRequests.CancelEvent
  target: string
}

export const isCancelCallEvent = (request: CallRequest): request is CancelCallEvent => {
  return request.type === CallRequests.CancelEvent
}

export interface InitRequest extends CallRequest {
  type: CallRequests.Init
  token: string
}

export type MessageInit = Omit<InitRequest, 'id'>

export const isInitEvent = (event: CallRequest): event is InitRequest => {
  return event.type === CallRequests.Init
}
