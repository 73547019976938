import styles from './TabModal.module.scss'
import {IClass} from '@/interfaces/general';
import classNames from 'classnames';
import {forwardRef, PropsWithChildren, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {Property} from 'csstype';

export interface ITabModal {
  show: () => void
  hide: () => void
}

interface TabModalProps extends IClass, PropsWithChildren {
  show?: boolean
  width?: Property.Width<string | number>
  onHide?: () => void
}

const TabModal = forwardRef<ITabModal, TabModalProps>((
  {width, className, children, onHide}: TabModalProps, ref) => {
  const [shown, setShown] = useState(false)
  const [show, setShow] = useState(false)
  const [hide, setHide] = useState(false)

  const hideCallback = useCallback(() => {
    if (!shown) {
      return
    }
    setHide(true)
    setShow(false)
  }, [shown])

  useEffect(() => {
    if (shown) {
      setShow(true)
    }
  }, [shown]);

  useEffect(() => {
    if (show) {
      document.addEventListener('click', hideCallback)
    }

    return () => {document.removeEventListener('click', hideCallback)}
  }, [show, hideCallback])

  useImperativeHandle(ref, () => ({
    show() {
      setShown(true)
    },
    hide: hideCallback
  }), [hideCallback])

  if (!shown) {
    return null
  }

  const handleAnimation = () => {
    if (hide) {
      setHide(false)
      setShown(false)
      onHide?.()
    }
  }

  return <div className={classNames(styles.box, className)} onClick={(e) => {e.stopPropagation()}}>
    <div
      className={
        classNames(
          styles.container,
          show && styles.show,
          hide && styles.hide,
        )}
      style={{width}}
      onAnimationEnd={handleAnimation}
    >
      {children}
    </div>
  </div>
})

export default TabModal
