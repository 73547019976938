import {v4 as uuid} from "uuid";
import request from "@/api/request";
import { AxiosProgressEvent } from "axios";
import {StatusOK} from '@/api/interfaces';

interface UploadImageProps {
    file: File,
    uid?: string
}

interface UploadImageReturn {
    url: string,
    thumbnail: string
}

export const uploadImage = ({file, uid = uuid()}: UploadImageProps): Promise<UploadImageReturn> => {
    const formData = new FormData()
    formData.append('uid', uid);  // Note: uid should be first!
    formData.append('image', file);
    return request('post', '/api/media/upload/image', formData)
        .then(data => {
            return data.data
        })
}

interface UploadFileProps {
    file: File,
    uid: string,
    onUploadProgress?: (e: AxiosProgressEvent) => void
}

interface UploadFile {
    mime_type: string
    size: number
    token: string
    url: string
}

export const uploadFile = async ({file, uid, onUploadProgress}: UploadFileProps): Promise<UploadFile> => {
  const formData = new FormData()
  formData.append('uid', uid);
  formData.append('file', file);
  const data = await request('post', '/api/media/upload/file', formData, {
    onUploadProgress,
  });
  return data.data.data;
}

interface UploadVideo extends StatusOK{
  thumbnail: string
  url: string
}
export const uploadVideo = async ({file, uid, onUploadProgress}: UploadFileProps): Promise<UploadVideo> => {
  const formData = new FormData()
  formData.append('uid', uid)
  formData.append('file', file)
  const {data} = await request(
    'post',
    '/api/media/upload/video',
    formData,
    {onUploadProgress}
    )
  return data
}

export const uploadAvatar = (avatar: Blob): Promise<UploadImageReturn> => {
    const formData = new FormData()
    formData.append('avatar', avatar)

    return request('post', '/api/profile/avatar/update', formData)
      .then(data => data.data)
}

interface UploadAudioProps {
  uid: string,
  blob: Blob
}

interface UploadAudio {
  url: string
}

export const uploadAudio = ({uid, blob}: UploadAudioProps): Promise<UploadAudio> => {
  const formData = new FormData()
  formData.append('uid', uid)
  formData.append('voicerecord', blob, uid + '.mp3')
  return request('post', '/api/media/upload/vrec_and_convert', formData)
    .then(data => data.data)
}

interface UploadFileWitProgressProps {
  url: string,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export const downloadFileWithProgress = ({url, onDownloadProgress}: UploadFileWitProgressProps) => {
  return request('get', url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    }
  }, {
    responseType: 'blob',
    onDownloadProgress
  })
}
