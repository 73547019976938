import styles from './AddContactPanel.module.scss'
import {FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {getActiveChatId, getChat, updateChat} from '@/store/chats/chats';
import {usePrivateChatList} from '@/hooks/usePrivateChatList';
import usePresence from '@/hooks/usePresence';
import {addToRoom} from '@/api/chats';
import {getUser} from '@/store/user/user';
import {getChatId} from '@/utils/chats';
import useVCard from '@/hooks/useVCard';
import {useMUChatList} from '@/hooks/useMUChatList';
import Confirm from '@/components/Modal/Confirm/Confirm';
import React, {ComponentProps, useState} from 'react';
import useModalSimple from '@/hooks/useModalSimple';
import useActiveService from '@/hooks/useActiveService'

const AddContactPanel = () => {
  const user = useAppSelector(getUser)
  const activeChatId = useAppSelector(getActiveChatId)
  const activeChat = useAppSelector(getChat(activeChatId + ''))
  const dispatch = useAppDispatch()
  const [confirmProps, setConfirmProps] = useState<ComponentProps<typeof Confirm>>({})
  const {removeContact} = usePrivateChatList()
  const {unsubscribe, subscribed, subscribe} = usePresence()
  const {getVCard} = useVCard()
  const {getMembers} = useMUChatList()
  const {formatMessage} = useIntl()
  const {setActiveChatId} = useActiveService()

  const handleHideConfirm = () => {
    setConfirmProps({})
    setActiveChatId(null)
  }

  const {
    show: showConfirmationDialog,
    hide: hideConfirmationDialog,
    visible: visibleConfirmationDialog
  } = useModalSimple({
    onHide: handleHideConfirm
  })

  const addContactHandler = () => {
    const jid = activeChat?.$jid || ''
    subscribed(jid)
    subscribe(jid)
  }

  const joinToGroup = () => {
    if (!activeChat || !user) {
      return
    }
    const jid = activeChat.$jid
    addToRoom({
      participantJids: [user.memberId],
      roomJid: getChatId(jid),
      referrer: activeChat?.ask || '',
    }).then(() => {
      dispatch(updateChat({
        chatJid: jid, options: {
          $subscription: 'both',
        },
      }))
      getVCard(jid)
      getMembers(jid)
    })
      .catch(() => {
      setConfirmProps({
        title: formatMessage({id: 'you_cannot_join_this_group'}),
        description: formatMessage({id: 'this_group_is_private_so_only'}),
        onlyOneButton: true,
        confirmTitle: formatMessage({id: 'CONFIRM.OK'})
      })
      showConfirmationDialog()
    })
  }

  const declineContactHandler = () => {
    const jid = activeChat?.$jid || ''
    unsubscribe(jid)
    removeContact(jid)
    setActiveChatId(null)
  }

  const declineGroup = () => {
    setActiveChatId(null)
  }

  return <div className={styles.panelWrapper}>
    {activeChat?.type === 'chat' && <FormattedMessage
      id={!activeChat?.$ask ? 'addContactToStartConversation' : 'yourRequestHasNotBeenAccepted'}
    >
      {title => <p className={styles.title}>{title}</p>}
    </FormattedMessage>}
    {activeChat?.type === 'groupchat' && <FormattedMessage
      id={'join_group_message'}
      values={{
        group: activeChat.name,
      }}
    >
      {title => <p className={styles.title}>{title}</p>}
    </FormattedMessage>}
    <div className={styles.buttons}>
      {(!activeChat?.$ask || activeChat?.type === 'groupchat') && <FormattedMessage
        id={activeChat?.type === 'groupchat' ? 'ACTION.JOIN_MUC' : 'ACTION.ADD_CONTACT'}>
        {txt => <button
          className={classNames('button', 'mainButton')}
          onClick={activeChat?.type === 'groupchat' ? joinToGroup : addContactHandler}
        >{txt}
        </button>}
      </FormattedMessage>}
      <FormattedMessage id={!activeChat?.$ask ? 'ACTION.DECLINE' : 'ACTION.CANCEL_REQUEST'}>
        {txt => <button
          className={classNames('button', 'secondaryButton')}
          onClick={activeChat?.type === 'groupchat' ? declineGroup : declineContactHandler}
        >{txt}
        </button>}
      </FormattedMessage>
    </div>
    {visibleConfirmationDialog && <Confirm hide={hideConfirmationDialog} {...confirmProps}/>}
  </div>
}

export default AddContactPanel
