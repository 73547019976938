import request from "./request";

export const removeFromChat = (uid: string, Jid: string, kind: string) => {
    return request('post', '/api/chat/message/delete', {uid, Jid, kind})
        .then(data => {
            return data.data
        })
}

export const removeFromGroup = (uid: string, activeChatJid: string) => {
  return request('post', '/api/group/message/delete', {uid, jid: activeChatJid})
    .then(data => {
      return data.data
    })
}
