import { TypeLang } from '@/store/language/language'

export type LangListType = {
  [key in TypeLang]: string;
}

export const LangList: LangListType = {
  'en': 'English',
  'cn': '简体中文',
  'tc': '繁體中文',
  'kr': '한국어',
  'jp': '日本語',
}

const adxLanguages: [TypeLang, number][] = [
  ['en', 1],
  ['cn', 4],
  ['jp', 6],
  ['tc', 7],
  ['kr', 8]
]


const adxLanguageIdMap = new Map<TypeLang, number>(adxLanguages)
const adxLanguageNameMap = new Map<number, TypeLang>(adxLanguages.map(([key, value]) => [value, key]))


export const getADXLanguageId = (lang: TypeLang): number => {
  return adxLanguageIdMap.get(lang) || adxLanguageIdMap.get('en') || -1
}

export const getADXLanguageName = (lang: number): TypeLang => {
  return adxLanguageNameMap.get(lang) || 'en'
}
