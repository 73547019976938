import styles from './CallMessage.module.scss'
import { IMessageComponent } from '@/components/Chat/ChatMessage/ChatMessage'
import GeneralMessage from '@/components/Chat/ChatMessage/GeneralMessage/GeneralMessage'
import { ICallMessage, IMessage } from '@/store/messages/messages'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { formatMinuteTime, getLastTime } from '@/utils/dateutils'
import { ReactComponent as CallImage } from '@/images/icons/call.svg'
import { INews } from '@/store/news/news'

export const isCallMessage = (message: IMessage | INews): message is ICallMessage => {
  return message.type === 'call'
}

const CallMessage = ({ msg, incoming }: IMessageComponent) => {
  const callMessage = isCallMessage(msg) ? msg : null

  if (!callMessage) {
    return null
  }

  const callMissed = ['canceled', 'missed'].includes(callMessage.call?.type || '')
  let messageTextId = 'unknown'
  switch (callMessage.call?.type) {
    case "missed":
    case "canceled":
      messageTextId = incoming ? 'CALL.MISSED' : 'CALL.CANCELED'
      break
    case "accessed":
      messageTextId = incoming ? 'CALL.INCOMING' : 'CALL.OUTGOING'
      break
  }

  return <GeneralMessage
    key={msg.id}
    msg={msg}
    incoming={incoming}
  >
    <div className={classNames(styles.callMsgBox, callMissed && styles.missed)}>
      <CallImage className={styles.callImg}/>
      <FormattedMessage id={messageTextId}>
        {txt => <span className={classNames(
          styles.callText,
          incoming && styles.incoming,
          callMissed && styles.missed
        )}>{txt}</span>}
      </FormattedMessage>
      {!callMissed && <span className={styles.duration}>{formatMinuteTime(callMessage.call?.duration || 0)}</span>}
      <span className={styles.time}>{getLastTime(callMessage.timestamp)}</span>
    </div>
  </GeneralMessage>
}

export default CallMessage
