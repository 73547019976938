import {ComponentProps, useCallback, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {leaveChat as apiLeaveChat, muteNotify} from '@/api/chats';
import {
  Chat,
  deleteChats,
  getActiveChatId,
  IGroupChatMember,
  GroupCategoriesMap,
  updateChat,
} from '@/store/chats/chats';
import useUtils from '@/hooks/useUtils';
import {getUser} from '@/store/user/user';
import usePresence from '@/hooks/usePresence';
import {usePrivateChatList} from '@/hooks/usePrivateChatList';
import {ErrorFactory} from '@/services/errorFactory';
import useModalSimple from '@/hooks/useModalSimple';
import Confirm from '@/components/Modal/Confirm/Confirm';
import {useIntl} from 'react-intl';
import Roster from '@/services/Roster';
import {
  checkAdmin,
  checkOwner,
  filterAcceptedChats,
  getNameOrNick,
  isMUChat,
  isMyTeam,
  isTeamChannel,
} from '@/utils/chats';
import AutoDeleteMessages from '@/components/Modal/AutoDeleteMessages/AutoDeleteMessages';
import {getOrCreateArray} from '@/utils/array';
import connection from '@/services/Connection/Connection';
import useActiveService from '@/hooks/useActiveService'

interface MuteChatProps {
  jid: string,
  mute: boolean
}

const ACTIONS = {
  FAVORITE: 'favorite',
  BLOCK_CONTACT: 'block',
  DELETE_AND_LEAVE_CHAT: 'delete_chat',
  DELETE_USER: 'delete_user',
  MUTE_CHAT: 'mute_chat',
  UNMUTE_CHAT: 'unmute_chat',
  AUTO_DELETE_MESSAGES: 'auto_delete_messages',
  ADD_TO_CONTACTS: 'add_to_contacts',
  REMOVE_FROM_CONTACTS: 'remove_from_contacts',
}

const useChatActions = () => {
  const user = useAppSelector(getUser)
  const activeJid = useAppSelector(getActiveChatId)
  const dispatch = useAppDispatch()
  const [confirmProps, setConfirmProps] = useState<ComponentProps<typeof Confirm>>({})
  const [autoDeleteMessagesProps, setAutoDeleteMessagesProps] = useState<ComponentProps<typeof AutoDeleteMessages>>({})
  const {toggleFavoriteUtils} = useUtils()
  const {unsubscribe} = usePresence()
  const {removeContact, blockContact} = usePrivateChatList()
  const {formatMessage} = useIntl()
  const {setActiveChatId} = useActiveService()

  const {show: showConfirmModal, hide: hideConfirmModal, visible: visibleConfirmModal} = useModalSimple()
  const {
    show: showAutoDeleteMessages,
    hide: hideAutoDeleteMessagesModal,
    visible: visibleAutoDeleteMessagesModal,
  } = useModalSimple()

  const muteChat = useCallback(async ({jid, mute}: MuteChatProps) => {
    try {
      await muteNotify({jid, mute})
      dispatch(updateChat({
        chatJid: jid, options: {
          notifyMuted: mute,
        },
      }))
    } catch (e) {
    }
  }, [dispatch])

  const toggleFavorite = useCallback(async (chat: Chat) => {
    if (!user) {
      return
    }
    const isFavorite = chat?.groups?.includes(GroupCategoriesMap.favorite)
    try {
      await toggleFavoriteUtils(chat, user, !!isFavorite)
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }, [user, toggleFavoriteUtils])

  const blockUser = useCallback(async (chat: Chat) => {
    if (chat) {
      unsubscribe(chat.$jid)
      removeContact(chat.$jid)
      if (activeJid === chat.$jid) {
        setActiveChatId(null)
      }
      await blockContact(chat?.$jid)
    }
  }, [activeJid, removeContact, unsubscribe, blockContact, setActiveChatId])

  const deleteUser = useCallback(async (chat: Chat) => {
    if (chat) {
      unsubscribe(chat.$jid)
      removeContact(chat.$jid)
      if (activeJid === chat.$jid) {
        setActiveChatId(null)
      }
    }
  }, [activeJid, removeContact, unsubscribe, setActiveChatId])

  const leaveChat = useCallback(async (chat?: Chat | null) => {
    if (chat) {
      await apiLeaveChat(chat)
      dispatch(deleteChats(chat.$jid))
      if (activeJid === chat.$jid) {
        setActiveChatId(null)
      }
    }
  }, [dispatch, activeJid, setActiveChatId])

  const onClickContextMenu = async (action: string, chat: Chat) => {
    switch (action) {
      case ACTIONS.FAVORITE:
        await toggleFavorite(chat)
        break
      case ACTIONS.BLOCK_CONTACT:
        setConfirmProps({
          title: formatMessage({id: 'CONFIRM.TITLE'}),
          confirmTitle: formatMessage({id: 'CONFIRM.YES'}),
          cancelTitle: formatMessage({id: 'CONFIRM.NO'}),
          description: formatMessage({id: 'block_contact_warning'}),
          onConfirm: () => blockUser(chat),
        })
        showConfirmModal()
        break
      case ACTIONS.DELETE_USER:
        setConfirmProps({
          title: formatMessage({id: 'CONTACTS.DELETE_CONTACT'}),
          confirmTitle: formatMessage({id: 'ACTION.DELETE'}),
          cancelTitle: formatMessage({id: 'ACTION.CANCEL'}),
          description: formatMessage({id: 'CONTACTS.USER_DELETE_ASK'},
            {
              name: getNameOrNick(chat),
            }),
          onConfirm: () => deleteUser(chat),
        })
        showConfirmModal()
        break
      case ACTIONS.DELETE_AND_LEAVE_CHAT:
        setConfirmProps({
          description: formatMessage({id: 'GROUP_CHAT.ASK_BEFORE_DELETE'}),
          onConfirm: () => leaveChat(chat),
        })
        showConfirmModal()
        break
      case ACTIONS.MUTE_CHAT:
      case ACTIONS.UNMUTE_CHAT:
        const mute = action === ACTIONS.MUTE_CHAT
        muteChat({jid: chat.$jid, mute})
        break
      case ACTIONS.AUTO_DELETE_MESSAGES:
        setAutoDeleteMessagesProps({
          chat: chat,
        })
        showAutoDeleteMessages()
        break
      case ACTIONS.ADD_TO_CONTACTS: {
        const groupsSet = new Set([...(chat.groups || [])])
        groupsSet.add(GroupCategoriesMap.accepted)
        const msg = Roster.Messages.updateItem({
          from: user?.$jid || '',
          to: chat.$jid,
          name: chat.nick || chat.name,
          groups: Array.from(groupsSet),
        })
        connection.sendStrophe(msg)
        break
      }
      case ACTIONS.REMOVE_FROM_CONTACTS: {
        const groups = chat.groups?.filter(group =>
          !([
            GroupCategoriesMap.accepted,
            GroupCategoriesMap.favorite,
          ].includes(group)))
        const msg = Roster.Messages.updateItem({
          from: user?.$jid || '',
          to: chat.$jid,
          name: chat.nick || chat.name,
          groups,
        })
        connection.sendStrophe(msg)
        break
      }
      default:
        throw ErrorFactory.createActionNotImplemented(action)
    }
  }

  const getList = useCallback((chat: Chat) => {
    const groups = new Set(chat.groups || [])
    const isFavorite = groups.has(GroupCategoriesMap.favorite)

    const list = [
      {
        text: formatMessage({id: chat.notifyMuted ? 'ACTION.UNMUTE_CHAT' : 'ACTION.MUTE_CHAT'}),
        action: chat.notifyMuted ? ACTIONS.UNMUTE_CHAT : ACTIONS.MUTE_CHAT,
      },
    ]
    if (!isTeamChannel(chat) && filterAcceptedChats(chat)) {
      list.push({
        text: formatMessage({id: isFavorite ? 'CONTACTS.REMOVE_FAVORITES' : 'CONTACTS.ADD_FAVORITES'}),
        action: ACTIONS.FAVORITE,
      })
    }
    if (isMUChat(chat) && !isMyTeam(groups)) {
      list.push({
        text: formatMessage({id: 'GROUP_CHAT.DELETE_LEAVE'}),
        action: ACTIONS.DELETE_AND_LEAVE_CHAT,
      })
    }
    if (isMUChat(chat) && (checkOwner(user?.$jid || '', getOrCreateArray<IGroupChatMember>(chat?.members)) ||
      checkAdmin(user?.$jid || '', getOrCreateArray<IGroupChatMember>(chat?.members)))) {
      list.push({
        text: formatMessage({id: 'AUTO_DElETE_MESSAGES.TITLE'}),
        action: ACTIONS.AUTO_DELETE_MESSAGES,
      })
    }
    if (!isMUChat(chat) && isMyTeam(groups)) {
      if (!groups.has(GroupCategoriesMap.accepted)) {
        list.push({
          text: formatMessage({id: 'add_to_contacts'}),
          action: ACTIONS.ADD_TO_CONTACTS,
        })
      } else {
        list.push({
          text: formatMessage({id: 'remove_from_contacts'}),
          action: ACTIONS.REMOVE_FROM_CONTACTS,
        })
      }
    }
    if (!isMUChat(chat)) {
      if (!isMyTeam(groups)) {
        list.push({
          text: formatMessage({id: 'action_block_contact'}),
          action: ACTIONS.BLOCK_CONTACT,
        })
        list.push({
          text: formatMessage({id: 'CONTACTS.DELETE_CONTACT'}),
          action: ACTIONS.DELETE_USER,
        })
      }
      list.push({
        text: formatMessage({id: 'AUTO_DElETE_MESSAGES.TITLE'}),
        action: ACTIONS.AUTO_DELETE_MESSAGES,
      })
    }

    return list
  }, [formatMessage, user?.$jid])

  return {
    muteChat,
    leaveChat,
    toggleFavorite,
    blockUser,
    deleteUser,
    confirmProps,
    showConfirmModal,
    hideConfirmModal,
    visibleConfirmModal,
    getList,
    autoDeleteMessagesProps,
    visibleAutoDeleteMessagesModal,
    hideAutoDeleteMessagesModal,
    onClickContextMenu,
  }
}

export default useChatActions
