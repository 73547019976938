import {useMUChatList} from '@/hooks/useMUChatList';
import {useAppDispatch} from '@/hooks/appHook';
import {useCallback, useRef} from 'react';
import {Chat, setMembersLoaded, setMutedUsers} from '@/store/chats/chats';
import {getMutedUsers} from '@/api/chats';
import {getChatId} from '@/utils/chats';

const useChatMembers = () => {
  const dispatch = useAppDispatch()
  const {getMembers} = useMUChatList()
  const chatMembersLoading = useRef<Set<string>>(new Set())

  const updateMembers = useCallback((groupJid: string) => {
    return getMembers(groupJid)
      .then(async () => {
        dispatch(setMembersLoaded({chatJid: groupJid, value: true}))
        const mutedUsers = await getMutedUsers(getChatId(groupJid))
        dispatch(setMutedUsers({chatJid: groupJid, mutedUsers}))
      })
  }, [dispatch, getMembers])

  const loadMembers = useCallback((chat: Chat) => {
    if (chat?.type === 'groupchat'
      && chat.$subscription !== 'none'
      && !chat.membersLoaded
      && !chatMembersLoading.current.has(chat.$jid)
    ) {
      chatMembersLoading.current.add(chat.$jid)
      updateMembers(chat.$jid)
        .finally(() => {
          chatMembersLoading.current.delete(chat.$jid)
        })
    }
  }, [updateMembers])

  return {
    updateMembers, loadMembers
  }
}

export default useChatMembers
