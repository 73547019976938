import {createSlice} from '@reduxjs/toolkit';
import {RootState} from "@/store";
import {TypeLang} from "@/store/language/language";
import AceStickers from "@/store/stickers/ace.json"

const STORAGE_STICKER_NAME = 'yc_stickers_data'

export enum Tabs {
  emoji = 'STICKERS.EMOJI',
  stickers = 'STICKERS.STICKERS',
  stickerStore = 'STICKERS.STICKERS_STORE'
}

type StickerTranslation = {
  [key in TypeLang]: string
}

export interface Sticker {
  id: string
  ff: string
  url: string
}
interface StickerPackCategory {
  id: string,
  name: StickerTranslation
  url: string
}

export interface StickerPackInfo {
  id: string
  bought: boolean
  category: StickerPackCategory
  coverUrl: string
  description: StickerTranslation
  downloads: number
  iconUrl: string
  name: StickerTranslation
  owner: string
  price: number
  publishedDate: Date
  reason: string
  stickers: Sticker[]
  version: number
  zipUrl: string
}

export interface StickerPack {
  id: string
  publishedDate: Date | null,
  updatedAt: Date | null
  version: string
  info?: StickerPackInfo
}

export interface MyStickerPack extends Omit<StickerPack, 'info'> {
  info: StickerPackInfo
}

type StickerStore = {
  store: {
    loaded: boolean,
    stickers: StickerPack[]
  }
}

type MyStickers = {
  myStickers: MyStickerPack[]
}

interface PackList {
  [key: string]: MyStickerPack
}

interface StoreTabs {
  activeTab: Tabs,
  activeSticker: string
}

const saveMyStickers = (packs: MyStickerPack[]) => {
  const packList: PackList = packs.reduce((acc, pack) => {
    acc[pack.id] = pack
    return acc
  }, {} as PackList)
  localStorage.setItem(STORAGE_STICKER_NAME, JSON.stringify(packList))
}

const getMyStickersFromStore = (): MyStickerPack[] => {
  const packList: PackList = {
    ...JSON.parse(localStorage.getItem(STORAGE_STICKER_NAME) || '{}'),
    ...AceStickers
  }

  return Object.keys(packList).map(key => packList[key])
}


const initialState: StickerStore & MyStickers & StoreTabs = {
  activeTab: Tabs.emoji,
  activeSticker: '',
  store: {
    loaded: false,
    stickers: [],
  },
  myStickers: getMyStickersFromStore(),
}

const sortPacks = (pack1: StickerPack, pack2: StickerPack) => {
  const date1 = pack1.updatedAt?.getTime() || pack1.publishedDate?.getTime() || 0
  const date2 = pack2.updatedAt?.getTime() || pack2.publishedDate?.getTime() || 0
  return date2 - date1
}

const createStickerPackMap = <T extends StickerPack>(stickerPacks: T[]): Map<string, T> => {
  const packMap: Map<string, T> = new Map()
  stickerPacks.forEach(pack => {
    packMap.set(pack.id, pack)
  })
  return packMap
}

const stickers = createSlice({
  name: 'stickers',
  initialState,
  reducers: {
    update(state, {payload}: {payload: StickerPack[] }) {
      const packMap = createStickerPackMap(state.store.stickers)
      payload.forEach(pack => {
        const oldSticker = packMap.get(pack.id)
        packMap.set(pack.id, {
          ...oldSticker,
          ...pack
        })
      })
      const packs = Array.from(packMap, ([_, value]) => value)
      packs.sort(sortPacks)
      state.store.stickers = packs
    },
    updateMyStickers(state, {payload}: {payload: MyStickerPack | MyStickerPack[]}) {
      const packMap = createStickerPackMap(state.myStickers)
      const packs = Array.isArray(payload) ? payload : [payload]
      packs.forEach(pack => {
        const oldSticker = packMap.get(pack.id)
        packMap.set(pack.id, {
          ...oldSticker,
          ...pack
        })
      })
      state.myStickers = Array.from(packMap, ([_, value]) => value)
      saveMyStickers(state.myStickers)
    },
    removeMyStickers(state, {payload}: {payload: string | string[]}) {
      const stickersForRemove = new Set(Array.isArray(payload) ? payload : [payload])
      state.myStickers = state.myStickers.filter(sticker => !stickersForRemove.has(sticker.id))
      saveMyStickers(state.myStickers)
    },
    loadPacks(state, {payload}: {payload: boolean}) {
      state.store.loaded = payload
    },
    updatePackInfo(state, {payload}: { payload: {id: string, info: StickerPackInfo} }) {
      const {id, info} = payload
      const packInfo = state.store.stickers.findIndex(pack => pack.id === id)
      if (~packInfo) {
        state.store.stickers[packInfo] = {
          ...state.store.stickers[packInfo],
          info: {
            ...state.store.stickers[packInfo].info,
            ...info
          }
        }
      }
    },
    setActiveTab(state, {payload}: {payload: Tabs}) {
      state.activeTab = payload
    },
    setActiveSticker(state, {payload}: {payload: string}) {
      state.activeSticker = payload
    }
  },
})


export const {
  update,
  updateMyStickers,
  removeMyStickers,
  loadPacks,
  updatePackInfo,
  setActiveTab,
  setActiveSticker,
} = stickers.actions
export const isStickersLoaded = (state: RootState): boolean => state.stickers.store.loaded
export const getStickerPacks = (state: RootState): StickerPack[] => state.stickers.store.stickers
export const getMyStickers = (state: RootState): MyStickerPack[] => state.stickers.myStickers
export const getActiveTab = (state: RootState): Tabs => state.stickers.activeTab
export const getActiveSticker = (state: RootState): string => state.stickers.activeSticker
export default stickers.reducer
