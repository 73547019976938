export interface StatusOK {
  status: 'ok'
}

export interface StatusError {
  error: {
    code: number
    reason: string
    message: string
  }
}

export const isStatusError = (value: Record<any, any>): value is StatusError => {
  return 'error' in value &&
    typeof value.error === 'object' &&
    'code' in value.error &&
    'reason' in value.error &&
    'message' in value.error
}

