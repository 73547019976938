import styles from './CreateSupportTicket.module.scss'
import Select, { SelectOption } from '@/components/Primitive/Select/Select'
import { getLang, TypeLang } from '@/store/language/language'
import { LangList } from '@/constants/languages'
import { useAppSelector } from '@/hooks/appHook'
import { ChangeEventHandler, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import TextInput from '@/components/Primitive/Inputs/TextInput/TextInput'
import FieldArea from '@/components/Primitive/FieldArea/FieldArea'
import Button from '@/components/Primitive/Buttons/Button/Button'
import { show as showNotify } from '@/components/Modal/UserNotify/UserNotify';
import { formatFileSize } from '@/utils/file'
import { createTicket } from '@/api/supportTickets'
import SupportTicketFilesList from '@/components/SupportTicket/SupportTicketFilesList/SupportTicketFilesList'
import { acceptedImagesStr, MAX_FILES_LENGTH, MAX_FILES_SIZE } from '@/components/SupportTicket/constants/files'
import { ISupportTicket } from '@/store/supportTickets/supportTickets'
import CirclePreloader from '@/components/Primitive/CirclePreloader/CirclePreloader'

interface CreateSupportTicketProps {
  onCreated?: (ticket: ISupportTicket) => void
}

const CreateSupportTicket = ({ onCreated }: CreateSupportTicketProps) => {
  const lang = useAppSelector(getLang)
  const [messageLang, setMessageLang] = useState(lang)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const fileInput = useRef<HTMLInputElement>(null)
  const [creatingTicket, setCreatingTicket] = useState(false)
  const { formatMessage } = useIntl()

  const langOptions = useMemo<SelectOption<TypeLang>[]>(() => Object
    .entries(LangList).map(([key, value]) => ({
      value: key as TypeLang,
      text: value,
    })), [])

  const titleHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTitle(e.target.value)
  }

  const messageHandler: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setMessage(e.target.value)
  }

  const openFileDialog = () => {
    fileInput.current?.click()
  }

  const handleSelectFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }
    if (files.length >= MAX_FILES_LENGTH) {
      return
    }
    if (file.size <= MAX_FILES_SIZE) {
      setFiles(prev => ([
        ...prev,
        file
      ]))
    } else {
      showNotify({
        message: formatMessage({ id: 'file_attachment_too_large' }, {
          size: formatFileSize(file.size) as string,
          limit: formatFileSize(MAX_FILES_SIZE) as string,
        }),
      })
    }
    if (fileInput.current) {
      fileInput.current.value = ''
    }
  }

  const removeFileHandler = (file: File) => {
    setFiles(prev => prev.filter(f => f !== file))
  }

  const clearForm = () => {
    setFiles([])
    setTitle('')
    setMessage('')
  }

  const send = () => {
    setCreatingTicket(true)
    createTicket({
      lang: messageLang,
      title,
      message,
      ...(files.length && {
        attach: files
      })
    })
      .then(ticket => {
        onCreated?.(ticket)
        clearForm()
      })
      .finally(() => {
        setCreatingTicket(false)
      })
  }

  const onLangChange = (value: TypeLang) => {
    setMessageLang(value)
  }

  const disabledSentButton = !message.length || !title.length || creatingTicket

  return <div className={styles.box}>
    {creatingTicket && <div className={styles.preloaderBox}>
      <CirclePreloader
        className={styles.preloader}
        color={'var(--main-color)'}
        withoutProgress
      />
    </div>}
    <Select
      className={styles.field}
      label={formatMessage({ id: 'language' })}
      defaultValue={lang}
      disabled={creatingTicket}
      options={langOptions}
      onChange={onLangChange}
    />
    <TextInput
      className={styles.field}
      label={formatMessage({ id: 'subject' })}
      maxLength={200}
      disabled={creatingTicket}
      onChange={titleHandler}
    />
    <FieldArea
      className={styles.field}
      label={formatMessage({ id: 'CHAT.TYPE_MESSAGE' })}
      minRows={6}
      maxRows={6}
      disabled={creatingTicket}
      onChange={messageHandler}
    />
    <SupportTicketFilesList
      className={styles.fileList}
      files={files}
      disabled={creatingTicket}
      onRemove={removeFileHandler}
    />
    {files.length < MAX_FILES_LENGTH && <FormattedMessage id={'choose_file'}>
      {txt => <Button
        className={styles.fileBtn}
        view={'link'}
        disabled={creatingTicket}
        onClick={openFileDialog}
      >
        <i className={'chat-clip'} />
        {txt}
      </Button>}
    </FormattedMessage>}
    <Button
      disabled={disabledSentButton}
      onClick={send}
    >
      Send
    </Button>
    <input
      ref={fileInput}
      className={'d-none'}
      type="file"
      accept={acceptedImagesStr}
      onChange={handleSelectFile}
    />
  </div>
}

export default CreateSupportTicket
