import request from '@/api/request';
import {StatusError, StatusOK} from '@/api/interfaces';

export const updateTeam = (): Promise<StatusOK> => {
  return request('post', '/api/team')
    .then(({data}: { data: StatusOK | StatusError }) => {
      if ('error' in data) {
        return Promise.reject(data.error?.message)
      }
      return data
    })
}
