import styles from './ContextMenu.module.scss'
import React, {CSSProperties, forwardRef, FunctionComponent, SVGProps} from 'react';
import classNames from "classnames";

export interface IContextMenuList {
  text: string,
  action: string,
  icon?: string,
  style?: CSSProperties,
  SvgElement?:  FunctionComponent<SVGProps<SVGSVGElement>>
}
export interface ContextMenuProps {
  list: IContextMenuList[],
  actionsDisabled?: string[]
  onClick?: (action: string, message: any, e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  style?: CSSProperties,
  message?: any
}

const ContextMenuList = forwardRef<HTMLUListElement, ContextMenuProps>((
  {list, onClick, actionsDisabled, style, ...props}: ContextMenuProps, ref
) => {

  return (
    <ul ref={ref}
        className={styles.contextMenu}
        style={style}>
      {list.map(item => {
        const {SvgElement} = item
          return <li
            style={item.style}
            className={`${styles.menuItem} ${(actionsDisabled || []).includes(item.action) ? styles.disabled : ''}`}
            key={item.action}
            onClick={typeof onClick === 'function' && !(actionsDisabled || []).includes(item.action)
              ? (e) => onClick(item.action, props.message, e)
              : undefined}
          >
            {!!item.icon && <i className={classNames(styles.ico, item.icon)}></i>}
            {!item.icon && SvgElement && <SvgElement className={styles.svgIcon}/>}
            <span>{item.text}</span>
          </li>
        }
      )}
    </ul>
  )
})

export default ContextMenuList;
