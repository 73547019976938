import {useEffect, useRef} from 'react';
import {getTypeChat} from '@/api/chats';
import {getActiveChatId, getChat, updateChat} from '@/store/chats/chats';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import { isActiveNews } from '@/store/news/news'

const WATCHER_CHANGED = 'useGroupTypeWatcher'
const event = new Event(WATCHER_CHANGED)

let hasWatcher = false

const useGroupType = () => {
  const dispatch = useAppDispatch()
  const currentChatId = useAppSelector(getActiveChatId)
  const currentChat = useAppSelector(getChat(currentChatId || ''))
  const activeNewsChat = useAppSelector(isActiveNews)
  const watch = useRef(false)
  const requests = useRef<Set<string>>(new Set())

  useEffect(() => {
    const checkWatcher = () => {
      if (!hasWatcher) {
        hasWatcher = true
        watch.current = true
      }
    }
    window.addEventListener(WATCHER_CHANGED, checkWatcher)
    return () => {
      window.removeEventListener(WATCHER_CHANGED, checkWatcher)
    }
  }, [])

  useEffect(() => {
    if (!hasWatcher) {
      hasWatcher = true
      watch.current = true
      window.dispatchEvent(event)
    }
    return () => {
      if (watch.current) {
        hasWatcher = false
        watch.current = false
        window.dispatchEvent(event)
      }
    }
  }, [])

  useEffect(() => {
    if (!watch.current) {
      return
    }
    const jid = currentChat?.$jid + ''
    if (currentChat &&
      !activeNewsChat &&
      currentChat.type !== 'chat' &&
      !currentChat.groupType &&
      !requests.current.has(jid)
    ) {
      requests.current.add(currentChat.$jid)
      getTypeChat(currentChat).then(({data}) => {
        dispatch(updateChat({
          chatJid: currentChat.$jid,
          options: {groupType: data.isPublic ? 'public' : 'private'},
        }))
      }).finally(() => {
        requests.current.delete(jid)
      })
    }
  }, [dispatch, currentChat, activeNewsChat])
}

export default useGroupType
