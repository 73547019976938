import {IMessageComponent} from '@/components/Chat/ChatMessage/ChatMessage';
import styles from './ImageMessage.module.scss'
import generalStyle from '../ChatMessage.module.scss'
import {IImageMessage, IMessage} from '@/store/messages/messages';
import React, {MouseEventHandler} from 'react';
import {show as showImage} from '@/components/Modal/ImageViewer/ImageViewer';
import {getChatId} from '@/utils/chats';
import {getLastTime} from '@/utils/dateutils';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import useGetNames from '@/hooks/useGetNames';
import ReplyMessage from '@/components/Chat/ChatMessage/ReplyMessage/ReplyMessage';
import {useAppSelector} from "@/hooks/appHook";
import {getActiveChatId, getChat} from "@/store/chats/chats";
import { INews } from '@/store/news/news'


export const isImageMessage = (msg: IMessage | INews): msg is IImageMessage => {
  return 'image' in msg
}
const ImageMessage = ({msg, incoming}: IMessageComponent) => {
  const {forwardedName, fromName} = useGetNames({message: msg, incoming})
  const activeChatId = useAppSelector(getActiveChatId)
  const activeChat = useAppSelector(getChat(activeChatId + ''))

  if (!isImageMessage(msg)) {
    return null
  }

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (msg.status === 'sending') {
      return
    }
    e.stopPropagation()
    showImage({message: msg})
  }

  return <div
    className={classNames(
      styles.imageWrapper,
      incoming && styles.incoming,
      msg.status === 'sending' && styles.notActive
    )}
    onClick={handleClick}>
    {msg.forwardedFrom && <FormattedMessage id={'forwarded_from'}>
      {txt => <p className={styles.forwardedFrom}>{txt} {forwardedName || getChatId(msg.forwardedFrom!)}</p>}
    </FormattedMessage>
    }
    {!!msg.replyMessage && <div className={styles.replyWrapper}><ReplyMessage message={msg.replyMessage}/></div>}
    <img
      className={classNames(styles.image, msg.status === 'sending' && styles.sending)}
      src={msg.image.thumbnail}
      alt=""
    />
    <div className={styles.text}>
      <div className={styles.nick}>{fromName}</div>
      <div className={styles.right}>
        <div className={styles.time}>
          {activeChat?.pin?.uid === msg.id && activeChat.pin.visible
            && <i className={classNames("chat-pin", styles.chatPin)} />}
          {getLastTime(msg.timestamp)}
        </div>
        {!incoming && (msg.status === 'sent' || msg.status === 'received')
          ? <i className={classNames(
            "chat-check",
            generalStyle.tick,
            msg.status === 'sent' ? styles.sent : null)}/> : null}
        {!incoming && msg.status === 'displayed' ? <i className={classNames(
          'chat-double-check seen',
          generalStyle.tick
        )}/> : null}
        {!incoming && msg.status === 'sending' ? <i className={classNames(
          "chat-clock",
          generalStyle.tick,
          styles.sent)}/> : null}
      </div>
    </div>
  </div>
}

export default ImageMessage
