import styles from './Participants.module.scss'
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {
  Chat,
  ChatList, getActiveChatId,
  getPrivateChats,
  IGroupChatMember,
  removeMembers, setMutedUsers,
  updateMembers,
} from '@/store/chats/chats';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualList, {MemoItemData} from '@/components/VirtualList/VirtualList';
import ParticipantItem, {
  IChatMember,
  ParticipantsData,
} from './ParticipantItem/ParticipantItem';
import {FocusEventHandler, MouseEvent, useCallback, useEffect, useState} from 'react';
import {BlockedUser, getBlockList} from '@/store/blockList/blockList';
import {checkAdmin, checkOwner, getChatId, isMyTeam, isTeamChannel} from '@/utils/chats';
import {getUser, IInitUser} from '@/store/user/user';
import {getOrCreateArray} from '@/utils/array';
import useContextMenu from '@/components/Menu/ContextMenu/useContextMenu';
import ContextMenuList, {ContextMenuProps} from '@/components/Menu/ContextMenu/ContextMenuList';
import {useIntl} from 'react-intl';
import {
  muteUser as apiMuteUser,
  removeFromRoom,
  unmuteUser as apiUnmuteUser,
  makeAdmin as makeAdminApi,
  unmakeAdmin as unmakeAdminApi,
} from '@/api/chats';
import memoize from 'memoize-one';
import useVCard from '@/hooks/useVCard';
import Search from '@/components/Primitive/Inputs/Search/Search';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import {ReactComponent as MakeAdminImage} from '@/images/icons/assignment_turned_in.svg';
import {ReactComponent as UnmakeAdminImage} from '@/images/icons/assignment_returned.svg';

interface ParticipantsProps {
  chat?: Chat | null
  forceHideContextMenu?: boolean
  className?: string
  fixHeight?: boolean
  onAddParticipants?: () => void
  onSearchFocus?: FocusEventHandler<HTMLElement>
  onOpenChat?: (user: IChatMember) => void
}

interface CreateMembersProps {
  members: IGroupChatMember[],
  chatList: ChatList,
  blockedUsers?: BlockedUser[]
  user: IInitUser | null
}

const ITEM_HEIGHT = 64

const ACTIONS = {
  MAKE_ADMIN: 'MAKE_ADMIN',
  UNMAKE_ADMIN: 'UNMAKE_ADMIN',
  MUTE_MEMBER: 'MUTE_MEMBER',
  UNMUTE_MEMBER: 'UNMUTE_MEMBER',
  REMOVE_MEMBER: 'REMOVE_MEMBER',
}

const contextMenuList = [
  {text: 'ACTION.MAKE_ADMIN', action: ACTIONS.MAKE_ADMIN, SvgElement: MakeAdminImage},
  {text: 'ACTION.UNMAKE_ADMIN', action: ACTIONS.UNMAKE_ADMIN, SvgElement: UnmakeAdminImage},
  {text: 'ACTION.MUTE_MEMBER', action: ACTIONS.MUTE_MEMBER, icon: 'chat-msg-mute'},
  {text: 'ACTION.UNMUTE_MEMBER', action: ACTIONS.UNMUTE_MEMBER, icon: 'chat-msg-unmute', style: {color: '#FF3F3D'}},
  {text: 'ACTION.REMOVE_MEMBER', action: ACTIONS.REMOVE_MEMBER, icon: 'chat-cross'},
]

const createMembers = ({members, chatList, blockedUsers = [], user}: CreateMembersProps): IChatMember[] => {
  const chatMembers: IChatMember[] = []
  const blockedUsersMap = new Map<string, BlockedUser>()
  blockedUsers.forEach(blockedUser => {
    blockedUsersMap.set(blockedUser.jid, blockedUser)
  })

  members.forEach(member => {
    const name = chatList[member.jid]?.vcard?.nickname ||
      blockedUsersMap.get(member.jid)?.name
    const userFields = member.jid !== user?.$jid ?
      {
        name: name || getChatId(member.jid),
        url: chatList[member.jid]?.vcard?.thumbnail,
        nick: chatList[member.jid]?.nick,
      } :
      {
        name: user?.nickname || '',
        url: user?.url,
        nick: chatList[member.jid]?.nick,
      }
    chatMembers.push({
      jid: member.jid,
      affiliation: member.affiliation,
      muted: member.muted,
      ...userFields,
    })
  })
  return chatMembers
}

const sortMembers = (member1: IChatMember, member2: IChatMember) => {
  if (member1.affiliation === 'owner') {
    return -1
  }
  if (member2.affiliation === 'owner') {
    return 1
  }
  if (member1.name < member2.name) {
    return -1
  }
  return 0
}

const filterMembers = (filter: string) => (member: IChatMember) =>
  member.jid.toLowerCase().includes(filter.trim().toLowerCase()) ||
  member.name.toLowerCase().includes(filter.trim().toLowerCase()) ||
  member.nick?.toLowerCase().includes(filter.toLowerCase().trim())

const createParticipantsData: MemoItemData<ParticipantsData> = memoize(
  ({users, ...participantsData}: ParticipantsData) => ({
    users,
    ...participantsData,
    length: users.length,
  }))

const Participants = (
  {
    chat,
    className,
    forceHideContextMenu,
    fixHeight,
    onAddParticipants,
    onSearchFocus,
    onOpenChat
  }: ParticipantsProps) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const [members, setMembers] = useState(getOrCreateArray<IGroupChatMember>(chat?.members))
  const [isOwner, setIsOwner] = useState(checkOwner(chat?.$jid || '', members))
  const [isAdmin, setIsAdmin] = useState(checkAdmin(chat?.$jid || '', members))
  const [canManage, setCanManage] = useState(!isMyTeam(chat?.groups || []))
  const chatList = useAppSelector(getPrivateChats)
  const blockedUsers = useAppSelector(getBlockList)
  const [contextMenuActive, setContextMenuActive] = useState(false)
  const [filter, setFilter] = useState('')
  const [searchHeight, setSearchHeight] = useState(40)
  const {formatMessage} = useIntl()
  const {getVCardWithQueue} = useVCard()
  const currentJid = useAppSelector(getActiveChatId)

  useEffect(() => {
    setCanManage(!isMyTeam(chat?.groups || []))
  }, [chat?.groups]);

  const setMuteUser = useCallback(async (user: IChatMember, mute: boolean) => {
    const oldMuted = user.muted || false
    try {
      dispatch(setMutedUsers({
        chatJid: chat?.$jid || '',
        mutedUsers: [{
          ...user,
          mute: mute,
        }],
      }))
      const api = mute ? apiMuteUser : apiUnmuteUser
      await api({jid: user.jid, roomId: getChatId(chat?.$jid || '')})
    } catch (err) {
      dispatch(setMutedUsers({
        chatJid: chat?.$jid || '',
        mutedUsers: [{
          ...user,
          mute: oldMuted,
        }],
      }))
    }
  }, [chat?.$jid, dispatch])

  const muteUser = useCallback(async (user: IChatMember) => {
    setMuteUser(user, true)
  }, [setMuteUser])

  const unmuteUser = useCallback(async (user: IChatMember) => {
    setMuteUser(user, false)
  }, [setMuteUser])

  const deleteMember = useCallback(async (deletedUser: IChatMember) => {
    const oldMembers = [...members]
    try {
      dispatch(removeMembers({chatJid: chat?.$jid || '', memberJids: [deletedUser.jid]}))
      await removeFromRoom({roomJid: chat?.$jid || '', participantJids: [getChatId(deletedUser.jid)]})
    } catch (e) {
      dispatch(updateMembers({chatJid: chat?.$jid || '', members: oldMembers}))
    }
  }, [dispatch, chat?.$jid, members])

  const makeAdmin = useCallback(async (user: IChatMember) => {
    await makeAdminApi({jid: user.jid, roomId: getChatId(chat?.$jid || '')}).then((res: any) => {
      dispatch(updateMembers({
        chatJid: currentJid || '',
        members: {
          jid: user.jid,
          affiliation: 'admin',
        },
      }))
    })
  }, [dispatch, currentJid, chat?.$jid])

  const unmakeAdmin = useCallback(async (user: IChatMember) => {
    await unmakeAdminApi({jid: user.jid, roomId: getChatId(chat?.$jid || '')}).then((res: any) => {
      dispatch(updateMembers({
        chatJid: currentJid || '',
        members: {
          jid: user.jid,
          affiliation: 'member',
        },
      }))
    })
  }, [dispatch, currentJid, chat?.$jid])

  const onClickContextMenu = useCallback((action: string, user: IChatMember) => {
    switch (action) {
      case ACTIONS.MUTE_MEMBER:
        muteUser(user)
        break
      case ACTIONS.UNMUTE_MEMBER:
        unmuteUser(user)
        break
      case ACTIONS.REMOVE_MEMBER:
        deleteMember(user)
        break
      case ACTIONS.MAKE_ADMIN:
        makeAdmin(user)
        break
      case ACTIONS.UNMAKE_ADMIN:
        unmakeAdmin(user)
        break
      default:
        throw new Error(`'${action}' action not implemented`)
    }
  }, [muteUser, unmuteUser, deleteMember, makeAdmin, unmakeAdmin])

  const onContextMenuHide = useCallback(() => {
    setContextMenuActive(false)
  }, [])

  const {show: showContextMenu, hide: hideContextMenu, ContextMenu} = useContextMenu<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: [],
      onClick: onClickContextMenu,
    },
    onHide: onContextMenuHide,
  })

  useEffect(() => {
    if (!forceHideContextMenu) {
      return
    }
    const hide = () => {
      setTimeout(hideContextMenu, 0)
    }
    if (contextMenuActive) {
      document.addEventListener('mouseup', hide)
    }
    return () => {
      document.removeEventListener('mouseup', hide)
    }
  }, [contextMenuActive, hideContextMenu, forceHideContextMenu])

  const onMenuClick = useCallback((user: IChatMember, e: MouseEvent) => {
    e.stopPropagation()
    const position = {
      x: e.pageX,
      y: e.pageY,
    }
    const list = [...contextMenuList]
    const filter = user.muted ? ACTIONS.MUTE_MEMBER : ACTIONS.UNMUTE_MEMBER
    showContextMenu({
      position,
      componentProps: {
        list: list
          .filter(menu => menu.action !== filter)
          .filter(menu => {
            if ((menu.action === ACTIONS.MAKE_ADMIN || menu.action === ACTIONS.UNMAKE_ADMIN) && !isOwner) {
              return false
            }
            if (user.affiliation === 'admin' && menu.action === ACTIONS.MAKE_ADMIN) {
              return false
            }
            return !(user.affiliation === 'member' && menu.action === ACTIONS.UNMAKE_ADMIN)
          })
          .map(menu => ({
            ...menu,
            text: formatMessage({id: menu.text}),
          })),
        message: user,
      },
    })
    setContextMenuActive(true)
  }, [isOwner, showContextMenu, formatMessage])

  const [userMembers, setUserMembers] = useState(
    createMembers({members, chatList, blockedUsers, user})
      .sort(sortMembers),
  )

  const [visibleMembers, setVisibleMembers] = useState(userMembers
    .filter(filterMembers(filter))
    .map(user => ({
      ...user,
      isTeam: isMyTeam(chatList[user.jid]?.groups || []),
    })))

  const [membersData, setMembersData] = useState<ParticipantsData>({
    users: visibleMembers,
    isOwner,
    isAdmin,
    canManage,
    canOpenChat: isTeamChannel(chat),
    onMenuClick,
    onOpenChat
  })

  useEffect(() => {
    setIsOwner(checkOwner(user?.$jid || '', members))
    setIsAdmin(checkAdmin(user?.$jid || '', members))
  }, [user?.$jid, members])

  useEffect(() => {
    setMembers(getOrCreateArray<IGroupChatMember>(chat?.members))
  }, [chat?.members])

  useEffect(() => {
    chat?.members?.forEach(member => {
      if (user?.$jid !== member.jid && !chatList[member.jid]?.vcard) {
        getVCardWithQueue({jid: member.jid, immediately: true})
      }
    })
  }, [chat?.members, user, getVCardWithQueue, chatList])

  useEffect(() => {
    if (!user) {
      return
    }
    setUserMembers(createMembers({members, chatList, blockedUsers, user})
      .sort(sortMembers))
  }, [members, chatList, blockedUsers, user, filter])

  useEffect(() => {
    setVisibleMembers(userMembers
      .filter(filterMembers(filter))
      .map(user => ({
        ...user,
        isTeam: isMyTeam(chatList[user.jid]?.groups || []),
      })))
  }, [userMembers, filter, chatList])

  useEffect(() => {
    setMembersData({
      users: visibleMembers,
      isOwner,
      isAdmin,
      canManage,
      canOpenChat: isTeamChannel(chat),
      onMenuClick,
      onOpenChat
    })
  }, [visibleMembers, isOwner, isAdmin, onMenuClick, canManage, onOpenChat, chat])

  if (chat?.type !== 'groupchat') {
    return null
  }

  const searchBoxRef = (el: Element | null) => {
    setSearchHeight(el?.scrollHeight || 0)
  }

  return <div
    className={classNames(styles.box, className)}
    style={{height: ITEM_HEIGHT * (fixHeight ? userMembers : visibleMembers).length + searchHeight}}
  >
    <div
      ref={searchBoxRef}
      className={styles.searchBox}>
      <Search wrapperClassName={styles.search} onChange={setFilter} onFocus={onSearchFocus}/>
      {canManage && (isOwner || isAdmin) && <IconButton
        className={styles.icon}
        onClick={onAddParticipants}
      ><i className={'chat-plus'}/></IconButton>}
    </div>
    <AutoSizer>
      {({height, width}) => <VirtualList<ParticipantsData>
        data={membersData}
        render={ParticipantItem}
        createItemData={createParticipantsData}
        itemSize={() => ITEM_HEIGHT}
        height={(height || 100) - searchHeight}
        width={width || 100}
      />}
    </AutoSizer>
    <ContextMenu/>
  </div>
}

export default Participants
