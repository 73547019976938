import styles from './BaseModal.module.scss'
import React, {CSSProperties, MouseEventHandler, useMemo, useRef} from 'react';
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import {useAppSelector} from '@/hooks/appHook';
import {getScreenHeight, isMobile} from '@/store/screenSize/screenSize';
import {ID_MODAL_PORTAL} from '@/constants/general';

export interface BaseModalProps extends React.PropsWithChildren {
  hide?: () => void,
  className?: string,
  wrapperClassName?: string,
  style?: CSSProperties
  inside?: boolean
  canSelfClose?: boolean
  onClick?: MouseEventHandler
}

export const BaseModal = (
  {
    children,
    hide,
    className,
    wrapperClassName,
    inside,
    style,
    canSelfClose = true,
    onClick
  }: BaseModalProps) => {
  const screenHeight = useAppSelector(getScreenHeight)
  const isMobileView = useAppSelector(isMobile)
  const wrapper = useRef(null)
  const containerElement = useMemo(
    () => document.getElementById(ID_MODAL_PORTAL),
    [],
  );

  const handleClose: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === wrapper.current) {
      hide?.()
    }
  }

  if (!containerElement) {
    throw new Error(`Portal "${ID_MODAL_PORTAL}" not found`)
  }

  return ReactDOM.createPortal((
    <div
      ref={wrapper}
      className={classNames(
        styles.modal,
        inside && styles.inside,
        wrapperClassName
      )}
      onMouseDown={canSelfClose ? handleClose : undefined}
      onClick={e => e.stopPropagation()}
    >
      <div
        style={{
          maxHeight: screenHeight - (isMobileView ? - 0 : 32) || '100vh',
          ...style,
        }}
        className={classNames(styles.body, className)}
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  ), containerElement)
}
