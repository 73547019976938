import {v4 as uuid} from 'uuid';
import {AnyObject} from '@/index';
import {createStropheFromObject} from '@/services/utils';
import dayjs from 'dayjs';

interface IOptionsHistory {
  maxstanzas?: number,
  maxchars?: number
  since?: number
}

interface IPresenceRoomProps extends IOptionsHistory {
  from: string,
  to: string,
}

const generalPresence = (jid: string, options: AnyObject) => {
  return $pres({
    id: uuid(),
    to: jid,
    ...options,
  }).tree()
}

class Presence {
  static Messages = {
    presence({maxstanzas, maxchars, since}: IOptionsHistory = {}) {
      return createStropheFromObject('presence', {
        $id: uuid(),
        priority: 0,
        ...((maxstanzas || maxchars || since) && {
          x: {
            $xmlns: Strophe.NS.MUC,
            history: {
              ...(maxstanzas && {$maxstanzas: maxstanzas.toString()}),
              ...(maxchars && {$maxchars: maxchars.toString()}),
              ...(since && {$since: dayjs(since / 1000).toISOString()}),
            },
          },
        }),
      }).tree()
    },
    presenceRoom({from, to, maxstanzas, maxchars, since}: IPresenceRoomProps) {
      const msg = $pres({
        id: uuid(),
        to: `${to}/${from}`,
      })
       .c('x', {
          xmlns: Strophe.NS.MUC,
        })
      if (maxstanzas != null || maxchars != null || since) {
       msg.c('history', {
          ...(maxstanzas != null && {maxstanzas: maxstanzas.toString()}),
          ...(maxchars != null && {maxchars: maxchars.toString()}),
          ...(since && {since: dayjs(since / 1000).toISOString()}),
        })
      }
      return msg.tree()
    },
    subscribe(jid: string) {
      return generalPresence(jid, {
        type: 'subscribe',
      })
    },
    unsubscribe(jid: string) {
      return generalPresence(jid, {
        type: 'unsubscribe',
      })
    },
    subscribed(jid: string) {
      return generalPresence(jid, {
        type: 'subscribed',
      })
    },
    unsubscribed(jid: string) {
      return generalPresence(jid, {
        type: 'unsubscribed',
      })
    },
    available() {
      return createStropheFromObject('presence', {
        priority: {
          _: '0'
        }
      }).tree()
    },
    unavailable() {
      return createStropheFromObject('presence', {
        $type: 'unavailable',
      }).tree()
    },
    notifyVCardUpdated() {
      return createStropheFromObject('presence', {
        $xmlns: 'jabber:client',
        $id: uuid(),
        priority: {
          _: 0,
        },
        vcard_update: {
          _: 'true',
        },
        vcard: {
          $xmlns: 'urn:yc:vcard:data',
        },
      }).tree()
    },
  }
}

export default Presence
