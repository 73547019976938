import styles from './FieldArea.module.scss'
import { TextareaAutosizeProps } from '@gry-dmitrij/react-textarea-autosize'
import { forwardRef, useId } from 'react'
import classNames from 'classnames'
import Textarea from '@/components/Primitive/Textarea/Textarea'

interface FieldAreaProps extends TextareaAutosizeProps {
  className?: string
  label?: string
}

const FieldArea = forwardRef<HTMLTextAreaElement, FieldAreaProps>((
  {
    className,
    label,
    ...props
  }, ref) => {
  const id = useId()
  return <div className={classNames(styles.box, className)}>
    {label && <label
      htmlFor={id}
      className={styles.label}
    >{label}</label>}
    <Textarea
      {...props}
      id={id}
      ref={ref}
    />
  </div>
})

export default FieldArea
