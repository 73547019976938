import {useEffect, useState} from 'react';
import {BaseModalProps} from "@/components/Modal/BaseModal/BaseModal";
import BaseModalWithTitle from "@/components/Modal/BaseModalWithTitle/BaseModalWithTitle";
import {statisticsApi} from "@/api/statistics";
import styles from './Statistics.module.scss'

const Statistics = ({hide}: BaseModalProps) => {
  const [members, setMembers] = useState([])

  useEffect(() => {
    statisticsApi().finally().then((userData) => {
      setMembers(userData.data.data)
    })
  }, [])

return <BaseModalWithTitle title="Statistics" hide={hide}>
  <table className={styles.table}>
    <thead>
    <td className={styles.thead}>Month</td>
    <td className={styles.thead}>Messages sent</td>
    <td className={styles.thead}>New members</td>
    <td className={styles.thead}>Active members</td>
    </thead>
    {members.map((member) => {
    return (<tr className={styles.tr}>
      <td className={styles.td}>{member['date']}</td>
      <td className={styles.td}>{member['messageAmount']}</td>
      <td className={styles.td}>{member['newMembersAmount']}</td>
      <td className={styles.td}>{member['activeMembersAmount']}</td>
    </tr>)
    })}
  </table>
</BaseModalWithTitle>
}

export default Statistics
