import { createAsyncThunk, createSlice, Draft } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { ICallMessageType, MessageType } from '@/interfaces/YC';
import { Sticker } from '@/store/stickers/stickers';
import { v4 as uuid } from 'uuid'
import { removeFile } from '@/store/chats/chats';
import { isFileMessage } from '@/components/Chat/ChatMessage/FileMessage/FileMessage';
import { INews } from '@/store/news/news'

export type IMessageStatus = 'sent' | 'received' | 'displayed' | 'sending'

interface ITimestamp {
  timestamp: number
}

export interface IMessageDate {
  date: Date
}

export const isIMessageDate = (message: ShowedMessage | INews | IMessageDate): message is IMessageDate => {
  return 'date' in message && Object.keys(message).length === 1
}

export interface IBreakMessage extends ITimestamp {
  id: string
  type: 'break'
}

export interface IMessage extends ITimestamp {
  id: string,
  type: MessageType,
  from: string,
  to: string,
  thread?: string,
  status?: IMessageStatus
  replyMessage?: IMessage
  forwardedFrom?: string
}

export type ShowedMessage = IMessage | IBreakMessage

export interface ITextMessage extends IMessage {
  text: string,
  isTranslating: boolean,
  isTranslated: boolean,
  textTranslate: string
}

export interface IImageMessage extends IMessage {
  image: {
    url: string,
    thumbnail: string
  }
}

export interface IFileFields {
  mimetype: string,
  name: string,
  size: number,
  url: string,
  token: string
}

export interface IFileMessage extends IMessage {
  file: IFileFields,
  uploaded: number | null,
  downloaded: number | null,
}

export interface IAudioMessage extends IMessage {
  url: string
  name?: string
}

export interface IVideoMessage extends IMessage {
  url: string
  thumbnail?: string
  uploaded: number | null,
  name?: string
  height?: number
}

export interface IStickerMessage extends IMessage {
  sticker: Partial<Omit<Sticker, 'id'>> & {
    id: string
  }
}

export interface ICallMessage extends IMessage {
  call?: {
    type: ICallMessageType,
    duration: number
  }
}

export interface IMessagesItem {
  [key: string]: {
    messages: ShowedMessage[],
    isLoaded: boolean,
    isFullLoaded: boolean,
    replyMessage: IMessage | null,
  }
}

export interface IMessages {
  messages: IMessagesItem,
}

interface UpdateWithBreakProps {
  chatJid: string,
  messages: IMessage | IMessage[]
  breakId?: string
  insert?: 'after' | 'before'
}

const initialState: IMessages & {
  lastMessage: IMessage | null
  lastMessageBeforeOffline: IMessage | null
} = {
  messages: {},
  lastMessage: null,
  lastMessageBeforeOffline: null,
}

const createIfNotExist = (state: Draft<IMessages>, jid: string) => {
  if (!state.messages[jid]) {
    state.messages[jid] = {
      messages: [],
      isLoaded: false,
      isFullLoaded: false,
      replyMessage: null,
    }
  }
}

export const isBreakMessage = (message: IMessage | IBreakMessage | INews): message is IBreakMessage => {
  return 'type' in message && message.type === 'break'
}

export const isNotBreakMessage = (message: IMessage | IBreakMessage): message is IMessage => {
  return message.type !== 'break'
}

const findLastMessage = (...messages: (IMessage | null)[]) => {
  return messages.reduce((acc, item) => ((acc?.timestamp || 0) < (item?.timestamp || 0)) ? item : acc, null)
}

const getLastMessageInList = (messageList: IMessages) => {
  const arrayMessages = Object.keys(messageList.messages)
    .map(key => messageList.messages[key].messages)
    .reduce((acc, item) => {
      acc.push(...item)
      return acc
    }, [] as IMessage[])
  return findLastMessage(...arrayMessages.filter(isNotBreakMessage))
}

const messagesSort = (mes1: IBreakMessage | IMessage, mes2: IBreakMessage | IMessage) => {
  const timestamp1 = mes1.timestamp || 0
  const timestamp2 = mes2.timestamp || 0
  return timestamp1 - timestamp2
}

const messages = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    set(state, { payload }: { payload: IMessages }) {
      state.messages = payload.messages
      state.lastMessage = findLastMessage(state.lastMessage, getLastMessageInList(state))
    },
    add(state, { payload }: { payload: { jid: string, message: IMessage } }) {
      const { jid, message } = payload
      createIfNotExist(state, jid)
      state.messages[jid].messages.push(message)
      state.lastMessage = findLastMessage(state.lastMessage, message)
    },
    update(state, { payload }: { payload: { jid: string, messages: IMessage[] } }) {
      const { jid, messages } = payload
      createIfNotExist(state, jid)

      messages.forEach(message => {
        const index = state.messages[jid].messages.findIndex(item => item.id === message.id)
        if (~index) {
          state.messages[jid].messages[index] = {
            ...state.messages[jid].messages[index],
            ...message,
          }
        } else {
          state.messages[jid].messages.push(message)
        }
      })
      state.messages[jid].messages.sort(messagesSort)
      state.lastMessage = findLastMessage(state.lastMessage, ...messages)
    },
    updateWithBreak(state, { payload }: { payload: UpdateWithBreakProps }) {
      const { messages, breakId, chatJid, insert = 'after' } = payload
      createIfNotExist(state, chatJid)
      if (breakId) {
        const oldBreakIndex = state.messages[chatJid].messages.findIndex(message => message.id === breakId)
        if (~oldBreakIndex) {
          state.messages[chatJid].messages.splice(oldBreakIndex, 1)
        }
      }
      const messagesArray = Array.isArray(messages) ? messages : [messages]
      messagesArray.sort(messagesSort)
      const messagesMap = new Map(state.messages[chatJid].messages
        .map((message, index) => [message.id, { index, message }]))
      const addBreak = !messagesMap
        .has(messagesArray[insert === 'after' ? messagesArray.length - 1 : 0]?.id)
      messagesArray.forEach(msg => {
        if (messagesMap.has(msg.id)) {
          const { index } = messagesMap.get(msg.id)!
          state.messages[chatJid].messages[index] = {
            ...state.messages[chatJid].messages[index],
            ...msg,
          }
        } else {
          state.messages[chatJid].messages.push(msg)
        }
      })
      if (addBreak && messagesArray.length > 0) {
        const breakMessage: IBreakMessage = {
          id: uuid(),
          type: 'break',
          timestamp: messagesArray[insert === 'after' ? messagesArray.length - 1 : 0].timestamp,
        }
        state.messages[chatJid].messages.push(breakMessage)
        state.messages[chatJid].messages.sort(messagesSort)
        if (insert === 'after') {
          const breakIndex = state.messages[chatJid].messages.findIndex(message => message.id === breakMessage.id)
          if (~breakIndex) {
            breakMessage.timestamp = state.messages[chatJid].messages[breakIndex + 1]?.timestamp || 0
          }
        }
      } else {
        state.messages[chatJid].messages.sort(messagesSort)
      }

      const length = state.messages[chatJid].messages.length
      const lastMessage = state.messages[chatJid].messages[length - 1]
      if (lastMessage?.type === 'break') {
        state.messages[chatJid].messages.splice(length - 1, 1)
      }
      state.lastMessage = findLastMessage(state.lastMessage, ...messagesArray)
    },
    setReply(state, { payload }: { payload: { jid: string, replyMessage: IMessage | null } }) {
      const { jid, replyMessage } = payload
      createIfNotExist(state, jid)
      state.messages[jid].replyMessage = replyMessage
    },
    remove(state, { payload }: { payload: { jid: string, message: IMessage } }) {
      const { jid, message } = payload
      const index = state.messages[jid].messages.findIndex(item => item.id === message.id) || -1
      if (~index) {
        const [removedMessage] = state.messages[jid].messages.splice(index, 1)
        if (removedMessage === state.lastMessage) {
          state.lastMessage = findLastMessage(state.lastMessage, getLastMessageInList(state))
        }
      }
    },
    clear(state, { payload }: { payload: string }) {
      if (state.messages[payload]) {
        state.messages[payload].messages = []
        state.lastMessage = findLastMessage(state.lastMessage, getLastMessageInList(state))
      }
    },
    messagesWasLoaded(state, { payload }: { payload: { jid: string, value: boolean } }) {
      const { jid, value } = payload
      createIfNotExist(state, jid)
      state.messages[jid].isLoaded = value
    },
    messagesWasFullLoaded(state, { payload }: { payload: { jid: string, value: boolean } }) {
      const { jid, value } = payload
      createIfNotExist(state, jid)
      state.messages[jid].isFullLoaded = value
    },
    setLastMessageBeforeOffline(state, { payload }: { payload: IMessage | null }) {
      state.lastMessageBeforeOffline = payload
    },
  },
})

export const {
  add,
  set,
  update,
  updateWithBreak,
  setReply,
  clear,
  messagesWasLoaded,
  messagesWasFullLoaded,
  setLastMessageBeforeOffline,
} = messages.actions


export const remove = createAsyncThunk('messages/removeMessage',
  ({ jid, message }: { jid: string, message: IMessage }, { dispatch }) => {
    dispatch(messages.actions.remove({ jid, message }))
    if (isFileMessage(message)) {
      dispatch(removeFile({ chatJid: jid, url: message.file.url }))
    }
  })

export const getAllMessages = (state: RootState) => state.messages.messages
export const getMessages = (jid: string) => (state: RootState) => state.messages.messages[jid]
export const getReplyMessage = (jid: string) => (state: RootState) => state.messages.messages[jid]?.replyMessage
export const getLastMessage = (state: RootState) => state.messages.lastMessage
export const getLastMessageBeforeOffline = (state: RootState) => state.messages.lastMessageBeforeOffline
export default messages.reducer
