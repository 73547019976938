import React, {useCallback, useState} from 'react';
import BaseModalWithTitle, {BaseModalWithTitleProps} from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import {useIntl} from "react-intl";
import classNames from "classnames";
import styles from "./Logout.module.scss";
import {logout as logoutRemovePush} from "@/api/logout";

interface LogoutProps extends Omit<BaseModalWithTitleProps, 'title'>{
  title?: string
  onClickLogout?: () => void
}

const Logout = ({hide, onClose, onBack, onClickLogout, ...props}: LogoutProps) => {
  const {formatMessage} = useIntl()
  const [logoutButtonDisable, setLogoutButtonDisable] = useState(false)

  const handleClose = () => {
    onClose?.()
    hide?.()
  }

  const handleLogout = useCallback(async () => {
    setLogoutButtonDisable(true)
    await logoutRemovePush().finally(() => {
      onClose?.()
      hide?.()
      onClickLogout?.()
      setLogoutButtonDisable(false)
    })
  }, [onClose, hide, onClickLogout])

  return <BaseModalWithTitle
    title={formatMessage({id: 'button_logout'})}
    onBack={onBack}
    onClose={onClose}
    hide={handleClose}
    {...props}
  >
    <div className={styles.block}>
      <div className={styles.text}>{formatMessage({id: 'you_are_going_to_logout'})}</div>
      <button
        className={classNames('button', 'mainButton', styles.button, logoutButtonDisable ? 'disabled' : '')}
        onClick={handleLogout}
        disabled={logoutButtonDisable}
      >{formatMessage({id: 'button_logout'})}
      </button>
    </div>
  </BaseModalWithTitle>
}

export default Logout
