import styles from './NewsMessage.module.scss'
import GeneralMessage from '@/components/Chat/ChatMessage/GeneralMessage/GeneralMessage'
import { IMessageComponent } from '@/components/Chat/ChatMessage/ChatMessage'
import { isNews } from '@/store/news/news'
import { getLastTime } from '@/utils/dateutils'

const NewsMessage = ({msg, incoming}: IMessageComponent) => {
  if (!isNews(msg)) {
    return null
  }

  return <GeneralMessage msg={msg} incoming={incoming}>
    <p className={styles.text}>
      <span className={styles.time}>{getLastTime(msg.timestamp)}</span>
      <span dangerouslySetInnerHTML={{ __html: msg.description }} />
    </p>
  </GeneralMessage>
}

export default NewsMessage
