import React, {useEffect, useState} from 'react';
import Picker from '@emoji-mart/react'
import './emoji.scss';
import en from '../translations/en.json'
import cn from '../translations/cn.json'
import jp from '../translations/jp.json'
import kr from '../translations/kr.json'
import tc from '../translations/tc.json'
import {useAppSelector} from '@/hooks/appHook';
import {getLang, TypeLang} from '@/store/language/language';


const i18n: {
  [key in TypeLang]: Record<string, string | Record<string, string>>
} = {en, cn, jp, kr, tc}

interface EmojiProps {
  onSelect?: (str: string) => void
}

interface EmojiElement {
  id: string
  keywords: string
  name: string
  native: string
  shortcodes: string
  unified: string
}

const Emoji = ({onSelect}: EmojiProps) => {
  const language = useAppSelector(getLang)
  const [lang, setLang] = useState(i18n[language])

  useEffect(() => {
    setLang(i18n[language] || i18n.en)
  }, [language])

  const handleSelect = (emoji: EmojiElement) => {
    onSelect?.(emoji.native)
  }

  return (
    <Picker
      i18n={lang}
      onEmojiSelect={handleSelect}
      theme="light"
      navPosition="none"
      skin={1}
      searchPosition="none"
      previewPosition="none"
      skinTonePosition="none"
      dynamicWidth={true}
      emojiSize={24}
      emojiButtonSize={40}
    />
  )
}

export default Emoji;
