import React from 'react';
import './App.css';
import {IntlProvider} from "react-intl";
import {BrowserRouter} from "react-router-dom";
import Router from "@/Routes/Routes";
import {getLang} from "@/store/language/language";
import en from "@/translations/en.json";
import cn from "@/translations/cn.json";
import tc from "@/translations/tc.json";
import kr from "@/translations/kr.json";
import jp from "@/translations/jp.json";
import {useAppSelector} from "@/hooks/appHook";
import {TypeLang} from "@/store/language/language";

type Messages = {
    [key in TypeLang]: Record<string, string>
}

const messages: Messages = {
  en, cn, tc, kr, jp
}

const App = () => {
    const lang = useAppSelector(getLang)
    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
                <BrowserRouter>
                    <Router/>
                </BrowserRouter>
        </IntlProvider>
    );
}

export default App;
