import {useCallback, useEffect, useRef, useState} from 'react';
import connection from '@/services/Connection/Connection';
import {ConnectionEventNames} from '@/services/Connection/IConnection';
import {useAppSelector} from '@/hooks/appHook';
import {getUser} from '@/store/user/user';
import {isInternetOnline} from '@/store/internetStatus/internetStatus';

const RECONNECT_WITHOUT_PAUSE = 3
const RECONNECT_TIMEOUT = 15000

let isConnecting = false
let reconnectAmount = 0

const useJabberConnect = () => {
  const user = useAppSelector(getUser)
  const online = useAppSelector(isInternetOnline)
  const [connectionStatus, setConnectionStatus] = useState(connection.Status)
  const connectHandler = useRef<() => void>()
  const timer = useRef<NodeJS.Timeout>()

  connectHandler.current = useCallback(async () => {
    if (connection.isConnected || isConnecting || !user) {
      return
    }
    clearTimeout(timer.current)
    timer.current = undefined
    const jid = `${user.memberId}@${process.env.REACT_APP_EJ_HOST}`
    const pass = user.accessToken
    try {
      isConnecting = true
      await connection.connect(jid, pass)
      reconnectAmount = 0
      isConnecting = false
    } catch (e) {
      isConnecting = false
      reconnectAmount++
      if (reconnectAmount < RECONNECT_WITHOUT_PAUSE) {
        connectHandler.current?.()
      } else {
        timer.current = setTimeout(() => {
          connectHandler.current?.()
        }, RECONNECT_TIMEOUT)
      }
    }
  }, [user])

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
      connectHandler.current = undefined
      connection.disconnect()
    }
  }, []);

  useEffect(() => {
    const onStatusChanged = (status: Strophe.Status) => {
      setConnectionStatus(status)
    }
    connection.addEventListener(ConnectionEventNames.StatusChanged, onStatusChanged)

    return () => {
      connection.removeEventListener(ConnectionEventNames.StatusChanged, onStatusChanged)
    }
  }, []);

  useEffect(() => {
    if (connectionStatus !== Strophe.Status.DISCONNECTED || !online || timer.current || user?.sso) {
      return
    }
    connectHandler.current?.()
  }, [connectionStatus, online, user?.sso]);

  useEffect(() => {
    if (!online) {
      connection.disconnect()
    }
  }, [online]);

}

export default useJabberConnect
