import {createSelector, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@/store';

export interface BlockedUser {
  jid: string,
  name: string
  avatar: string
}

const initialState : {
  users: BlockedUser[]
} = {
  users: []
}

interface IPayloadJids {
  payload: BlockedUser | BlockedUser[]
}

const blockList = createSlice({
  name: 'blockList',
  initialState,
  reducers: {
    set(state, {payload}: IPayloadJids) {
      state.users = Array.isArray(payload) ? payload : [payload]
    },
    update(state, {payload}:IPayloadJids) {
      const users = new Map<string, BlockedUser>()
      state.users.forEach(user => {
        users.set(user.jid, user)
      });
      (Array.isArray(payload) ? payload : [payload]).forEach(user => {
        users.set(user.jid, user)
      })
      state.users = Array.from(users, ([_, user]) => user)
    },
    remove(state, {payload}: {payload: string | string[]}) {
      // const users = new Set(state.users);
      const users = new Map<string, BlockedUser>();
      state.users.forEach(user => {
        users.set(user.jid, user)
      });
      (Array.isArray(payload) ? payload : [payload]).forEach(jid => {
        users.delete(jid)
      })
      state.users = Array.from(users, ([_, user]) => user)
    }
  }
})

export const {
  update,
  set,
  remove
} = blockList.actions

export const getBlockUsers = (state: RootState) => state.blockList.users
export const getBlockList = createSelector(
  [getBlockUsers],
  (users) => Array.from(users)
)
export const checkUserBlocked = (jid: string) =>
  (state: RootState): boolean => !!state.blockList.users.find(user => user.jid ===jid)
export default blockList.reducer
