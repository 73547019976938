import React, {
  CSSProperties,
  FC, ForwardRefExoticComponent, RefAttributes,
  RefCallback,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

export enum MENU_POSITIONS {
  RIGHT = 'right',
  LEFT = 'left'
}

const defaultOptions = {
  positions: [MENU_POSITIONS.LEFT]
}

export type onItemClick = (action: string, message: any, event: React.MouseEvent<FC, MouseEvent>) => void

interface IComponent {
  style?: CSSProperties
}

interface UseContextMenuProps<T> {
  options?: {
    positions: MENU_POSITIONS[]
  },
  onClick?: onItemClick,
  onHide?: () => void
  Component: FC<RefAttributes<T> & IComponent>
    | ForwardRefExoticComponent<T & IComponent>
  componentProps: T
}

interface IPosition {
  x: number,
  y: number
}

interface showProps<T> {
  position: IPosition,
  componentProps?: T
}

interface ISize {
  height: number,
  width: number
}

const useContextMenu = <T extends IComponent,>(menuProps: UseContextMenuProps<T>) => {
  const {
    onHide,
    options = defaultOptions,
    Component,
    componentProps
  } = menuProps
  const [isShow, setIsShow] = useState(false)
  const [menuPositionHorizontal, setMenuPositionHorizontal] = useState<MENU_POSITIONS>(MENU_POSITIONS.LEFT)
  const [menuPosition, setMenuPosition] = useState({x: 0, y: 0})
  const [coords, setCoords] = useState({left: 0, top: 0})
  const [componentPropsState, setComponentPropsState] = useState(componentProps)

  useEffect(() => {
    if (!options.positions.includes(menuPositionHorizontal)) {
      const position = options.positions.includes(MENU_POSITIONS.RIGHT)
        ? MENU_POSITIONS.RIGHT
        : MENU_POSITIONS.LEFT
      setMenuPositionHorizontal(position)
    }
  }, [menuPositionHorizontal, options.positions])

  const hide = useCallback(() => {
    setIsShow(false)
    if (typeof onHide === 'function') {
      onHide()
    }
  }, [onHide])

  const show = useCallback((
    {
      position,
      componentProps

    }: showProps<T>) => {
    componentProps && setComponentPropsState(prevState => ({
      ...prevState,
      ...componentProps
    }))
    setMenuPosition(position);
    setIsShow(true)
  }, [])

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        document.addEventListener('click', hide)
      }, 0)
    }

    return () => {
      document.removeEventListener('click', hide)
    }
  }, [isShow, hide])

  const changeCoords = useCallback((size: ISize) => {
    const top = size.height + menuPosition.y > document.documentElement.clientHeight - 20
      ? menuPosition.y - size.height
      : menuPosition.y
    let left =
      size.width + menuPosition.x > document.documentElement.clientWidth - 20
      || menuPositionHorizontal === MENU_POSITIONS.RIGHT
        ? menuPosition.x - size.width
        : menuPosition.x
    if (left < 0) {
      left = document.documentElement.clientWidth / 2 - size.width / 2
    }

    if (coords.top !== top || coords.left !== left) {
      setCoords({top, left})
    }

  }, [menuPosition, menuPositionHorizontal, coords])

  const componentRef: RefCallback<T> = useCallback((element: T) => {
    if (!element || !(element instanceof Element)) {
      return
    }
    const newSize = {
      height: element.clientHeight,
      width: element.clientWidth
    }
    changeCoords(newSize)
  }, [changeCoords])

  const ContextMenuComponent = useMemo(() => () => (
    <>
      {isShow && <Component
        ref={componentRef}
        style={coords}
        {...componentPropsState}
      />}
    </>
  ), [isShow, componentPropsState, Component, componentRef, coords])

  return {
    show,
    hide,
    ContextMenu: ContextMenuComponent,
    onHide,
  }
}

export default useContextMenu;
