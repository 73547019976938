import React, {useState} from 'react'
import {BaseModalProps} from "@/components/Modal/BaseModal/BaseModal";
import BaseModalWithTitle from "@/components/Modal/BaseModalWithTitle/BaseModalWithTitle";
import {useIntl} from "react-intl";
import Input from "@/components/Primitive/Inputs/Input/Input";
import Button from "@/components/Primitive/Buttons/Button/Button";
import {authenticate, loginAsMember as login} from "@/api/auth";
import {setToken, setUser} from "@/store/user/user";
import {useAppDispatch} from "@/hooks/appHook";
import {logout} from "@/api/logout";

const LoginAsMember = ({hide}: BaseModalProps) => {
  const dispatch = useAppDispatch()
  const {formatMessage} = useIntl()
  const [memberId, setMemberId] = useState('')

  const handleLoginAsMember = () => {
    logout().finally(() => {
      login({memberId}).then((userData) => {
        dispatch(setUser(userData))
        authenticate({
          memberId: userData.memberId,
          accessToken: userData.accessToken
        }).then((userToken) => {
          dispatch(setToken(userToken.data.token))
          window.location.reload()
        })
      })
    })
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleLoginAsMember()
    }
  }

  return <BaseModalWithTitle
    title="Login as member"
    hide={hide}
  >
    <div style={{display:'flex',justifyContent:'center'}}>
    <Input autoFocus={true} type="text" value={memberId} onChange={(e) => setMemberId(e.target.value)}
           maxLength={7} placeholder={formatMessage({id: 'PROFILE.MEMBERID'})} onKeyPress={handleKeyPress} />
      <Button style={{marginLeft:'12px'}} onClick={handleLoginAsMember}>Login</Button>
    </div>
  </BaseModalWithTitle>
}

export default LoginAsMember
