import styles from './CropImage.module.scss'
import {BaseModal, BaseModalProps} from '@/components/Modal/BaseModal/BaseModal';
import CropImageComponent from '@/components/CropImage/CropImage';
import {Area} from 'react-easy-crop'
import {useState} from 'react';
import Button from '@/components/Primitive/Buttons/Button/Button';
import {FormattedMessage} from 'react-intl';
import Preloader from '@/components/Preloader/Preloader';
import {getCroppedImg} from '@/components/Modal/CropImage/utils/utils';

interface CropImageProps extends BaseModalProps {
  image: File | undefined,
  onSaveArea?: (file: Blob) => void,
  actionBeforeClose?: (file: Blob) => (void | Promise<void>)
}

const CropImage = ({hide, image, onSaveArea, actionBeforeClose, ...props}: CropImageProps) => {
  const [preloader, setPreloader] = useState(false)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()

  const handleOnChange = (croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const handleCropImage = async () => {
    if (!image || !croppedAreaPixels) {
      throw new Error('Data is not complete')
    }
    setPreloader(true)
    try {
      const url = URL.createObjectURL(image)
      const blob = await getCroppedImg(url, croppedAreaPixels)
      if (!blob) {
        throw new Error('Failed to create file')
      }
      onSaveArea?.(blob)
      if (actionBeforeClose) {
        await actionBeforeClose(blob)
      }
    } finally {
      setPreloader(false)
      hide?.()
    }
  }

  if (!image) {
    return <div>file not found</div>
  }

  return <BaseModal
    hide={hide}
    {...props}
  >
    <div className={styles.cropperBox}>
      {!preloader && <>
        <CropImageComponent image={image} onChange={handleOnChange}/>
        <div className={styles.buttons}>
          <FormattedMessage id={'cropImage'}>
            {txt => <Button onClick={handleCropImage}>{txt}</Button>}
          </FormattedMessage>
          <FormattedMessage id={'ACTION.CANCEL'}>
            {txt => <Button view={'link'} onClick={hide}>{txt}</Button>}
          </FormattedMessage>
        </div>
      </>}
      {preloader && <Preloader />}
    </div>

  </BaseModal>
}

export default CropImage
