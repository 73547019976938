import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@/store';

const BREAKPOINT_MOBILE = 820

const initialState = {
  height: 0,
  width: 0
}

const screenSize = createSlice({
  name: 'screenSize',
  initialState,
  reducers: {
    setHeight(state, {payload}: { payload: number }) {
      state.height = payload
    },
    setWidth(state, {payload}: {payload: number}) {
      state.width = payload
    },
    setSize(state, {payload}: {payload: {height: number, width: number}}) {
      state.height = payload.height
      state.width = payload.width
    }
  }
})


export const getScreenHeight = (state: RootState): number => state.screenSize.height
export const getScreenWidth = (state: RootState): number => state.screenSize.width
export const getScreenSize = (state: RootState): {width: number, height: number} => state.screenSize
export const isMobile = (state: RootState): boolean => state.screenSize.width <= BREAKPOINT_MOBILE
export const {setHeight, setWidth, setSize} = screenSize.actions
export default screenSize.reducer
