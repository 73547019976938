import {CbList} from './IEventListener';

class EventListener<T extends Record<string, (...event: any[]) => any>> {
  protected _cbList: CbList<T> = {}

  addEventListener<K extends keyof T>(
    type: K,
    listener: T[K]
  ) {
    if (!this._cbList[type]) {
      this._cbList[type] = []
    }
    this._cbList[type]!.push(listener)
  }

  removeEventListener<K extends keyof T>(
    type: K,
    listener?: T[K]
  ) {
    if (!listener) {
      this._cbList[type] = []
      return
    }
    const listeners = this._cbList[type]
    if (!listeners) {
      return;
    }
    const index = listeners.findIndex(l => l === listener)
    if (~index) {
      listeners.splice(index, 1)
    }
  }
  emit<K extends keyof T>(type: K, ...opts: Parameters<T[K]>) {
    const events = [...this._cbList[type] || []]
    events?.forEach(l => {
      l(...opts)
    })
  }
}

export default EventListener
