import styles from './Header.module.scss'
import classNames from 'classnames';
import Avatar from '@/components/Avatar/Avatar';
import { useAppSelector } from '@/hooks/appHook';
import { Chat, getActiveChatId, getChat } from '@/store/chats/chats';
import { isMobile } from '@/store/screenSize/screenSize';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import { ComponentProps, MouseEventHandler, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useChatMembers from '@/hooks/useChatMembers';
import useModalSimple from '@/hooks/useModalSimple';
import { ReactComponent as ShareImage } from '@/images/icons/share.svg';
import GroupChatType from '@/components/Modal/GroupChatType/GroupChatType';
import { getChatsCache } from '@/store/cache/chats';
import { IClass } from '@/interfaces/general';
import AutoDeleteMessages from '@/components/Modal/AutoDeleteMessages/AutoDeleteMessages';
import { getNameOrNick } from '@/utils/chats';
import callSocket from '@/services/CallSocket/CallSocket';
import { SocketEvents } from '@/services/Socket/ISocket';
import { getActiveNewsChat } from '@/store/news/news'
import useActiveService from '@/hooks/useActiveService'

interface HeaderProps extends IClass {
  canCall?: boolean
  onShowChatInfo?: () => void
  onCallDialogShow?: () => void
}

const lastActive = (date: boolean | Date | undefined) => {
  if (!date) {
    return { code: 'CHAT.LAST_ACTIVITY.OFFLINE', val: '0' }
  }
  if (date === true) {
    return { code: 'CHAT.LAST_ACTIVITY.ONLINE', val: '0' }
  }
  let diffDate = Math.floor(((new Date()).getTime() - date.getTime()) / 1000)

  const hour = 60 * 60;
  if (diffDate < hour) {
    return { code: 'CHAT.LAST_ACTIVITY.MINUTES', val: Math.floor(diffDate / 60) || 1 }
  } else if (diffDate < 24 * hour) {
    return { code: 'CHAT.LAST_ACTIVITY.HOURS', val: Math.floor(diffDate / hour) || 1 }
  } else if (diffDate < 30 * 24 * hour) {
    return { code: 'CHAT.LAST_ACTIVITY.DAYS', val: Math.floor(diffDate / (24 * hour)) || 1 }
  }
  return { code: 'CHAT.LAST_ACTIVITY.LONG', val: '0' }
}

const Header = ({ className, canCall, onShowChatInfo, onCallDialogShow }: HeaderProps) => {
  const activeChatJid = useAppSelector(getActiveChatId)
  const chat = useAppSelector(getChat(activeChatJid || ''))
  const chatCache = useAppSelector(getChatsCache)
  const newsChat = useAppSelector(getActiveNewsChat)
  const mobileView = useAppSelector(isMobile)
  const [cachedChat, setCachedChat] = useState<Chat | null>(chat ?
    {
      ...chatCache[chat.$jid],
      ...chat,
    } : null)
  const [lastActivityText, setLastActivityText] = useState(lastActive(chat?.lastActive))
  const [callSocketOpened, setCallSocketOpened] = useState(callSocket.isOpened)
  const { updateMembers, loadMembers } = useChatMembers()
  const { formatMessage } = useIntl()
  const [autoDeleteMessagesProps, setAutoDeleteMessagesProps] = useState<ComponentProps<typeof AutoDeleteMessages>>({})
  const { setActiveChatId } = useActiveService()

  useEffect(() => {
    const onStateChanged = (state: boolean) => {
      setCallSocketOpened(state)
    }
    callSocket.addEventListener(SocketEvents.onStateChanged, onStateChanged)
    return () => {
      callSocket.removeEventListener(SocketEvents.onStateChanged, onStateChanged)
    }
  }, []);

  useEffect(() => {
    if (chat) {
      setCachedChat(prevValue => prevValue?.$jid === chat.$jid ? {
        ...prevValue,
        ...chat,
      } : {
        ...chatCache[chat.$jid],
        ...chat,
      })
    }
  }, [chat, chatCache])

  useEffect(() => {
    if (chat) {
      loadMembers(chat)
    }
  }, [chat, updateMembers, loadMembers])

  useEffect(() => {
    setLastActivityText(lastActive(chat?.lastActive))

    if (!chat?.lastActive) {
      return
    }

    const interval = setInterval(() => {
      setLastActivityText(lastActive(chat?.lastActive))
    }, 30000)

    return () => {
      clearInterval(interval)
    }
  }, [chat])

  const handleBack: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setActiveChatId(null)
  }

  const { show: showShareGroup, hide: hideShareGroup, visible: visibleShareGroup } = useModalSimple()
  const {
    show: showAutoDeleteMessages,
    hide: hideAutoDeleteMessagesModal,
    visible: visibleAutoDeleteMessagesModal,
  } = useModalSimple()

  const handleShareGroup: MouseEventHandler = (event) => {
    event.stopPropagation()
    showShareGroup()
  }

  const handleAutoDelete: MouseEventHandler = (event) => {
    if (chat) {
      setAutoDeleteMessagesProps({
        chat: chat
      })
      event.stopPropagation()
      showAutoDeleteMessages()
    }
  }

  const onCallDialogClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    onCallDialogShow?.()
  }

  const userCanCall = cachedChat?.type === 'chat' && canCall && callSocketOpened

  if (newsChat) {
    return <div
      className={classNames(
        styles.header,
        styles.notActive,
        className
      )}
    >
      {mobileView && <IconButton
        className={styles.backButton}
        onClick={handleBack}
      >
        <i className={'chat-arrow-l'} />
      </IconButton>}
      <Avatar
        className={styles.avatar}
        src={newsChat.avatar}
        name={formatMessage({ id: 'announcements' })} />
      <div className={styles.content}>
        <div className={styles.chatNameBox}>
          <FormattedMessage id='announcements'>
            {txt => <p className={styles.userName}>{txt}</p>}
          </FormattedMessage>
        </div>
      </div>
    </div>
  }

  return <div
    className={classNames(
      styles.header,
      cachedChat?.$subscription === 'none' && styles.notActive,
      className)}
    onClick={cachedChat?.$subscription !== 'none' ? onShowChatInfo : undefined}
  >
    {mobileView && <IconButton
      className={styles.backButton}
      onClick={handleBack}
    >
      <i className={'chat-arrow-l'} />
    </IconButton>}
    <Avatar className={styles.avatar} src={cachedChat?.vcard?.thumbnail}
            name={chat ? getNameOrNick(chat) : undefined} />
    <div className={styles.content}>
      <div className={styles.chatNameBox}>
        <p className={styles.userName}>{cachedChat?.name ? getNameOrNick(cachedChat) : ''}</p>
        {cachedChat?.notifyMuted && <i className={'chat-mute'} />}
      </div>
      {cachedChat?.type === 'groupchat' &&
        <FormattedMessage
          id={'GROUP_CHAT.MEMBERS'}
          values={{ members: cachedChat.members?.length || 0 }}
        >
          {txt => <p className={styles.members}>
            {cachedChat?.members && cachedChat.$subscription !== 'none' ? txt : <>&nbsp;</>}
          </p>}
        </FormattedMessage>}
      {cachedChat?.type === 'chat' &&
        <p className={styles.members}>
          {cachedChat.lastActive ?
            formatMessage({ id: lastActivityText.code }, { val: lastActivityText.val }) : <>&nbsp;</>}
        </p>}
    </div>
    {userCanCall && <IconButton
      onClick={onCallDialogClick}
    >
      <i className={classNames('chat-call-answer', styles.infoIcon)} />
    </IconButton>}
    {cachedChat?.groupType === 'public' && chat?.$subscription !== 'none' && <IconButton onClick={handleShareGroup}>
      <ShareImage />
    </IconButton>}
    {
      chat?.autoDeleteMessages && chat?.autoDeleteMessages.typePeriod !== 'disable' &&
      <i className={classNames('chat-autodelete', styles.infoIcon)} onClick={handleAutoDelete} />
    }
    {cachedChat?.$subscription !== 'none' && <i className={classNames('chat-info', styles.infoIcon)} />}
    {visibleShareGroup && <GroupChatType hide={hideShareGroup} />}
    {visibleAutoDeleteMessagesModal &&
      <AutoDeleteMessages hide={hideAutoDeleteMessagesModal} {...autoDeleteMessagesProps} />}
  </div>

}

export default Header
