import styles from './ReplyMessage.module.scss'
import {IMessage} from '@/store/messages/messages';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import {ReactComponent as ArrowImage} from '@/images/icons/replyArrow.svg';
import {getChatId} from '@/utils/chats';
import {isTextMessage} from '@/components/Chat/ChatMessage/TextMessage/TextMessage';
import {isImageMessage} from '@/components/Chat/ChatMessage/ImageMessage/ImageMessage';
import {ReactComponent as FileImage} from '@/images/icons/file-download.svg';
import {isFileMessage} from '@/components/Chat/ChatMessage/FileMessage/FileMessage';

interface ReplyMessageProps {
  msg: IMessage
  fromName?: string
  onClose?: () => void
}

const ReplyMessage = ({msg, fromName, onClose}: ReplyMessageProps) => {
  return <div className={styles.replyMessage}>
    <IconButton
      className={styles.closeBtn}
      onClick={onClose}
    />
    <ArrowImage className={styles.replyArrow} />
    {isImageMessage(msg) && <img
      className={styles.image}
      src={msg.image.thumbnail}
      alt=""
    />}
    {isFileMessage(msg) && <div className={styles.fileImage}><FileImage/></div>}
    <div className={styles.content}>
      <p className={styles.userName}>{fromName || getChatId(msg.from)}</p>
      {
        isTextMessage(msg) ? <p
          className={styles.textMessage}
        >{msg.text}</p> :
        <p className={styles.typeMessage}>{msg.type}</p>
      }
    </div>
  </div>
}

export default ReplyMessage
