import React, {forwardRef} from 'react';
import styles from './Input.module.scss'
import classNames from 'classnames';

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(({className,...props}: InputProps, ref) => {
  return <input ref={ref} className={classNames(styles.input, className)} {...props}/>
})

export default Input
