import styles from './BreakMessage.module.scss'
import {IBreakMessage, updateWithBreak} from '@/store/messages/messages';
import CirclePreloader from '@/components/CirclePreloader/CirclePreloader';
import {ElementType, useContext, useEffect, useRef} from 'react';
import messagesStyles from '@/components/Chat/ChatMessages/ChatMessages.module.scss';
import {getMessages} from '@/api/chats';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {getActiveChatId, getChat} from '@/store/chats/chats';
import {fromHistoryToMessage} from '@/utils/messages';
import {getChatId} from '@/utils/chats';
import {ChatMessagesContext} from '@/components/Chat/ChatMessages/ChatMessages';

interface BreakMessageProps {
  message: IBreakMessage
  TagName?: ElementType
}

const MESSAGE_LOADING_LIMIT = 10

const BreakMessage = ({message, TagName = 'div'}: BreakMessageProps) => {
  const {disableLoading} = useContext(ChatMessagesContext)
  const dispatch = useAppDispatch()
  const currentChatJid = useAppSelector(getActiveChatId)
  const currentChat = useAppSelector(getChat(currentChatJid + ''))
  const containerRef = useRef<HTMLDivElement>(null)
  const visibleObserver = useRef<IntersectionObserver>()
  const messageLoading = useRef(false)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }
    if (!visibleObserver.current) {
      const options: IntersectionObserverInit = {
        root: document.querySelector(messagesStyles.box),
      }
      const cb: IntersectionObserverCallback = async (entries) => {
        if (entries[0].isIntersecting && !messageLoading.current && !disableLoading) {
          messageLoading.current = true
          try {
            const messages = await getMessages({
              id: getChatId(currentChat?.$jid || ''),
              isRoom: currentChat?.type === 'groupchat',
              limit: MESSAGE_LOADING_LIMIT,
              end: message.timestamp
            })
            dispatch(updateWithBreak({
              messages: messages.map(message => fromHistoryToMessage(message)),
              breakId: message.id,
              chatJid: currentChat?.$jid || '',
              insert: 'before'
            }))
          } finally {
            messageLoading.current = false
          }
        }
      }
      visibleObserver.current = new IntersectionObserver(cb, options)
    }
    visibleObserver.current.observe(containerRef.current)
    return () => {
      visibleObserver.current?.disconnect()
      visibleObserver.current = undefined
    }
  }, [message, currentChat, dispatch, disableLoading])

  return <TagName
    className={styles.box}
    ref={containerRef}
  ><CirclePreloader/></TagName>
}

export default BreakMessage
