import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@/store';

export enum Tabs {
  Chats = "TABS.CHATS",
  Contacts = "TABS.CONTACTS",
  Profile = "TABS.PROFILE",
  Service = "TABS.SERVICES",
  Team = "TABS.MY_TEAM"
}

const initialState = {
  tab: Tabs.Chats
}

const  navTab = createSlice({
  name: 'navTabs',
  initialState,
  reducers: {
    setTab(state, {payload}: {payload: Tabs}) {
      state.tab = payload
    }
  }
})

export const {setTab} = navTab.actions
export const getCurrentTab = (state: RootState) => state.navTabs.tab
export default navTab.reducer
