import styles from './AudioMessage.module.scss'
import generalStyle from '../ChatMessage.module.scss'
import GeneralMessage from '@/components/Chat/ChatMessage/GeneralMessage/GeneralMessage';
import {IMessageComponent} from '@/components/Chat/ChatMessage/ChatMessage';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import {ReactComponent as StartImage} from '@/images/icons/triangle-right-offset.svg';
import {ReactComponent as StopImage} from '@/images/icons/pause.svg';
import VoiceBar from '@/components/VoiceBar/VoiceBar';
import React, {ChangeEventHandler, useCallback, useEffect, useRef, useState} from 'react';
import {IAudioMessage, IMessage} from '@/store/messages/messages';
import {Howl, Howler} from 'howler'
import classNames from 'classnames';
import {formatMinuteTime, getLastTime} from '@/utils/dateutils';
import CirclePreloader from '@/components/Primitive/CirclePreloader/CirclePreloader';
import {useAppSelector} from '@/hooks/appHook';
import {getActiveChatId, getChat} from '@/store/chats/chats';
import {createPubSub} from '@inficen/react-events';
import {downloadFileWithProgress} from '@/api/upload';
import {getFileExtension, getMimeType} from '@/utils/file';
import {saveAs} from 'file-saver';
import {AxiosProgressEvent} from 'axios/index';
import { INews } from '@/store/news/news'

type Event = {
  name: string,
  payload: {
    messageId: string,
  }
}

const {publish, useSubscribe} = createPubSub<Event>()

interface ILoadAudioNotify {
  messageId: string,
}

const DEFAULT_NAME = 'AudioMessageNotifier'
const LOAD_AUDIO_EVENT = 'loadAudioEvent'
export const loadAudioNotify = ({messageId}: ILoadAudioNotify) => {
  const nameEvent = `${DEFAULT_NAME}/${LOAD_AUDIO_EVENT}`
  publish(nameEvent, {messageId})
}

export const isAudioMessage = (msg: IMessage | INews): msg is IAudioMessage => {
  return msg.type === 'audio' &&
    'url' in msg
}

let playingSound: Howl
Howler.usingWebAudio = false
const AudioMessage = ({msg, incoming}: IMessageComponent) => {
  const audioMessage = isAudioMessage(msg) ? msg : null
  const activeChatId = useAppSelector(getActiveChatId)
  const activeChat = useAppSelector(getChat(activeChatId + ''))
  const [audioProgressValue, setAudioProgressValue] = useState(0);
  const [loaded, setLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setIsDuration] = useState(0)
  const [status, setStatus] = useState(audioMessage?.status)
  const [progress, setProgress] = useState(0)
  const player = useRef<Howl>()
  const downloadAudio = useRef<Function>()
  const msgUrl = isAudioMessage(msg) ? msg.url : null

  const progressFile = useCallback((progressEvent: AxiosProgressEvent) => {
    setProgress(Math.round((progressEvent.progress || 0) * 100))
  }, [])

  downloadAudio.current = useCallback(async () => {
    if (!isAudioMessage(msg)) {
      return
    }
    const {data} = await downloadFileWithProgress({
      url: msg.url,
      onDownloadProgress: progressFile,
    })
    setTimeout(() => {
      setProgress(0)
    }, 500)
    const fileExt = getFileExtension(msg.url)

    const mimeType = getMimeType(fileExt)
    const blob = new Blob([data], mimeType ? {type: mimeType} : undefined)
    saveAs(blob, msg.name || 'audio.'+fileExt)
  }, [msg, progressFile])

  useSubscribe(`${DEFAULT_NAME}/${LOAD_AUDIO_EVENT}`, ({messageId}) => {
    if (messageId !== msg.id) {
      return
    }
    downloadAudio.current?.()
  })

  useEffect(() => {
    setStatus(audioMessage?.status)
  }, [audioMessage?.status])

  useEffect(() => {
    if (!msgUrl) {
      return
    }
    const sound = new Howl({
      src: [msgUrl],
    })
    let timer: NodeJS.Timer
    const onLoad = () => {
      setLoaded(true)
      setIsDuration(Math.round(sound.duration()))
    }
    if (sound.state() === 'loaded') {
      onLoad()
    }
    sound.once('load', () => {
      onLoad()
    })
    sound.once('loaderror', (...props) => {
      console.log('audio load error: ', msgUrl, ' - ', ...props) // eslint-disable-line no-console
    })
    sound.on('play', () => {
      if (!!playingSound && playingSound !== sound) {
        playingSound.pause()
      }
      playingSound = sound
      setIsPlaying(true)
      timer = setInterval(() => {
        const seek = sound.seek() || 0;
        const percentage = Math.round(((seek / sound.duration()) * 100 || 0) * 100) / 100;
        setAudioProgressValue(percentage)
      }, 100)
    })
    sound.on('pause', () => {
      setIsPlaying(false)
      clearInterval(timer)
    })
    sound.on('end', () => {
      setIsPlaying(false)
      setAudioProgressValue(0)
      clearInterval(timer)
    })
    player.current = sound
    setAudioProgressValue(0)
    setIsPlaying(false)
    return () => {
      sound.stop()
      sound.off()
    }

  }, [msgUrl])

  const handleAudioSeek: ChangeEventHandler<HTMLInputElement> = (e) => {
    const sound = player.current
    if (!sound) {
      return
    }
    const percentage = parseFloat(e.target.value)
    sound.seek(sound.duration() * percentage / 100)
    setAudioProgressValue(percentage)
  }

  const handlePlay = async () => {
    const sound = player.current
    if (!sound) {
      return
    }
    if (sound.playing()) {
      sound.pause()
    } else {
      sound.play()
    }
  }

  if (!audioMessage) {
    return null
  }

  return <GeneralMessage msg={msg} incoming={incoming}>
    <div className={styles.box}>
      <IconButton
        className={classNames(styles.btn, !loaded && styles.disabled)}
        disabled={!loaded}
        {...(('ontouchstart' in document.documentElement) ? {
          onTouchStart: handlePlay,
        } : {
          onClick: handlePlay,
        })}
      >
        {isPlaying ? <StopImage/> : <StartImage/>}
        {status === 'sending' && <CirclePreloader className={styles.preloader} withoutProgress={true}/>}
        <CirclePreloader className={styles.preloader} progress={progress} />
      </IconButton>
      <div className={styles.barBox}>
        <VoiceBar value={audioProgressValue}/>
        <input
          className={styles.seekInput}
          value={audioProgressValue}
          onChange={handleAudioSeek}
          type="range"
          step="0.01"
          min="0"
          max="100"
        />
      </div>
      <span className={styles.duration}>{audioProgressValue > 0 ?
        formatMinuteTime(audioProgressValue * duration / 100)
        : formatMinuteTime(duration)}</span>
    </div>
    <div className={styles.bottom}>
      <span className={styles.time}>
        {activeChat?.pin?.uid === msg.id && activeChat.pin.visible
          && <i className={classNames('chat-pin', styles.chatPin)}/>}
        {getLastTime(msg.timestamp)}
      </span>
      {!incoming && (audioMessage.status === 'sent' || audioMessage.status === 'received')
        ? <i className={classNames(
          'chat-check',
          generalStyle.tick,
          audioMessage.status === 'sent' ? styles.sent : null)}/> : null}
      {!incoming && audioMessage.status === 'displayed' ? <i className={classNames(
        'chat-double-check seen',
        generalStyle.tick,
      )}/> : null}
      {!incoming && audioMessage.status === 'sending' ? <i className={classNames(
        'chat-clock',
        generalStyle.tick,
        styles.sent)}/> : null}
    </div>
  </GeneralMessage>
}

export default AudioMessage
