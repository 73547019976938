import styles from './SupportTicketMessageSender.module.scss'
import Textarea from '@/components/Primitive/Textarea/Textarea'
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { ChangeEventHandler, useRef, useState } from 'react'
import { acceptedImagesStr, MAX_FILES_LENGTH, MAX_FILES_SIZE } from '@/components/SupportTicket/constants/files'
import SupportTicketFilesList from '@/components/SupportTicket/SupportTicketFilesList/SupportTicketFilesList'
import { show as showNotify } from '@/components/Modal/UserNotify/UserNotify'
import { formatFileSize } from '@/utils/file'
import { IClass } from '@/interfaces/general'

export interface ISentSupportMessage {
  message: string
  files?: File[]
}

interface SupportTicketMessageSenderProps extends IClass{
  onSend?: (message: ISentSupportMessage) => Promise<void>
}

const SupportTicketMessageSender = (
  {
    className,
    onSend,
  }: SupportTicketMessageSenderProps) => {
  const [message, setMessage] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const [uploading, setUploading] = useState(false)
  const fileInput = useRef<HTMLInputElement>(null)
  const { formatMessage } = useIntl()

  const onChangeMessage: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setMessage(e.target.value)
  }

  const removeFileHandler = (file: File) => {
    setFiles(prev => prev.filter(f => f !== file))
  }

  const handleSelectFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }
    if (files.length >= MAX_FILES_LENGTH) {
      return
    }
    if (file.size <= MAX_FILES_SIZE) {
      setFiles(prev => ([
        ...prev,
        file
      ]))
    } else {
      showNotify({
        message: formatMessage({ id: 'file_attachment_too_large' }, {
          size: formatFileSize(file.size) as string,
          limit: formatFileSize(MAX_FILES_SIZE) as string,
        }),
      })
    }
    if (fileInput.current) {
      fileInput.current.value = ''
    }
  }

  const clearForm = () => {
    setMessage('')
    setFiles([])
  }

  const sendingHandler = () => {
    if (!onSend) {
      clearForm()
      return
    }
    setUploading(true)
    onSend({
      message,
      ...(!!files.length && { files })
    }).then(() => {
      clearForm()
    }).finally(() => {
      setUploading(false)
    })
  }

  const openFileDialog = () => {
    fileInput.current?.click()
  }

  const disabledSendButton = !message.length || uploading
  const disabledUploadButton = files.length >= MAX_FILES_LENGTH || uploading

  return <div className={classNames(styles.box, className)}>
    <Textarea
      className={styles.textarea}
      value={message}
      minRows={4}
      maxRows={4}
      disabled={uploading}
      placeholder={formatMessage({ id: 'CHAT.TYPE_MESSAGE' })}
      onChange={onChangeMessage}
    />
    <div className={styles.btns}>
      <IconButton
        className={styles.icon}
        disabled={disabledUploadButton}
        onClick={openFileDialog}
      >
        <i className={classNames('chat-clip')} />
      </IconButton>
      <IconButton
        className={classNames(
          styles.icon,
          !disabledSendButton && styles.active
        )}
        disabled={disabledSendButton}
        onClick={sendingHandler}
      >
        <i className={classNames('chat-send')} />
      </IconButton>
    </div>
    <SupportTicketFilesList
      files={files}
      disabled={uploading}
      onRemove={removeFileHandler}
    />
    <input
      ref={fileInput}
      className={'d-none'}
      type="file"
      accept={acceptedImagesStr}
      onChange={handleSelectFile}
    />
  </div>
}

export default SupportTicketMessageSender
