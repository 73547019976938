import React from 'react';
import styles from './Confirm.module.scss'
import BaseModalWithTitle from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import {BaseModalProps} from '@/components/Modal/BaseModal/BaseModal';
import Button from '@/components/Primitive/Buttons/Button/Button';
import {FormattedMessage, useIntl} from 'react-intl';

interface ConfirmProps extends BaseModalProps {
  title?: string,
  description?: string,
  onConfirm?: () => void,
  onCancel?: () => void,
  confirmTitle?: string,
  cancelTitle?: string,
  onlyOneButton?: boolean
}

const Confirm = (
  {
    hide,
    title,
    description,
    onConfirm,
    onCancel,
    confirmTitle,
    cancelTitle,
    onlyOneButton,
  }: ConfirmProps) => {
  const {formatMessage} = useIntl()

  const handleOnConfirm = () => {
    onConfirm?.()
    hide?.()
  }

  const handleOnCancel = () => {
    onCancel?.()
    hide?.()
  }

  return <BaseModalWithTitle
    title={title ? title : formatMessage({id: 'CONFIRM.TITLE'})}
    hide={hide}
  >
    {!!description && <p>{description}</p>}
    <FormattedMessage id={'CONFIRM.YES'}>
      {txt => <Button onClick={handleOnConfirm}>{confirmTitle || txt}</Button>}
    </FormattedMessage>
    {!onlyOneButton && <FormattedMessage id={'CONFIRM.NO'}>
      {txt => <Button
        onClick={handleOnCancel}
        view={'link'}
        className={styles.closeIco}
      >{cancelTitle || txt}</Button>}
    </FormattedMessage>}

  </BaseModalWithTitle>
}

export default Confirm
