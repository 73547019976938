import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import { TypeLang } from '@/store/language/language'

export enum SupportTicketStatus {
  opened = 'opened',
  closed = 'closed'
}

export interface ISupportTicketMessage {
  id: number
  files?: string[] | null
  date: string
  message: string
  postRate: number
  replierName: string
}

export interface ISupportTicket {
  id: number
  title: string
  status: SupportTicketStatus
  language: TypeLang
  created: string
  isRead: boolean
  lastReplier?: {
    id: number | null
    name: string
    date: string
  }
  messages?: ISupportTicketMessage[] | null
}

export interface ISupportTickets {
  tickets: ISupportTicket[]
  ticketsMap: Map<number, ISupportTicket>
  unreadMessageAmount: number
  fullLoaded: boolean
}

const initialState: ISupportTickets = {
  tickets: [],
  ticketsMap: new Map(),
  unreadMessageAmount: 0,
  fullLoaded: false
}

interface UnshiftMessageProps {
  ticketId: number
  message: ISupportTicketMessage
}

const updateMap = (state: Draft<ISupportTickets>, tickets: ISupportTicket[]) => {
  state.ticketsMap = new Map(tickets.map(ticket => [ticket.id, ticket]))
}

const supportTickets = createSlice({
  name: 'supportTickets',
  initialState,
  reducers: {
    setTickets(state, { payload }: PayloadAction<ISupportTicket[]>) {
      state.tickets = payload
      updateMap(state, state.tickets)
    },
    addTickets(state, { payload }: PayloadAction<ISupportTicket[]>) {
      state.tickets.push(...payload)
      updateMap(state, state.tickets)
    },
    unshiftTicket(state, { payload }: PayloadAction<ISupportTicket>) {
      state.tickets.unshift(payload)
      updateMap(state, state.tickets)
    },
    updateTicket(state, { payload: ticket }: PayloadAction<ISupportTicket>) {
      const ticketIndex = state.tickets.findIndex(t => t.id === ticket.id)
      if (~ticketIndex) {
        state.tickets.splice(ticketIndex, 1, ticket)
      }
      updateMap(state, state.tickets)
    },
    unshiftTicketMessage(state, { payload }: PayloadAction<UnshiftMessageProps>) {
      const {ticketId, message} = payload
      const ticket = state.tickets.find(ticket => ticket.id === ticketId)
      if (ticket) {
        if (!ticket.messages) {
          ticket.messages = []
        }
        ticket.messages.unshift(message)
        updateMap(state, state.tickets)
      }
    },
    setReadTicket(state, { payload }: PayloadAction<number>) {
      const index = state.tickets.findIndex(ticket => ticket.id === payload)
      if (~index) {
        state.tickets[index].isRead = true
      }
      updateMap(state, state.tickets)
    },
    setTicketsFullLoaded(state, { payload }: PayloadAction<boolean>) {
      state.fullLoaded = payload
    },
    setUnreadMessageAmount(state, { payload }: PayloadAction<number>) {
      state.unreadMessageAmount = payload
    },
    decUnreadMessage(state) {
      if (state.unreadMessageAmount > 0) {
        state.unreadMessageAmount--
      }
    }
  }
})


export const {
  setTickets,
  addTickets,
  unshiftTicket,
  updateTicket,
  unshiftTicketMessage,
  setTicketsFullLoaded,
  setReadTicket,
  setUnreadMessageAmount,
  decUnreadMessage
} = supportTickets.actions
export default supportTickets.reducer
export const getSupportTickets = (state: RootState) => state.supportTickets.tickets
export const getTicketsFullLoaded = (state: RootState) => state.supportTickets.fullLoaded
export const getTicketById = (id: number | null) =>
  (state: RootState): ISupportTicket | null => id == null ? null : state.supportTickets.ticketsMap.get(id) || null
export const getUnreadMessagesAmount = (state: RootState) => state.supportTickets.unreadMessageAmount
