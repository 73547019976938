import {BaseModal, BaseModalProps} from '@/components/Modal/BaseModal/BaseModal';
import ModalTitle, {ModalTitleProps} from '@/components/Modal/ModalTitle/ModalTitle';
import {PropsWithChildren} from 'react';
import styles from './BaseModalWithTitle.module.scss'

export interface BaseModalWithTitleProps extends BaseModalProps, ModalTitleProps, PropsWithChildren {

}

const BaseModalWithTitle = (
  {
    hide,
    title,
    canClose,
    canBack,
    onBack,
    onClose,
    children,
    ...props
  }:BaseModalWithTitleProps) => {

  const onCloseHandler = () => {
    hide?.()
    onClose?.()
  }

  return <BaseModal
    hide={hide}
    {...props}
  >
    <ModalTitle
      title={title}
      canClose={canClose}
      canBack={canBack}
      onBack={onBack}
      onClose={onCloseHandler}
    />
    <div className={styles.context}>{children}</div>

  </BaseModal>
}

export default BaseModalWithTitle
