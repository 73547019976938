// import styles from './StickerStore.module.scss'
import {useAppDispatch, useAppSelector} from "@/hooks/appHook";
import {
  getMyStickers,
  getStickerPacks, MyStickerPack,
  removeMyStickers,
  StickerPack,
  updateMyStickers,
  updatePackInfo,
} from '@/store/stickers/stickers';
import VirtualList from "@/components/VirtualList/VirtualList";
import {useCallback, useEffect, useRef, useState} from 'react';
import memoize from "memoize-one";
import StickerStoreItem,
{StickerPackData} from "@/components/EmojiPicker/StickerStore/StickerStoreItem/StickerStoreItem";
import AutoSizer from "react-virtualized-auto-sizer";
import {ListOnItemsRenderedProps} from "react-window";
import {getStickerPackInfo} from "@/api/stickers";
import {getUser} from "@/store/user/user";
import {getChatId} from "@/utils/chats";
import {getLang} from "@/store/language/language";

const createPackData = memoize((props: StickerPackData) => ({
  ...props,
  length: props.packs.length
}))

const StickerStore = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const lang = useAppSelector(getLang)
  const stickerPacks = useAppSelector(getStickerPacks)
  const myStickers = useAppSelector(getMyStickers)

  const [packData, setPackData] = useState<StickerPackData>({
    packs: stickerPacks,
    lang,
    myStickers: new Set(myStickers.map(pack => pack.id))
  })
  const queueLoad = useRef<string[]>([])
  const loading = useRef<Set<string>>(new Set())

  const startLoad = () => {
    if (loading.current.size > 2 || !user?.$jid) {
      return
    }
    const id = queueLoad.current.shift()
    if (!id) {
      return;
    }
    loading.current.add(id)
    getStickerPackInfo({
      id,
      memberId: getChatId(user.$jid)
    })
      .then((info) => {
        dispatch(updatePackInfo({id, info}))
      })
      .finally(() => {
        loading.current.delete(id)
        startLoad()
      })
    startLoad()
  }

  const addMyPack = useCallback((pack: MyStickerPack) => {
    dispatch(updateMyStickers(pack))
  }, [dispatch])

  const removeMyPack = useCallback((pack: StickerPack) => {
    dispatch(removeMyStickers(pack.id))
  }, [dispatch])

  useEffect(() => {
    setPackData({
      packs: stickerPacks,
      lang,
      myStickers: new Set(myStickers.map(pack => pack.id)),
      onAdd: addMyPack,
      onRemove: removeMyPack
    })
  }, [stickerPacks, lang, addMyPack, removeMyPack, myStickers])

  const itemsRendered = (
    {
      overscanStartIndex,
      overscanStopIndex,
    }:  ListOnItemsRenderedProps) => {
    const queueSet = new Set(queueLoad.current)
    for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
      const pack = packData.packs[i]
      if (pack
        && !pack.info
        && !loading.current.has(pack.id)
        && !queueSet.has(pack.id)
      ) {
        queueLoad.current.push(pack.id)
        queueSet.add(pack.id)
      }
    }
    startLoad()
  }

  return <AutoSizer>
    {({width, height}) => (
      <VirtualList<StickerPackData>
        data={packData}
        render={StickerStoreItem}
        createItemData={createPackData}
        itemSize={() => 126}
        height={height || 100}
        width={width || 100}
        onItemsRendered={itemsRendered}
        overscanCount={2}
      />)}

  </AutoSizer>
}

export default StickerStore
