import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {useCallback, useEffect} from 'react';
import {Stanza} from '@/interfaces/XMPP';
import {getMUChats, getPrivateChats, setMUChats, setPrivateChats} from '@/store/chats/chats';
import {AnyObject} from '@/index';
import JXON from 'jxon';
import VCard from '@/services/VCard';
import {getUser} from '@/store/user/user';
import {isMUChat} from '@/utils/chats';
import useQueueSender from '@/hooks/useQueueSender';
import connection from '@/services/Connection/Connection';

interface GetVCardWithQueueProps {
  jid: string,
  timeout?: number
  immediately?: boolean
}

const useVCard = ({watch = false} = {}) => {
  const privateChats = useAppSelector(getPrivateChats)
  const rooms = useAppSelector(getMUChats)
  const user = useAppSelector(getUser)
  const dispatch = useAppDispatch()
  const {send: sendToQueue} = useQueueSender({
    queueName: 'vcard',
    limit: 20,
    minInterval: 100,
    timeout: 10000,
  })

  const messageHandler = useCallback((msg: Stanza) => {
    const xmlObj: AnyObject = JXON.stringToJs(msg.outerHTML)
    console.log('vcard: ', xmlObj)  // eslint-disable-line no-console
    const {$from, vCard} = xmlObj.iq
    let chat = privateChats[$from] || rooms[$from]
    for (const key in vCard) {
      const lowerKey = key.toLowerCase()
      if (key !== lowerKey) {
        vCard[lowerKey] = vCard[key]
        delete vCard[key]
      }
    }

    if (!chat) {
      chat = {
        $jid: $from,
        $subscription: 'none',
      }
    }

    if (chat) {
      const isPrivateChat = !isMUChat(chat)
      const updateChats = isPrivateChat ? setPrivateChats : setMUChats
      const oldChats = isPrivateChat ? privateChats : rooms
      dispatch(updateChats({
        ...oldChats,
        [$from]: {
          ...chat,
          name: typeof vCard.nickname === 'string' ? vCard.nickname : '',
          vcard: vCard,
        },
      }))
    }
    return true
  }, [dispatch, privateChats, rooms])

  useEffect(() => {
    if (!watch) {
      return
    }
    const refHandler = connection?.addHandler(messageHandler, 'vcard-temp', 'iq')
    return () => {
      if (refHandler) {
        connection?.deleteHandler(refHandler)
      }
    }
  }, [messageHandler, watch])

  const getVCard = useCallback((jid: string, timeout?: number) => {
    const msg = VCard.Messages.getVCard({
      from: user?.$jid || '',
      to: jid,
    })
    return connection.send(msg, timeout)
  }, [user?.$jid])

  const getVCardWithQueue = useCallback((
    {
      jid,
      timeout,
      immediately
    }: GetVCardWithQueueProps): Promise<unknown> => {
    const msg = VCard.Messages.getVCard({
      from: user?.$jid || '',
      to: jid,
    })
    const promise = sendToQueue({
      message: msg,
      timeout,
      immediately,
      watchBy: jid,
    })
    return promise
  }, [user?.$jid, sendToQueue])

  return {getVCard, getVCardWithQueue}
}

export default useVCard
