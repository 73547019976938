import request from "@/api/request";

interface hidePinProps {
  chat:     string,
  kind:     string,
  typeHide: string,
}

export const hidePin = ({chat, kind, typeHide}: hidePinProps) => {
  return request('post', '/api/message/hide-pin', {
    chat,
    kind,
    typeHide,
  }).then(({data}) => {
    return data
  })
}
