export interface Stanza {
  outerHTML: string,
  [key: string]: any
}

export enum IMessageTypes {
  iq,
  message,
  presence
}
export type IMessageType = keyof typeof IMessageTypes

export type IMessageJSON = {
  [key in IMessageType]: {
    $id: string,
    [key: string]: any
  }
}