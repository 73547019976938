import {createSelector, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@/store';
import {IVcard, MutedUser} from '@/api/chats';
import {isMUChat} from '@/utils/chats';
import {LoadState} from '@/interfaces/general';

const initialState: {
  privateChats: ChatList,
  muChats: ChatList,
  privateChatsLoaded: LoadState,
  muChatsLoaded: LoadState,
  threadsLoaded: LoadState,
  activeChatId: string | null
} = {
  privateChats: {},
  muChats: {},
  privateChatsLoaded: 'unload',
  muChatsLoaded: 'unload',
  threadsLoaded: 'unload',
  activeChatId: null,
}

export interface ChatList {
  [key: string]: Chat
}

export type IChatType = 'chat' | 'groupchat'

export type IChatPublicType = 'private' | 'public'

export type Affiliation = 'member' | 'admin' | 'owner' | 'none'

export interface IGroupChatMember {
  jid: string,
  affiliation: Affiliation,
  muted?: boolean
}

interface UpdateMembersProps {
  chatJid: string,
  members: IGroupChatMember | IGroupChatMember[] | null
}

interface RemoveMembersProps {
  chatJid: string,
  memberJids: string[]
}

interface UpdateChatProps {
  chatJid: string,
  options: Partial<Chat>
}

interface AddFilesProps {
  chatJid: string,
  files: ChatFile[]
  loaded?: boolean
  before?: boolean
}

export enum GroupCategoriesMap {
  consultant = 'Consultant',
  customer = 'Customer',
  sponsor = 'Sponsor',
  favorite = 'Favorites',
  accepted = 'Accepted',
  pending = 'Pending',
}

export type GroupCategories = typeof GroupCategoriesMap[keyof typeof GroupCategoriesMap]

// export type GroupCategories = keyof typeof GroupCategoriesMap
export type GroupCategoriesStr = ''
  | `${GroupCategories}`
  | `${GroupCategories},${GroupCategories}`
  | `${GroupCategories},${GroupCategories},${GroupCategories}`
  | `${GroupCategories},${GroupCategories},${GroupCategories},${GroupCategories}`
  | `${GroupCategories},${GroupCategories},${GroupCategories},${GroupCategories},${GroupCategories}`

export interface ChatFile {
  uid: string
  url: string,
  name: string
}

export enum TypePeriod {
  disable,
  day,
  week,
  month
}

export const isTypePeriod = (str: string): str is TypePeriodStr =>
  str in TypePeriod

export type TypePeriodStr = keyof typeof TypePeriod

type TypeAutoDeleteMessages = {
  period: number,
  typePeriod: TypePeriodStr,
  mine: boolean,
  visible: boolean,
}

type PinMessage = {
  uid: string,
  text: string,
  type: string,
  timestamp: number,
  visible: boolean,
}

export interface Chat {
  thread?: string,
  $jid: string
  $subscription?: 'both' | 'from' | 'remove' | 'none'
  type?: IChatType
  $ask?: string,
  ask?: string,
  groups?: GroupCategories[],
  name?: string
  nick?: string
  resources?: {},
  vcard?: IVcard
  muted?: boolean
  notifyMuted?: boolean
  members?: IGroupChatMember[] | null,
  membersLoaded?: boolean,
  lastActive?: boolean | Date,
  files?: {
    list: ChatFile[]
    loaded: boolean
  },
  groupType?: IChatPublicType,
  autoDeleteMessages?: TypeAutoDeleteMessages,
  pin?: PinMessage | null,
}

type RemoveFileProps = {
  chatJid: string
} & ({
  url: string
  uid?: string
} | {
  uid: string
  url?: string
})

const chats = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    clear(state) {
      state.privateChats = {}
      state.muChats = {}
      state.privateChatsLoaded = 'unload'
      state.muChatsLoaded = 'unload'
      state.activeChatId = null
    },
    setPrivateChats(state, {payload}: { payload: ChatList }) {
      state.privateChats = payload
    },
    setPrivateChatsLoaded(state, {payload}: { payload: LoadState }) {
      state.privateChatsLoaded = payload
    },
    setThreadsLoaded(state, {payload}: { payload: LoadState }) {
      state.threadsLoaded = payload
    },
    setMUChats(state, {payload}: { payload: ChatList }) {
      state.muChats = payload
    },
    setMUChatsLoaded(state, {payload}: { payload: LoadState }) {
      state.muChatsLoaded = payload
    },
    setActiveChatId(state, {payload}: { payload: string | null }) {
      state.activeChatId = payload
    },
    addMUChat(state, {payload}: { payload: Chat }) {
      if (state.muChats[payload.$jid]) {
        return
      }
      state.muChats[payload.$jid] = payload
    },
    deleteChats(state, {payload}: { payload: string | string[] }) {
      const deletedChatJids = Array.isArray(payload) ? payload : [payload]
      const muChats = {
        ...state.muChats,
      }
      const privateChats = {
        ...state.privateChats,
      }
      deletedChatJids.forEach(jid => {
        delete muChats[jid]
        delete privateChats[jid]
      })
      state.muChats = muChats
      state.privateChats = privateChats
    },
    updateChat(state, {payload}: { payload: UpdateChatProps }) {
      const {chatJid, options} = payload
      const allChats = {...state.muChats, ...state.privateChats}
      const chat = allChats[chatJid]
      if (!chat) {
        return
      }
      const chats = chatJid.endsWith(`@conference.${process.env.REACT_APP_EJ_HOST}`) ?
        state.muChats :
        state.privateChats
      chats[chatJid] = {
        ...chats[chatJid],
        ...options,
      }
    },
    addFiles(state, {payload}: { payload: AddFilesProps }) {
      const {chatJid, files, loaded, before} = payload
      const allChats = {...state.muChats, ...state.privateChats}
      const chat = allChats[chatJid]
      if (!chat) {
        return
      }
      const list = chat.files?.list || []
      if (before) {
        list.unshift(...files)
      } else {
        list.push(...files)
      }
      const chats = isMUChat(chat) ? state.muChats : state.privateChats
      chats[chatJid] = {
        ...chat,
        files: {
          loaded: loaded != null ? loaded : chat.files?.loaded || false,
          list,
        },
      }
    },
    removeFile(state, {payload}: { payload: RemoveFileProps }) {
      const {chatJid, url, uid} = payload
      const chat = state.muChats[chatJid] || state.privateChats[chatJid]
      if (!chat?.files || chat.files.list.length === 0) {
        return
      }
      let fileIndex = chat.files.list.findIndex(file =>
        (!!url && file.url === url) ||
        (!!uid && file.uid === uid))
      if (~fileIndex) {
        chat.files.list.splice(fileIndex, 1)
        return;
      }
    },
    updateMembers(state, {payload}: { payload: UpdateMembersProps }) {
      let {chatJid, members} = payload
      const chat = state.muChats[chatJid]
      if (!chat) {
        return
      }
      if (members == null) {
        state.muChats[chatJid].members = members
        return
      }
      if (!Array.isArray(members)) {
        members = [members]
      }
      const membersMap: Map<string, IGroupChatMember> = new Map();
      (state.muChats[chatJid].members || []).forEach(member => {
        membersMap.set(member.jid, member)
      })
      members.forEach(member => {
        const oldMember = membersMap.get(member.jid)
        membersMap.set(member.jid, {
          ...oldMember,
          ...member,
        })
      })
      state.muChats[chatJid].members = Array.from(membersMap, ([_, value]) => value)
    },
    removeMembers(state, {payload}: { payload: RemoveMembersProps }) {
      const {chatJid, memberJids} = payload
      if (!state.muChats[chatJid]) {
        return
      }
      state.muChats[chatJid].members = state.muChats[chatJid].members
        ?.filter(member => !memberJids.includes(member.jid))
    },
    setMembersLoaded(state, {payload}: { payload: { chatJid: string, value: boolean } }) {
      const {chatJid, value} = payload
      if (!state.muChats[chatJid]) {
        return
      }
      state.muChats[chatJid].membersLoaded = value
    },
    setMutedUsers(state, {payload}: { payload: { chatJid: string, mutedUsers: MutedUser[] } }) {
      const {chatJid, mutedUsers} = payload
      if (!state.muChats[chatJid]) {
        return
      }
      const members = new Map()
      state.muChats[chatJid].members?.forEach(member => {
        members.set(member.jid, member)
      })
      mutedUsers.forEach(mutedUser => {
        const member = members.get(mutedUser.jid)
        if (member) {
          member.muted = mutedUser.mute
        }
      })
      state.muChats[chatJid].members = Array.from(members, ([_, value]) => value)
    },
  },
})

export const {
  clear,
  setPrivateChats,
  setPrivateChatsLoaded,
  setThreadsLoaded,
  setMUChats,
  setMUChatsLoaded,
  setActiveChatId,
  deleteChats,
  updateChat,
  addFiles,
  removeFile,
  updateMembers,
  removeMembers,
  setMembersLoaded,
  setMutedUsers,
  addMUChat,
} = chats.actions

export const getPrivateChats = (state: RootState): ChatList => state.chats.privateChats
export const getMUChats = (state: RootState): ChatList => state.chats.muChats
export const getAllChats = createSelector(
  [getPrivateChats, getMUChats],
  (privateChats, muChats) => ({...privateChats, ...muChats}),
)
export const isPrivateChatsLoaded = (state: RootState): boolean => state.chats.privateChatsLoaded === 'loaded'
export const isMuChatsLoaded = (state: RootState): boolean => state.chats.muChatsLoaded === 'loaded'
export const isThreadsLoaded = (state: RootState) => state.chats.threadsLoaded === 'loaded'
export const privateChatsLoaded = (state: RootState): LoadState => state.chats.privateChatsLoaded
export const muChatsLoaded = (state: RootState): LoadState => state.chats.muChatsLoaded
export const getActiveChatId = (state: RootState): string | null => state.chats.activeChatId
export const getActiveChat = (state: RootState): Chat | null => state.chats.activeChatId ?
  (state.chats.privateChats[state.chats.activeChatId] ||
    state.chats.muChats[state.chats.activeChatId] ||
    null) : null
export const getChat = (jid: string) => (state: RootState): Chat | null =>
  ({...state.chats.privateChats, ...state.chats.muChats})[jid] || null
export default chats.reducer
