import RecordRTC, {Options, RecordRTCPromisesHandler} from 'recordrtc'

const MAX_RECORDING_TIME = 1800; //seconds

export enum MediaType {
  AUDIO = 'audio',
  VIDEO = 'video'
}

type MimeType =
  | 'audio/webm'
  | 'audio/webm;codecs=pcm'
  | 'video/mp4'
  | 'video/webm'
  | 'video/webm;codecs=vp9'
  | 'video/webm;codecs=vp8'
  | 'video/webm;codecs=h264'
  | 'video/x-matroska;codecs=avc1'
  | 'video/mpeg'
  | 'audio/wav'
  | 'audio/ogg'
  | 'audio/mpeg'
  | 'audio/webm;codecs=vorbis'
  | 'audio/webm;codecs=opus'
  | 'audio/mp4';

const MediaRecorder = window.MediaRecorder || {
  isTypeSupported() {
    return false
  },
}

type MediaRecordingBlobEvent = 'stop'
type MediaRecordingStringEvent = 'error'

type MediaRecordingTypeEvents = MediaRecordingBlobEvent | MediaRecordingStringEvent

type BlobEvent = (blob: Blob) => void
type StringEvent = (reason: string) => void

type EventMap = {
  [key in MediaRecordingBlobEvent]: BlobEvent
} & {
  [key in MediaRecordingStringEvent]: StringEvent
}

type MediaRecordingListener = Record<MediaRecordingBlobEvent, Set<BlobEvent>>
  & Record<MediaRecordingStringEvent, Set<StringEvent>>

interface Listener {
  addListener<T extends MediaRecordingTypeEvents>(event: T, cb: EventMap[T]): void

  removeListener<T extends MediaRecordingTypeEvents>(event: T, cb?: EventMap[T]): void
}

const getStringFromError = (err: any) => {
  if (err instanceof DOMException) {
    return err.message
  } else if (typeof err === 'string') {
    return err
  } else if (typeof err.toString === 'function') {
    return err.toString()
  }
  return 'Error'
}

interface StartStreamProps {
  seconds?: number,
  stream?: MediaStream
}

class MediaRecordingService implements Listener {
  private maxRecordingTime = MAX_RECORDING_TIME;
  private recordingType = MediaType.AUDIO
  private recordRTC: RecordRTCPromisesHandler | null = null
  private stream: MediaStream | null = null
  private timer: NodeJS.Timer | undefined

  protected started = false;

  private listener: MediaRecordingListener = {
    stop: new Set(),
    error: new Set(),
  }

  private isMimeTypeSupported(mimeType: string) {
    if (typeof (MediaRecorder || {}).isTypeSupported !== 'function') {
      return false;
    }

    return MediaRecorder.isTypeSupported(mimeType);
  }

  get supportedAudioMime(): MimeType {
      const mimes: MimeType[] = [
        'audio/mpeg',
        'audio/ogg',
        'audio/wav',
        'audio/webm;codecs=opus',
        'audio/webm;codecs=pcm',
        'audio/webm;codecs=vorbis',
        'audio/webm',
        'audio/mp4'
      ];
      for (let i = 0; i < mimes.length; i++) {
        if (this.isMimeTypeSupported(mimes[i])) {
          return mimes[i];
        }
      }
      return 'audio/wav';
  }


  get supportedVideoMime(): MimeType {
    const mimes: MimeType[] = [
      'video/x-matroska;codecs=avc1',
      'video/webm;codecs=h264',
      'video/webm;codecs=vp9',
      'video/webm;codecs=vp8',
      'video/webm',
    ];

    for (let i = 0; i < mimes.length; i++) {
      if (this.isMimeTypeSupported(mimes[i])) {
        return mimes[i];
      }
    }
    return 'video/webm';
  }

  get recordingOptions(): Options {
    // const mime = this.recordingType === MediaType.VIDEO ? this.supportedVideoMime : this.supportedAudioMime;
    return {
      recorderType: RecordRTC.StereoAudioRecorder,
      type: (this.recordingType === MediaType.VIDEO ? 'video' : 'audio'),
      // mimeType: mime,
      timeSlice: 1000,
      // onTimeStamp: this.onTimeStamp.bind(this)
    };
  }

  addListener<T extends MediaRecordingTypeEvents>(event: T, cb: EventMap[T]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.listener[event].add(cb)
  }

  removeListener<T extends MediaRecordingTypeEvents>(event: T, cb?: EventMap[T]) {
    if (cb === undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.listener[event] = new Set()
      return
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.listener[event].delete(cb)
  }

  recordAudio({seconds, stream}: StartStreamProps = {}) {
    this.recordingType = MediaType.AUDIO;
    return this.startMediaRecording({seconds, stream});
  }

  recordVideo({seconds, stream}: StartStreamProps = {}) {
    this.recordingType = MediaType.VIDEO;
    return this.startMediaRecording({seconds, stream});
  }

  private startMediaRecording({seconds, stream}: StartStreamProps): Promise<void> {
    if (this.started) {
      const error = 'recordingIsAlreadyInProgress'
      this.onError(error)
      return Promise.reject(error);
    }

    this.started = true;
    this.maxRecordingTime = seconds || MAX_RECORDING_TIME;

    let resultPromise
    if (stream) {
      // stream provided
      resultPromise = this.startStream(stream)
    } else {
      const mediaConstraints: MediaStreamConstraints = {
        audio: true,
        ...(this.recordingType === MediaType.VIDEO && {
          video: {
            width: {min: 1280},
            height: {min: 720},
          },
        }),
      };

      resultPromise = navigator.mediaDevices.getUserMedia(mediaConstraints)
        .then((stream) => {
          return this.startStream(stream)
        })
        .catch((err) => {
          this.started = false
          this.onError(err)
          return Promise.reject(err)
        });
    }

    return resultPromise.catch(() => {
      this.started = false
      return Promise.reject()
    })
  }

  private async startStream(stream: MediaStream): Promise<void> {
    const options = this.recordingOptions;

    if (this.recordRTC) {
      await this.recordRTC.destroy();
      this.recordRTC = null;
    }

    this.destroyStream()

    this.stream = stream


    this.recordRTC = new RecordRTCPromisesHandler(stream, options);
    this.timer = setTimeout(() => {
      this.stopRecord()
    }, this.maxRecordingTime * 1000)
    return this.recordRTC.startRecording()
  }

  destroyStream() {
    if (this.stream) {
      const tracks = this.stream.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
    }
    this.stream = null
  }

  async stopRecord() {
    this.started = false
    clearTimeout(this.timer)

    if (!this.recordRTC) {
      return Promise.reject()
    }

    try {
      await this.recordRTC.stopRecording()
      const blob = await this.recordRTC.getBlob()
      this.onRecordingStopped(blob)
    } catch (err) {
      this.onError(getStringFromError(err))
      return err
    } finally {
      await this.recordRTC.destroy();
      this.recordRTC = null;
      this.destroyStream()
    }
    return Promise.resolve()
  }

  private async onRecordingStopped(blob?: Blob) {
    const file = blob || await this.recordRTC?.getBlob()
    if (!file) {
      this.listener.error.forEach(cb => {
        cb('Failed to create blob')
      })
    } else {
      this.listener.stop.forEach(cb => {
        cb(file)
      })
    }
  }

  private onError(err: any) {
    this.listener.error.forEach(cb => {
      cb(getStringFromError(err))
    })
  }

}

const mediaRecordingService = new MediaRecordingService()
export default mediaRecordingService
