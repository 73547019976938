import styles from './CropImage.module.scss'
import Cropper, {Point, Area} from 'react-easy-crop'
import {ChangeEventHandler, useState} from 'react';
import classNames from 'classnames';

interface CropImageProps {
  image: File,
  className?: string
  crop?: Point,
  cropShape?: 'rect' | 'round'
  onChange?: (croppedAreaPixels: Area) => void
}

const CropImage = (
  {
    image,
    className,
    crop = {x:0, y: 0},
    cropShape = 'round',
    onChange
  }: CropImageProps) => {
  const [imageUrl] = useState(URL.createObjectURL(image))
  const [zoom, setZoom] = useState(1);
  const [cropState, setCropState] = useState<Point>(crop);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    onChange?.(croppedAreaPixels)
  }

  const handleChangeZoom: ChangeEventHandler<HTMLInputElement> = (e) => {
    setZoom(prevState => parseFloat(e.target.value) || prevState)
  }

  return <div className={classNames(styles.cropper, className)}>
    <div className={styles.imageBox}>
      <Cropper
        image={imageUrl}
        crop={cropState}
        zoom={zoom}
        aspect={1}
        cropShape={cropShape}
        showGrid={false}
        onCropChange={setCropState}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </div>
    <input
      className={styles.input}
      value={zoom}
      type="range"
      name="zoom"
      aria-labelledby="Zoom"
      min="1"
      max="3"
      step={0.01}
      onChange={handleChangeZoom}
    />
  </div>
}

export default CropImage
