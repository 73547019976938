import {v4 as uuid} from "uuid";
import {Affiliation} from '@/store/chats/chats';
import {createStropheFromObject} from '@/services/utils';

export interface IGetMembersProps {
  from: string,
  chatJid: string,
  affiliation: Affiliation
}

class MUChat{
  static Messages = {
    getList(id: string) {
      return  $iq({
        id: uuid(),
        from: id,
        to: 'conference.' + process.env.REACT_APP_EJ_HOST,
        type: 'get'
      })
        .c('query', {
          xmlns: 'http://jabber.org/protocol/disco#items'
        }).tree()
    },

    getMembers({from, chatJid, affiliation}: IGetMembersProps) {
      return createStropheFromObject('iq', {
        $from: from,
        $id: uuid(),
        $to: chatJid,
        $type: 'get',
        query: {
          $xmlns: 'http://jabber.org/protocol/muc#admin',
          item: {
            $affiliation: affiliation,
          }
        }
      }).tree()
    }
  }
}

export default MUChat
