import request from "./request";

export const pinList = () => {
  return request('get', '/api/message/pin/list', {})
    .then(data => {
      return data.data
    })
}

export const pin = (uid: string, chat: string, kind: string) => {
  return request('post', '/api/message/pin', {uid, chat, kind})
    .then(data => {
      return data.data
    })
}

export const unPin = (uid: string, chat: string, kind: string) => {
  return request('post', '/api/message/unpin', {uid, chat, kind})
    .then(data => {
      return data.data
    })
}
