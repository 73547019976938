import React from "react";
import {IMessage} from "@/store/messages/messages";
import styles from "@/components/Pin/Message/MessagePin.module.scss";
import {useAppSelector, useAppDispatch} from "@/hooks/appHook";
import {getActiveChatId, getChat, updateChat} from "@/store/chats/chats";
import {IPin} from "@/components/Pin/Pin";
import {FormattedMessage, useIntl} from "react-intl";
import classNames from "classnames";
import {hidePin} from "@/api/hide-pin";

const MessagePin = ({onClickReply}: IPin) => {
  const activeChatId = useAppSelector(getActiveChatId)
  const currentChat = useAppSelector(getChat(activeChatId + ''))
  const {formatMessage} = useIntl()
  const dispatch = useAppDispatch()

  const handleChatCross = () => {
    if (currentChat && currentChat?.$jid && currentChat?.type) {
      hidePin({"chat": currentChat?.$jid, "kind": currentChat?.type, "typeHide": 'message'}).then((res) => {
        dispatch(updateChat({
          chatJid: currentChat.$jid || '',
          options: {
            pin: null
          },
        }))
      })
    }
  }

  if (!currentChat?.pin || ! currentChat.pin.visible) {
    return <></>
  }

  const msg: IMessage = {
    id: currentChat?.pin?.uid,
    from: "",
    to: "",
    timestamp: currentChat?.pin?.timestamp,
    type: "text"
  }

    return <div className={styles.pin}>
      <div className={styles.body} onClick={() => onClickReply?.(msg)}>
        <div className={styles.rectangle}></div>
        <div>
        <div className={styles.title}>{formatMessage({id: 'CHAT.PINNED_MESSAGE'})}</div>
        <div className={styles.text}>
          {currentChat?.pin?.type === "file" &&
            <><i className={classNames("chat-clip", styles.chatClip)} />{currentChat?.pin?.text}</>}
          {currentChat?.pin?.type === "image" && <FormattedMessage id="CHAT.TYPE_MESSAGE_IMAGE"></FormattedMessage>}
          {currentChat?.pin?.type === "audio" && <FormattedMessage id="CHAT.TYPE_MESSAGE_AUDIO"></FormattedMessage>}
          {currentChat?.pin?.type === "video" && <FormattedMessage id="CHAT.TYPE_MESSAGE_VIDEO"></FormattedMessage>}
          {currentChat?.pin?.type === "sticker" && <FormattedMessage id="CHAT.TYPE_MESSAGE_STICKER"></FormattedMessage>}
          {currentChat?.pin?.type === "text" && currentChat?.pin?.text}
        </div>
        </div>
      </div>
      <i className={classNames("chat-cross", styles.chatCross)} onClick={handleChatCross} />
  </div>
}

export default MessagePin
