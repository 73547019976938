import styles from './SupportTicketDetails.module.scss'
import BaseModalWithTitle, { BaseModalWithTitleProps } from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle'
import {
  ISupportTicket,
  unshiftTicketMessage,
} from '@/store/supportTickets/supportTickets'
import SupportTicketHeader from '@/components/SupportTicket/SupportTicketHeader/SupportTicketHeader'
import SupportTicketMessageSender
  , { ISentSupportMessage } from '@/components/SupportTicket/SupportTicketMessageSender/SupportTicketMessageSender'
import SupportTicketDialog from '@/components/SupportTicket/SupportTicketDialog/SupportTicketDialog'
import { sendTicketMessage } from '@/api/supportTickets'
import { useAppDispatch } from '@/hooks/appHook'

interface SupportTicketDetailsProps extends Omit<BaseModalWithTitleProps, 'title'>{
  ticket?: ISupportTicket | null
  messageLoading?: boolean
}

const SupportTicketDetails = (
  {
    ticket,
    messageLoading,
    ...props
  }
  : SupportTicketDetailsProps) => {
  const dispatch = useAppDispatch()
  if (!ticket) {
    return null
  }

  const onMessageSend = async({ message, files }: ISentSupportMessage): Promise<void> => {
    const ticketMessage = await sendTicketMessage({
      ticketId: ticket.id,
      message,
      files
    })
    dispatch(unshiftTicketMessage({
      ticketId: ticket.id,
      message: ticketMessage
    }))
  }

  return <BaseModalWithTitle
    title={`${ticket.id || ''}`}
    {...props}
  >
    <div className={styles.box}>
      <SupportTicketHeader
        className={styles.header}
        ticket={ticket}
      />
      <SupportTicketMessageSender
        className={styles.sender}
        onSend={onMessageSend}
      />
      <SupportTicketDialog
        className={styles.dialogs}
        ticket={ticket}
        loading={messageLoading}
      />
    </div>
  </BaseModalWithTitle>
}

export default SupportTicketDetails
