import { CSSProperties, memo, useCallback } from 'react';
import Avatar from '@/components/Avatar/Avatar';
import styles from './ChatItem.module.scss'
import generalStyles from '@/styles/YcGeneral.module.scss'
import { getLastMessageTimeStr } from '@/utils/dateutils';
import { areEqual } from 'react-window';
import classNames from 'classnames';
import { ChatsDataWithDivider, Divider, filterFavoriteChats, getNameOrNick, INewsChat } from '@/utils/chats';
import { Chat, GroupCategoriesMap } from '@/store/chats/chats';
import { IMessage, isNotBreakMessage } from '@/store/messages/messages';
import { isTextMessage } from '@/components/Chat/ChatMessage/TextMessage/TextMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModifiedTouchEvent } from "@/components/Chat/ChatMessage/ChatMessage";
import { LongPressCallback, LongPressEventType, useLongPress } from "use-long-press";
import { isTouchEvent } from "@/utils/events";
import { ReactComponent as StarImage } from "@/images/icons/star-fill.svg";
import crownImage from '@/images/icons/crown.png'
import { getTypeTranslation } from '@/utils/messages';
import { CHAT_NAME } from '@/store/news/news'

export const CHAT_ITEM_HEIGHT = 64

interface IChatItem {
  index: number,
  style: CSSProperties,
  data: ChatsDataWithDivider
}

export const isChat = (chatOrDivider: Chat | Divider | INewsChat): chatOrDivider is Chat => {
  return '$jid' in chatOrDivider
}

export const isDivider = (chatOrDivider: Chat | Divider | INewsChat): chatOrDivider is Divider => {
  return 'name' in chatOrDivider && Object.keys(chatOrDivider).length === 1
}

export const isNewsChat = (chatOrDivider: Chat | Divider | INewsChat): chatOrDivider is INewsChat => {
  return 'news' in chatOrDivider
}

const getLastMessageText = (message: IMessage | undefined) => {
  if (!message) {
    return 'CHAT.NO_MESSAGE'
  }

  if (isTextMessage(message) && message.type === 'text') {
    return message.text
  }

  return getTypeTranslation(message.type) || 'No Data'
}

const ChatItem = memo(({ index, style, data }: IChatItem) => {
  const {
    chats,
    showFullInfo,
    activeChatId,
    activeNews,
    onClick,
    onContextMenu,
    messages,
    draft,
    user,
  } = data
  const chat = chats[index]
  const { formatMessage } = useIntl()

  const handleContextMenu = useCallback((e: ModifiedTouchEvent) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    const chat = chats[index]
    if (isDivider(chat)) {
      return
    }
    onContextMenu?.(e, chat)
  }, [chats, index, onContextMenu])

  const longPress: LongPressCallback = useCallback((event) => {
    if (!isTouchEvent(event)) {
      return
    }
    const newEvent: ModifiedTouchEvent = {
      ...event,
      pageX: 0,
      pageY: 0,
    }
    if (event.type === 'touchstart') {
      newEvent.pageX = event.changedTouches[0]?.pageX + 10
      newEvent.pageY = event.changedTouches[0]?.pageY + 10
    }
    handleContextMenu(newEvent)
  }, [handleContextMenu])

  const bind = useLongPress(longPress, {
    cancelOnMovement: 5,
    detect: LongPressEventType.Touch
  })

  if (isDivider(chat)) {
    return <div style={style} className={styles.category}>{chat.name}</div>
  }

  if (isNewsChat(chat)) {
    const chatName = formatMessage({ id: 'announcements' })
    const regExp = /<.*?>/ig
    const lastNews = chat.news[chat.news.length - 1]
    const description = lastNews?.description.replace(regExp, '')
    const timestamp = lastNews.timestamp
    const unreadMessages = chat.news.reduce((sum, news) => sum + +!news.viewed, 0)
    return <div
      style={style}
      className={classNames(styles.chatItem, activeNews === CHAT_NAME && styles.active)}
      onClick={() => onClick?.(chat.id)}
    >
      <div className={styles.avatarBlock}>
        <Avatar className={styles.avatar} src={chat.avatar} name={chatName} />
      </div>
      <div className={styles.titleBlock}>
        <div className={styles.name}>
          <span className={styles.textName}>{chatName}</span>
        </div>
        <div className={styles.desc}>
          {showFullInfo &&
            (<div className={styles.lastMessage}>
              {description}
            </div>)
          }
        </div>
      </div>
      <div className={styles.addInfo}>
        <div className={styles.time}>{getLastMessageTimeStr(timestamp)}</div>
        <div className={styles.addInfoIcons}>
          {showFullInfo && !!unreadMessages && <span className={styles.counter}>{unreadMessages}</span>}
        </div>

      </div>
    </div>
  }

  const chatMessages = messages?.[chat.$jid]
  const lastShowedMessage = chatMessages?.messages[chatMessages?.messages.length - 1]
  const lastMessage = lastShowedMessage && isNotBreakMessage(lastShowedMessage) ? lastShowedMessage : undefined
  const draftText = draft?.[chat.$jid]
  let amountUnreadMessages = 0

  if (chatMessages) {
    amountUnreadMessages = chatMessages.messages
      .filter(isNotBreakMessage)
      .filter(message => message.from !== user?.$jid && message.status !== 'displayed').length
  }

  const isFavorite = filterFavoriteChats(chat)

  return <div
    style={style}
    className={classNames(styles.chatItem, activeChatId === chat.$jid && styles.active)}
    onClick={() => {
      onClick?.('' + chat.$jid)
    }}
    onContextMenu={(e) => {
      handleContextMenu(e)
    }}
    {...bind()}
  >
    <div className={styles.avatarBlock}>
      <Avatar src={chat.vcard?.thumbnail} className={styles.avatar} name={getNameOrNick(chat)} />
      {chat?.lastActive === true ? <div className={styles.online}></div> : null}
    </div>
    <div className={styles.titleBlock}>
      <div className={styles.name}>
        {isFavorite && <StarImage />}
        {chat.type === 'chat' && chat.groups?.includes(GroupCategoriesMap.sponsor) && <img
          className={styles.sponsorIcon}
          src={crownImage}
          alt={'crown'}
        />}
        {chat.type === 'chat' &&
          (chat.groups?.includes(GroupCategoriesMap.customer) || chat.groups?.includes(GroupCategoriesMap.consultant))
          && <span className={generalStyles.teamIcon}>L1</span>
        }
        {chat.type === 'groupchat' ? <i className="chat-group"></i> : null}
        <span className={styles.textName}>{getNameOrNick(chat)}</span>
        {chat.notifyMuted && <i className={'chat-mute'} />}
      </div>
      <div className={styles.desc}>
        {showFullInfo &&
          (!!draftText
            ? <FormattedMessage id={'draft'}>
              {txt => <div className={styles.draft}>{txt}: {draftText}</div>}
            </FormattedMessage>
            : <div className={styles.lastMessage}>
              {formatMessage({
                id: getLastMessageText(lastMessage),
                defaultMessage: getLastMessageText(lastMessage)
              })}
            </div>)
        }
      </div>
    </div>
    <div className={styles.addInfo}>
      {lastMessage
        ? <div className={styles.time}>{getLastMessageTimeStr(lastMessage.timestamp || '')}</div>
        : null}
      <div className={styles.addInfoIcons}>
        {showFullInfo && !!amountUnreadMessages && <span className={styles.counter}>{amountUnreadMessages}</span>}
      </div>

    </div>

  </div>
}, areEqual)

export default ChatItem
