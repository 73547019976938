import {useAppSelector} from '@/hooks/appHook';
import {getChat} from '@/store/chats/chats';
import {getCachedChat} from '@/store/cache/chats';
import {useEffect, useState} from 'react';

interface ICachedChatProps {
  jid: string
}
const useCachedChat = ({jid}: ICachedChatProps) => {
  const activeChat = useAppSelector(getChat(jid))
  const cachedChat = useAppSelector(getCachedChat(jid))
  const [activeChatWithCache, setActiveChatWithCache] = useState({
    ...cachedChat,
    ...activeChat
  })

  useEffect(() => {
    setActiveChatWithCache({
      ...cachedChat,
      ...activeChat
    })
  }, [activeChat, cachedChat]);

  return {
    cachedChat: activeChatWithCache
  }
}

export default useCachedChat
