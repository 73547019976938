import {v4 as uuid} from 'uuid';
import {createStropheFromObject} from '@/services/utils';
import {IUserVCard} from '@/store/user/user';

interface JidQuery {
  from: string,
  to: string,
}

interface SetVCardProps extends IUserVCard{
  to?: string,
}

class VCard {
  static Messages = {
    getVCard({from, to}: JidQuery): Element {
      return $iq({
        from,
        to,
        type: 'get',
        id: uuid(),
      })
        .c('vCard', {
          xmlns: 'vcard-temp',
        })
        .tree()
    },
    getVCards(jids: JidQuery[]): Element[] {
      return jids.map(jid => this.getVCard(jid))
    },
    setVCard({nickname, to, url, thumbnail}: SetVCardProps): Element {
      const structure = {
        $xmlns: 'jabber:client',
        $type: 'set',
        ...(!!to ? {$to: to} : null),
        $id: uuid(),
        vCard: {
          $xmlns: 'vcard-temp',
          NICKNAME: {
            _: nickname,
          },
          ...(!!thumbnail ? {
            THUMBNAIL: {
              _: thumbnail,
            },
          } : null),
          ...(!!url ? {
            URL: {
              _: url,
            },
          } : null),
        },
      }
      return createStropheFromObject('iq', structure).tree()
    },
  }
}

export default VCard
