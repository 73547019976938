import {v4 as uuid} from 'uuid';

interface IGetLastActive {
  from: string
  to: string
}

class PrivateChat {
    static Messages = {
        getList(id: string) {
            return $iq({
                from: id,
                type: 'get',
                id: uuid()
            })
                .c('query', {
                    xmlns: Strophe.NS.ROSTER
                }).tree()
        },
      getLastActive({from, to}: IGetLastActive) {
        return $iq({
          from,
          to,
          id: uuid(),
          type: 'get'
        })
          .c('query', {
            xmlns: 'jabber:iq:last'
          }).tree()
      }
    }
}

export default PrivateChat
