import styles from './TextInput.module.scss'
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, useId } from 'react'
import classNames from 'classnames'
import Input from '@/components/Primitive/Inputs/Input/Input'

interface TextInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string
  label?: string
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((
  {
    className,
    label,
    ...props
  }, ref) => {
  const id = useId()
  return <div className={classNames(styles.box, className)}>
    {label && <label
      htmlFor={id}
      className={styles.label}
    >{label}</label>}
    <Input
      {...props}
      id={id}
      ref={ref}
    />
  </div>
})

export default TextInput
