import styles from './ChatFiles.module.scss'
import {addFiles, Chat, ChatFile} from '@/store/chats/chats';
import {getChatId, isMUChat} from '@/utils/chats';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualList from '@/components/VirtualList/VirtualList';
import ChatFileItem, {ChatFileData} from './ChatFileItem/ChatFileItem';
import {useCallback, useEffect, useRef, useState} from 'react';
import {getFileList} from '@/api/chats';
import {ListOnItemsRenderedProps} from 'react-window';
import {useAppDispatch} from '@/hooks/appHook';
import memoize from 'memoize-one';
import {ReactComponent as FolderImage} from '@/images/icons/folder.svg';
import {FormattedMessage} from 'react-intl';

interface ChatFilesProps {
  chat?: Chat | null
  onFileShow?: () => void
  maxHeight?: number
}

const ITEM_HEIGHT = 58
const DOWNLOAD_FILE_LIMIT = 10

const createChatsFileData = memoize((props: ChatFileData) => ({
  ...props,
  length: props.files.length,
}))

const ChatFiles = ({chat, onFileShow, maxHeight}: ChatFilesProps) => {
  const [files, setFiles] = useState<ChatFile[]>(chat?.files?.list || [])
  const dispatch = useAppDispatch()
  const loading = useRef(false)
  const visibleObserver = useRef<IntersectionObserver>()
  const containerRef = useRef<HTMLDivElement>(null)
  const lastElementRef = useRef<HTMLDivElement>()

  const loadFiles = useCallback(() => {
    if (!chat || loading.current || chat?.files?.loaded) {
      return
    }
    loading.current = true
    getFileList({
      jid: getChatId(chat.$jid),
      isRoom: isMUChat(chat),
      offset: chat.files?.list.length || 0,
      limit: DOWNLOAD_FILE_LIMIT
    })
      .then(list => {
        dispatch(addFiles({chatJid: chat.$jid, files: list, loaded: list.length < DOWNLOAD_FILE_LIMIT}))
      })
      .finally(() => {
        loading.current = false
      })
  }, [chat, dispatch])

  const handleLastElementRef = useCallback((el: HTMLDivElement | null) => {
    lastElementRef.current = el || undefined
    if (!visibleObserver.current) {
      return
    }
    if (el) {
      visibleObserver.current?.observe(el)
    } else {
      visibleObserver.current?.disconnect()
    }
  }, [])

  const intersectionCb: IntersectionObserverCallback = useCallback((entry) => {
    if (entry[0].isIntersecting) {
      loadFiles()
    }
  }, [loadFiles])

  useEffect(() => {
    visibleObserver.current = new IntersectionObserver(intersectionCb)
    if (!maxHeight) {
      handleLastElementRef(lastElementRef.current || null)
    }

    return () => {
      visibleObserver.current?.disconnect()
      visibleObserver.current = undefined
    };
  }, [intersectionCb, handleLastElementRef, maxHeight])

  useEffect(() => {
    if (chat?.files?.loaded || (chat?.files?.list.length || 0) > 10) {
      return
    }
    loadFiles()
  }, [chat, loadFiles])

  useEffect(() => {
    setFiles(chat?.files?.list || [])
  }, [chat?.files?.list])

  const onItemsRendered = ({overscanStopIndex}: ListOnItemsRenderedProps) => {
    if (!maxHeight) {
      return
    }
    if (overscanStopIndex === (chat?.files?.list.length || 0) - 1) {
      loadFiles()
    }
  }

  if (!chat) {
    return null
  }

  return <div
    ref={containerRef}
    className={styles.listBox}
    style={{
      height: files.length * ITEM_HEIGHT,
      maxHeight,
    }}
  >
    {chat.files?.loaded && chat.files.list.length === 0 ? <div className={styles.emptyBox}>
      <FolderImage className={styles.emptyImage}/>
      <FormattedMessage id={'no_files'}>{txt => <p className={styles.emptyText}>{txt}</p>}</FormattedMessage>
    </div> : <>
      <AutoSizer>
        {({width, height}) => <VirtualList<ChatFileData>
          data={{
            files,
            onFileShow,
          }}
          render={ChatFileItem}
          createItemData={createChatsFileData}
          itemSize={() => ITEM_HEIGHT}
          height={height || 100}
          width={width || 100}
          onItemsRendered={onItemsRendered}
        />}
      </AutoSizer>
      {!maxHeight && <div
        className={styles.lastElement}
        ref={handleLastElementRef}/>}
    </>}

  </div>
}

export default ChatFiles
