import styles from "./ServiceInfo.module.scss";
import {SERVICES_LIST} from "../List/Services";
import {useAppSelector} from "@/hooks/appHook";
import {getActiveService} from "@/store/services/services";
import {useIntl} from "react-intl";
import IconButton from "@/components/Primitive/Buttons/IconButton/IconButton";
import { MouseEventHandler, useState } from "react";
import {isMobile} from "@/store/screenSize/screenSize";
import {getAConnectUrl, getADXMailUrl} from "@/api/services";
import useActiveService from '@/hooks/useActiveService'

const ServiceInfo = () => {
  const activeService = useAppSelector(getActiveService)
  const serviceCurrent = SERVICES_LIST.find(item => item.name === activeService);
  const {formatMessage} = useIntl()
  const mobileView = useAppSelector(isMobile)
  const {setActiveService} = useActiveService()
  const [disable, setDisable] = useState(false)

  const handleBack: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setActiveService(null)
  }

  const onClickLink = () => {
    if (disable)
      return

    setDisable(true)
    switch (serviceCurrent?.name) {
      case 'ADX_MAIL':
        getADXMailUrl().then(result => {
          window.open(result.url, '_blank');
        })
        break
      case 'ADX_CONNECT':
        getAConnectUrl().then(result => {
          window.open(result.url, '_blank');
        })
        break
      default:
        if (serviceCurrent)
          window.open(serviceCurrent?.link, '_blank');
        break
    }

    setDisable(false)
  }

  if (! activeService) {
    return <></>
  }

  return <>
    <div className={styles.header}>
      {mobileView && <IconButton
        className={styles.backButton}
        onClick={handleBack}
      >
        <i className={'chat-arrow-l'} />
      </IconButton>}
      <img className={styles.avatar} src={serviceCurrent?.icon} alt="logo"/>
      <div className={styles.infoBlock}>
        <div className={styles.title}>{formatMessage({id: `SERVICES.${serviceCurrent?.name}`})}</div>
        <div className={styles.description}>{serviceCurrent?.displayLink}</div>
      </div>
    </div>
    <div className={styles.info}>
      <img src={serviceCurrent?.icon} alt={formatMessage({id: `SERVICES.${serviceCurrent?.name}`})} />
      <h4>{formatMessage({id: `SERVICES.${serviceCurrent?.name}`})}</h4>
      <p className={styles.sendButton} onClick={onClickLink}>
        {formatMessage(
          {id: `SERVICES.GO_TO_LINK`},
          {link: formatMessage({id: `SERVICES.${serviceCurrent?.name}`})
          })}
      </p>
    </div>
  </>
}

export default ServiceInfo
