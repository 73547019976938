import { useLottie } from "lottie-react";
import animation from "./app-preloader.json";
import styles from "./Preloader.module.scss"
import classNames from 'classnames';

interface PreloaderProps {
  className?: string
}
const Preloader = ({className}: PreloaderProps) => {
  const options = {
    animationData: animation,
    loop: true,
    autoplay: true,
    className: styles.loader,
  };

  const { View } = useLottie(options);

  return <div className={classNames(styles.container, className)}>{View}</div>;
}

export default Preloader
