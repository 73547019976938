import { partial } from 'filesize'

export const getFileExtension = (name: string) => {
  const parts = name.split('.');
  return parts[parts.length - 1] || '';
}

const MimeTypesMap: Record<string, string> = {
  png: 'image/png',
  gif: 'image/gif',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  mp4: 'video/mp4',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export const getMimeType = (ext: string): string | undefined => {
  return MimeTypesMap[ext]
}

export const isAudioFile = (file: File) => {
  return ['audio/mpeg', 'audio/wav'].includes(file.type)
}

export const isVideoFile = (file: File) => {
  return ['video/mp4'].includes(file.type)
}
export const formatFileSize = partial({
  standard: "jedec",
  base: 2
})
