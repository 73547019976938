import {useCallback, useEffect} from 'react';
import MUChat, {IGetMembersProps} from '@/services/MUChat';
import {
  Affiliation,
  Chat,
  ChatList,
  getActiveChatId,
  getChat,
  getMUChats,
  isMuChatsLoaded,
  setMUChats,
  setMUChatsLoaded,
  updateMembers,
} from '@/store/chats/chats';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {AnyObject} from '@/index';
import JXON from 'jxon';
import {v4 as uuid} from 'uuid'
import {Stanza} from '@/interfaces/XMPP';
import {getUser} from '@/store/user/user';
import connection from '@/services/Connection/Connection';
import useActiveService from '@/hooks/useActiveService'

interface IAffiliationMember {
  $jid: string,
  $affiliation: Affiliation
}

export const useMUChatList = ({watch = false} = {}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const muChats = useAppSelector(getMUChats)
  const currentChatJid = useAppSelector(getActiveChatId)
  const currentChat = useAppSelector(getChat(currentChatJid || ''))
  const muChatsLoaded = useAppSelector(isMuChatsLoaded)
  const {setActiveChatId} = useActiveService()

  const chatMembersUpdated = useCallback((stanza: Element) => {
    const {iq} = JXON.stringToJs(stanza.outerHTML) as AnyObject
    if (!iq?.query?.item || !iq?.$from) {
      return true
    }
    const items: IAffiliationMember[] = Array.isArray(iq.query.item) ? iq.query.item : [iq.query.item]
    dispatch(updateMembers(
      {
        chatJid: '' + iq.$from,
        members: items.map((item: IAffiliationMember) => ({jid: item.$jid, affiliation: item.$affiliation}))
      }))
    return true
  }, [dispatch])

  const listUpdated = useCallback((stanza: Stanza) => {
    const list: ChatList = {...muChats};
    const msg: AnyObject = JXON.stringToJs(stanza.outerHTML)
    const items = Array.isArray(msg.iq.query.item) ? msg.iq.query.item : [msg.iq.query.item]

    if (!muChatsLoaded) {
      const chatSet = new Set(items.map((item: any) => item?.$jid))
      if (currentChat?.type === 'groupchat' && !chatSet.has(currentChat.$jid)) {
        setActiveChatId(null)
      }
      Object.keys(list).forEach(key => {
        if (!chatSet.has(key)) {
          delete list[key]
        }
      })
    }

    items.forEach((item: Chat) => {
      if (!item) {
        return
      }
      const thread = uuid()
      list[item.$jid] = {
        ...list[item.$jid],
        ...item,
        thread,
        type: 'groupchat',
      }
    })
    dispatch(setMUChats(list))
    dispatch(setMUChatsLoaded('loaded'))
    return true
  }, [dispatch, muChats, muChatsLoaded, currentChat, setActiveChatId])

  useEffect(() => {
    if (!watch || !connection) {
      return
    }
    const refHandler: Symbol[] = []
    refHandler.push(connection.addHandler(listUpdated, 'http://jabber.org/protocol/disco#items', ''))
    refHandler.push(connection.addHandler(chatMembersUpdated, 'http://jabber.org/protocol/muc#admin', 'iq'))
    return () => {
      refHandler.forEach(handler => {
        connection?.deleteHandler(handler)
      })
    }
  }, [listUpdated, chatMembersUpdated, watch])

  const update = useCallback((id: string, force = true) => {
    const msg = MUChat.Messages.getList(id)
    if (connection.isConnected) {
      if (force) {
        dispatch(setMUChatsLoaded('loading'))
      }
      connection.send(msg)
    }
  }, [dispatch])

  const getMembers = useCallback((chatJid: string) => {
    if (!connection.isConnected) {
      return Promise.reject()
    }
    const promises: Promise<any>[] = []
    const options: IGetMembersProps = {
      from: user?.$jid || '',
      chatJid,
      affiliation: 'member',
    }
    const affiliations: Affiliation[] = ['member', 'admin', 'owner']
    affiliations.forEach(item => {
      options.affiliation = item
      const msg = MUChat.Messages.getMembers(options)
      promises.push(connection.send(msg))
    })
    return Promise.all(promises)
  }, [user?.$jid])

  return {
    update,
    getMembers,
  }
}
