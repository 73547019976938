import {BaseModalProps} from "@/components/Modal/BaseModal/BaseModal";
import BaseModalWithTitle from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import RadioButtons, {RadioButtonItem} from "@/components/Primitive/RadioButtons/RadioButtons";
import stylesRadioButtons from "@/components/Modal/GroupChatType/GroupChatType.module.scss";
import {Chat, IGroupChatMember, isTypePeriod, updateChat} from "@/store/chats/chats";
import {useAppDispatch, useAppSelector} from "@/hooks/appHook";
import React, {useRef, useState} from "react";
import {setAutoDeleteMessages} from "@/api/auto-delete";
import styles from "./AutoDeleteMessages.module.scss"
import {FormattedMessage, useIntl} from "react-intl";
import {checkAdmin, checkOwner} from "@/utils/chats";
import {getUser} from "@/store/user/user";
import {getOrCreateArray} from "@/utils/array";
import GetAutoDeleteMessage from "@/components/Modal/AutoDeleteMessages/GetAutoDeleteMessage";

interface AutoDeleteMessagesProps extends BaseModalProps {
  chat?: Chat,
}

enum RADIO_BUTTON_ACTIONS {
  DISABLE = 'disable',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

const radioButtonList: RadioButtonItem[] = [
  {action: RADIO_BUTTON_ACTIONS.DISABLE, title: 'DISABLED'},
  {action: RADIO_BUTTON_ACTIONS.DAY, title: 'DAY'},
  {action: RADIO_BUTTON_ACTIONS.WEEK, title: 'WEEK'},
  {action: RADIO_BUTTON_ACTIONS.MONTH, title: 'MONTH'},
]

const AutoDeleteMessages = ({hide, chat}: AutoDeleteMessagesProps) => {
  const user = useAppSelector(getUser)
  let periodTypeDefault = 'disable'
  if (chat?.autoDeleteMessages) {
    periodTypeDefault = chat?.autoDeleteMessages?.typePeriod || 'disable'
  }
  const [periodType, setPeriodType] = useState(periodTypeDefault)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const previousPeriodType = useRef("")
  const [messageError, setMessageError] = useState("")
  const {formatMessage} = useIntl()

  const changeAutoDelete = (action: string) => {
    if (periodType === previousPeriodType.current) {
      previousPeriodType.current = ""
      return
    }
    if (action === periodType || isLoading || !chat?.$jid || !chat?.type) {
      return
    }
    if (isTypePeriod(action)) {
      previousPeriodType.current = periodType
      setPeriodType(action)
      setIsLoading(true)
      setAutoDeleteMessages({period: 1, typePeriod: action, jid: chat.$jid, kind: chat.type}
      ).then(() => {
        dispatch(updateChat({
          chatJid: chat.$jid || '',
          options: {
            autoDeleteMessages: {
              period: 1,
              typePeriod: action,
              mine: true,
              visible: true,
            }
          },
        }))
        setIsLoading(false)
      }).catch((err) => {
        setPeriodType(previousPeriodType.current)
        setMessageError(err.response.data.code)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }

  const checkOwnerOrAdmin = () => {
    return checkOwner(user?.$jid || '', getOrCreateArray<IGroupChatMember>(chat?.members)) ||
      checkAdmin(user?.$jid || '', getOrCreateArray<IGroupChatMember>(chat?.members))
  }

  if (!chat || !user) {
    return <></>
  }

  return <BaseModalWithTitle
    hide={hide}
    title={formatMessage({id: 'AUTO_DElETE_MESSAGES.TITLE'})}
  >
    { messageError && <div className={styles.error}>
      <FormattedMessage id="AUTO_DElETE_MESSAGES.LIMIT_UPDATE"></FormattedMessage>
    </div> }
    {
      (chat?.type === 'chat' || (chat?.type === 'groupchat' && checkOwnerOrAdmin())) ?
      <>
        <div className={styles.description}>
          <FormattedMessage id="AUTO_DELETE_MESSAGES.DESCRIPTION"></FormattedMessage>
        </div>
        <RadioButtons
          className={stylesRadioButtons.radioButtons}
          list={radioButtonList.map(item => ({
            ...item,
            title: formatMessage({id: 'AUTO_DELETE_MESSAGES.'+item.title}, {val: 1}),
          }))}
          value={periodType || 'disable'}
          disable={isLoading}
          onChange={changeAutoDelete}
        />
      </> : GetAutoDeleteMessage(chat)
    }
  </BaseModalWithTitle>
}

export default AutoDeleteMessages
