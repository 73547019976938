import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import { INewsChat } from '@/utils/chats'
import { ShowedMessage } from '@/store/messages/messages'
import newsAvatar from './images/announcements.png'

export const CHAT_NAME = 'announcements'

export interface INews {
  id: string
  announcementId: number
  description: string
  languageId: number
  publishedAt: string
  timestamp: number
  title: string
  viewed: boolean
  visibleTo: number
  type: 'news'
}

export const isNews = (message: ShowedMessage | INews): message is INews => {
  return 'announcementId' in message
}

const createNewsChat = (news: INews[], name: string): INewsChat => {
  return {
    id: CHAT_NAME,
    news,
    name,
    avatar: newsAvatar
  }
}

const initialState: {
  news: INews[]
  active: string | null
} = {
  news: [],
  active: null
}

const news = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews(state, { payload }: { payload: INews[] }) {
      state.news = payload
    },
    setActiveNews(state, { payload }: { payload: string | null }) {
      state.active = payload
    },
    changeNewsViewed(state, { payload }: { payload: {newsId: number, viewed?: boolean} }) {
      const {newsId, viewed = true} = payload
      const news = state.news.find(news => news.announcementId === newsId)
      if (news) {
        news.viewed = viewed
      }
    }
  }
})

export const {
  setNews,
  setActiveNews,
  changeNewsViewed
} = news.actions
export const getNews = (state: RootState): INews[] => state.news.news
export const getActiveNews = (state: RootState): string | null => state.news.active
export const isActiveNews = (state: RootState): boolean => !!state.news.active
export const getUnreadNews = (state: RootState): number =>
  state.news.news.reduce((sum, news) => sum + +!news.viewed, 0)
export const getNewsChat = createSelector(
  getNews,
  (news) => createNewsChat(news, CHAT_NAME)
)
export const getActiveNewsChat = createSelector(
  [isActiveNews, getNews],
  (isActive, news) => isActive ? createNewsChat(news, CHAT_NAME) : null
)

export default news.reducer
