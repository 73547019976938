import React from "react";
import classNames from "classnames";
import styles from './VoiseBar.module.scss'

const heights = [4, 14, 20, 18, 24, 14, 4, 4, 4, 10, 6, 24, 10, 8, 4, 10, 12, 4, 4, 4, 14,
  18, 10, 12, 14, 16, 4, 4, 4, 12, 12, 18, 14, 22, 10, 10, 6, 10, 4];
const barHeight = 24;

interface VoiceBarProps {
  value: number
}

const VoiceBar = React.memo(({value}: VoiceBarProps) => {
  return (
    <svg
      className={styles.voiceBar}
      width="100%"
      height={barHeight}
      viewBox={`0 0 154 ${barHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {
        heights.map((h, i) =>
          <line
            key={"line_" + i}
            x1={(i * 4) + 1}
            x2={(i * 4) + 1}
            y1={(barHeight / 2) - ((h - 2) / 2)}
            y2={(barHeight / 2) + ((h - 2) / 2)}
            className={classNames(i < ((value / 100) * heights.length) && styles.trackBefore)}
            stroke="black"
            strokeWidth={2}
            strokeLinecap="round"
          />
        )
      }
    </svg>
  )
})

export default VoiceBar;
