import DefaultAvatar from '@/images/icons/avatar.svg'
import styles from './Avatar.module.scss'
import classNames from 'classnames'
import { CSSProperties, ImgHTMLAttributes, useEffect, useState } from 'react';
import {getFirstChar} from '@/utils/text';

interface AvatarProps extends ImgHTMLAttributes<HTMLImageElement>{
  src?: string,
  className?: string
  name?: string
  size?: 'medium' | 'small' | 'big' | number
}

const Avatar = ({src = DefaultAvatar, size, name, className, ...props}: AvatarProps) => {
  const [avatarSrc, setAvatarSrc] = useState(src || DefaultAvatar)

  useEffect(() => {
    setAvatarSrc(src || DefaultAvatar)
  }, [src])

  const style: CSSProperties = {
    width: typeof size === 'number' ? size : undefined,
    height: typeof size === 'number' ? size : undefined
  }

  if (avatarSrc === DefaultAvatar && name) {
    return <div
      style={style}
      className={classNames(
      styles.textBox,
      typeof size === 'string' && styles[size],
      `userColor${name.toUpperCase().charCodeAt(0) % 5 + 1}`,
      className
    )}>
      <span>{getFirstChar(name).toUpperCase()}</span>
    </div>
  }

  return <img
    {...props}
    style={style}
    className={classNames(
      styles.avatar,
      typeof size === 'string' && styles[size],
      className
    )}
    src={avatarSrc}
    alt="avatar"
    onError={() => {setAvatarSrc(DefaultAvatar)}}
  />
}

export default Avatar
