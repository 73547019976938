import {v4 as uuid} from 'uuid';
import {useCallback, useEffect, useRef} from 'react';
import connection from '@/services/Connection/Connection';


type ResolveFunc = (value?: unknown) => void
type RejectFunc = (reason?: string) => void

interface QueueItem {
  message: Element,
  resolve: ResolveFunc
  reject: RejectFunc
  timeout?: number
  immediately?: boolean
  watchBy?: string
}

const GENERAL_QUEUE = uuid()
const DEFAULT_LIMIT = 10

const queue: {
  [key: string]: {
    list: QueueItem[]
    runningRequest: number
    limit: number
    minInterval: number
    lastSend: number
    timer?: NodeJS.Timeout
    activePromises: Map<string, Promise<unknown>>
  }
} = {}

const emptyFunc = () => {
}

interface SendProps {
  message: Element,
  timeout?: number,
  immediately?: boolean
  watchBy?: string
}

const createQueueIfNo = (name: string) => {
  if (!queue[name]) {
    queue[name] = {
      list: [],
      runningRequest: 0,
      limit: DEFAULT_LIMIT,
      minInterval: 0,
      lastSend: 0,
      activePromises: new Map(),
    }
  }
}

interface QueueSenderProps {
  queueName?: string
  timeout?: number
  limit?: number
  minInterval?: number
}

const useQueueSender = ({queueName = GENERAL_QUEUE, timeout, limit, minInterval}: QueueSenderProps) => {
  const queueNameRef = useRef(queueName)
  const timeoutRef = useRef(timeout)

  useEffect(() => {
    timeoutRef.current = timeout
  }, [timeout]);

  useEffect(() => {
    queueNameRef.current = queueName
    createQueueIfNo(queueName)
    if (limit) {
      queue[queueName].limit = limit
    }
  }, [queueName, limit]);

  useEffect(() => {
    createQueueIfNo(queueName)
    queue[queueName].minInterval = minInterval || 0
  }, [minInterval, queueName]);

  const processQueue = useCallback(() => {
    const queueName = queueNameRef.current
    const list = queue[queueName]
    if (list.limit <= queue[queueName].runningRequest || list.list.length === 0) {
      return
    }
    const now = performance.now()
    if (list.lastSend !== 0 && now - list.lastSend < list.minInterval) {
      if (!list.timer) {
        const time = list.minInterval - now + list.lastSend
        list.timer = setTimeout(() => {
          list.timer = undefined
          processQueue()
        }, time > list.minInterval ? list.minInterval : time)
      }
      return;
    }
    clearTimeout(list.timer)
    list.timer = undefined
    const immediatelyItemIndex = list.list.findIndex(item => item.immediately)
    const immediatelyItem = ~immediatelyItemIndex ?
      list.list.splice(immediatelyItemIndex, 1)[0] : undefined
    const item = immediatelyItem || list.list.shift()
    if (!item) {
      processQueue()
      return;
    }
    list.runningRequest++
    list.lastSend = performance.now()
    connection.send(item.message, item.timeout)
      .then(() => {
        item.resolve()
      })
      .catch(e => {
        item.reject(e)
      })
      .finally(() => {
        if (item.watchBy) {
          list.activePromises.delete(item.watchBy)
        }
        list.runningRequest--
        processQueue()
      })
    processQueue()
  }, [])

  const send = useCallback(({message, timeout: time, immediately, watchBy}: SendProps) => {
    createQueueIfNo(queueName)
    const list = queue[queueName]
    let promise = (watchBy != null) && list.activePromises.get(watchBy)
    if (promise) {
      return promise
    }
    let resolve: ResolveFunc = emptyFunc
    let reject: RejectFunc = emptyFunc
    promise = new Promise((res, rej) => {
      resolve = res
      reject = rej
    })
    queue[queueName].list.push({
      message,
      resolve,
      reject,
      timeout: time || timeout,
      watchBy,
      immediately,
    })
    if (watchBy) {
      list.activePromises.set(watchBy, promise)
    }
    processQueue()
    return promise
  }, [queueName, timeout, processQueue])

  const clear = useCallback(() => {
    const list = queue[queueName]
    if (!list) {
      return
    }
    clearTimeout(list.timer)
    list.timer = undefined
    list.list.forEach(item => {
      item.reject('clear')
    })
  }, [queueName])

  return {
    send,
    clear,
  }
}

export default useQueueSender
