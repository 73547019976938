import styles from './SupportTicketHeader.module.scss'
import { ISupportTicket } from '@/store/supportTickets/supportTickets'
import { IClass } from '@/interfaces/general'
import classNames from 'classnames'
import SupportTicketStatusComponent
  from '@/components/SupportTicket/SupportTicketStatusComponent/SupportTicketStatusComponent'
import { FormattedMessage } from 'react-intl'
import { LangList } from '@/constants/languages'
import { getFullDateFormat } from '@/utils/dateutils'

interface SupportTicketHeaderProps extends IClass{
    ticket?: ISupportTicket
}

const SupportTicketHeader = (
  {
    className,
    ticket
  }: SupportTicketHeaderProps) => {
  if (!ticket) {
    return null
  }

  return <div className={classNames(styles.box, className)}>
    <SupportTicketStatusComponent
      className={styles.status}
      status={ticket.status}
    />
    <p className={styles.title}>{ticket.title}</p>
    <div className={styles.options}>
      <p className={styles.option}>
        <FormattedMessage id="language">
          {txt => <span className={styles.optionName}>{txt}: </span>}
        </FormattedMessage>
        {LangList[ticket.language]}
      </p>
      {ticket.lastReplier && <p className={styles.option}>
        <FormattedMessage id="last_update">
          {txt => <span className={styles.optionName}>{txt}: </span>}
        </FormattedMessage>
        {getFullDateFormat(ticket.lastReplier.date)}
      </p>}
      <p className={styles.option}>
        <FormattedMessage id="created_on">
          {txt => <span className={styles.optionName}>{txt}: </span>}
        </FormattedMessage>
        {getFullDateFormat(ticket.created)}
      </p>
    </div>
  </div>
}

export default SupportTicketHeader
