import {ElementType} from 'react';
import {IMessageDate, ShowedMessage} from '@/store/messages/messages';
import styles from "./DateMessage.module.scss";
import dayjs from "dayjs";
import { INews } from '@/store/news/news'

export const isDateMessage = (msg: ShowedMessage | IMessageDate | INews): msg is IMessageDate => {
  return 'date' in msg
}

interface DateMessageProps extends IMessageDate {
  TagName?: ElementType
}

const DateMessage = ({date, TagName = 'div'}: DateMessageProps) => {
  const dateFormat = dayjs(date);

  return <TagName className={styles.divider}>{dateFormat.format('L')}</TagName>
}

export default DateMessage;


