import { useAppDispatch } from '@/hooks/appHook'
import { useCallback, useMemo } from 'react'
import { setActiveService } from '@/store/services/services'
import { setActiveChatId as setActiveChat } from '@/store/chats/chats'
import { setActiveNews } from '@/store/news/news'

const useActiveService = () => {
  const dispatch = useAppDispatch()
  const SERVICES = useMemo(() => [setActiveService, setActiveChat, setActiveNews], [])

  const disableServices = useCallback((services: typeof SERVICES) => {
    services.forEach(s => {
      dispatch(s(null))
    })
  }, [dispatch])

  const setActiveServiceId = useCallback((id: string | null) => {
    dispatch(setActiveService(id))
    disableServices(SERVICES.filter(s => s !== setActiveService))
  }, [disableServices, dispatch, SERVICES])

  const setActiveChatId = useCallback((id: string | null) => {
    dispatch(setActiveChat(id))
    const services = SERVICES.filter(s => s !== setActiveChat)
    disableServices(services)
  }, [disableServices, dispatch, SERVICES])

  const setAnnouncementActive = useCallback((name: string | null) => {
    dispatch(setActiveNews(name))
    disableServices(SERVICES.filter(s => s !== setActiveNews))
  }, [disableServices, dispatch, SERVICES])

  return {
    setActiveChatId,
    setActiveService: setActiveServiceId,
    setActiveNews: setAnnouncementActive
  }
}

export default useActiveService
