import 'strophe.js'
import {createStropheFromObject} from '@/services/utils';
import {v4 as uuid} from 'uuid'
import {IMessageJSON, IMessageType, IMessageTypes} from '@/interfaces/XMPP';
import JXON from 'jxon';
import {AnyObject} from '@/index';
import {
  CbList,
  ConnectionEventMap,
  ConnectionEventNames, IMessagePromise,
  IMessagePromises,
  IPromiseObj,
  PingProps,
} from './IConnection';
import ConnectionHandler from '@/services/Connection/ConnectionHandler';
import {getConnectionStatus} from '@/services/Connection/utils';

const MAX_TIMEOUT = 5000

const msgValidate = (msg: AnyObject) => {
  const key = Object.keys(msg)[0]
  if (!(key in IMessageTypes)) {
    return 'wrong message'
  }
  if (!msg[key].$id) {
    return 'missing id'
  }
  return 'ok'
}

const checkMessageJSON = (msg: AnyObject): msg is IMessageJSON => {
  return msgValidate(msg) === 'ok'
}

const promises: IMessagePromises = {}

const addPromise = ({key, uid, promise}: {
  key: IMessageType,
  uid: string,
  promise: IMessagePromise
}) => {
  if (!(key in promises)) {
    promises[key] = {}
  }
  promises[key]![uid] = promise
}

const removePromise = ({key, uid}: {
  key: IMessageType,
  uid: string,
}) => {
  delete promises[key]?.[uid]
}

const handleMessage = (message: IMessageJSON) => {
  if (msgValidate(message) === 'ok') {
    const key = Object.keys(message)[0] as IMessageType
    const uid = message[key].$id
    const promise = promises[key]?.[uid]
    if (promise) {
      promise.resolve(message)
      clearTimeout(promise.timer)
      delete promises[key]?.[uid]
    }
  }
}

class Connection {
  private _socketId = 0
  private _conn: Strophe.Connection | null
  private _cbList: CbList
  private _connectPromise: IPromiseObj | null = null
  private _isConnected = false
  private _isDisconnecting = false
  private _status = Strophe.Status.DISCONNECTED
  private _lastStatus: Strophe.Status = Strophe.Status.DISCONNECTED
  private _handlers: Map<Symbol, {
    stropheHandler: Strophe.Handler | undefined,
    connectionHandler: ConnectionHandler
  }>

  get isConnected() {
    return this._isConnected
  }

  get Status() {
    return this._status
  }

  get StatusName() {
    return getConnectionStatus(this._status)
  }

  constructor() {
    this._conn = null
    this._cbList = Object.values(ConnectionEventNames)
      .reduce((acc: Record<string, any[]>, key) => {
        acc[key] = []
        return acc
      }, {}) as CbList
    this._handlers = new Map()
  }

  Messages = {
    ping({from}: PingProps) {
      return createStropheFromObject('iq', {
        $id: uuid(),
        $from: from,
        $to: process.env.REACT_APP_EJ_HOST,
        $type: 'get',
        ping: {
          $xmlns: 'urn:xmpp:ping',
        },
      }).tree()
    },
  }

  addEventListener<K extends keyof ConnectionEventMap>(
    type: K,
    listener: ConnectionEventMap[K],
  ) {
    this._cbList[type].push(listener)
  }

  removeEventListener<K extends keyof ConnectionEventMap>(
    type: K,
    listener?: ConnectionEventMap[K],
  ) {
    if (!listener) {
      this._cbList[type] = []
      return
    }
    const index = this._cbList[type].findIndex(l => l === listener)
    if (~index) {
      this._cbList[type].splice(index, 1)
    }
  }

  addHandler(handler: (stanza: Element) => boolean,
             ns: string,
             name: string,
             type?: string | string[],
             id?: string,
             from?: string,
             options?: { matchBareFromJid: boolean, ignoreNamespaceFragment: boolean }): Symbol {
    const handlerId: Symbol = Symbol('ConnectionHandler')
    const self = this
    const newHandler = (stanza: Element) => {
      const result = handler(stanza)
      if (!result) {
        self.deleteHandler(handlerId)
      }
      return result
    }
    const stropheHandler: Strophe.Handler | undefined =
      this._conn?.addHandler(newHandler, ns, name, type, id, from, options)
    const connectionHandler: ConnectionHandler =
      new ConnectionHandler(newHandler, ns, name, type, id, from, options)

    this._handlers.set(
      handlerId,
      {
        connectionHandler,
        stropheHandler,
      },
    )
    return handlerId
  }

  deleteHandler(handlerId: Symbol) {
    const handRef = this._handlers.get(handlerId)
    this._handlers.delete(handlerId)
    if (handRef?.stropheHandler) {
      this._conn?.deleteHandler(handRef.stropheHandler)
    }
  }

  private updateConnectedCb(status: Strophe.Status, socketId: number) {
    if (this._socketId !== socketId || status === this._lastStatus) {
      return
    }
    this._lastStatus = status
    this._status = status
    switch (status) {
      case Strophe.Status.CONNECTED:
        this._connectPromise?.resolve()
        this._connectPromise = null
        this._isConnected = true
        this._cbList[ConnectionEventNames.Connected].forEach(listener => listener())
        this._cbList[ConnectionEventNames.ConnectedChanged].forEach(listener => listener(true))
        break;
      case Strophe.Status.DISCONNECTED:
        this._connectPromise?.reject()
        this._connectPromise = null
        this._conn?.reset()
        this._conn = null
        this._isDisconnecting = false
        break
    }

    if (this._isConnected && status !== Strophe.Status.CONNECTED) {
      this._isConnected = false
      this._cbList[ConnectionEventNames.Disconnected].forEach(listener => listener())
      this._cbList[ConnectionEventNames.ConnectedChanged].forEach(listener => listener(false))
    }
    this._cbList[ConnectionEventNames.StatusChanged].forEach(listener => listener(status))
  }

  private addOldHandlers(conn: Strophe.Connection) {
    const handlers = this._handlers.entries()
    for (const handler of handlers) {
      const handlerId = handler[0]
      const {connectionHandler} = handler[1]
      const stropheHandler = conn.addHandler(
        connectionHandler.handler,
        connectionHandler.ns,
        connectionHandler.name,
        connectionHandler.type,
        connectionHandler.id,
        connectionHandler.from,
        connectionHandler.options,
      )
      this._handlers.set(handlerId, {
        connectionHandler,
        stropheHandler,
      })
    }
  }

  private messageHandler(stanza: Element) {
    const message = JXON.stringToJs(stanza.outerHTML) as IMessageJSON
    console.log('general handler: ', message) // eslint-disable-line no-console
    handleMessage(message)
    return true
  }

  connect(jid: string, pass: string) {
    if (this._conn?.connected) {
      return Promise.resolve()
    }
    if (this._conn) {
      return Promise.reject()
    }
    const conn = new Strophe.Connection(process.env.REACT_APP_YC_SERVER || '', {})
    conn.addHandler(this.messageHandler.bind(this), '', '')
    this.addOldHandlers(conn)
    this._socketId++
    const id = this._socketId
    const update = (status: Strophe.Status) => {
      this.updateConnectedCb(status, id)
    }
    conn.connect(jid, pass, update.bind(this))
    this._conn = conn
    return new Promise((resolve, reject) => {
      this._connectPromise = {
        resolve,
        reject,
      }
    })
  }

  disconnect() {
    if (!this._conn || this._isDisconnecting) {
      return
    }
    this._isDisconnecting = true
    this._conn?.disconnect('')
  }

  private addPromiseMessage({msg, resolve, reject, timeout = MAX_TIMEOUT}: {
      msg: IMessageJSON,
      resolve: (value: (IMessageJSON | PromiseLike<IMessageJSON>)) => void,
      reject:  (reason?: any) => void,
      timeout?: number
    },
  ) {
    const key = Object.keys(msg)[0] as IMessageType
    const uid = msg[key].$id
    const timer = setTimeout(() => {
      reject(`timeout: ${timeout / 1000} s, type: ${key}, uid: ${uid}`)
      removePromise({key, uid})
    }, timeout)
    const syncMessage = {
      resolve,
      reject,
      timer,
    }
    addPromise({
      key,
      uid,
      promise: syncMessage,
    })
  }

  send(msg: Element, timeout = MAX_TIMEOUT): Promise<IMessageJSON> {
    if (!this._conn?.connected) {
      return Promise.reject('disconnected')
    }
    const conn = this._conn
    const msgObj: AnyObject = JXON.stringToJs(msg.outerHTML)

    if (!checkMessageJSON(msgObj)) {
      return Promise.reject(msgValidate(msgObj))
    }

    return new Promise((resolve, reject) => {
      if (!conn.connected) {
        reject('disconnected')
        return
      }
      this.addPromiseMessage({
        msg: msgObj,
        resolve,
        reject,
        timeout
      })
      conn.send(msg)
    })
  }

  sendStrophe(msg: Element | Strophe.Builder) {
    this._conn?.send(msg)
    return !!this._conn
  }

}

const connection = new Connection()
export default connection
