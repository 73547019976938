import styles from './SupportTicketMessage.module.scss'
import { ISupportTicketMessage } from '@/store/supportTickets/supportTickets'
import { IClass } from '@/interfaces/general'
import classNames from 'classnames'
import { ElementType } from 'react'
import Avatar from '@/components/Avatar/Avatar'
import supportImage from '@/images/icons/support.svg'
import { getFullDateFormat } from '@/utils/dateutils'
import SupportTicketDocPreview from '@/components/SupportTicket/SupportTicketDocPreview/SupportTicketDocPreview'

interface SupportTicketMessageProps extends IClass {
  message?: ISupportTicketMessage | null
  TagName?: ElementType
}

const SupportTicketMessage = (
  {
    className,
    TagName = 'div',
    message
  }: SupportTicketMessageProps) => {
  if (!message) {
    return null
  }
  const incoming = !!message.replierName
  return <TagName className={classNames(
    styles.box,
    className
  )}>
    {incoming && <div className={styles.avatarBox}>
      <Avatar
        className={styles.avatar}
        src={supportImage}
      />
      <span className={styles.replierName}>{message.replierName}</span>
    </div>}
    <div className={classNames(
      styles.message,
      incoming && styles.incoming,
    )}>
      <span>{message.message}</span>
      <span className={styles.date}>{getFullDateFormat(message.date)}</span>
    </div>
    {!!message.files?.length && <ul
      className={styles.imageList}
    >
      {message.files.map((file, index) => <li
        className={styles.imageItem}
        key={file}
      >
        <a
          href={file}
          target="_blank"
          rel="noreferrer"
        >
          <SupportTicketDocPreview
            className={styles.image}
            src={file}
          />
        </a>
      </li>)}
    </ul>}
  </TagName>
}

export default SupportTicketMessage
