import {CustomWindow} from '@/interfaces/general'

declare let window: CustomWindow

document.addEventListener('DOMContentLoaded', () => {
  if (window.Android) {
    window.Android.onLoaded()
  }

  if (window.webkit?.messageHandlers) {
    window.webkit.messageHandlers.youchatMessageHandler.postMessage('loaded')
  }
})
