export const changeSearchParams = (newSearchParams: URLSearchParams, add = false) => {
  const newUrl = window.location.origin + window.location.pathname + '?' + newSearchParams.toString();
  let method: 'replaceState' | 'pushState' = 'replaceState'
  if (add) {
    method = 'pushState'
  }
  window.history[method]({path: newUrl}, '', newUrl)
}

export const getQueryParams = () => {
  const searchParams = new URLSearchParams(document.location.search)
  const query = searchParams.toString()
  return query ? '?' + query : ''
}
